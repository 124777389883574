import React from 'react';
import useAuth from '../../hooks/useAuth';
import useValidator from '../../hooks/useValidator';
import IconTitle from '../common/IconTitle';
import MuiModal from '../common/MuiModal';
import MuiMultiStep, { MuiFormStep } from '../forms/MuiMultiStep';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import useAsyncState from '../../hooks/useAsyncState';
import { useDispatch } from 'react-redux';
import useSnackbar from '../../hooks/useSnackbar';
import { updateUser } from '../../reducers/authReducer';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import LocationSection from '../forms/LocationSection';

const LocationModal = ({ modalControl: givenModalControl }) => {
  const internalModalControl = useMuiModalControl();
  const modalControl = givenModalControl || internalModalControl;
  const { user } = useAuth();
  const validator = useValidator();
  const { location } = user || {};
  const locationLoadingState = useAsyncState(false);
  const { snackbar } = useSnackbar();
  const dispatch = useDispatch();

  const initialValues = {
    streetNumber: location?.lineOne || '',
    streetName: location?.lineTwo || '',
    postalCode: location?.postalCode || '',
    city: location?.city || '',
    province: location?.province || '',
    country: location?.country || '',
    countryShort: location?.countryShort || '',
  };
  const validationSchema = validator((schema) => ({
    ...schema.streetNumber(),
    ...schema.streetName(),
    ...schema.city(),
    ...schema.province(),
    ...schema.country(),
    ...schema.countryShort(),
  }));

  const handleSubmit = async (values) => {
    const location = {
      city: values.city,
      province: values.province,
      postal_code: values.postalCode,
      street_number: values.streetNumber,
      street_name: values.streetName,
      country: values.country,
      country_short: values.countryShort,
    };
    const newAddress = {
      location,
      billing_location_same_as_primary_location: true,
      billing_location: location,
    };

    try {
      await dispatch(updateUser(newAddress));
      snackbar({ message: 'profileUpdateSuccess' });
    } catch (_) {
      snackbar({ message: 'profileUpdateFailed' });
    }
    modalControl.handleClose();
  };

  const { style, field } = makeProps({ locationLoadingState });
  return (
    <MuiModal {...{ modalControl, ...style.modal }}>
      <MuiMultiStep
        {...{
          initialValues,
          validationSchema,
          onSubmit: handleSubmit,
          modalControl,
          ...style.form,
        }}
      >
        <MuiFormStep>
          <LocationSection {...field.locationSection} />
        </MuiFormStep>
      </MuiMultiStep>
    </MuiModal>
  );

  function makeProps({ locationLoadingState }) {
    return {
      style: {
        modal: {
          cardProps: {
            sx: {
              width: { xs: '100%', md: '38rem', lg: '40rem' },
            },
          },
        },
        form: {
          preventExit: true,
        },
        formStack: {
          direction: 'column',
          sx: {
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
            maxWidth: '40rem',
            mx: 'auto',
            py: '1rem',
          },
        },
        multiFields: {
          direction: { xs: 'column', md: 'row' },
          sx: {
            gap: '1rem',
            width: '100%',
            display: { xs: 'flex', md: 'grid' },
            gridTemplateColumns: { xs: 'unset', md: '1fr 1fr' },
            alignItems: { xs: 'center', md: 'flex-start' },
          },
        },
      },
      field: {
        locationSection: {
          title: (
            <IconTitle
              {...{
                title: {
                  id: 'progressBar.locationModal.title',
                  dm: 'Shipping Location',
                },
                titleProps: {},
                icon: <LocationOnOutlinedIcon {...{ fontSize: 'inherit' }} />,
              }}
            />
          ),
          stackProps: { sx: { pt: '0' } },
          names: [
            'streetNumber',
            'streetName',
            'postalCode',
            'city',
            'province',
            'country',
            'countryShort',
          ],
          loadingState: locationLoadingState,
        },
      },
    };
  }
};

export default LocationModal;

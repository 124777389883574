import React, { useContext, useCallback, useEffect } from 'react';
import useGetData from '../../hooks/useGetData';
import { useSocket } from './SocketProvider';
import useAsyncState from '../../hooks/useAsyncState';
import {
  threadArrayMapper,
  threadMapper,
  messageMapper,
  markHiddenThreads,
} from '../../utils/fetchMapper';
import tryClient from '../../utils/tryClient';
import useAuth from '../../hooks/useAuth';
import useSubscriptionPlan from '../../hooks/useSubscriptionPlan';
import { hasObjectWith } from '../../utils/arrayUtils';

export const MessageContext = React.createContext();

export const useMessage = () => useContext(MessageContext);

const debugMode = !true;

const MessageProvider = ({ children }) => {
  const { subscription, subscriptionLoading } = useSubscriptionPlan();
  const { limits } = subscription || {};
  const { maxOpenThreads } = limits || {};

  const { socket } = useSocket();
  const { user } = useAuth();
  const id = user?.id;
  const maxThreads = user?.roles !== '2' ? -1 : maxOpenThreads || 0;

  const [totalUnread, setTotalUnread] = useAsyncState(0);
  const [activeThread, setActiveThread] = useAsyncState('');
  const [loadingThread, setLoadingThread] = useAsyncState('');
  const [activeCount, setActiveCount] = useAsyncState(0);
  const {
    loading,
    data: contactList,
    setData: setContactList,
    refresh,
  } = useGetData({
    endpoint: id && !subscriptionLoading && '/conversation',
    dataHandler: useCallback(
      ({ data }) => {
        const [threadArray, newActiveCount] = markHiddenThreads(
          threadArrayMapper(data.data, id),
          maxThreads,
        );
        setActiveCount(newActiveCount);
        return threadArray;
      },

      [id, maxThreads, setActiveCount],
    ),
    ...{ dontClear: true, debugMode },
  });

  const { loading: singleThreadLoading } = useGetData({
    endpoint: id && loadingThread && `/conversation/${loadingThread}`,
    dataHandler: useCallback(
      ({ data }) =>
        setContactList((current) => {
          const [threadArray, newActiveCount] = markHiddenThreads(
            hasObjectWith(current, 'id', loadingThread)
              ? current.map((thread) =>
                  thread.id !== loadingThread ? thread : threadMapper(data, id),
                )
              : [...current, threadMapper(data, id)],
            maxThreads,
          );
          setActiveCount(newActiveCount);
          return threadArray;
        }),
      [id, setContactList, loadingThread, maxThreads, setActiveCount],
    ),
    debugMode,
  });

  useEffect(() => {
    if (loading || singleThreadLoading) return;
    setLoadingThread('');
  }, [loading, singleThreadLoading, setLoadingThread]);

  const markRead = useCallback(
    async (messageId, conversationId) => {
      const checkHidden = contactList.reduce(
        (isHidden, nextThread) =>
          isHidden || (nextThread.id === conversationId && nextThread.isHidden),
        false,
      );
      if (checkHidden) return;
      if (debugMode) console.log({ markRead: messageId });
      const { err } = await tryClient(`/messages/seen/${messageId}`, {
        method: 'PATCH',
      });
      if (err && debugMode) console.log({ err });
    },
    [contactList],
  );

  const receiveMessage = useCallback(
    (messageServerData) => {
      const receivedMessage = messageMapper(messageServerData);
      const {
        conversationId,
        senderId,
        messageId: receivedId,
        ...contentAndFlags
      } = receivedMessage;

      if (debugMode) console.log({ messageServerData, receivedMessage });

      const { isAutoGenerated, receiverId, isSeen } = receivedMessage;
      const conversation = contactList.find(({ id }) => id === conversationId);

      if (!conversation) return setLoadingThread(conversationId);

      if (!isAutoGenerated && conversation.status === 'closed')
        return setLoadingThread(conversationId);

      if (debugMode) console.log({ isAutoGenerated, receiverId, id, isSeen });

      if (isAutoGenerated && !isSeen && receiverId === id)
        return setLoadingThread(conversationId);

      if (activeThread === conversationId && senderId !== id && !isSeen) {
        markRead(receivedId, conversationId);
        receivedMessage.isSeen = true;
        contentAndFlags.isSeen = true;
      }

      setContactList((current) =>
        current.map((contact) => ({
          ...contact,
          ...(contact.id === conversationId && {
            messages: contact.messages
              .map(({ messageId }) => messageId)
              .includes(receivedId)
              ? contact.messages.map((message) => ({
                  ...message,
                  ...(message.messageId === receivedId && {
                    ...contentAndFlags,
                  }),
                }))
              : [receivedMessage, ...contact.messages],
          }),
        })),
      );
    },
    [contactList, setContactList, activeThread, id, markRead, setLoadingThread],
  );

  useEffect(() => {
    if (!contactList.length) setTotalUnread(0);
    else
      setTotalUnread(
        contactList
          .filter(({ isDeleted }) => !isDeleted)
          .map((contact) =>
            contact.messages
              .filter(({ isDeleted }) => !isDeleted)
              .map(({ receiverId, isSeen }) =>
                receiverId === id && !isSeen ? 1 : 0,
              )
              .reduce((sum, current) => sum + current, 0),
          )
          .reduce((sum, unread) => sum + unread, 0),
      );
  }, [contactList, id, setTotalUnread]);

  useEffect(() => {
    if (!socket) return;
    socket.on('message', receiveMessage);
    return () => socket.off('message', receiveMessage);
  }, [socket, receiveMessage]);

  return (
    <MessageContext.Provider
      {...{
        value: {
          loading: loading || subscriptionLoading,
          totalUnread,
          setTotalUnread,
          markRead,
          contactList,
          setContactList,
          receiveMessage,
          activeThread,
          setActiveThread,
          refresh,
          loadingThread,
          activeCount,
          initialLoading: loading,
        },
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;

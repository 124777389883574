import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { FaHome, AiOutlineBarChart, AiFillSetting } from 'react-icons/all';
import { useIntl } from 'react-intl';
import useAuth from '../../hooks/useAuth';

const Root = styled(Box)`
  background: #333;
  font-size: 1.5em;
  color: #ccc;
`;

const NavItemCompact = ({ icon, path, ariaLabel }) => {
  return (
    <Flex p={3} justify="center">
      <Link to={path} aria-label={ariaLabel}>
        {icon}
      </Link>
    </Flex>
  );
};

const CompactDrawer = () => {
  const { formatMessage } = useIntl();
  const { isAuthenticated, isAdmin, isModerator } = useAuth();

  return (
    <Root
      as="nav"
      display={['none', 'none', 'none', 'none', 'block']}
      position="fixed"
      h="calc(100vh - 48px)"
      w="55px"
    >
      <Stack spacing={8} mt={3}>
        <NavItemCompact
          icon={<FaHome />}
          path="/"
          ariaLabel={formatMessage({
            id: 'navigation.go.home',
            defaultMessage: 'Navigate to home',
          })}
        />
        {isAuthenticated && (
          <NavItemCompact
            icon={<AiOutlineBarChart />}
            path="/dashboard"
            ariaLabel={formatMessage({
              id: 'navigation.go.dashboard',
              defaultMessage: 'Navigate to user dashboard',
            })}
          />
        )}
        {(isAdmin || isModerator) && (
          <NavItemCompact
            icon={<AiFillSetting />}
            path="/admin"
            ariaLabel={formatMessage({
              id: 'navigation.go.admin',
              defaultMessage: 'Navigate to admin control panel',
            })}
          />
        )}
      </Stack>
    </Root>
  );
};

export default CompactDrawer;

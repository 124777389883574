import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MuiModalCloseButton = ({
  // one or the other required
  modalControl, // useMuiModalControl() output
  handleClose, // function to close modal
  icon, // replaces default icon, <IconComponent />
  iconProps,
  sxIcon,
  sx, // on the button
  ...rest // on the button
}) => {
  const onClick = (event) => {
    event.stopPropagation();
    if (modalControl?.handleClose) modalControl.handleClose();
    else handleClose();
  };

  const { button, defaultIcon } = makeProps({ sx, sxIcon });
  return (
    <IconButton {...{ onClick, ...button, ...rest }}>
      {icon || <CloseIcon {...{ ...defaultIcon, ...iconProps }} />}
    </IconButton>
  );

  function makeProps({ sx, sxIcon }) {
    return {
      button: { color: 'headingText', sx: { p: '0.25rem', ...sx } },
      defaultIcon: { sx: { ...sxIcon } },
    };
  }
};

export default MuiModalCloseButton;

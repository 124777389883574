import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import MultiStepNavigation from './MultiStepNavigation';
import PreventExit from './PreventExit';
// import { scrollToTop } from '../../utils/scrollToTop';
import useScrollToTop from '../../hooks/useScrollToTop';
// import useUrl from '../../hooks/useUrl';
import useNavSize from '../../hooks/useNavSize';
// import { Redirect, useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import MuiText from '../intl/MuiText';
import { CircularProgress } from '@mui/material';
import useBreakpoint from '../../hooks/useBreakpoint';
import useQueryState from '../../hooks/useQueryState';

const MultiStepForm = ({
  children,
  initialValues,
  onSubmit,
  termAcceptted,
  registing,
  isValidAddress,
  userType,
  validationSchema,
  secondaryButtons,
  setAcceptError = () => {},
  stepState: givenStepState,
}) => {
  const { md } = useBreakpoint();
  const internalStepState = useState(0);
  const [stepNumber, setStepNumber] = givenStepState || internalStepState;
  const [snapshot, setSnapshot] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const step = React.Children.toArray(children)[stepNumber];
  const totalSteps = React.Children.toArray(children).length;
  const isLastStep = stepNumber === totalSteps - 1;
  const { scrollToTop } = useScrollToTop();
  //   const { queries } = useUrl();
  //   const pageNumber = Number(queries?.page);
  //   const location = useLocation();
  //   const { pathname } = location;
  const formRef = useRef(null);
  const { headerRef, pageRef } = useNavSize();
  const navHeight = headerRef?.current?.clientHeight || 0;
  const [pageNumber, setPageNumber] = useQueryState('step', 0);

  useEffect(() => {
    setPageNumber(stepNumber + 1);
  }, [stepNumber, setPageNumber]);

  useEffect(() => {
    var currentFields = [];
    var step = React.Children.toArray(children)[stepNumber];
    function getFields({ props }) {
      if (props) {
        if (props.name && !currentFields.includes(props.name))
          currentFields.push(props.name);
        else {
          if (!props.children) return;

          if (Array.isArray(props.children)) {
            props.children.forEach((e) => {
              if (!e) return;
              if (typeof e === 'object') getFields(e);
            });
          } else {
            getFields(props.children);
          }
        }
      }
    }
    getFields(step);

    if (userType === 'individual') {
      let result = currentFields.filter(
        (e) =>
          ![
            'organization',
            'organizationType',
            'employmentSize',
            'website',
          ].includes(e),
      );
      setSnapshot(result);
    } else {
      setSnapshot(currentFields);
    }
  }, [userType, children, stepNumber, isValidAddress]);

  const totalOffset = (node, offset = 0) =>
    offset + (node ? node?.offsetTop + totalOffset(node?.offsetParent) : 0);

  const topError = ({ errors }) =>
    Object.keys(errors).reduce(
      (topError, fieldName, _, array) => {
        Array(array.length)
          .fill('')
          .forEach((_, index) => {
            const fieldElement = formRef?.current?.[index];
            if (fieldElement?.id !== fieldName || !fieldElement?.clientHeight)
              return;

            const fieldOffset = totalOffset(fieldElement);
            if (!topError?.errorOffset || topError?.errorOffset > fieldOffset) {
              topError.fieldName = fieldName;
              topError.errorOffset = fieldOffset;
            }
          });
        return topError;
      },
      { fieldName: '', errorOffset: null },
    );

  const checkIfHasError = (errors) =>
    snapshot.reduce((error, field) => error || errors[field], false);

  const next = (formik) => {
    snapshot.forEach((field) => formik.setFieldTouched(field));
    let error = checkIfHasError(formik.errors);
    if (error) {
      const { errorOffset } = topError(formik);
      const pageScrollPosition = pageRef?.current?.scrollTop || 0;
      const errorScrollPosition = errorOffset - navHeight - 32;
      if (pageScrollPosition > errorScrollPosition)
        scrollToTop({ behavior: 'smooth', top: errorScrollPosition });
      return;
    }
    scrollToTop();
    setStepNumber(stepNumber + 1);
  };

  const back = () => {
    scrollToTop();
    setStepNumber(stepNumber - 1);
  };

  const submit = async (formik) => {
    snapshot.forEach((field) => formik.setFieldTouched(field));
    let error = checkIfHasError(formik.errors);
    if (error || !termAcceptted) {
      const { errorOffset } = topError(formik);
      setAcceptError(!termAcceptted);
      if (errorOffset === null) return;
      const pageScrollPosition = pageRef?.current?.scrollTop || 0;
      const errorScrollPosition = errorOffset - navHeight - 32;
      if (pageScrollPosition > errorScrollPosition)
        scrollToTop({ behavior: 'smooth', top: errorScrollPosition });
      return;
    }
    setAcceptError(false);
    setSubmitting(true);
    await onSubmit(formik.values, formik);
    setSubmitting(false);
  };

  const text = {
    processing: {
      id: 'auth.signup.processing',
      dm: 'Tradvo is now processing your request.',
    },
  };

  const style = {
    processingBox: {
      flexDirection: md ? 'column-reverse' : 'row',
      alignItems: 'center',
      justifyContent: 'center',
      p: md ? '2rem 1rem' : '4rem 2rem',
    },
    processing: {
      sx: {
        textAlign: 'center',
        fontSize: '1.125rem',
      },
    },
    loadingCircle: {
      size: '24px',
      sx: { flexShrink: '0', [md ? 'mt' : 'mr']: '1rem' },
    },
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
        validateOnMount
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form {...{ ref: formRef }}>
            {/* {pageNumber !== stepNumber + 1 && (
              <Redirect to={pathname + `?page=${stepNumber + 1}`} />
            )} */}
            <PreventExit
              {...{
                isSubmitting: submitting,
                prevent: formik.dirty && !submitting,
                ignore: pageNumber !== stepNumber + 1,
              }}
            />
            {registing ? (
              <Flex {...style.processingBox}>
                <PreventExit {...{ prevent: registing }} />
                <CircularProgress {...style.loadingCircle} />
                <MuiText {...{ ...text.processing, ...style.processing }} />
              </Flex>
            ) : (
              step
            )}
            <MultiStepNavigation
              hasNext={stepNumber > 0}
              isLastStep={isLastStep}
              registing={registing}
              termAcceptted={termAcceptted}
              handleNext={() => next(formik)}
              handleBack={() => back()}
              handleSubmit={() => submit(formik)}
              secondaryButtons={secondaryButtons}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default MultiStepForm;

export const FormStep = ({ stepName, children }) => children;

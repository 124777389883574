import React from 'react';
import useMuiMenuControl from '../../hooks/useMuiMenuControl';
import MuiModal from '../common/MuiModal';
import NewInvitationForm from './NewInvitationForm';

const NewInvitationModal = ({ modalControl: givenModalControl }) => {
  const defaultModalControl = useMuiMenuControl();
  const modalControl = givenModalControl || defaultModalControl;
  const { style } = makeProps();
  return (
    <MuiModal {...{ modalControl, ...style.modal }}>
      <NewInvitationForm {...{ modalControl }} />
    </MuiModal>
  );

  function makeProps() {
    return {
      text: {},
      style: {
        modal: {
          cardProps: {
            sx: {
              width: '100%',
              maxWidth: { xs: '95vw', md: 'min(95vw, 50rem)' },
            },
          },
        },
      },
    };
  }
};

export default NewInvitationModal;

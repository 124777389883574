import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { ORGANIZATION_TYPES } from '../../data/constants';
import useAsyncState from '../../hooks/useAsyncState';
import useAuth from '../../hooks/useAuth';
import useLocale from '../../hooks/useLocale';
import useMessages from '../../hooks/useMessages';
import useRecaptcha from '../../hooks/useRecaptcha';
import useScrollToTop from '../../hooks/useScrollToTop';
import useSnackbar from '../../hooks/useSnackbar';
import useValidator from '../../hooks/useValidator';
import Flex from '../common/Flex';
import MuiLink from '../common/MuiLink';
import MuiLoading from '../common/MuiLoading';
import MuiCheckbox from '../forms/MuiCheckbox';
import MuiFormField from '../forms/MuiFormField';
import MuiMultiStep, { MuiFormStep } from '../forms/MuiMultiStep';
import MuiSelect from '../forms/MuiSelect';
import MuiText from '../intl/MuiText';
import { supplierMapper } from '../../utils/fetchMapper';
import useGetData from '../../hooks/useGetData';
import tryClient from '../../utils/tryClient';
import { login, updateUser } from '../../reducers/authReducer';
import { RFQformDataFormatter } from '../../utils/formUtils';
import { RFQsToDatabaseMapper } from '../../utils/clientMapper';
import LocationSection from '../forms/LocationSection';

const MessageSellerForm = ({
  // must give full seller or sellerId.
  seller: givenSeller,
  sellerId: givenSellerId,
  modalControl,
  loading: externalLoading,
  loadBeforeOpen,
  startOpen,
}) => {
  const { refresh } = useMessages();
  const consumerRoleId = '3';
  const dispatch = useDispatch();
  const validator = useValidator();
  const { snackbar } = useSnackbar();
  const { user, isAuthenticated } = useAuth();
  const recaptchaVerification = useRecaptcha();
  const locationLoadingState = useAsyncState(false);
  const { open = true, handleClose } = modalControl || {};
  const [hasLastPage, setHasLastPage] = useState(!isAuthenticated);
  const { nameSort, isEnglish, isFrench, locale, attemptFormat } = useLocale();
  const { smoothScroll } = useScrollToTop();

  const typeOptions = useMemo(
    () => [
      ...nameSort(
        ORGANIZATION_TYPES.filter(({ roles }) => roles.includes('3')),
      ),
      { id: 'other', name: isEnglish ? 'Other' : isFrench ? 'Autre' : 'أخرى' },
    ],
    [nameSort, isEnglish, isFrench],
  );

  useEffect(() => {
    if (open) return;
    setHasLastPage(!isAuthenticated);
  }, [open, isAuthenticated]);

  const { data: apiSeller, loading: sellerLoading } = useGetData({
    endpoint:
      !givenSeller &&
      givenSellerId &&
      (open || loadBeforeOpen) &&
      `/suppliers/${givenSellerId}`,
    dataHandler: useCallback(({ data }) => supplierMapper(data), []),
    initialValue: {},
  });

  const seller = givenSeller || apiSeller;
  const loading = !!(sellerLoading || externalLoading);

  //   const { location = {} } = user || {};
  const location = useRef(user?.location);
  const newUserValues = !hasLastPage
    ? null
    : {
        organization: '',
        organizationType: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        termsChecked: false,
        streetNumber: '',
        streetName: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        countryShort: '',
      };

  const newRfqValues = {
    supplier: givenSeller?.id || givenSellerId,
    requestMessage: '',
    rfqsType: 'direct',
    streetNumber: location?.current?.lineOne || '',
    streetName: location?.current?.lineTwo || '',
    city: location?.current?.city || '',
    province: location?.current?.province || '',
    postalCode: location?.current?.postalCode || '',
    country: location?.current?.country || '',
    countryShort: location?.current?.countryShort || '',
  };

  const initialValues = {
    ...newUserValues,
    ...newRfqValues,
  };

  const validationSchema = validator((schema) => ({
    ...schema.requestMessage(),
    ...schema.streetNumber(),
    ...schema.streetName(),
    ...schema.city(),
    ...schema.province(),
    ...schema.country(),
    ...schema.countryShort(),
    ...(hasLastPage && {
      ...schema.firstName(),
      ...schema.lastName(),
      ...schema.email(),
      ...schema.organization(),
      ...schema.organizationType({ required: false }),
      ...schema.password(),
      ...schema.requiredCheck({ name: 'termsChecked' }),
    }),
  }));

  const resetForm = (err, values, { setTouched, setValues, setErrors }) => {
    setValues({ ...values, password: '' }, false);
    if (err.status !== 422 || !err.error?.email)
      return snackbar({ message: 'registerError' });

    setTouched({ email: true }, false);
    setErrors({
      email: attemptFormat({
        id: 'auth.register.userExists',
        dm: 'That email address is already taken',
      }),
    });
  };

  const isUserKey = (key) => Object.keys(newUserValues).includes(key);

  const handleSignup = async (values, actions) => {
    if (isAuthenticated) return;

    const userValues = Object.entries(values).reduce(
      (userValues, [key, value]) => {
        if (isUserKey(key)) userValues[key] = value;
        return userValues;
      },
      {},
    );

    const newUser = {
      first_name: userValues?.firstName,
      last_name: userValues?.lastName,
      email: userValues?.email,
      emailConfirm: userValues?.email,
      password: userValues?.password,
      password_confirmation: userValues?.password,
      roles: consumerRoleId,
      organization: userValues?.organization,
      organizationType: userValues?.organizationType,
      two_factor_enable: false,
      language: locale,
      location: {
        street_number: userValues?.streetNumber,
        street_name: userValues?.streetName,
        city: userValues?.city,
        province: userValues?.province,
        postal_code: userValues?.postalCode,
        country: userValues?.country,
        country_short: userValues?.countryShort,
      },
    };

    const { e: registerErr } = await tryClient('/register', {
      method: 'POST',
      body: newUser,
    });

    if (registerErr) {
      snackbar({ message: 'registerError' });
      resetForm(registerErr, values, actions);
      return { err: true };
    }

    const { email, password } = newUser;
    const { err } = await dispatch(login({ email, password }));
    if (err) {
      snackbar({ message: 'loginError' });
      return { err: true };
    }
    snackbar({ message: 'loginSuccess' });
  };

  const isRfqKey = (key) => Object.keys(newRfqValues).includes(key);

  const handleSubmit = async (values, actions) => {
    if (!(await recaptchaVerification())) return;

    const rfqValues = Object.entries(values).reduce(
      (rfqValues, [key, value]) => {
        if (isRfqKey(key)) rfqValues[key] = value;
        return rfqValues;
      },
      {},
    );

    const { err: signupError } = (await handleSignup(values, actions)) || {};
    if (signupError) return;

    if (isAuthenticated && !location?.country) {
      try {
        await dispatch(
          updateUser({
            location: {
              street_number: values?.streetNumber,
              street_name: values?.streetName,
              city: values?.city,
              province: values?.province,
              postal_code: values?.postalCode,
              country: values?.country,
              country_short: values?.countryShort,
            },
          }),
        );
      } catch (_) {
        snackbar({ message: 'locationUpdateError' });
      }
    }

    const formData = RFQformDataFormatter(RFQsToDatabaseMapper(rfqValues));

    const { err } = await tryClient(`/rfqs`, {
      method: 'POST',
      formData,
    });
    if (err) return snackbar({ message: 'error' });
    if (hasLastPage && window.location.pathname === '/') smoothScroll();
    refresh();
    snackbar({ message: 'quoteSuccess' });
    if (handleClose) handleClose();
  };
  const { text, style, field } = makeProps({
    modalControl,
    user,
    locationLoadingState,
    seller,
  });
  return (
    <MuiLoading {...{ loading, sx: { my: '5rem' } }}>
      <MuiMultiStep
        {...{
          initialValues,
          validationSchema,
          onSubmit: handleSubmit,
          ...style.form,
        }}
      >
        <MuiFormStep>
          <Flex {...style.formStack}>
            <MuiFormField {...field.requestMessage} />
          </Flex>
        </MuiFormStep>
        <MuiFormStep>
          <LocationSection {...field.locationSection} />
        </MuiFormStep>
        {hasLastPage && (
          <MuiFormStep>
            <Flex {...style.formStack}>
              <MuiText {...text.signupTitle} />
              <MuiText {...text.signupSubtitle} />
              <Flex {...style.multiFields}>
                <MuiFormField {...field.firstName} />
                <MuiFormField {...field.lastName} />
              </Flex>
              <Flex {...style.multiFields}>
                <MuiFormField {...field.organization} />
                <MuiSelect
                  {...{ ...field.organizationType, options: typeOptions }}
                />
              </Flex>
              <MuiFormField {...field.email} />
              <MuiFormField {...field.password} />
              <MuiCheckbox {...field.termsChecked} />
            </Flex>
          </MuiFormStep>
        )}
      </MuiMultiStep>
    </MuiLoading>
  );

  function makeProps({ modalControl, locationLoadingState, seller }) {
    const replaceLink = { target: '_blank', sxText: { fontSize: 'inherit' } };
    return {
      text: {
        signupTitle: {
          id: 'feed.post.requestButton.signupTitle',
          dm: 'Track your request',
          sx: { color: 'headingText.main', fontSize: '1rem' },
        },
        signupSubtitle: {
          id: 'feed.post.requestButton.signupSubtitle',
          dm: 'Last step! Create your account to track your request',
          sx: {
            color: 'headingText.main',
            fontSize: '0.875rem',
            mt: '-0.5rem',
          },
        },
      },
      style: {
        form: {
          enableReinitialize: true,
          preventExit: true,
          modalControl,
          title: {
            id: 'rfqs.messageSeller.title',
            dm: 'Contact {BUSINESS}',
          },
          titleProps: {
            replaceMap: { '{BUSINESS}': <span>{seller.name}</span> },
          },
        },
        formStack: {
          direction: 'column',
          sx: {
            gap: '1rem',
            width: '100%',
            maxWidth: '30rem',
            mx: 'auto',
            py: '1rem',
          },
        },
        multiFields: {
          direction: { xs: 'column', md: 'row' },
          sx: {
            gap: '1rem',
            width: '100%',
            display: { xs: 'flex', md: 'grid' },
            gridTemplateColumns: { xs: 'unset', md: '1fr 1fr' },
            alignItems: { xs: 'center', md: 'flex-start' },
          },
        },
      },
      field: {
        requestMessage: {
          name: 'requestMessage',
          label: {
            id: 'forms.requestMessage',
            dm: 'Request Message',
          },
          multiline: true,
          rows: 3,
          required: true,
          sx: { width: '100%' },
        },
        locationSection: {
          names: [
            'streetNumber',
            'streetName',
            'postalCode',
            'city',
            'province',
            'country',
            'countryShort',
          ],
          loadingState: locationLoadingState,
        },
        firstName: {
          name: 'firstName',
          label: { id: 'forms.firstName', dm: 'First Name' },
          noPlaceholder: true,
          sx: { maxWidth: '25rem' },
          required: true,
        },
        lastName: {
          name: 'lastName',
          label: { id: 'forms.lastName', dm: 'Last Name' },
          noPlaceholder: true,
          sx: { maxWidth: '25rem' },
          required: true,
        },
        organization: {
          name: 'organization',
          label: { id: 'forms.organization', dm: 'Business Name' },
          noPlaceholder: true,
          sx: { width: '100%', maxWidth: '25rem' },
        },
        organizationType: {
          name: 'organizationType',
          label: { id: 'forms.organizationType', dm: 'Business Type' },
          accessor: 'id',
          labelAccessor: 'name',
          sx: { width: '100%', maxWidth: '25rem' },
        },
        email: {
          name: 'email',
          label: { id: 'forms.email', dm: 'Email' },
          sx: { width: '100%' },
          required: true,
        },
        password: {
          name: 'password',
          label: { id: 'forms.password', dm: 'password' },
          sx: { width: '100%' },
          type: 'password',
          required: true,
        },
        termsChecked: {
          name: 'termsChecked',
          label: (
            <MuiText
              {...{
                id: 'auth.signup.seller.acceptTerms',
                dm: 'I accept the {TERMS} and {PRIVACY} *',
                sx: { fontSize: '0.875rem' },
                replaceMap: {
                  '{TERMS}': (
                    <MuiLink
                      {...{
                        to: '/terms-and-services',
                        id: 'auth.signup.seller.termsLink',
                        dm: 'Tradvo Terms of Use',
                        ...replaceLink,
                      }}
                    />
                  ),
                  '{PRIVACY}': (
                    <MuiLink
                      {...{
                        to: '/privacy-page',
                        id: 'auth.signup.seller.privacyLink',
                        dm: 'Privacy Policy',
                        ...replaceLink,
                      }}
                    />
                  ),
                  '*': <span {...{ style: { color: 'red' } }}>*</span>,
                },
              }}
            />
          ),
        },
      },
    };
  }
};

export default MessageSellerForm;

import { useCallback } from 'react';
import useAsyncState from './useAsyncState';
import useNavSize from './useNavSize';

const useMuiMenuControl = ({ debugMode } = {}) => {
  const { pageRef } = useNavSize() || {};
  const [anchorEl, setAnchorEl] = useAsyncState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (e) => {
    if (debugMode) console.log({ e });
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const buttonTop = anchorEl?.offsetTop || 0;
  const scrollHeight = pageRef?.current?.scrollTop || 0;

  return { anchorEl, open, handleOpen, handleClose, buttonTop, scrollHeight };
};

export default useMuiMenuControl;

import { Button, Box, Tag } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NavItemToggle = ({
  path,
  linkName,
  count,
  onClose,
  style,
  handleTabIsOpen,
  isOpen,
  tab,
}) => {
  const history = useHistory();
  const activeTabStyle = isOpen
    ? {
        bg: '#ccc',
        color: '#333',
        //'> button': { color: '#333' },
        transition: '0s',
      }
    : null;
  return (
    <Box
      as="li"
      p={2}
      minWidth={'150px'}
      display="flex"
      flex={1}
      justifyContent="space-between"
      fontSize="md"
      ml={8}
      color="#ccc"
      _hover={{
        bg: '#ccc',
        color: '#333',
        '> button': { color: '#333' },
        transition: '0s',
      }}
      onClick={() => {
        if (onClose) onClose();
        history.push(path);
        if (handleTabIsOpen) {
          if (tab === '') handleTabIsOpen('dashboard');
          else handleTabIsOpen(tab);
        }
      }}
      cursor="pointer"
      {...style}
      {...activeTabStyle}
    >
      {handleTabIsOpen ? (
        linkName
      ) : (
        <Button
          variant="link"
          color="#ccc"
          _hover={{ color: '#333' }}
          fontSize="md"
          transition="0s"
        >
          {linkName}
        </Button>
      )}
      {count && (
        <Tag
          size="sm"
          variant="solid"
          colorScheme="orange"
          borderRadius="20px"
          height="auto"
          fontSize="md"
        >
          {count}
        </Tag>
      )}
    </Box>
  );
};

export default NavItemToggle;

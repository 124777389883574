import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const PageTracker = ({ debugMode }) => {
  const { pathname, search } = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    ReactGA.initialize(GA_TRACKING_ID);
    setInitialized(true);
  }, [initialized]);

  useEffect(() => {
    if (!initialized) return;
    if (debugMode) console.log({ GA_PageView: pathname + search });
    ReactGA.pageview(pathname + search);
  }, [debugMode, initialized, pathname, search]);
  return <></>;
};

export default PageTracker;

import React, { forwardRef } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import MuiText from '../intl/MuiText';
import useEventTracker from '../../hooks/useEventTracker';
import { mergeProps } from '../../utils/mergeProps';

const MuiLink = forwardRef(
  (
    {
      children,
      id,
      dm,
      defaultMessage,
      fontSize,
      textAs,
      sxText,
      lines,
      hoverColor,
      sxTextWithIcons,
      sxSkeleton,
      skeletonLines,
      groupSkeletons,
      loading,
      startIcon,
      endIcon,
      sx,
      nonText,
      href,
      to,
      GA, // { category = '', action = '', label = '', debugMode: false }
      onClick: givenOnClick,
      forceAllLines,
      hint,
      ...rest
    },
    ref,
  ) => {
    const textProps = {
      id,
      dm,
      defaultMessage,
      fontSize,
      sxSkeleton,
      skeletonLines,
      groupSkeletons,
      loading,
      as: textAs,
      lines,
      hoverColor,
      sx: sxText,
      forceAllLines,
      hint,
    };

    const eventTracker = useEventTracker();
    const format = (inputText) => inputText; //? inputText.replace(/\s/g, '-').replace('%', '') : '';

    return loading || !(to || href) ? (
      nonText ? (
        <>{children}</>
      ) : (
        <MuiText
          {...{ ...textProps, sx: mergeProps(sxText, sx), ...(ref && { ref }) }}
        >
          {children}
        </MuiText>
      )
    ) : (
      <Link
        {...{
          sx: { textDecoration: 'none', ...sx },
          href,
          to: format(to),
          onClick: (...clickArgs) => {
            eventTracker(GA);
            if (givenOnClick) givenOnClick(...clickArgs);
          },
          ...(ref && { ref }),
          ...rest,
        }}
        {...(!href && { component: RouterLink })}
      >
        {nonText ? (
          <>{children}</>
        ) : startIcon || endIcon ? (
          <MuiText
            {...{
              fontSize,
              as: textAs,
              sx: {
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                gap: '0.5rem',
                ...sxTextWithIcons,
              },
              sxSkeleton,
              skeletonLines,
              groupSkeletons,
              loading,
              lines,
              forceAllLines,
            }}
          >
            {startIcon || <></>}
            <MuiText {...textProps}>{children}</MuiText>
            {endIcon || <></>}
          </MuiText>
        ) : (
          <MuiText {...textProps}>{children}</MuiText>
        )}
      </Link>
    );
  },
);

export default MuiLink;

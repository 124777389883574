import { useCallback, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import {
  TOAST_DEFAULT_DURATION,
  TOAST_DEFAULT_IS_CLOSABLE,
  TOAST_DEFAULT_POSITION,
} from '../data/constants';

function useToaster() {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const defaultOptions = useMemo(
    () => ({
      position: TOAST_DEFAULT_POSITION,
      duration: TOAST_DEFAULT_DURATION,
      isClosable: TOAST_DEFAULT_IS_CLOSABLE,
    }),
    [],
  );

  return useCallback(
    (str, options = {}) => {
      switch (str) {
        case 'registrationError':
          toast({
            title: formatMessage({
              id: 'auth.register.error',
              defaultMessage: 'Unable to create account. Try again later.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'loginSuccess':
          toast({
            title: formatMessage({
              id: 'auth.login.success',
              defaultMessage: 'Logged in successfully.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'resetPasswordSuccess':
          toast({
            title: formatMessage({
              id: 'auth.password.change.success',
              defaultMessage: 'Successfully changed your password!',
            }),
            description: formatMessage({
              id: 'auth.password.change.success.description',
              defaultMessage: 'Please use your new password from now on.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'resetPasswordFail':
          toast({
            title: formatMessage({
              id: 'auth.password.change.failure',
              defaultMessage: 'Could not change your password.',
            }),
            description: formatMessage({
              id: 'auth.password.change.failure.token',
              defaultMessage:
                'Your token has expired. Please attempt "Forgot Password" again.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'resetPasswordOtherError':
          toast({
            title: formatMessage({
              id: 'auth.password.change.failure',
              defaultMessage: 'Could not change your password.',
            }),
            description: formatMessage({
              id: 'auth.password.change.failure.description',
              defaultMessage: 'Please try again later.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'verifyEmail':
          toast({
            title: formatMessage({
              id: 'auth.login.failure',
              defaultMessage: 'Could not log in.',
            }),
            description: formatMessage({
              id: 'auth.login.failure.emailVerification',
              defaultMessage:
                'You must verify your email account first. Please check your email inbox.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'adminApproval':
          toast({
            title: formatMessage({
              id: 'auth.login.failure',
              defaultMessage: 'Could not log in.',
            }),
            description: formatMessage({
              id: 'auth.login.failure.adminApproval',
              defaultMessage:
                'This account is pending approval from an administrator.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'accountSuspended':
          toast({
            title: formatMessage({
              id: 'auth.login.failure',
              defaultMessage: 'Could not log in.',
            }),
            description: formatMessage({
              id: 'auth.login.failure.accountSuspended',
              defaultMessage: 'This account has been suspended.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'logout':
          toast({
            title: formatMessage({
              id: 'auth.logout.success',
              defaultMessage: 'Logged out successfully.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'wait':
          toast({
            title: formatMessage({
              id: 'auth.login.towFactor.failure',
              defaultMessage: 'Could not authentication code.',
            }),
            description: formatMessage({
              id: 'auth.login.towFactor.failure.wait',
              defaultMessage:
                'Please wait 2 minutes before sending another authentication code.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'error':
          toast({
            title: formatMessage({
              id: 'auth.toast.error',
              defaultMessage: 'Something went wrong',
            }),
            description: formatMessage({
              id: 'auth.toast.error.description',
              defaultMessage: 'Please try again later.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'noNestedCategory':
          toast({
            title: formatMessage({
              id: 'categories.noNestedCategory',
              defaultMessage: 'No nested categories found',
            }),
            description: formatMessage({
              id: 'categories.noNestedCategory.description',
              defaultMessage: 'Please try another category.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'newSuccess':
          toast({
            title: formatMessage({
              id: `forms.success.${options.id}.new`,
              defaultMessage: `New ${options.recordName} request has been submitted!`,
            }),
            description: formatMessage({
              id: 'forms.success.adminApproval',
              defaultMessage:
                'Please note that the request status is now pending and an administrator must approve your request.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'editSuccess':
          toast({
            title: formatMessage({
              id: `forms.success.${options.id}.edit`,
              defaultMessage: `${options.dm} data updated!`,
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'unableEdit':
          toast({
            title: formatMessage({
              id: `forms.update.${options.id}.failure`,
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'updateFail':
          toast({
            title: formatMessage({
              id: 'table.update.failure',
              defaultMessage: 'Update failed',
            }),
            description: formatMessage({
              id: 'table.update.failure.tryAgain',
              defaultMessage: 'Please try again later',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'noBusiness':
          toast({
            title: formatMessage({
              id: 'table.update.failure.noBusiness',
              defaultMessage: 'The listing does not have an approved business',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'applyFail':
          toast({
            title: formatMessage({
              id: 'products.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'products.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected listing status. This action could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'postsBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'posts.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'posts.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected posts status. This action could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'bidEditSuccess':
          toast({
            title: formatMessage({
              id: `forms.success.bid.edit`,
              defaultMessage: `Bid data updated!`,
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;

        case 'bulkActionsSuccess':
          toast({
            title: formatMessage({
              id: `forms.success.${options.id}.editStatus`,
              defaultMessage: `${options.dm} status updated!`,
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'publishActionFail':
          toast({
            title: formatMessage({
              id: 'products.publishaction.failure',
              defaultMessage: 'Publish/Unpublish action failed',
            }),
            description: formatMessage({
              id: 'products.publishaction.failure.badrequest',
              defaultMessage:
                'Only approved listings can be published or unpublished.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'approveAdsActionFail':
          toast({
            title: formatMessage({
              id: 'ads.approveaction.failure',
              defaultMessage: 'Approve action failed',
            }),
            description: formatMessage({
              id: 'ads.approveaction.failure.badrequest',
              defaultMessage: 'Can not Approve an expired advertise',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'enableAdsActionFail':
          toast({
            title: formatMessage({
              id: 'ads.enableaction.failure',
              defaultMessage: 'Enable/Disable action failed',
            }),
            description: formatMessage({
              id: 'ads.enableaction.failure.badrequest',
              defaultMessage: 'Only approved ads can be Enabled or Disabled.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'fetchFail':
          toast({
            title: formatMessage({
              id: '"admin.filter.failure',
              defaultMessage: 'Filter failed',
            }),
            description: formatMessage({
              id: 'admin.filter.failure.badrequest',
              defaultMessage:
                'Please note that there is no record of the selected filter',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'productsBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'products.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'products.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected listing status. This action could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'reviewsBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'reviews.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'reviews.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected reviews status. This action could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'adsBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'ads.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'ads.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected ads status. This acction could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'expertsBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'experts.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'experts.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected experts status. This acction could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;

        case 'biosafetyBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'biosafety.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'biosafety.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected biosafetyLab status. This acction could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;

        case 'regulationBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'regulation.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'regulation.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected regulation status. This acction could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'couponsBulkActionsFail':
          toast({
            title: formatMessage({
              id: 'coupons.bulkaction.failure',
              defaultMessage: 'Bulk action failed',
            }),
            description: formatMessage({
              id: 'coupons.bulkaction.failure.badrequest',
              defaultMessage:
                'Please Check selected acoupons. This acction could not applied to some of them.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'translateFail':
          toast({
            title: formatMessage({
              id: 'forms.translate.error',
              defaultMessage: 'Could not translate.',
            }),
            description: formatMessage({
              id: 'table.update.failure.tryAgain',
              defaultMessage: 'Please try again later',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'adminOnly':
          toast({
            title: formatMessage({
              id: 'auth.adminOnly',
              defaultMessage: 'You must be an administrator to view this page.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'mustLogin':
          toast({
            title: formatMessage({
              id: 'auth.mustLogin',
              defaultMessage: 'You must be logged in to view this page.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'notViewPermitted':
          toast({
            title: formatMessage({
              id: 'auth.notViewPermitted',
              defaultMessage: 'You do not have permission to view that page.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'lackViewPermission':
          toast({
            title: formatMessage({
              id: 'auth.lackViewPermission',
              defaultMessage:
                options.message ||
                'Sorry, you do not have permission to access this URL',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'verificationResent':
          toast({
            title: formatMessage({
              id: 'pages.verifyEmail.tokenResent',
              defaultMessage: 'Your verification token has been resent.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'requestLimit':
          toast({
            title: formatMessage({
              id: 'pages.verifyEmail.requestLimitTitle',
              defaultMessage: 'Your verification token has been resent.',
            }),
            description: formatMessage({
              id: 'pages.verifyEmail.requestLimitDescription',
              defaultMessage:
                'It looks like we have recently sent you a verification email. Please wait for 5 minutes before retrying.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'itemTypeAlreadyExists':
          toast({
            title: formatMessage({
              id: 'forms.errors.itemTypes.exists',
              defaultMessage: 'That item type already exists.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'deleteSuccess':
          toast({
            title: formatMessage({
              id: 'actions.delete.success',
              defaultMessage: 'Record deleted successfully',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'newPost':
          toast({
            title: formatMessage({
              id: 'forms.success.post.new',
            }),
            description: formatMessage({
              id: 'forms.success.adminApproval',
              defaultMessage:
                'Please note that the request status is now pending and an administrator must approve your request.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'editPost':
          toast({
            title: formatMessage({
              id: 'forms.success.post.edit',
              defaultMessage: 'Post data has been successfully updated!',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'newReply':
          toast({
            title: formatMessage({
              id: 'forms.success.reply.new',
              defaultMessage: 'A new reply post has been submitted!',
            }),
            description: formatMessage({
              id: 'forms.success.adminApproval',
              defaultMessage:
                'Please note that the request status is now pending and an administrator must approve your request.',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'emailSubscribe':
          toast({
            title: formatMessage({
              id: 'posts.emailNotifications.subscribe',
              defaultMessage: 'Subscribed to email notifications',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'emailUnsubscribe':
          toast({
            title: formatMessage({
              id: 'posts.emailNotifications.unsubscribe',
              defaultMessage: 'Unsubscribed from email notifications',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'copySuccess':
          toast({
            title: formatMessage({
              id: 'forms.copy.success',
              defaultMessage: 'Text Copied To Clipboard',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'copyError':
          toast({
            title: formatMessage({
              id: 'forms.copy.error',
              defaultMessage: 'Text Not Copied',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'userBulkSuccessSingle':
          toast({
            title: formatMessage({
              id: 'admin.users.bulkAction.success.single',
              defaultMessage: 'User Successfully Updated',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'userBulkSuccessPlural':
          toast({
            title: formatMessage({
              id: 'admin.users.bulkAction.success.plural',
              defaultMessage: 'Users Successfully Updated',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'userBulkErrorSingle':
          toast({
            title: formatMessage({
              id: 'admin.users.bulkAction.error.single',
              defaultMessage: 'Unable to Update User',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'userBulkErrorPlural':
          toast({
            title: formatMessage({
              id: 'admin.users.bulkAction.error.plural',
              defaultMessage: 'Unable to Update Users',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputeBulkSuccessSingle':
          toast({
            title: formatMessage({
              id: 'admin.dispute.bulkActions.success.single',
              defaultMessage: 'Update Successful',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputeBulkSuccessPlural':
          toast({
            title: formatMessage({
              id: 'admin.dispute.bulkActions.success.plural',
              defaultMessage: 'Update Successful',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputeBulkErrorSingle':
          toast({
            title: formatMessage({
              id: 'admin.dispute.bulkActions.error.single',
              defaultMessage: 'Update Failed',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputeBulkErrorPlural':
          toast({
            title: formatMessage({
              id: 'admin.dispute.bulkActions.error.plural',
              defaultMessage: 'Update Failed',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'editDisputeSuccess':
          toast({
            title: formatMessage({
              id: 'forms.success.dispute.edit',
              defaultMessage: 'Dispute successfully edited',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'createDisputeSuccess':
          toast({
            title: formatMessage({
              id: 'forms.success.dispute.create',
              defaultMessage: 'Dispute successfully created',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'apiKeyDeleteSuccess':
          toast({
            title: formatMessage({
              id: 'user.deleteKey.success',
              defaultMessage: 'Key Deleted',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'apiKeyDeleteError':
          toast({
            title: formatMessage({ id: options.message || 'auth.toast.error' }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.open.success':
          toast({
            title: formatMessage({
              id: 'disputes.action.approve.success',
              defaultMessage: 'Dispute Approved',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.open.failed':
          toast({
            title: formatMessage({
              id: 'disputes.action.approve.failed',
              defaultMessage: 'Failed to Approve Dispute',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.rejected.success':
          toast({
            title: formatMessage({
              id: 'disputes.action.reject.success',
              defaultMessage: 'Dispute Rejected',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.rejected.failed':
          toast({
            title: formatMessage({
              id: 'disputes.action.reject.failed',
              defaultMessage: 'Failed to Reject Dispute',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.resolved.success':
          toast({
            title: formatMessage({
              id: 'disputes.action.resolve.success',
              defaultMessage: 'Dispute Resolved',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.resolved.failed':
          toast({
            title: formatMessage({
              id: 'disputes.action.resolve.failed',
              defaultMessage: 'Failed to Resolve Dispute',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.delete.success':
          toast({
            title: formatMessage({
              id: 'disputes.action.delete.success',
              defaultMessage: 'Dispute Deleted',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.delete.failed':
          toast({
            title: formatMessage({
              id: 'disputes.action.delete.failed',
              defaultMessage: 'Failed to Delete Dispute',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.restored.success':
          toast({
            title: formatMessage({
              id: 'disputes.action.restore.success',
              defaultMessage: 'Dispute Restored',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputes.action.restored.failed':
          toast({
            title: formatMessage({
              id: 'disputes.action.restore.failed',
              defaultMessage: 'Failed to Restore Dispute',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'deleteDisputerAttachmentSuccess':
          toast({
            title: formatMessage({
              id: 'disputes.attachment.delete.success',
              defaultMessage: 'Attachment Deleted',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'deleteDisputerAttachmentFailed':
          toast({
            title: formatMessage({
              id: 'disputes.attachment.delete.failed',
              defaultMessage: 'Failed to Delete Attachment',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'disputeOfferCounterSuccess':
          toast({
            title: formatMessage({
              id: 'forms.success.dispute.counterOffer.counter',
              defaultMessage: 'Counter Offer Sent',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'disputeOfferRespondSuccess':
          toast({
            title: formatMessage({
              id: 'forms.success.dispute.counterOffer.respond',
              defaultMessage: 'Response Sent',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'deleteCounterOfferSuccess':
          toast({
            title: formatMessage({
              id: 'forms.success.dispute.counterOffer.delete',
              defaultMessage: 'Counter Offer Deleted',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderBulkSuccessSingle':
          toast({
            title: formatMessage({
              id: 'admin.orders.bulkAction.success.single',
              defaultMessage: 'Order Successfully Updated',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderBulkSuccessPlural':
          toast({
            title: formatMessage({
              id: 'admin.orders.bulkAction.success.plural',
              defaultMessage: 'Orders Successfully Updated',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderBulkErrorSingle':
          toast({
            title: formatMessage({
              id: 'admin.orders.bulkAction.error.single',
              defaultMessage: 'Unable to Update Order',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'orderBulkErrorPlural':
          toast({
            title: formatMessage({
              id: 'admin.orders.bulkAction.error.plural',
              defaultMessage: 'Unable to Update Orders',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'orderDeletedSuccess':
          toast({
            title: formatMessage({
              id: 'orders.action.delete.success',
              defaultMessage: 'Order Successfully Deleted',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderDeletedFailed':
          toast({
            title: formatMessage({
              id: 'orders.action.delete.failed',
              defaultMessage: 'Order Deletion failed',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'orderRestoredSuccess':
          toast({
            title: formatMessage({
              id: 'orders.action.restore.success',
              defaultMessage: 'Order Successfully Restored',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderRestoredFailed':
          toast({
            title: formatMessage({
              id: 'orders.action.restore.failed',
              defaultMessage: 'Order Restoration failed',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'orderStatusSuccess':
          toast({
            title: formatMessage({
              id: `orders.action.${options.action}.success`,
              defaultMessage: 'Order Status Updated',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderStatusFailed':
          toast({
            title: formatMessage({
              id: `orders.action.${options.action}.failed`,
              defaultMessage: 'Order Status Update failed',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'orderBuyAgainSuccess':
          toast({
            title: formatMessage({
              id: 'orders.action.buyAgain.success',
              defaultMessage: 'Order Placed Again',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'orderBuyAgainFailed':
          toast({
            title: formatMessage({
              id: 'orders.action.buyAgain.failed',
              defaultMessage: 'Failed To Place Order Again',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        case 'profilePictureUpload':
          toast({
            title: formatMessage({
              id: 'userProfile.updatePicture.toast',
              defaultMessage: 'Profile picture updated',
            }),
            status: 'success',
            ...defaultOptions,
          });
          return;
        case 'imageTooLarge':
          toast({
            title: formatMessage({
              id: 'userProfile.updatePicture.toast.tooLarge',
              defaultMessage:
                'The profile picture may not be greater than 10240 kilobytes.',
            }),
            status: 'error',
            ...defaultOptions,
          });
          return;
        default:
          return;
      }
    },
    [defaultOptions, formatMessage, toast],
  );
}

export default useToaster;

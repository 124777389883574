import React from 'react';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import { mergeProps } from '../../utils/mergeProps';
import MuiModalButton from '../common/MuiModalButton';
import { NavbarMessages } from '../../data/icons';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import SellerRequestModalContent from './SellerRequestModalContent';
import MuiOfferForm from '../offer/MuiOfferForm';
import MessageSellerForm from './MessageSellerForm';

const MessageSellerButton = ({
  // must give full seller or sellerId.
  seller: givenSeller,
  sellerId: givenSellerId,
  modalButtonProps,
  modalControl: givenModalControl,
  loading: externalLoading,
  disabled: externalDisabled,
  loadBeforeOpen,
  startOpen,
}) => {
  const { user, isAuthenticated } = useAuth();
  const defaultModalControl = useMuiModalControl(startOpen);
  const modalControl = givenModalControl || defaultModalControl;
  const [sellerStartedOffer, setSellerStartedOffer] = useState(false);
  const offerModalControl = useMuiModalControl();

  const { style } = makeProps({
    modalControl,
    user,
    isAuthenticated,
    externalDisabled,
    externalLoading,
    sellerStartedOffer,
    setSellerStartedOffer,
    offerModalControl,
  });
  return (
    <MuiModalButton {...{ modalControl, ...style.modal(modalButtonProps) }}>
      <MessageSellerForm
        {...{
          seller: givenSeller,
          sellerId: givenSellerId,
          modalControl,
          loading: externalLoading,
          loadBeforeOpen,
          startOpen,
        }}
      />
    </MuiModalButton>
  );

  function makeProps({
    modalControl,
    user,
    isAuthenticated,
    externalDisabled,
    externalLoading,
    sellerStartedOffer,
    setSellerStartedOffer,
    offerModalControl,
  }) {
    const { roles } = user || {};
    const { handleOpen } = modalControl || {};
    return {
      style: {
        modal: (props) =>
          mergeProps(props, {
            onClick: handleOpen,
            cardProps: { sx: { width: '30rem' } },
            buttonProps: {
              id: 'businessProfile.headerCard.message',
              dm: 'Message',
              modalControl: offerModalControl,
              borderRadius: 1,
              startIcon: <NavbarMessages {...{ fontSize: 'inherit' }} />,
              variant: 'outlined',
              disabled: externalDisabled,
              ...(sellerStartedOffer && {
                overRideModalProps: {
                  cardProps: { sx: { p: '1rem' } },
                  modalBody: (
                    <MuiOfferForm
                      {...{
                        modalControl: offerModalControl,
                        loading: externalLoading,
                      }}
                    />
                  ),
                },
              }),
              onClose: () => setSellerStartedOffer(false),
              restrictions: [
                {
                  condition: !isAuthenticated || roles === '3',
                  //   message: 'getRFQbuyerRole',
                  modal: {
                    cardProps: {
                      sx: {
                        width: '100%',
                        maxWidth: { xs: '95vw', md: 'min(80vw, 40rem)' },
                      },
                    },
                    modalBody: (
                      <SellerRequestModalContent
                        {...{ setSellerStartedOffer }}
                      />
                    ),
                  },
                },
              ],
            },
          }),
      },
    };
  }
};

export default MessageSellerButton;

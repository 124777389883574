import React, { forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton } from '@mui/material';
import Flex from '../common/Flex';
import WrapOn from '../common/WrapOn';
import useLocale from '../../hooks/useLocale';
import { useMemo } from 'react';
import checkFunc from '../../utils/checkFunc';
import useParseBreakpointObject from '../../hooks/useParseBreakpointObject';
import HelpIcon from '@mui/icons-material/Help';
import MuiTooltip from '../common/MuiTooltip';
import { mergeProps } from '../../utils/mergeProps';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const MuiText = forwardRef(
  (
    {
      id,
      dm,
      defaultMessage,
      children,
      fontSize,
      fontFamily = 'poppins',
      as,
      hoverColor,
      sx,
      lines,
      forceAllLines,
      dangerouslySetInnerHTML,
      onClick,
      loading,
      skeletonProps,
      sxSkeleton,
      skeletonLines,
      fullLastSkeleton,
      finalSkeletonWidth,
      groupSkeletons,
      variant,
      splitText,
      item,
      itemProps,
      replace, // (word) => wordReplacement
      replaceMap: givenReplaceMap, // { word: wordReplacement } // keys are case-insensitive
      replaceTradvo,
      hint, // { id, dm, tooltipProps, icon, outlineIcon, iconProps, size, color }
      ...rest
    },
    ref,
  ) => {
    if (defaultMessage) dm = defaultMessage;
    const { attemptFormat } = useLocale();
    const parseBreakpoint = useParseBreakpointObject();
    const variantProps = variantStyles({ variant });
    const replaceMap = useMemo(
      () =>
        !givenReplaceMap
          ? null
          : Object.entries(givenReplaceMap).reduce(
              (lowerCaseObj, [key, value]) => {
                lowerCaseObj[key.toLowerCase()] = attemptFormat(value);
                return lowerCaseObj;
              },
              {},
            ),
      [givenReplaceMap, attemptFormat],
    );
    const lineHeight = sx?.lineHeight || '1.25';
    const unitlessHeight = !Number.isNaN(Number(lineHeight));

    const style = {
      as: 'span',
      fontSize: '0.75rem',
      fontFamily,
      ...variantProps,
      ...(as && { as }),
      ...(fontSize && { fontSize }),
      sx: {
        ...variantProps?.sx,
        ...(lines && {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: `${lines}`,
          WebkitBoxOrient: 'vertical',
          ...(forceAllLines && {
            lineHeight,
            height: `calc(${parseBreakpoint(lineHeight)} * ${lines}${
              unitlessHeight ? 'em' : ''
            })`,
          }),
        }),
        ...(onClick && { cursor: 'pointer' }),
        ...(hoverColor && { '&:hover': { color: hoverColor } }),
        ...sx,
      },
      variant,
      ...rest,
    };

    const skeleton = {
      text: (last) => ({
        sx: {
          fontSize: style?.fontSize || style.sx?.fontSize || '0.75rem',
          ...style.sx,
          ...sxSkeleton,
          ...(last &&
            (finalSkeletonWidth
              ? { width: finalSkeletonWidth }
              : {
                  width:
                    style.sx?.width || sxSkeleton?.width
                      ? `calc(${style.sx?.width || sxSkeleton?.width} / 2)`
                      : '40%',
                })),
          height: variantProps?.sx?.height || sx?.height,
        },
        ...skeletonProps,
      }),
    };

    const skeletonArray = Array(skeletonLines || lines || 1).fill('');
    const displayText = attemptFormat(id ? { id, dm } : children) || '';

    return (
      <WrapOn
        {...{
          Wrap: Grid,
          on: item || !!itemProps,
          item,
          ...itemProps,
          ...(ref && { ref }),
        }}
      >
        <WrapOn
          {...{
            Wrap: Flex,
            on: !!hint,
            ...hint?.flexProps,
            sx: {
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.25rem',
              ...hint?.flexProps?.sx,
            },
          }}
        >
          {loading ? (
            <WrapOn {...{ Wrap: Flex, on: groupSkeletons, ...groupSkeletons }}>
              {skeletonArray.map((_, key, { length }) => (
                <Skeleton
                  {...{
                    ...skeleton.text(key === length - 1 && length > 1),
                    key,
                  }}
                />
              ))}
            </WrapOn>
          ) : dangerouslySetInnerHTML ? (
            <Typography
              {...{
                onClick,
                ...style,
                dangerouslySetInnerHTML,
                ...(ref && !(item || !!itemProps) && { ref }),
              }}
            />
          ) : displayText ? (
            <Typography
              {...{
                onClick,
                ...style,
                ...(ref && !(item || !!itemProps) && { ref }),
              }}
            >
              {splitText
                ? displayText
                    .split(' ')
                    .map((text, key) => <span {...{ key }}>{text + ' '}</span>)
                : replace
                ? displayText.split(' ').map(replace)
                : replaceMap
                ? displayText
                    .split(' ')
                    .map((word, key) => (
                      <React.Fragment {...{ key }}>
                        {checkFunc(replaceMap[word.toLowerCase()], word) ?? (
                          <span>{' ' + word + ' '}</span>
                        )}
                      </React.Fragment>
                    ))
                : displayText}
              {/* {id && formatMessage({ id, defaultMessage })}
        {!id && children} */}
            </Typography>
          ) : (
            <></>
          )}
          {!!hint && (
            <MuiTooltip
              {...{
                clickToggle: true,
                title: { id: hint?.id, dm: hint?.dm },
                ...hint?.tooltipProps,
              }}
            >
              {React.cloneElement(
                hint?.icon ||
                  (hint?.outlineIcon ? <HelpOutlineIcon /> : <HelpIcon />),
                mergeProps(hint?.iconProps, {
                  sx: {
                    fontSize: hint?.size || '0.975rem',
                    color: hint?.color || 'icons.main',
                  },
                }),
              )}
            </MuiTooltip>
          )}
        </WrapOn>
      </WrapOn>
    );
    function variantStyles({ variant }) {
      const variants = {
        pageTitle: {
          as: 'h2',
          fontSize: { xs: '1.5rem', md: '2.25rem' },
          color: 'primary',
        },
        cardTitle: {
          as: 'h3',
          fontSize: { xs: '0.875rem', md: '1rem' },
          color: 'headingText',
        },
        sectionTitle: {
          sx: { fontSize: '1rem', color: 'primary.main' },
        },
      };
      return variants[variant] || {};
    }
  },
);

export default MuiText;

import React from 'react';
import useAuth from '../../hooks/useAuth';
import useSubscriptionPlan from '../../hooks/useSubscriptionPlan';
import Flex from '../common/Flex';
import ImageBox from '../common/ImageBox';
import MuiCard from '../common/MuiCard';
import MuiNamedLink from '../common/MuiNamedLink';
import { SUBSCRIPTION_PLANS as plans } from '../../data/constants';
import MuiTag from '../intl/MuiTag';

const UserNavCard = ({ handleClose }) => {
  const { user } = useAuth();
  const { plan, planLoading } = useSubscriptionPlan();
  const isSeller = user?.roles === '2';

  const { text, style } = makeProps({
    user,
    plans,
    plan,
    planLoading,
    handleClose,
  });
  return (
    <MuiCard {...style.card}>
      <ImageBox {...style.image} />
      <Flex {...style.stack}>
        <MuiNamedLink {...text.name} />
        <Flex {...style.linkAndPlan}>
          <MuiNamedLink {...text.view} />
          {isSeller && <MuiTag {...text.plan} />}
        </Flex>
      </Flex>
    </MuiCard>
  );

  function makeProps({ user, plans, plan, planLoading, handleClose }) {
    const planColor =
      plans.find(({ id }) => id === plan.type)?.color || 'headingText';
    const { profileImage, firstName, roles } = user || {};
    return {
      text: {
        name: {
          userProfile: user,
          children: firstName,
          onClick: handleClose,
          hoverColor: 'primary.main',
          lines: 1,
          sxText: {
            display: 'block',
            fontWeight: 'bold',
            fontSize: '1.125rem',
            height: '1.5rem',
            color: 'headingText.main',
            maxWidth: '100%',
          },
        },
        view: {
          id: 'navbar.navItem.viewProfile',
          dm: 'View Profile',
          userProfile: user,
          onClick: handleClose,
          hoverColor: 'primary.main',
          sxText: { color: 'headingText.main', lineHeight: '1.25rem' },
        },
        plan: {
          id: `plan.type.${plan.type}`,
          dm: plan.type,
          loading: planLoading,
          sx: { borderRadius: '10rem' },
          sxText: { fontWeight: 'bold' },
          color: `${planColor}.main`,
          textColor: 'card.main',
          ...(plan.type === 'basic' && {
            color: `${planColor}.filter`,
            textColor: `${planColor}.main`,
          }),
          sxSkeleton: { width: '4rem', borderRadius: '10rem' },
        },
      },
      style: {
        card: {
          direction: 'row',
          sx: {
            p: '1rem',
            gap: '1rem',
            m: '0.5rem 1rem',
            alignItems: 'center',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
          },
        },
        image: {
          namedLink: { userProfile: user, onClick: handleClose },
          image: profileImage,
          variant: 'profilePicture',
          userRole: roles,
          size: '2.5rem',
          boxProps: { sx: { borderRadius: '50%' } },
        },
        stack: {
          direction: 'column',
          sx: { alignItems: 'flex-start', flexGrow: '1', gap: '0.25rem' },
        },
        linkAndPlan: {
          sx: {
            width: '100%',
            gap: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        },
      },
    };
  }
};

export default UserNavCard;

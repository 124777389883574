import { useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { BreakpointContext } from '../components/wrappers/BreakpointWrapper';

const useBreakpoint = (firstInput, secondInput) => {
  const output = useContext(BreakpointContext);
  const noInput = !firstInput && !secondInput;
  const sizeFirst = firstInput && !['up', 'down'].includes(firstInput);
  const direction =
    (noInput ? 'down' : sizeFirst ? secondInput : firstInput) || 'down';
  const size = sizeFirst ? firstInput : secondInput;
  const usingCustom = noInput || typeof size === 'number';

  const customValue = usingCustom
    ? sizeFirst
      ? firstInput
      : secondInput
    : 1000;

  const customOutput = useMediaQuery(
    `(${direction === 'down' ? 'max' : 'min'}-width:${customValue}px)`,
  );

  return noInput
    ? output[direction]
    : usingCustom
    ? customOutput
    : output[direction][size];
};

export default useBreakpoint;

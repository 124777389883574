import React from 'react';
import Flex from '../common/Flex';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { mergeProps } from '../../utils/mergeProps';
import useFormState from '../../hooks/useFormState';
import useQueryState from '../../hooks/useQueryState';
import CloseIcon from '@mui/icons-material/Close';

const NavSearchBar = ({
  triggerSearch,
  sxBox,
  sxField,
  loading,
  boxProps,
  inputProps,
}) => {
  const { attemptFormat } = useLocale();
  const [searchText, setSearchText] = useQueryState('search', '', {
    encoded: true,
  });
  const [value, onChange] = useFormState(searchText);

  const clearSearch = () => {
    onChange({ target: { value: '' } });
    setSearchText('');
  };

  const { style } = makeProps({
    loading,
    attemptFormat,
    clearSearch,
    value,
    onChange,
    triggerSearch,
  });
  return (
    <Flex {...style.search(mergeProps(boxProps, { sx: sxBox }))}>
      <InputBase
        {...style.searchInput(mergeProps(inputProps, { sx: sxField }))}
      />
    </Flex>
  );
  function makeProps({
    loading,
    attemptFormat,
    value,
    onChange,
    triggerSearch,
  }) {
    const onKeyDown = ({ key }) => key === 'Enter' && triggerSearch(value);

    return {
      style: {
        search: (props) =>
          mergeProps(props, {
            sx: {
              position: 'relative',
              borderRadius: '5px',
              backgroundColor: loading
                ? 'placeholderText.light'
                : 'background.main',
              my: '0.5rem',
              '&:hover': {
                backgroundColor: loading
                  ? 'iconsText.light'
                  : 'background.dark',
              },
            },
          }),
        searchIconWrapper: (props) =>
          mergeProps(props, {
            sx: {
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '0.5rem',
            },
          }),
        searchIcon: (props) =>
          mergeProps(props, {
            color: loading ? 'bodyText' : 'primary',
            sx: { height: '1.5rem', width: '1.5rem' },
          }),
        searchInput: (props) =>
          mergeProps(props, {
            value,
            onChange,
            onKeyDown,
            type: 'search',
            disabled: loading,
            placeholder: attemptFormat({ id: 'search.dots', dm: 'Search...' }),
            inputProps: {
              'aria-label': attemptFormat({ id: 'search', dm: 'Search' }),
            },
            sx: {
              color: loading ? 'bodyText.main' : 'primary.main',
              pl: '0.25rem',
              pr: '0.25rem',
              fontSize: '0.875rem',
              '& .MuiInputAdornment-root': { width: '1.75rem' },
            },
            startAdornment: (
              <InputAdornment {...{ position: 'start' }}>
                <IconButton
                  {...{ color: 'primary', sx: { p: '0.25rem' } }}
                  {...{ onClick: () => triggerSearch(value) }}
                >
                  <SearchIcon {...{ sx: { fontSize: '1.5rem' } }} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment {...{ position: 'start' }}>
                <IconButton
                  {...{
                    onClick: clearSearch,
                    color: 'bodyText',
                    sx: { p: '0.25rem', ...(!value && { display: 'none' }) },
                  }}
                >
                  <CloseIcon {...{ sx: { fontSize: '1.5rem' } }} />
                </IconButton>
              </InputAdornment>
            ),
          }),
      },
    };
  }
};

export default NavSearchBar;

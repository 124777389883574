import { Autocomplete, Chip, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import useLocale from '../../hooks/useLocale';
import { controlInputs, isTag } from '../../hooks/useValidator';
import Flex from '../common/Flex';
import MuiText from '../intl/MuiText';

const MuiTagsField = ({
  name = 'tags',
  label = { id: 'posts.demandPost.tags', dm: 'Tags' },
  required,
  disabled,
  placeholder = {
    id: 'forms.tags.placeholder',
    dm: "Separate your tags with a ','",
  },
  limit = 4,
}) => {
  const { attemptFormat } = useLocale();
  const [inputValue, setInputValue] = useState('');
  const [warning, setWarning] = useState('');
  const {
    setFieldValue,
    values: { [name]: currentValue },
    errors: { [name]: fieldError },
    // touched: { [name]: fieldTouched },
    touched,
    setTouched,
    validateForm,
  } = useFormikContext();
  const { [name]: fieldTouched } = touched;
  const limitReached = currentValue.length >= limit;
  // tag1, test-multi-word-tag, tag3, t

  const validChips = (value) =>
    value
      .replaceAll(/\s+/g, ',')
      .replaceAll(/['"]+/g, '')
      .split(',')
      .filter((el) => {
        if (!controlInputs(el)) {
          setWarning(text.noControlled);
          return false;
        }
        const isValid = isTag(el);
        if (!isValid && !!el) setWarning(text.invalidTags);
        return isValid;
      })
      .reduce(
        (fullArray, nextChip) =>
          fullArray.concat(
            [nextChip].filter((chip) => {
              const isUnique = ![...fullArray, ...currentValue]
                .map((el) => el.toLowerCase())
                .includes(chip.toLowerCase());
              if (!isUnique) setWarning(text.duplicateTags);
              return isUnique;
            }),
          ),

        [],
      );

  const updateInput = (value = '') =>
    value &&
    setFieldValue(
      name,
      [...currentValue, ...validChips(value)].slice(0, limit),
    );

  const formattedLabel = attemptFormat(label);
  const formattedPlaceholder = attemptFormat(placeholder);
  const { text, style } = makeProps();
  return (
    <Field {...{ name }}>
      {({ form: { isSubmitting } }) => (
        <Flex {...style.stack}>
          <Autocomplete
            {...{
              ...style.autocomplete,
              inputValue,
              value: currentValue,
              componentsProps: {
                clearIndicator: {
                  disabled: isSubmitting,
                  onClick: () => {
                    setWarning('');
                    setFieldValue(name, []);
                  },
                },
              },
              renderInput: (params) => (
                <TextField
                  {...style.textInput({
                    ...params,
                    label: formattedLabel,
                    disabled: isSubmitting,
                    placeholder: formattedPlaceholder,
                    error: fieldTouched && !!fieldError,
                    helperText: fieldTouched && fieldError,
                  })}
                />
              ),
              renderTags: (value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...{
                      ...style.chip(getTagProps({ index }), option),
                      disabled: isSubmitting,
                      onDelete: () =>
                        setFieldValue(name, removeIndex(currentValue, index)),
                    }}
                  />
                )),
              onKeyDown: ({
                code,
                // shiftKey
              }) => {
                if (code !== 'Backspace' || inputValue || !currentValue.length)
                  return;
                setFieldValue(name, [...currentValue].slice(0, -1));
                // if (!shiftKey)
                setInputValue([...currentValue].slice(-1)[0] + ' '); // space is added so last char isn't removed
              },
              onInputChange: (_, value = '') => {
                setWarning('');
                if (limitReached) {
                  setInputValue('');
                  setWarning(text.limitReached);
                  return;
                }
                if (!triggerChips(value)) return setInputValue(value);
                if (!validChips(value).length && value) return;

                setInputValue('');
                updateInput(value);
                setTimeout(() => validateForm(), 0);
              },
              onBlur: ({ target: { value } }) => {
                if (!validChips(value).length && value) return;
                setInputValue('');
                updateInput(value);
                setTouched({ ...touched, [name]: true });
                setTimeout(() => validateForm(), 0);
              },
              onChange: ({ target: { value } }) => {
                updateInput(value);
                setTimeout(() => validateForm(), 0);
              },
            }}
          />
          {!!warning && (
            <MuiText {...style.warning}>{attemptFormat(warning)}</MuiText>
          )}
        </Flex>
      )}
    </Field>
  );

  function removeIndex(inputArray, index) {
    return [...inputArray].filter((_, arrayIndex) => arrayIndex !== index);
  }

  function triggerChips(value = '') {
    return [',', ' ', '\n'].includes(value.slice(-1));
  }

  function makeProps() {
    return {
      text: {
        invalidTags: {
          id: 'forms.error.tags.length',
          dm: 'Tags must be at least two characters long',
        },
        noControlled: {
          id: 'forms.error.tags.controlled',
          dm: 'Tags cannot be urls, phone numbers, or email addresses',
        },
        duplicateTags: {
          id: 'forms.error.tags.duplicate',
          dm: 'No duplicate tags',
        },
        limitReached: {
          id: 'forms.error.tags.limit',
          dm: 'Maximum of 4 tags',
        },
      },
      style: {
        stack: { direction: 'column', sx: { width: '100%' } },
        textField: (props) => ({
          ...props,
          sx: {
            position: 'relative',
            width: '100%',
            '& .MuiInputBase-input': {
              py: '8.5px',
              height: '2.5rem',
              fontSize: '0.875rem',
            },
            '& .MuiOutlinedInput-root': {
              height: '2.5rem',
              overflow: 'hidden',
            },
            '& .MuiInputLabel-asterisk': {
              fontWeight: 'bold',
              color: 'error.main',
            },
            '& .MuiInputLabel-root': {
              top: '-7px',
              fontSize: '0.875rem',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(12px, -8px) scale(0.9)',
              },
            },
            '& .MuiInputLabel-shrink': { top: '0' },
            ...props?.sx,
          },
        }),
        autocomplete: {
          multiple: true,
          id: 'tags-filled',
          options: [],
          freeSolo: true,
          sx: { width: '100%' },
        },
        textInput: (props) => ({
          ...props,
          sx: {
            width: '100%',
            '& input': {
              width: 'calc(100% - 0.5rem) !important',
              p: '0.25rem !important',
            },
            '& label': {
              transform: 'translate(14px, 12px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(12px, -8px) scale(0.8)',
              },
            },
            ...props?.sx,
          },
          variant: 'outlined',
        }),
        chip: (props, option) => ({
          variant: 'outlined',
          label: option,
          ...props,
          sx: {
            backgroundColor: 'background.light',
            '& .MuiChip-deleteIcon': {
              color: 'primary.main',
              '&:hover': { color: 'primary.dark' },
            },
            ...props?.sx,
          },
        }),
        warning: {
          sx: { fontSize: '0.875rem', color: 'warning.main' },
        },
      },
    };
  }
};

export default MuiTagsField;

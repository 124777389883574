import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Stack,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useRedirect from '../../hooks/useRedirect';
import useToaster from '../../hooks/useToaster';
import { logout } from '../../reducers/authReducer';
import LoginModal from '../authentication/LoginModal';
import RegisterModal from '../authentication/RegisterModal';
import FormattedButton from '../intl/FormattedButton';
import AdminMenuSection from './AdminMenuSection';
import HeaderSection from './HeaderSection';
import HomeMenuSection from './HomeMenuSection';
import TopSectionExpandedDrawer from './TopSectionExpandedDrawer';
import UserMenuSection from './UserMenuSection';
import UserProfileSection from './UserProfileSection';
import { useIntl } from 'react-intl';
import useAuth from '../../hooks/useAuth';

const StyledDrawerBody = styled(DrawerBody)`
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    scrollbar-face-color: #333;
    scrollbar-shadow-color: #808080;
    scrollbar-highlight-color: #808080;
    scrollbar-3dlight-color: #333;
    scrollbar-darkshadow-color: #333;
    scrollbar-track-color: #333;
    scrollbar-arrow-color: #808080;
  }
`;

const ExpandedDrawer = ({ btnIcon }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const toaster = useToaster();
  const isLoggedIn = Boolean(user);
  /*hamburger expanded drawer*/
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  /* NavItem toggle */
  const [showHome, setShowHome] = useState(true);
  const [showUserDashboard, setShowUserDashboard] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);

  const handleToggleHome = () => setShowHome(!showHome);
  const handleToggleUserDashboard = () =>
    setShowUserDashboard(!showUserDashboard);
  const handleToggleAdmin = () => setShowAdmin(!showAdmin);

  const theme = useTheme();
  const globalFontSize = theme.fontSizes.md.split('rem')[0];

  const [isInline, setIsInline] = useState(false);
  const { isAuthenticated, isAdmin, isModerator } = useAuth();

  useEffect(() => {
    if (globalFontSize >= 1.5) {
      setIsInline(false);
    } else {
      setIsInline(true);
    }
  }, [globalFontSize, setIsInline]);

  const handleLogout = () => {
    dispatch(logout());
    redirect('/');
    toaster('logout');
  };

  return (
    <>
      <Button
        ref={btnRef}
        onClick={onOpen}
        px={0}
        rounded="md"
        aria-label={formatMessage({
          id: 'navbar.expand',
          defaultMessage: 'Expand navbar menu',
        })}
        aria-expanded={isOpen}
        aria-controls="app-drawer"
        colorScheme="newBlack"
      >
        {btnIcon}
      </Button>
      <Drawer
        id="app-drawer"
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
        initialFocusRef={DrawerCloseButton}
        // closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent background="#333">
          <TopSectionExpandedDrawer />
          <Stack
            display={{ base: 'flex', lg: 'none' }}
            justifyContent="space-evenly"
            isInline
            // background="#eee"
            mt={4}
            width="81%"
            marginLeft={6}
            borderRadius="0.25rem"
          >
            {isLoggedIn ? (
              <FormattedButton
                variant="solid"
                onClick={handleLogout}
                style={{
                  backgroundColor: '#EDF2F7',
                  color: '#000',
                  width: '100%',
                }}
                id="auth.logout"
                defaultMessage="Logout"
                mr={4}
              />
            ) : (
              <Stack flexDirection="row" fontSize="md" width="100%">
                <RegisterModal
                  isSolid
                  style={{
                    backgroundColor: '#EDF2F7',
                    color: '#000',
                    width: '100%',
                  }}
                />
                <LoginModal
                  isSolid
                  style={{
                    backgroundColor: '#EDF2F7',
                    color: '#000',
                    width: '100%',
                    marginLeft: '13px',
                  }}
                />
              </Stack>
            )}
          </Stack>

          <HeaderSection isInline={isInline} />
          <StyledDrawerBody
            background="#333"
            color="#ccc"
            overflowY="scroll"
            pt={0}
          >
            {isAuthenticated && (
              <>
                {/* user details */}
                <UserProfileSection
                  onClick={() => {
                    onClose();
                    history.push('/profile');
                  }}
                />
              </>
            )}
            <Divider mt={3} />
            <Box
              as="nav"
              role="navigation"
              aria-label={formatMessage({
                id: 'navigation.menu.main',
                defaultMessage: 'Main navigation',
              })}
            >
              {/* Home Menu Items */}
              <HomeMenuSection
                onClick={handleToggleHome}
                onClose={onClose}
                isOpen={isOpen}
                showHome={showHome}
              />
              <Divider />
              {isAuthenticated && (
                <>
                  {/* User Dashboard */}
                  <UserMenuSection
                    onClick={handleToggleUserDashboard}
                    onClose={onClose}
                    isOpen={isOpen}
                    showUserDashboard={showUserDashboard}
                  />
                  <Divider />
                </>
              )}

              {isAuthenticated && (isAdmin || isModerator) && (
                <>
                  {/* Admin */}
                  <AdminMenuSection
                    onClick={handleToggleAdmin}
                    showAdmin={showAdmin}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                  <Divider />
                </>
              )}
            </Box>
          </StyledDrawerBody>
          {/* <DrawerFooter background="#333">
            <Stack
              flex={1}
              display={{ base: 'flex', lg: 'none' }}
              justifyContent="space-evenly"
              isInline
            >
              {isLoggedIn ? (
                <FormattedButton
                  variant="link"
                  colorScheme="blue"
                  onClick={handleLogout}
                  id="auth.logout"
                  defaultMessage="Logout"
                  mr={4}
                />
              ) : (
                <Stack flexDirection="row">
                  <Box mr={4}>
                    <LoginModal />
                  </Box>
                  <Box>
                    <RegisterModal />
                  </Box>
                </Stack>
              )}
            </Stack>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ExpandedDrawer;

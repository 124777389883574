import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconLink from './IconLink';
import Flex from '../common/Flex';
// import MenuArrow from './MenuArrow';
import { logout } from '../../reducers/authReducer';
import { useDispatch } from 'react-redux';
import { boxShadows } from '../../theme/materialTheme';
import { useHistory } from 'react-router-dom';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import useBreakpoint from '../../hooks/useBreakpoint';
import useNavSize from '../../hooks/useNavSize';
import useMuiMenuControl from '../../hooks/useMuiMenuControl';
import ImageBox from '../common/ImageBox';
import useAuth from '../../hooks/useAuth';
import UserNavCard from './UserNavCard';
import SellerNavInfo from './SellerNavInfo';
import SellerStoreNavInfo from './SellerStoreNavInfo';
import SellerNavGoToTradvo from './SellerNavGoToTradvo';
import { Divider } from '@mui/material';
import { refHeight } from '../../utils/nodeInfo';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const ProfileIcon = ({ navLinks = [] }) => {
  const { headerRef, bottomNavRef, progressBarRef } = useNavSize();
  const { push } = useHistory();
  const { user, isAuthenticated, isLoading } = useAuth();
  const { subdomainValue } = useSelector(({ intl }) => intl);
  const { anchorEl, open, handleOpen, handleClose } = useMuiMenuControl();
  const dispatch = useDispatch();
  const { md } = useBreakpoint();
  const smallMobile = useBreakpoint(350);

  const navHeight =
    refHeight(headerRef) + refHeight(bottomNavRef) - refHeight(progressBarRef);

  const linksToShow = navLinks.filter(
    ({ dropdown, loginRequired, viewRestricted }) =>
      dropdown && (!loginRequired || isAuthenticated) && !viewRestricted,
  );

  const handleLogout = () => {
    push('/');
    dispatch(logout());
  };

  const settingsId = 'account-settings-menu';
  const { text, style } = makeProps({
    settingsId,
    smallMobile,
    md,
    navHeight,
    anchorEl,
    open,
    handleClose,
    user,
  });

  return (
    <Flex>
      {!!linksToShow.length && (
        <IconButton
          {...{ ...text.iconButton, ...style.iconButton }}
          onClick={(e) => {
            e.preventDefault();
            handleOpen(e);
          }}
        >
          {(isAuthenticated || isLoading) && <ImageBox {...style.avatar} />}
          <ArrowDropDownIcon color="icons" />
        </IconButton>
      )}
      <Menu {...{ ...style.menu, id: settingsId }}>
        <Flex {...style.linksArea}>
          {isAuthenticated && (
            <>
              <UserNavCard {...{ handleClose }} />
              <SellerNavInfo {...{ handleClose }} />
              {!subdomainValue && <SellerStoreNavInfo {...{ handleClose }} />}
              {subdomainValue && <SellerNavGoToTradvo {...{ handleClose }} />}

              <Divider
                {...{ sx: { color: 'placeholderText.main', m: '0.5rem 1rem' } }}
              />
            </>
          )}
          {linksToShow.map((iconLink, key) => (
            <MenuItem onClick={handleClose} {...{ key, ...style.menuItem }}>
              <IconLink {...iconLink} menu />
            </MenuItem>
          ))}
          {isAuthenticated && (
            <>
              <Divider
                {...{ sx: { color: 'placeholderText.main', m: '1.5rem 1rem' } }}
              />
              <Flex {...{ sx: { alignItems: 'center', gap: '1rem' } }}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleLogout();
                  }}
                  {...style.menuItem}
                >
                  <IconLink
                    text={text.logout}
                    icon={LogoutTwoToneIcon}
                    menu
                    color="error"
                  />
                </MenuItem>
                {md && (
                  <IconButton
                    {...{
                      onClick: handleClose,
                      color: 'headingText',
                      sx: { p: '0.25rem', flexShrink: '0', mr: '1rem' },
                    }}
                  >
                    <CloseIcon {...{ sx: { fontSize: 'inherit' } }} />
                  </IconButton>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Menu>
    </Flex>
  );

  function makeProps({
    settingsId,
    smallMobile,
    md,
    navHeight,
    anchorEl,
    open,
    handleClose,
    user,
  }) {
    const iconSize = !md ? '2.5rem' : !smallMobile ? '2rem' : '1.5rem';
    const { profileImage, roles } = user || {};
    return {
      text: {
        logout: { id: 'auth.logout', dm: 'Logout' },
        iconButton: {
          'aria-label': 'show more',
          'aria-controls': settingsId,
          'aria-haspopup': 'true',
        },
      },
      style: {
        avatar: {
          image: profileImage,
          size: iconSize,
          variant: 'profilePicture',
          userRole: roles,
          boxProps: { sx: { borderRadius: '50%' } },
        },
        iconButton: {
          disableRipple: true,
          sx: { p: 0, my: 'auto', '&:hover': { background: 'none' } },
        },
        menu: {
          anchorEl,
          open,
          onClose: handleClose,
          keepMounted: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          TransitionProps: { timeout: { enter: 300, exit: 300 } },
          ...(md
            ? {
                transformOrigin: { vertical: 'top', horizontal: 'right' },
                sx: {
                  top: '2.6rem',
                  '&>*': {
                    width: '100vw !important',
                    left: '0 !important',
                    right: '0 !important',
                    boxShadow: boxShadows.menu.large,
                    borderRadius: 0,
                    overflow: 'visible',
                  },
                  '& ul': {
                    p: '0',
                    position: 'absolute',
                    width: '100vw !important',
                    left: '0 !important',
                    right: '0 !important',
                  },
                },
              }
            : {
                transformOrigin: { vertical: 'top', horizontal: 'right' },
                sx: {
                  top: '3.25rem',
                  '&>*': {
                    boxShadow: '0',
                    filter: `drop-shadow(${boxShadows.menu.large})`,
                    borderRadius: 2,
                    overflow: 'visible',
                  },
                },
              }),
        },
        menuItem: { sx: { p: '0', width: '100%' } },
        linksArea: {
          customScroll: true,
          direction: 'column',
          sx: {
            backgroundColor: 'card.main',
            width: '20rem',
            maxHeight: `calc(100vh - ${navHeight}px - ${md ? 0 : 32}px)`,
            ...(md && {
              width: '100%',
              boxShadow: '0px 2px 8px rgb(0 0 0 / 16%)',
              //   height: '100vh',
              //   maxHeight: `calc(100vh - ${
              //     (headerRef?.current?.clientHeight || 0) - 32 // must match top value in pixels
              //   }px)`,
            }),
          },
        },
      },
    };
  }
};

export default ProfileIcon;

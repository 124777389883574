import { useCallback, useEffect, useState } from 'react';

const useResizeRef = ({ debugMode } = {}) => {
  const [sizeNode, setSizeNode] = useState(null);
  const [sizeState, setSizeState] = useState({});

  const getSizes = useCallback(
    (node) =>
      setSizeState({
        ...({
          clientHeight: node?.clientHeight,
          clientLeft: node?.clientLeft,
          clientTop: node?.clientTop,
          clientWidth: node?.clientWidth,
          offsetHeight: node?.offsetHeight,
          offsetLeft: node?.offsetLeft,
          offsetTop: node?.offsetTop,
          offsetWidth: node?.offsetWidth,
          scrollHeight: node?.scrollHeight,
          scrollLeft: node?.scrollLeft,
          scrollTop: node?.scrollTop,
          scrollWidth: node?.scrollWidth,
        } || {}),
      }),
    [],
  );

  const sizeRef = useCallback(
    (node) => {
      setSizeNode(node);
      getSizes(node);
    },
    [getSizes],
  );

  if (debugMode) console.log({ sizeState, sizeRef, sizeNode });
  useEffect(() => {
    const resizeListener = () => {
      if (debugMode) console.log('resizeListener', { sizeNode });
      getSizes(sizeNode);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [debugMode, getSizes, sizeNode]);

  const refresh = useCallback(() => getSizes(sizeNode), [getSizes, sizeNode]);

  return { sizeRef, sizeState, sizeNode, refresh };
};

export default useResizeRef;

import React from 'react';
import useSubscriptionPlan from '../../hooks/useSubscriptionPlan';
import Flex from '../common/Flex';
import { NavbarMessages } from '../../data/icons';
import MuiText from '../intl/MuiText';
import MuiButton from '../intl/MuiButton';

const OfferLimitBlocker = () => {
  const { plan } = useSubscriptionPlan();
  const { maxMonthlyOffers } = plan?.limits || {};

  const { text, style } = makeProps({ maxMonthlyOffers });
  return (
    <Flex {...style.stack}>
      <NavbarMessages {...style.icon} />
      <MuiText {...text.title} />
      <MuiText {...text.limitMessage} />
      <MuiText {...text.upgradeMessage} />
      <MuiButton {...style.upgradeButton} />
    </Flex>
  );

  function makeProps({ maxMonthlyOffers }) {
    return {
      text: {
        title: {
          id: 'posts.offerPost.maxLimitReached',
          dm: 'Maximum limit reached',
          sx: {
            fontSize: { xs: '1.125rem', md: '2.25rem' },
            color: 'primary.main',
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        limitMessage: {
          ...(maxMonthlyOffers > 1
            ? {
                id: 'posts.offerPost.limitTextPlural',
                dm: 'Select a plan to post offers this month', // plural
              }
            : {
                id: 'posts.offerPost.limitTextSingular',
                dm: 'Select a plan to post offers this month', // singular
              }),
          replaceMap: { '{LIMIT}': maxMonthlyOffers },
          sx: {
            fontSize: { xs: '0.875rem', md: '1.125rem' },
            textAlign: 'center',
          },
        },
        upgradeMessage: {
          id: 'posts.offerPost.upgradeMessage',
          dm:
            'Upgrade to a higher plan to add more offers and reach more customers',
          sx: {
            fontSize: { xs: '1rem', md: '1.25rem' },
            textAlign: 'center',
          },
        },
      },
      style: {
        stack: {
          direction: 'column',
          sx: {
            p: '0.75rem 0.5rem',
            gap: '1rem',
            alignItems: 'center',
            fontSize: { xs: '5rem', md: '8rem' },
          },
        },
        icon: {
          outline: 'primary.main',
          fill: 'transparent',
          cart: 'secondary.main',
          style: { fontSize: 'inherit' },
        },
        upgradeButton: {
          id: 'messages.chat.limit.upgradeButton',
          dm: 'Upgrade',
          to: '/seller-plan',
          fontSize: '1rem',
          sx: { p: '0.75rem 3rem' },
        },
      },
    };
  }
};

export default OfferLimitBlocker;

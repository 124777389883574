import React from 'react';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import MuiLink from '../common/MuiLink';
import useLocale from '../../hooks/useLocale';
import MuiText from '../intl/MuiText';
import Flex from '../common/Flex';
import WrapOn from '../common/WrapOn';
import useBreakpoint from '../../hooks/useBreakpoint';
import { parseColor } from '../../theme/materialTheme';

const IconLink = ({
  text,
  path,
  icon: Icon,
  menu,
  dropdown,
  badgeContent,
  badgeColor,
  loginRequired,
  viewRestricted,
  color: givenColor,
  ...rest
}) => {
  const { attemptFormat } = useLocale();
  const { md } = useBreakpoint();
  const active =
    path === '/'
      ? window.location.pathname === path
      : window.location.pathname.includes(path);

  const { style } = makeProps({
    menu,
    attemptFormat,
    text,
    active,
    md,
    badgeColor,
    givenColor,
  });
  return (
    <WrapOn
      {...{ Wrap: Flex, on: path === '/notifications', sx: { mt: 'auto' } }}
    >
      <Flex {...style.container}>
        <MuiLink to={path} nonText {...style.link} {...rest}>
          <Flex {...style.layout}>
            <Badge {...{ badgeContent, ...style.badge }}>
              <WrapOn {...style.iconWrap}>
                <Icon {...style.icon} />
              </WrapOn>
            </Badge>
            <Flex {...style.textBox}>
              <MuiText {...style.text}>{attemptFormat(text)}</MuiText>
            </Flex>
          </Flex>
        </MuiLink>
        {!menu && <Box {...style.underline} />}
      </Flex>
    </WrapOn>
  );

  function makeProps({
    menu,
    attemptFormat,
    text,
    active,
    md,
    badgeColor,
    givenColor,
  }) {
    const color = parseColor(
      `${
        givenColor || (active ? 'primary' : menu ? 'headingText' : 'icons')
      }.main`,
    );
    return {
      style: {
        container: {
          direction: 'column',
          sx: {
            mt: 'auto',
            padding: menu ? '0' : { xs: '0.5rem 0.25rem 0', md: '0 0.75rem' },
          },
          minWidth: menu ? '15rem' : 'unset',
          width: menu ? '100%' : 'unset',
        },
        link: {
          sx: { textDecoration: 'none' },
          'aria-label': `Link to ${attemptFormat(text)}`,
        },
        iconWrap: {
          Wrap: Flex,
          on: menu,
          sx: {
            padding: '0.375rem',
            borderRadius: '50%',
            backgroundColor: `${
              givenColor || (active ? 'primary' : 'headingText')
            }.filter`,
          },
        },
        icon: {
          style: {
            color,
            width: menu ? '1.25rem' : '1.75rem',
            height: menu ? '1.25rem' : '1.75rem',
            transition: 'color 300ms',
          },
        },
        textBox: { sx: { maxWidth: menu ? '100%' : 'unset' } },
        text: {
          noWrap: true,
          sx: {
            display: 'block',
            color,
            mr: menu ? '1rem' : '',
            mb: menu ? '' : 'auto',
            height: menu ? { xs: '1.25rem' } : { xs: '1rem', md: '1.125rem' },
            fontSize: menu
              ? { xs: '0.875rem' }
              : { xs: '0.6875rem', md: '0.75rem' },
            transition: 'color 300ms',
          },
        },
        underline: {
          sx: {
            width: '100%',
            height: '0.125rem',
            mt: '0.125rem',
            borderRadius: '1rem',
            transition: 'background-color 300ms',
            ...(active && { backgroundColor: 'primary.main' }),
          },
        },
        layout: {
          direction: menu ? 'row' : 'column',
          sx: {
            alignItems: 'center',
            ...(menu && {
              p: '0.5rem 1rem',
              justifyContent: 'flex-start',
              gap: '0.75rem',
            }),
          },
        },
        badge: {
          max: 99,
          color: badgeColor || 'error',
          sx: { '& .MuiBadge-badge': { fontSize: '0.625rem', p: '0 0.25rem' } },
        },
      },
    };
  }
};

export default IconLink;

import React from 'react';
import { Flex } from '@chakra-ui/react';
//import useMediaQuery from '@mui/material/useMediaQuery';
//import { useTheme } from '@mui/material/styles';
import MuiButton from '../intl/MuiButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useBreakpoint from '../../hooks/useBreakpoint';

const MultiStepNavigation = ({
  hasNext: hasGoBack,
  isLastStep,
  handleNext,
  handleBack,
  termAcceptted,
  handleSubmit,
  registing,
  disbaled = false,
  secondaryButtons,
}) => {
  const { md } = useBreakpoint();
  //const theme = useTheme();
  const text = {
    goBack: {
      id: 'auth.signup.GOBACK',
      dm: 'GO BACK',
    },
    continue: {
      id: 'auth.signup.CONTINUE',
      dm: 'CONTINUE',
    },
    submit: {
      id: 'auth.signup.SUBMIT',
      dm: 'SUBMIT',
    },
    selection: {
      id: 'auth.signup.accountSelection',
      dm: 'ACCOUNT SELECTION',
    },
  };

  const style = {
    selection: {
      to: '/account-type',
      startIcon: (
        <ArrowBackIosIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
      ),
      variant: 'outlined',
      ...(secondaryButtons && { color: 'secondary' }),
      sxText: { ml: 0 },
      sx: {
        fontSize: '1rem',
        width: '20rem',
        padding: '0rem',
        justifyContent: 'space-between',
        p: '0.5rem 1rem',
        flexGrow: '1',
        flexShrink: '1',
        ...(md && { height: '4rem' }),
      },
    },
    goBack: {
      startIcon: (
        <ArrowBackIosIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
      ),
      variant: 'outlined',
      ...(secondaryButtons && { color: 'secondary' }),
      sxText: { ml: 0 },
      sx: {
        fontSize: '1rem',
        width: '20rem',
        padding: '0rem',
        justifyContent: 'space-between',
        p: '0.5rem 1rem',
        flexGrow: '1',
        flexShrink: '1',
        ...(md && { height: '4rem' }),
      },
    },
    continue: {
      endIcon: (
        <ArrowForwardIosIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
      ),
      ...(secondaryButtons && {
        color: 'secondary',
        textColor: 'card.main',
        iconColor: 'card.main',
      }),
      sx: {
        fontSize: '1rem',
        width: '20rem',
        padding: '0rem',
        p: '0.5rem 1rem',
        flexGrow: '1',
        flexShrink: '1',
        justifyContent: 'space-between',
        ...(md && { height: '4rem' }),
      },
    },
    submit: {
      ...(secondaryButtons && {
        color: 'secondary',
        textColor: 'card.main',
        iconColor: 'card.main',
      }),
      sx: {
        fontSize: '1rem',
        width: '20rem',
        p: '0.5rem 1rem',
        flexGrow: '1',
        flexShrink: '1',
        ...(md && { height: '4rem' }),
      },
    },
  };
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems="stretch"
      style={{ gap: '1rem' }}
      marginY={md ? 4 : 8}
    >
      {!registing && (
        <>
          {hasGoBack ? (
            <MuiButton
              {...{ ...text.goBack, ...style.goBack }}
              onClick={handleBack}
            />
          ) : (
            <MuiButton {...{ ...text.selection, ...style.selection }} />
          )}
        </>
      )}
      {!isLastStep ? (
        <MuiButton
          {...{ ...text.continue, ...style.continue }}
          onClick={handleNext}
          disabled={disbaled}
        />
      ) : (
        <>
          {!registing && (
            <MuiButton
              {...{ ...text.submit, ...style.submit }}
              onClick={handleSubmit}
            />
          )}
        </>
      )}
    </Flex>
  );
};
export default MultiStepNavigation;

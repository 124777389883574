import React from 'react';
import MuiLink from '../common/MuiLink';
// import mainLogo from '../../data/iconFiles/main-logo.svg';
import useLocale from '../../hooks/useLocale';
import StoreImgLogo from '../sellers/StoreImgLogo';
import useParseBreakpointObject from '../../hooks/useParseBreakpointObject';
import useIntersection from '../../hooks/useIntersection';
import MuiText from '../intl/MuiText';
import Flex from '../common/Flex';

const StoreLogo = ({ business, subdomainStoreId, loading }) => {
  const { attemptFormat } = useLocale();
  const parseBreakpoint = useParseBreakpointObject();
  const avatarSize = parseBreakpoint({ xs: '2rem', md: '3rem' });
  const { ref: popUpRef /*, isIntersecting */ } = useIntersection();
  // const planName = 'basic';
  const { name, planName } = business || {};

  const text = {
    aria: attemptFormat({
      id: 'tradvo.logoAria',
      dm: 'Website Logo',
    }),
    alt: attemptFormat({
      id: 'tradvo.logoLink',
      dm: 'Tradvo Logo and home link',
    }),
  };

  const style = {
    link: {
      nonText: true,
      sx: {
        display: 'flex',
        alignItems: 'center',
        mr: '2rem',
        flexShrink: '0',
      },
    },
    name: {
      children: name,
      loading,
      as: 'h1',
      sx: {
        fontSize: { xs: '0.75rem', md: '1.25rem' },
        fontWeight: 'bold',
        color: 'headingText.main',
        lineHeight: '1.5rem',
      },
      sxSkeleton: { width: '5%' },
    },
    // logo: { style: { width: '9.375rem' } },
    logo: {
      seller: business,
      loading,
      avatarSize,
      ...(!loading && { planName }),
      hasOffset: false,
      namedLink: undefined,
      wrapProps: {
        sx: {
          mb: '0rem',
          width: avatarSize,
          height: {
            xs: `calc(${avatarSize} / 2)`,
            md: `calc(2rem + calc(${avatarSize} / 2))`,
          },
          flexShrink: '0',
        },
      },
      offsetProps: { sx: { top: '0rem' } },
      boxProps: { ref: popUpRef },
      badgeSize: { xs: '0.8rem', md: '1.0rem' },
    },
    nameAndRating: {
      sx: {
        alignItems: 'center',
        gap: { xs: '0.1rem', md: '0.3rem' },
        justifyContent: { xs: 'space-between', md: 'flex-start' },
        width: '100%',
      },
    },
  };

  return (
    <MuiLink to="/" aria-label={text.aria} {...style.link}>
      {/* <img src={mainLogo} alt={text.alt} {...style.logo} /> */}
      <Flex {...style.nameAndRating}>
        <StoreImgLogo {...style.logo} />

        <MuiText {...style.name} />
      </Flex>
    </MuiLink>
  );
};

export default StoreLogo;

import React from 'react';
import Flex from '../common/Flex';
import MuiMultiStep, { MuiFormStep } from '../forms/MuiMultiStep';
import MuiButton from '../intl/MuiButton';
import MuiText from '../intl/MuiText';
import EmailInput from './EmailInput';
import useSnackbar from '../../hooks/useSnackbar';
import tryClient from '../../utils/tryClient';
import useValidator from '../../hooks/useValidator';
import useAuth from '../../hooks/useAuth';
import LinkIcon from '@mui/icons-material/Link';
import { mergeProps } from '../../utils/mergeProps';
import { parseColor, parseStyle } from '../../theme/materialTheme';
import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { formatNamedLink } from '../../utils/formatNamedLink';

const NewInvitationForm = ({
  modalControl,
  dontClose,
  afterSubmit,
  noTrack,
}) => {
  const { user, supplier } = useAuth();
  const validator = useValidator();
  const { snackbar } = useSnackbar();
  const { attemptFormat } = useLocale();
  const [darkMode, setDarkMode] = useState(false);
  const onChange = () => setDarkMode((current) => !current);
  const shareLink =
    window.location.origin +
    formatNamedLink({ supplier, append: '?follow=true' });

  const initialValues = {
    invitedUserRole: '3',
    invitedEmailList: [],
    sellerUserId: user?.id,
    businessProfileId: supplier?.id,
    inviteType: 'buyerRegistration',
    inviteMethod: 'email',
  };

  const validationSchema = validator((schema) => ({
    ...schema.invitedEmailList(),
  }));

  const handleSubmit = async (values) => {
    const formattedValues = {
      invited_email_list: values.invitedEmailList,
      invited_user_role: values.invitedUserRole,
      invite_type: values.inviteType,
      invite_method: values.inviteMethod,
      user_id: values.sellerUserId,
      business_id: values.businessProfileId,
    };

    const { err } = await tryClient('/send-invitation', {
      method: 'POST',
      body: formattedValues,
    });

    if (err) return snackbar({ message: 'error' });
    snackbar({ message: `inviteSuccess${noTrack ? '' : 'Track'}` });
    if (modalControl && !dontClose) modalControl.handleClose();
    if (afterSubmit) afterSubmit();
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      snackbar({ message: 'linkCopySuccess' });
    } catch (e) {
      snackbar({ message: 'linkCopyError' });
    }
  };
  const srcDoc = makeIframeDoc({ darkMode, supplier, attemptFormat });
  const { text, style, field } = makeProps({
    modalControl,
    srcDoc,
    supplier,
    attemptFormat,
    darkMode,
    onChange,
  });

  const iframeText = `<iframe ${Object.entries(style.iframe)
    .map(
      ([key, value]) =>
        `${key}="${
          key === 'srcDoc' ? value.replaceAll('"', '&quot;') : parseStyle(value)
        }"`,
    )
    .join(' ')}></iframe>`;

  const copyIframe = async () => {
    try {
      await navigator.clipboard.writeText(iframeText);
      snackbar({ message: 'iframeCopySuccess' });
    } catch (e) {
      snackbar({ message: 'iframeCopyError' });
    }
  };

  return (
    <Flex {...style.container}>
      <Flex {...style.section}>
        <MuiText {...text.formTitle} />
        <MuiText {...text.limitNote} />
        <MuiMultiStep
          {...{ initialValues, validationSchema, onSubmit: handleSubmit }}
          {...style.multiStepForm}
        >
          <MuiFormStep>
            {() => (
              <Flex {...style.formBox}>
                <EmailInput {...field.email} />
              </Flex>
            )}
          </MuiFormStep>
        </MuiMultiStep>
      </Flex>
      <Flex {...style.stack({ sx: { width: { xs: '100%', md: '50%' } } })}>
        <Flex {...style.section}>
          <Flex {...style.titleAndButton}>
            <MuiText {...text.copyLinkTitle} />
            <MuiButton {...style.copyButton(copyLink)} />
          </Flex>
          <MuiText {...text.copyLinkDescription} />
          <Flex {...style.linkBox(copyLink)}>
            <MuiText {...text.link}>{shareLink}</MuiText>
          </Flex>
        </Flex>
        <Flex {...style.section}>
          <Flex {...style.titleAndButton}>
            <MuiText {...text.copyBadgeTitle} />
            <MuiButton {...style.copyButton(copyIframe)} />
          </Flex>
          <Flex {...style.iframeContainer}>
            <Flex {...style.stack()}>
              <MuiText {...text.iframeInfo} />
              <FormControlLabel {...style.checkbox} />
            </Flex>
            <iframe
              title={attemptFormat({
                id: 'invitations.form.iframeTitle',
                dm: 'Tradvo Business Card',
              })}
              {...style.iframe}
            ></iframe>
          </Flex>
          <Flex {...style.linkBox(copyIframe)}>
            <MuiText {...text.link}>{iframeText}</MuiText>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );

  function makeIframeDoc({ darkMode, supplier, attemptFormat }) {
    const { logoImage, name } = supplier || {};
    const { src, croppedArea } = logoImage || {};
    const boxSize = '2rem';
    const imageSize = (dimension) =>
      croppedArea?.[dimension]
        ? `calc(100 * ${boxSize} / ${croppedArea?.[dimension]})`
        : 'auto';
    const card = parseStyle({
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
      padding: '0.625rem',
      borderRadius: '12px',
      border: '1px solid',
      fontFamily: 'Sans-Serif',
      borderColor: parseColor(darkMode ? 'primary.main' : 'secondary.main'),
      backgroundColor: parseColor(darkMode ? 'primary.main' : 'card.main'),
    });
    const topRow = parseStyle({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '0.5rem',
    });
    const imageBox = parseStyle({
      borderRadius: '5px',
      border: '1px solid',
      borderColor: parseColor(darkMode ? 'card.main' : 'icons.main'),
      backgroundColor: parseColor('card.main'),
      width: boxSize,
      height: boxSize,
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      flexShrink: '0',
    });
    const imageStyle = parseStyle({
      objectFit: 'cover',
      width: 'auto',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      ...(logoImage && {
        position: 'absolute',
        ...(croppedArea
          ? {
              top: 0,
              left: 0,
              width: imageSize('width'),
              height: imageSize('height'),
              transform: `translate(${-croppedArea.x}%,${-croppedArea.y}%)`,
            }
          : { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }),
      }),
    });
    const nameStyle = parseStyle({
      color: parseColor(darkMode ? 'card.main' : 'headingText.main'),
      fontSize: '0.75rem',
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      textDecoration: 'none',
    });
    const buttonBox = parseStyle({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.5rem',
    });

    const buttonStyles = {
      border: '1px solid',
      borderColor: parseColor(darkMode ? 'card.main' : 'primary.main'),
      borderRadius: '6px',
      fontSize: '0.75rem',
      textDecoration: 'none',
      padding: '0.375rem 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    const messageButton = parseStyle({
      ...buttonStyles,
      color: parseColor(darkMode ? 'card.main' : 'primary.main'),
      backgroundColor: parseColor(darkMode ? 'primary.main' : 'card.main'),
    });
    const followButton = parseStyle({
      ...buttonStyles,
      color: parseColor(darkMode ? 'primary.main' : 'card.main'),
      backgroundColor: parseColor(darkMode ? 'card.main' : 'primary.main'),
    });

    const buttonLink = (append) =>
      window.location.origin + formatNamedLink({ supplier, append });

    const poweredStyle = parseStyle({
      fontSize: '0.5rem',
      color: parseColor(darkMode ? 'card.main' : 'bodyText.main'),
      display: 'block',
      textAlign: 'right',
      textDecoration: 'none',
    });

    return `<style>body{margin:0}</style><section style="${card}"><div style="${topRow}"><a style="${imageBox}" target="_blank" href="${buttonLink()}"><img src="${src}" style="${imageStyle}"/></a><a style="${nameStyle}" target="_blank" href="${buttonLink()}">${name}</a></div><div style="${buttonBox}"><a style="${messageButton}" target="_blank" href="${buttonLink(
      '?message=true',
    )}">${attemptFormat({
      id: 'invitations.form.message',
      dm: 'Message',
    })}</a><a style="${followButton}" target="_blank" href="${buttonLink(
      '?follow=true',
    )}">${attemptFormat({
      id: 'invitations.form.follow',
      dm: 'Follow',
    })}</a></div><a style="${poweredStyle}" target="_blank" href="${
      window.location.origin
    }">${attemptFormat({
      id: 'invitations.form.tradvoPowered',
      dm: 'Powered by Tradvo',
    })}</a></section>`;
  }

  function makeProps({
    modalControl,
    srcDoc,
    supplier,
    attemptFormat,
    darkMode,
    onChange,
  }) {
    const sectionTitle = (props) =>
      mergeProps(props, {
        sx: { fontWeight: 'bold', color: 'headingText.main', fontSize: '1rem' },
      });
    const sectionDescription = (props) =>
      mergeProps(props, {
        sx: { color: 'headingText.main', fontSize: '0.875rem' },
      });
    return {
      text: {
        formTitle: sectionTitle({
          id: 'invitations.form.sendInvites',
          dm: 'Send Invitations',
        }),
        limitNote: sectionDescription({
          id: 'invitations.form.limitNote',
          dm:
            'This form will send follow invites to the entered emails, note that the maximum invitations for a week is 20',
        }),
        copyLinkTitle: sectionTitle({
          id: 'invitations.form.copyProfile',
          dm: 'Copy Profile Link',
        }),
        copyLinkDescription: sectionDescription({
          id: 'invitations.form.copyProfileDescription',
          dm:
            'Share a link! When clicked, users will be directed automatically to follow your business page',
        }),
        copyBadgeTitle: sectionTitle({
          id: 'invitations.form.copyEmbed',
          dm: 'Copy Embed Badge',
        }),
        iframeInfo: sectionDescription({
          id: 'invitations.form.copyEmbedDescription',
          dm:
            'This will appear on your website to let customers interact with you directly',
        }),
        link: {
          sx: { fontSize: '0.875rem', minWidth: '0', whiteSpace: 'nowrap' },
        },
      },
      style: {
        container: {
          direction: { xs: 'column', md: 'row' },
          sx: {
            gap: '1rem',
            alignItems: { xs: 'stretch', md: 'flex-start' },
            width: '100%',
          },
        },
        iframe: {
          srcDoc,
          title: [
            supplier.name,
            attemptFormat({
              id: 'invitations.form.iframeTitle',
              dm: 'Tradvo Business Card',
            }),
          ]
            .filter((el) => el)
            .join(' - '),
          width: '160px',
          height: '120px',
          style: {
            backgroundColor: 'transparent',
            margin: '0 auto',
            border: 'none',
          },
          allowtransparency: 'true',
        },
        iframeContainer: {
          direction: { xs: 'column', sm: 'row', md: 'column', lg: 'row' },
          sx: { gap: '1rem' },
        },
        section: {
          direction: 'column',
          sx: {
            gap: '0.5rem',
            p: '1rem',
            borderRadius: '8px',
            backgroundColor: 'background.dark',
          },
        },
        stack: (props) =>
          mergeProps(props, { direction: 'column', sx: { gap: '1rem' } }),
        multiStepForm: {
          preventExit: true,
          modalControl,
          buttonProps: {
            submitProps: {
              id: 'invitations.form.submit',
              dm: 'Send',
              sxText: { fontSize: '1.125rem', fontWeight: 'bold' },
            },
          },
        },
        formBox: { direction: 'column', sx: { py: '0.5rem' } },
        titleAndButton: {
          sx: { justifyContent: 'space-between', alignItems: 'center' },
        },
        copyButton: (clickAction) => ({
          id: 'invitations.form.copyButton',
          dm: 'Copy',
          variant: 'text',
          startIcon: <LinkIcon />,
          sx: { p: '0.125rem 0.5rem' },
          ...(clickAction && { onClick: async () => await clickAction() }),
        }),
        linkBox: (clickAction) => ({
          customScroll: { height: '0.5rem' },
          onClick: async () => await clickAction(),
          sx: {
            overflowX: 'auto',
            alignItems: 'center',
            p: '0.5rem 0',
            borderRadius: '5px',
            backgroundColor: 'background.light',
          },
        }),
        checkbox: {
          control: <Checkbox {...{ checked: darkMode, onChange }} />,
          label: attemptFormat({
            id: 'invitations.form.darkModeLabel',
            dm: 'Dark Mode',
          }),
          sx: { mt: '-0.75rem', mr: 'auto' },
        },
      },
      field: {
        email: {
          name: 'invitedEmailList',
          placeholder: {
            id: 'invitations.form.placeholder',
            dm: 'Type emails separated with a comma ","',
          },
          textInputProps: { autoFocus: true },
          autocompleteProps: {
            sx: {
              '& .MuiOutlinedInput-root': {
                minHeight: '8rem',
                backgroundColor: 'transparent',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
              },
            },
          },
        },
      },
    };
  }
};

export default NewInvitationForm;

import useSnackbar from './useSnackbar';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';
import tryClient from '../utils/tryClient';

const useRecaptcha = (hookInput = 'contact_form') => {
  const { snackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verification = useCallback(async () => {
    if (!executeRecaptcha) return false;
    const token = await executeRecaptcha(hookInput);

    const { err } = await tryClient(`/recaptcha/verify/${token}`);
    if (!err) return true;

    snackbar({ message: 'recaptchaFailed' });
    return false;
  }, [executeRecaptcha, hookInput, snackbar]);

  return verification;
};

export default useRecaptcha;

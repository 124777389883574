import { combineReducers } from 'redux';
import authReducer from './authReducer';
import intlReducer from './intlReducer';
import settingsReducer from './settingsReducer';
import pageReducer from './pageReducer';
import dataReducer from './dataReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  intl: intlReducer,
  settings: settingsReducer,
  page: pageReducer,
  data: dataReducer,
});

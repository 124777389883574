import React from 'react';
import { CircularProgress, Flex } from '@chakra-ui/react';
import FormattedText from '../intl/FormattedText';

function PageLoadingIcon() {
  return (
    <Flex justify="center" align="center" h="50vh" flexDirection="column">
      <CircularProgress color="blue.500" isIndeterminate mb={2} />
      <FormattedText id="navigation.wait" defaultMessage="Please wait" />
    </Flex>
  );
}

export default PageLoadingIcon;

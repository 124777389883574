import React from 'react';
import Flex from '../common/Flex';
import FooterContent from './FooterContent';
import StoreFooterContent from './StoreFooterContent';
import { useSelector } from 'react-redux';

const MuiFooter = ({ noFooter, footerRef }) => {
  const { style } = makeProps();
  const { subdomainValue } = useSelector(({ intl }) => intl);

  return (
    <>
      {!noFooter && (
        <Flex {...{ ...style.container, ref: footerRef }}>
          {!subdomainValue ? (
            <FooterContent {...style.content} />
          ) : (
            <StoreFooterContent {...style.content} />
          )}
        </Flex>
      )}
    </>
  );

  function makeProps() {
    return {
      style: {
        container: {
          sx: {
            flex: '0',
            backgroundColor: 'footer.main',
            zIndex: '3',
            justifyContent: 'center',
            position: 'relative',
          },
        },
        content: {
          linkProps: { sx: { color: 'footerLink.main' } },
          containerProps: { sx: { width: '100%' } },
          mainBoxProps: {
            sx: {
              position: 'relative',
              p: '1rem 1rem 0.5rem',
              right: { xs: '0', narrowPhone: 'calc(calc(100% - 100vw)/2)' },
              maxWidth: { xs: '100%', narrowPhone: 'min(90vw, 45rem)' },
              mx: { xs: '0', narrowPhone: 'auto' },
              display: { xs: 'flex', narrowPhone: 'grid' },
              alignItems: 'flex-start',
              flexDirection: 'column',
              gridTemplateColumns: {
                narrowPhone: '1fr 1fr',
                md: '1fr 1fr 1fr 1fr',
              },
            },
          },
          bottomBoxProps: {
            sx: {
              width: '100%',
              pr: `calc(1.5rem - calc(100vw - 100%))`,
              justifyContent: 'center',
              backgroundColor: 'footer.dark',
            },
          },
        },
      },
    };
  }
};

export default MuiFooter;

import { useEffect } from 'react';
import useNavSize from './useNavSize';

const useScrollListener = ({ scrollListener, debugMode = false } = {}) => {
  const { pageRef } = useNavSize();
  useEffect(() => {
    if (!scrollListener) return;
    const page = pageRef.current;

    if (debugMode) console.log('Add Scroll Listener');
    page.addEventListener('scroll', scrollListener);
    return () => {
      if (debugMode) console.log('Remove Scroll Listener');
      page.removeEventListener('scroll', scrollListener);
    };
  }, [pageRef, scrollListener, debugMode]);
};

export default useScrollListener;

import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Field } from 'formik';
import Flex from '../common/Flex';
import MuiErrorText from './MuiErrorText';
import useLocale from '../../hooks/useLocale';

const MuiCheckbox = ({
  name,
  label,
  required,
  boxProps,
  checkboxProps,
  controlProps,
  errorProps,
}) => {
  const { attemptFormat } = useLocale();
  const { style } = makeProps();
  return (
    <Field {...{ name }}>
      {({ field: { value, onChange }, form: { isSubmitting } }) => (
        <Flex {...style.box(boxProps)}>
          <FormControlLabel
            {...{
              control: (
                <Checkbox
                  {...{ name, required, checked: value, onChange }}
                  {...style.checkbox(checkboxProps, isSubmitting)}
                />
              ),
              label: React.isValidElement(label) ? label : attemptFormat(label),
              ...style.control(controlProps),
            }}
          />
          <MuiErrorText {...{ name, ...style.error(errorProps) }} />
        </Flex>
      )}
    </Field>
  );

  function makeProps() {
    return {
      style: {
        box: (props) => ({
          direction: 'column',
          ...props,
          sx: { width: '100%', ...props?.sx },
        }),
        checkbox: (props, isSubmitting) => ({
          ...props,
          disabled: isSubmitting || props?.disabled,
          sx: { p: '0.25rem', ...props?.sx },
        }),
        control: (props) => ({
          ...props,
          sx: {
            gap: '0.25rem',
            m: '0',
            ...props?.sx,
          },
        }),
        error: (props) => ({
          ...props,
          sx: { ...props?.sx },
        }),
      },
    };
  }
};

export default MuiCheckbox;

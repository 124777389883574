import React from 'react';
import Flex from '../common/Flex';
import MuiCard from '../common/MuiCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageBox from '../common/ImageBox';
import MuiText from '../intl/MuiText';
import { parseColor } from '../../theme/materialTheme';

const SellerInterestCard = ({ seller, selected }) => {
  const { text, style } = makeProps({ seller, selected });
  return (
    <MuiCard {...style.card}>
      <Flex {...style.iconBox}>
        <CheckCircleIcon {...style.icon} />
      </Flex>
      <ImageBox {...style.image} />
      <MuiText {...text.name} />
    </MuiCard>
  );

  function makeProps({ seller, selected }) {
    const { name, logoImage, planName } = seller || {};
    return {
      text: {
        name: {
          children: name,
          lines: 2,
          forceAllLines: true,
          sx: { textAlign: 'center', m: '0.25rem', color: 'headingText.main' },
        },
      },
      style: {
        card: {
          sx: {
            position: 'relative',
            m: '0',
            p: '0',
            maxWidth: '100%',
            overflow: 'hidden',
            boxShadow: '0px 2px 8px rgb(0 0 0 / 16%)',
            outline: '2px solid',
            outlineColor: 'transparent',
            ...(selected && { outlineColor: parseColor('primary.main') }),
            transition: 'outline-color 200ms',
          },
        },
        iconBox: {
          sx: {
            position: 'absolute',
            zIndex: '1',
            top: '0.25rem',
            right: '0.25rem',
            opacity: '0',
            fontSize: '1.75rem',
            color: 'primary.main',
            borderRadius: '10rem',
            backgroundColor: 'card.main',
            ...(selected && { opacity: '1' }),
            transition: 'opacity 200ms',
          },
        },
        icon: {
          sx: { fontSize: 'inherit' },
        },
        image: {
          image: logoImage,
          size: '100%',
          aspectRatio: 1 / 1,
          //   variant: 'sellerLogo',
          planName,
        },
      },
    };
  }
};

export default SellerInterestCard;

const isArray = (arr) => Array.isArray(arr);

const typeCheck = (entity) =>
  isArray(entity) ? 'array' : entity === null ? 'null' : typeof entity;

const parse = {
  //   array: (key, value) => value.length && `${key}=` + value.join('%5C'),
  array: (key, value) => value.length && `${key}=` + value.join(),
  object: (name, object) =>
    Object.entries(object)
      //   .map(([key, value]) => `${name}%5B${key}%5D=${value}`)
      .map(([key, value]) => `${name}[${key}]=${value}`)
      .join('&'),
  number: (key, value) => `${key}=${value}`,
  boolean: (key, value) => `${key}=${value ? 'true' : 'false'}`,
  string: (key, value) => value.trim() && `${key}=${value.trim()}`,
  null: (key) => `${key}=null`,
  undefined: () => '',
};

const getTimeAgo = (dateOffset) => {
  const offsetNumber = Number(dateOffset?.number || 0);
  const unit = dateOffset?.unit || 'days';
  const targetDate = new Date(
    {
      days: new Date().setDate(new Date().getDate() - offsetNumber),
      months: new Date().setMonth(new Date().getMonth() - offsetNumber),
      years: new Date().setFullYear(new Date().getFullYear() - offsetNumber),
    }[unit] || new Date(),
  );

  return targetDate.toLocaleDateString('en-CA');
};

export const stringifyParams = (params) => {
  const {
    search,
    searchin,
    // type,
    categories,
    page,
    perpage,
    sort,
    field,
    // types,
    startDate,
    endDate,
    ...rest
  } = params;

  const queryString = [
    search && 'search=' + search,
    searchin && search && 'searchin=' + searchin,
    // type && 'types=' + type,
    categories?.length && 'category_ids=' + categories.join(),
    page && 'pagination[page]=' + page,
    perpage && 'pagination[perpage]=' + perpage,
    sort && 'sort[sort]=' + sort,
    field && 'sort[field]=' + field,
    ...(startDate?.number || endDate?.number
      ? [
          'start-date=' + getTimeAgo(startDate),
          'end-date=' + getTimeAgo(endDate),
        ]
      : []),
    // types?.length && 'types=' + types.join(','),
    ...Object.entries(rest).map(([key, value]) =>
      parse[typeCheck(value)](key, value),
    ),
  ]
    .filter((el) => el)
    .join('&');

  return queryString ? '?' + queryString : '';
};

export const stringToObject = (queryString) => {
  const [, query = ''] = (queryString || '').split('?');
  const queryArray = query.split('&') || [];
  const queryObject = queryArray.reduce((currentObject, newQuery) => {
    const [param, value] = newQuery.split('=');
    currentObject[
      {
        'sort[sort]': 'sort',
        'sort[field]': 'field',
        'pagination[page]': 'page',
        'pagination[perpage]': 'perpage',
      }[param] || param
    ] = value;
    return currentObject;
  }, {});

  return queryObject;
};

import React, { useMemo, useState } from 'react';
import useLocale from '../../hooks/useLocale';
export const HelmetContext = React.createContext();

const HelmetProvider = ({ children }) => {
  const { attemptFormat } = useLocale();

  const [title, setTitle] = useState(
    'Tradvo - AI Powered platform where businesses and Individuals connect, engage, and shop.',
  );
  const [description, setDescription] = useState(
    'Build your profile as a seller, and get followers of businesses and individuals with interest in your products or services. Engage with your supplier and get the best prices as a buyer.',
  );

  const titleString = attemptFormat(title);
  const descriptionString = attemptFormat(description);

  const value = useMemo(
    () => ({
      title: titleString,
      setTitle,
      description: descriptionString,
      setDescription,
    }),
    [titleString, descriptionString],
  );

  return (
    <HelmetContext.Provider {...{ value }}>{children}</HelmetContext.Provider>
  );
};

export default HelmetProvider;

import { useCallback } from 'react';
import useAsyncState from './useAsyncState';

const useMuiModalControl = (initialValue) => {
  const [canClose, setCanClose] = useAsyncState(true);
  const [closeAttempted, setCloseAttempted] = useAsyncState(false);
  const [open, setOpen] = useAsyncState(initialValue || false);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(
    () => (canClose ? setOpen(false) : setCloseAttempted(true)),
    [canClose, setOpen, setCloseAttempted],
  );

  const toggle = useCallback(() => setOpen((current) => !current), [setOpen]);

  const forceOpen = useCallback(() => setOpen(true), [setOpen]);
  const forceClose = useCallback(() => setOpen(false), [setOpen]);

  return {
    open,
    setOpen,
    handleOpen,
    handleClose,
    toggle,
    canClose,
    setCanClose,
    closeAttempted,
    setCloseAttempted,
    forceOpen,
    forceClose,
  };
};

export default useMuiModalControl;

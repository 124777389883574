import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const BreakpointContext = React.createContext();

const BreakpointWrapper = ({ children }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const verySmallPhoneDown = useMediaQuery(
    theme.breakpoints.down('verySmallPhone'),
  );
  const narrowPhoneDown = useMediaQuery(theme.breakpoints.down('narrowPhone'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const maxContentDown = useMediaQuery(theme.breakpoints.down('maxContent'));
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));
  const xxlDown = useMediaQuery(theme.breakpoints.down('xxl'));
  const xsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const verySmallPhoneUp = useMediaQuery(
    theme.breakpoints.up('verySmallPhone'),
  );
  const narrowPhoneUp = useMediaQuery(theme.breakpoints.up('narrowPhone'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const maxContentUp = useMediaQuery(theme.breakpoints.up('maxContent'));
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const xxlUp = useMediaQuery(theme.breakpoints.up('xxl'));

  const value = useMemo(
    () => ({
      down: {
        xs: xsDown,
        verySmallPhone: verySmallPhoneDown,
        narrowPhone: narrowPhoneDown,
        sm: smDown,
        md: mdDown,
        lg: lgDown,
        maxContent: maxContentDown,
        xl: xlDown,
        xxl: xxlDown,
      },
      up: {
        xs: xsUp,
        verySmallPhone: verySmallPhoneUp,
        narrowPhone: narrowPhoneUp,
        sm: smUp,
        md: mdUp,
        lg: lgUp,
        maxContent: maxContentUp,
        xl: xlUp,
        xxl: xxlUp,
      },
    }),
    [
      xsDown,
      verySmallPhoneDown,
      narrowPhoneDown,
      smDown,
      mdDown,
      lgDown,
      maxContentDown,
      xlDown,
      xxlDown,
      xsUp,
      verySmallPhoneUp,
      narrowPhoneUp,
      smUp,
      mdUp,
      lgUp,
      maxContentUp,
      xlUp,
      xxlUp,
    ],
  );

  return (
    <BreakpointContext.Provider {...{ value }}>
      {children}
    </BreakpointContext.Provider>
  );
};

export default BreakpointWrapper;

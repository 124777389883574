import React from 'react';
import { Box } from '@chakra-ui/react';

const Container = ({ padding = '0', margin = '0 auto', children, ...rest }) => {
  return (
    <Box maxW={1300} width="95%" m={margin} p={padding} {...rest}>
      {children}
    </Box>
  );
};

export default Container;

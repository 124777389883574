import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useToaster from '../../hooks/useToaster';

function RedirectToHomepage({ toast = 'mustLogin' }) {
  const history = useHistory();
  const toaster = useToaster();

  useEffect(() => {
    const redirect = () => {
      history.push('/');
      toaster(toast);
    };

    redirect();
  }, [history, toast, toaster]);

  return null;
}

export default RedirectToHomepage;

import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  FILE_UPLOAD_PHOTO_SIZE_LIMIT,
  FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS,
  FILE_UPLOAD_CODE_SUPPORTED_FORMATS,
  DOCUMENT_UPLOAD_PHOTO_SUPPORTED_FORMATS,
  MESSAGE_UPLOAD_SIZE_LIMIT,
  MESSAGE_UPLOAD_FILE_TYPES,
  DISPUTE_UPLOAD_FILE_TYPES,
  PHONE_NUMBER_REGEX,
  EMAIL_REGEX,
  TAX_OPTIONS,
  MIN_TAG_SIZE,
  EMAIL_TEXT_REGEX,
  URL_TEXT_REGEX,
  PHONE_NUMBER_TEXT_REGEX,
  VIDEO_UPLOAD_TYPES,
  FILE_UPLOAD_VIDEO_SIZE_LIMIT,
} from '../data/constants';

function useValidator() {
  const intl = useIntl();

  const msg = (id) => intl.formatMessage({ id: 'forms.error.' + id });
  function validator(callback) {
    return Yup.object().shape(callback(schema));
  }
  const schema = {
    password: () => ({
      password: Yup.string()
        .min(8, msg('password.min'))
        .max(255, msg('max'))
        // .matches(
        //   /*
        //     /^
        //       (?=.*\d)          // should contain at least one digit
        //       (?=.*[a-z])       // should contain at least one lower case
        //       (?=.*[A-Z])       // should contain at least one upper case
        //       [a-zA-Z0-9\W]{8,}   // should contain at least 8 from the mentioned characters
        //     $/
        //   */
        //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\W]{8,}$/,
        //   msg('password.valid'),
        // )
        .required(msg('password')),
    }),
    currentPassword: () => ({
      currentPassword: Yup.string()
        .min(8, msg('password.min'))
        .max(255, msg('max'))
        .required(msg('password')),
    }),
    newPasswordConfirm: () => ({
      newPasswordConfirm: Yup.string()
        .oneOf(
          [Yup.ref('newPassword'), null],
          intl.formatMessage({ id: 'auth.error.password.confirm' }),
        )
        .required(
          intl.formatMessage({ id: 'auth.error.password.confirm.required' }),
        ),
    }),
    newPassword: () => ({
      newPassword: Yup.string()
        .notOneOf(
          [Yup.ref('currentPassword'), null],
          intl.formatMessage({ id: 'forms.error.password.notSame' }),
        )
        .min(8, msg('password.min'))
        .max(255, msg('max'))
        .matches(
          /*
          /^
            (?=.*\d)          // should contain at least one digit
            (?=.*[a-z])       // should contain at least one lower case
            (?=.*[A-Z])       // should contain at least one upper case
            [a-zA-Z0-9\W]{8,}   // should contain at least 8 from the mentioned characters
          $/
        */
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9\W]{8,}$/,
          msg('password.valid'),
        )
        .required(msg('password')),
    }),
    passwordConfirm: () => ({
      passwordConfirm: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          intl.formatMessage({ id: 'auth.error.password.confirm' }),
        )
        .required(
          intl.formatMessage({ id: 'auth.error.password.confirm.required' }),
        ),
    }),
    firstName: () => ({
      firstName: Yup.string()
        .min(2, msg('firstName.min'))
        .max(20, msg('firstName.max'))
        .required(msg('firstName')),
    }),
    lastName: () => ({
      lastName: Yup.string()
        .min(3, msg('min'))
        .max(20, msg('lastName.max'))
        .required(msg('lastName')),
    }),
    email: () => ({
      email: Yup.string().email(msg('email.valid')).required(msg('email')),
    }),
    subdomain: () => ({
      //subdomainvalue: Yup.string().subdomainvalue(msg('subdomainvalue.valid')).required(msg('subdomainvalue')),

      // /^[a-z0-9]+([-_][a-z0-9]+)*$/;
      subdomainvalue: Yup.string()
        .matches(
          /^[a-z0-9]+([-_][a-z0-9]+)*([.][a-z]{2,})?$/,
          msg('subdomainvalue.valid'),
        )
        .min(3, msg('subdomainvalue.short'))
        .required(msg('subdomainvalue')),
    }),
    userEmail: () => ({
      email: Yup.string()
        .test('checkEmail', msg('email.valid'), (value) => {
          if (!value) return true;
          const words = value.split(' ');
          let result = true;
          words.forEach((word) => {
            if (result) result = EMAIL_REGEX.test(word);
          });
          return result;
        })
        .required(msg('email')),
    }),
    emailConfirm: () => ({
      emailConfirm: Yup.string()
        .oneOf(
          [Yup.ref('email'), null],
          intl.formatMessage({ id: 'auth.error.email.notMatch' }),
        )
        .required(
          intl.formatMessage({ id: 'auth.error.email.confirm.required' }),
        ),
    }),
    phone: ({ isOptional } = {}) => ({
      phone: Yup.string().test(
        'validPhone',
        msg('phone.valid'),
        (value) => isPhone(value) || (isOptional && !value),
      ),
    }),
    organization: ({ required } = { required: false }) => {
      const orgSchema = Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(70, msg('organization.max'));
      return {
        organization: required
          ? orgSchema.required(msg('organization'))
          : orgSchema,
      };
    },
    organizationLogoUrl: () => ({
      organizationLogoUrl: Yup.string()
        .min(3, msg('min'))
        .max(1000, msg('url.max'))
        .url(msg('url.valid')),
    }),
    organizationType: ({ required } = { required: true }) => ({
      organizationType: required
        ? Yup.string().required(msg('organizationType'))
        : Yup.string(),
    }),
    employmentSize: () => ({
      employmentSize: Yup.string().required(msg('employmentSize')),
    }),
    specifyOrganization: () => {
      const orgSchema = Yup.string().min(3, msg('min')).max(255, msg('max'));
      return {
        specifyOrganization: orgSchema.when('organizationType', {
          is: 'OTHER',
          then: orgSchema.required(msg('specifyOrganization')),
          otherwise: orgSchema,
        }),
      };
    },
    website: () => ({
      website: Yup.string()
        .matches(/^http[s]{0,1}:\/\//gi, msg('website.needHttp'))
        .max(1000, msg('website.max'))
        .url(msg('website.valid')),
      //.required(msg('website')),
    }),
    facebookUrl: () => ({
      facebookUrl: Yup.string()
        .matches(/^http[s]{0,1}:\/\//gi, msg('website.needHttp'))
        .max(1000, msg('website.max'))
        .url(msg('website.valid')),
      //.required(msg('website')),
    }),
    linkedInUrl: () => ({
      linkedInUrl: Yup.string()
        .matches(/^http[s]{0,1}:\/\//gi, msg('website.needHttp'))
        .max(1000, msg('website.max'))
        .url(msg('website.valid')),
      //.required(msg('website')),
    }),
    contactEmail: () => ({
      contactEmail: Yup.string().email(msg('email.valid')),
    }),
    whatsappPhone: () => ({
      whatsappPhone: Yup.string().test(
        'validPhone',
        msg('phone.valid'),
        (value) => !value || isPhone(value),
      ),
    }),
    fieldOfResearch: () => ({
      fieldOfResearch: Yup.string()
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('fieldOfResearch')),
    }),
    imageUrl: () => ({
      imageUrl: Yup.string()
        .min(3, msg('min'))
        .max(1000, msg('url.max'))
        .url(msg('url.valid')),
    }),
    // invitedEmailList: () => ({
    //     invitedEmailList: Yup.array()
    //       .test(
    //         'nonZeroLength',
    //         msg('invitedEmailList.min'),
    //         (data) => data?.length > 0,
    //       )
    //       .test('areEmails', msg('invitedEmailList.invalidEmails'), (data) =>
    //         data.reduce(
    //           (result, stringLine) => result && isEmail(stringLine),
    //           true,
    //         ),
    //       ),
    //   }),
    tags: () => ({
      tags: Yup.array().test('areTags', msg('tags.validTags'), (data) =>
        data.reduce(
          (result, tagString) =>
            result && isTag(tagString) && controlInputs(tagString),
          true,
        ),
      ),
    }),
    labName: () => ({
      labName: Yup.string().required(),
    }),
    labLevel: () => ({
      labLevel: Yup.number()
        .min(1, msg('labLevel.min'))
        .max(4, msg('labLevel.max'))
        .required(),
    }),
    ratingOld: () => ({
      rating: Yup.number()
        .min(1, msg('rating.min'))
        .max(5, msg('rating.max'))
        .required(),
    }),
    contactPerson: () => ({
      contactPerson: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('contactPerson')),
    }),
    descriptionOfServices: () => ({
      descriptionOfServices: Yup.string()
        .min(3, msg('min'))
        .max(1000, msg('descriptionOfServices.max'))
        .required(msg('descriptionOfServices')),
    }),
    supplierName: () => ({
      supplierName: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('supplierName')),
    }),
    bio: () => ({
      bio: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('bio.max'))
        .required(msg('bio')),
    }),
    issuerNameEnglish: () => ({
      issuerNameEnglish: Yup.string()
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('issuerNameEnglish')),
    }),
    issuerNameFrench: () => ({
      issuerNameFrench: Yup.string().min(3, msg('min')).max(255, msg('max')),
    }),
    titleEnglish: () => ({
      titleEnglish: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('titleEnglish')),
    }),
    titleFrench: () => ({
      titleFrench: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    regulationTextEnglish: () => ({
      regulationTextEnglish: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('regulationText.max'))
        .required(msg('regulationTextEnglish')),
    }),
    regulationTextFrench: () => ({
      regulationTextFrench: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('regulationText.max')),
    }),
    sourceUrlEnglish: () => ({
      sourceUrlEnglish: Yup.string()
        .min(3, msg('min'))
        .max(1000, msg('url.max'))
        .url(msg('url.valid'))
        .required(msg('sourceUrlEnglish')),
    }),
    sourceUrlFrench: () => ({
      sourceUrlFrench: Yup.string()
        .min(3, msg('min'))
        .max(1000, msg('url.max'))
        .url(msg('url.valid')),
    }),
    publishingDate: () => ({
      publishingDate: Yup.date().required(msg('publishingDate')),
    }),
    title: () => ({
      title: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('title')),
    }),
    sku: () => ({
      sku: Yup.string(),
    }),
    description: () => ({
      description: Yup.string()
        .test(
          'controlInputs',
          msg('productDescription.controlInputs'),
          controlInputs,
        )
        .required(msg('description')),
    }),
    price: () => ({
      price: Yup.number().required(msg('price')),
    }),
    productPrice: () => ({
      price: Yup.number(),
    }),
    quantity: () => ({
      quantity: Yup.number().required(msg('quantity')),
    }),
    productQuantity: () => ({
      quantity: Yup.number(),
    }),
    biddingPrice: () => ({
      biddingPrice: Yup.number().required(msg('biddingPrice')),
    }),
    shipping_cost: () => ({
      shipping_cost: Yup.number().required(msg('shipping_cost')),
    }),
    orderCourier: () => ({
      orderCourier: Yup.string().required(msg('orderCourier')),
    }),
    trackingNumber: () => ({
      trackingNumber: Yup.string().required(msg('trackingNumber')),
    }),
    uploadVideo: () => ({}),
    postTextEnglish: () => ({
      postTextEnglish: Yup.string()
        .min(3, msg('min'))
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .max(100000, msg('postTextEnglish.max'))
        .required(msg('postTextEnglish')),
    }),
    postTextFrench: () => ({
      postTextFrench: Yup.string()
        .min(3, msg('min'))
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .max(100000, msg('postTextFrench.max')),
    }),
    itemQuantity: () => ({
      itemQuantity: Yup.number()
        .min(1, msg('itemQuantity.min'))
        .required(msg('itemQuantity')),
    }),
    unitPrice: () => ({
      unitPrice: Yup.number()
        .moreThan(0, msg('unitPrice.min'))
        .required(msg('unitPrice')),
    }),
    moq: () => ({
      moq: Yup.number().min(1, msg('moq.min')).required(msg('moq')),
    }),
    itemTypeDropdown: () => ({
      itemType: Yup.string().required(msg('itemType')),
    }),
    productItemTypeDropdown: () => ({
      itemType: Yup.string(),
    }),
    itemType: () => ({
      itemTypes: Yup.array().required(msg('itemType')).min(1).max(1),
    }),
    itemTypes: () => ({
      itemTypes: Yup.array().required(msg('itemType')).min(1),
    }),
    category: () => ({
      category: Yup.string()
        .max(255, msg('max'))
        .min(1)
        .required(msg('category')),
    }),
    products: ({ isRequired } = {}) => {
      const base = Yup.string().max(255, msg('max'));
      return {
        products: isRequired ? base.required(msg('products')) : base,
      };
    },
    lineOne: () => ({
      lineOne: Yup.string().max(255, msg('max')),
    }),
    addressOne: () => ({
      addressOne: Yup.string().max(255, msg('max')),
    }),
    lineTwo: () => ({
      lineTwo: Yup.string().max(255, msg('max')),
    }),
    addressTwo: () => ({
      addressTwo: Yup.string().max(255, msg('max')),
    }),
    streetNumber: () => ({
      streetNumber: Yup.string()
        .max(255, msg('max'))
        .required(msg('streetNumber')),
    }),
    streetName: () => ({
      streetName: Yup.string().max(255, msg('max')).required(msg('streetName')),
    }),
    city: () => ({
      city: Yup.string()
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('city')),
    }),
    province: () => ({
      province: Yup.string().required(msg('province')),
    }),
    country: () => ({
      country: Yup.string().required(msg('country')),
    }),
    countryShort: () => ({
      countryShort: Yup.string().required(msg('country')),
    }),
    postalCode: () => ({
      postalCode: Yup.string(),
    }),
    promotedProducts: () => ({
      promotedProducts: Yup.array().required(msg('promotedProducts')).min(1),
    }),
    emergency: () => ({
      emergency: Yup.string(),
    }),
    product_id: () => ({
      product_id: Yup.string().required(msg('product_id')),
    }),
    supplier_id: () => ({
      supplier: Yup.string(),
    }),
    category_id: () => ({
      category_id: Yup.string().required(msg('category_id')),
    }),
    requestMessage: () => ({
      requestMessage: Yup.string()
        .required(msg('requestMessage'))
        .min(2, msg('requestMessage.min'))
        .test(
          'no-urls-phoneNumber-email',
          msg('messageText.controlInputs'),
          controlInputs,
        ),
    }),
    special_requirements: () => ({
      special_requirements: Yup.mixed().test(
        'no-urls-phoneNumber-email',
        msg('messageText.controlInputs'),
        controlInputs,
      ),
    }),
    budget: () => ({
      budget: Yup.number().required(msg('budget')),
    }),
    emergencies: () => ({
      emergencies: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(msg('emergencies')),
        }),
      ),
    }),
    categories: () => ({
      categories: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(msg('categories')),
          }),
        )
        .required(msg('requiredCategory'))
        .min(1),
    }),
    checkSuppliers: () => ({
      supplier: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(msg('supplier')),
          }),
        )
        .required(msg('requiredSupplier'))
        .min(1),
    }),
    adsCategories: () => ({
      categories: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(msg('categories')),
        }),
      ),
    }),
    parent_category_id: () => ({
      category_id: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(msg('category_id')).min(1),
        }),
      ),
    }),
    budgetPerDay: () => ({
      budgetPerDay: Yup.number()
        .min(3, msg('budgetPerDay.min'))
        .max(100, msg('budgetPerDay.max'))
        .required(msg('required')),
    }),
    costPerClick: () => ({
      costPerClick: Yup.number().required(msg('required')),
    }),
    postTitleEnglish: () => ({
      postTitleEnglish: Yup.string()
        .required(msg('postTitleEnglish'))
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    postTitleFrench: () => ({
      postTitleFrench: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    photoUrl: () => ({
      photoUrl: Yup.string()
        .min(3, msg('min'))
        .max(1000, msg('url.max'))
        .url(msg('url.valid'))
        .required(msg('photoUrl')),
    }),
    postCategory: () => ({
      postCategory: Yup.string()
        .required(msg('postCategory'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    address: ({ linesRequired } = {}) => ({
      location: Yup.object().shape({
        ...(linesRequired
          ? {
              lineOne: schema.lineOne().lineOne.required(msg('streetNumber')),
              lineTwo: schema.lineTwo().lineTwo.required(msg('streetName')),
            }
          : { ...schema.lineOne(), ...schema.lineTwo() }),
        ...schema.city(),
        ...schema.province(),
        ...schema.postalCode(),
        //...schema.country(), no such field in the form
      }),
    }),
    shippingAddress: () => ({
      shipping_address: Yup.object().shape({
        ...schema.addressOne(),
        ...schema.addressTwo(),
        ...schema.city(),
        ...schema.province(),
        ...schema.postalCode(),
      }),
    }),
    shippingPreferences: () => ({
      shippingPreferences: Yup.string().required(msg('shippingPreferences')),
    }),
    shipping_method: () => ({
      shipping_method: Yup.string().max(255, msg('max')),
    }),
    // suppliers: () => ({
    //   suppliers: Yup.array()
    //     .required(msg('suppliers'))
    //     .min(3, msg('min'))
    //     .max(255, msg('max')),
    // }),
    postType: () => ({
      postType: Yup.string()
        .required(msg('postType'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    disputeDescription: () => ({
      disputeDescription: Yup.string()
        .min(3, msg('min'))
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .max(100000, msg('disputeDescription.max'))
        .required(msg('disputeDescription')),
    }),
    desiredResolution: () => ({
      desiredResolution: Yup.string()
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('desiredResolution')),
    }),
    disputeResolution: () => ({
      disputeResolution: Yup.string().required(msg('disputeResolution')),
    }),
    disputeIssueType: () => ({
      disputeIssueType: Yup.string().required(msg('disputeIssueType')),
    }),
    counterOfferMessage: () => ({
      counterOfferMessage: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(10000, msg('counterOfferMessage.max'))
        .required(msg('counterOfferMessage')),
    }),
    counterOfferMoreInfo: () => ({
      counterOfferMoreInfo: Yup.string()
        .min(3, msg('min'))
        .max(10000, msg('counterOfferMoreInfo.max'))
        .required(msg('counterOfferMoreInfo')),
    }),
    disputerResponseMessage: () => ({
      disputerResponseMessage: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(10000, msg('disputerResponseMessage.max'))
        .required(msg('disputerResponseMessage')),
    }),
    disputedCounterOfferStatus: () => ({
      disputedCounterOfferStatus: Yup.string().required(
        msg('disputedCounterOfferStatus'),
      ),
    }),
    rfqsType: () => ({
      rfqsType: Yup.string()
        .required(msg('rfqsType'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    bidDeliveryDate: () => ({
      bidDeliveryDate: Yup.string().required(msg('bidDeliveryDate')),
    }),
    bidUnitPrice: () => ({
      bidUnitPrice: Yup.number()
        .min(0.01, msg('bidUnitPrice.min'))
        .test('wholeCents', msg('bidUnitPrice.wholeCents'), wholeCents)
        .required(msg('bidUnitPrice')),
    }),
    taxes: ({ name }) => ({
      [name]: Yup.array()
        .min(1, msg('taxes'))
        .test('empty', msg('taxes.empty'), (data) =>
          data.reduce((result, tax) => result && tax.id, true),
        )
        .test('specified', msg('taxes.specifiedName'), (data) =>
          data.reduce(
            (result, { specifiedName }) => result && (specifiedName ?? true),
            true,
          ),
        )
        .test('alreadyExists', msg('taxes.alreadyExists'), (data) =>
          data.reduce(
            (result, { specifiedName }) =>
              result &&
              (!specifiedName ||
                TAX_OPTIONS.reduce(
                  (isUnique, { id }) => isUnique && id !== specifiedName,
                  true,
                )),
            true,
          ),
        )
        .test('zero', msg('taxes.zero'), (data) =>
          data.reduce((result, tax) => result && tax.percent > 0, true),
        ),
    }),
    bidTaxes: () => ({
      bidTaxes: Yup.number().min(0, msg('bidTaxes.min')),
    }),
    bidQuantity: () => ({
      bidQuantity: Yup.number()
        .min(1, msg('bidQuantity.min'))
        .test('wholeNumber', msg('wholeNumber'), wholeNumber)
        .required(msg('bidQuantity')),
    }),
    bidShipping: () => ({
      bidShipping: Yup.number()
        .test('wholeCents', msg('wholeCents'), wholeCents)
        .required(msg('bidShipping')),
      // .moreThan(0, msg('bidShipping.min'))
    }),
    shippingMethod: () => ({
      shippingMethod: Yup.string().required(msg('shippingMethod')),
    }),
    roles: () => ({
      roles: Yup.string().required(msg('roles')),
    }),
    roleNameEnglish: () => ({
      roleNameEnglish: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('roleNameEnglish')),
    }),
    roleDescriptionEnglish: () => ({
      roleDescriptionEnglish: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('roleDescriptionEnglish.max'))
        .required(msg('roleDescriptionEnglish')),
    }),
    roleNameFrench: () => ({
      roleNameFrench: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max'))
        .required(msg('roleNameFrench')),
    }),
    roleDescriptionFrench: () => ({
      roleDescriptionFrench: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('roleDescriptionFrench.max'))
        .required(msg('roleDescriptionFrench')),
    }),
    role: () => ({
      role: Yup.string().required(msg('role')),
    }),
    pluginName: () => ({
      pluginName: Yup.string().required(msg('pluginName')),
    }),
    scriptPath: () => ({
      scriptPath: Yup.string().required(msg('scriptPath')),
    }),
    dataSourceEnName: () => ({
      dataSourceEnName: Yup.string().required(msg('dataSourceEnName')),
    }),
    dataSourceFrName: () => ({
      dataSourceFrName: Yup.string().required(msg('dataSourceFrName')),
    }),
    dataSourceUrl: () => ({
      dataSourceUrl: Yup.string().required(msg('dataSourceUrl')),
    }),
    loginUrl: () => ({
      loginUrl: Yup.string().required(msg('loginUrl')),
    }),
    currentState: () => ({
      currentState: Yup.string().required(msg('currentState')),
    }),
    collectionName: () => ({
      collectionName: Yup.string().required(msg('collectionName')),
    }),
    entriesFetched: () => ({
      entriesFetched: Yup.string().required(msg('entriesFetched')),
    }),
    runningCadenceMins: () => ({
      runningCadenceMins: Yup.string().required(msg('runningCadenceMins')),
    }),
    lastRun: () => ({
      lastRun: Yup.string().required(msg('lastRun')),
    }),
    messageText: () => ({
      messageText: Yup.string()
        .test('no-empty', msg('messageText.empty'), function (value) {
          const { messageAttachment } = this.parent;
          if (!messageAttachment[0]) return !!value;
          return true;
        })
        .test('controlInputs', msg('messageText.controlInputs'), controlInputs)
        .max(10000, msg('messageText.max')),
    }),
    messageAttachment: () => ({
      messageAttachment: Yup.array()
        .test('fileType', msg('messageAttachment.type'), (files) =>
          fileType(files, MESSAGE_UPLOAD_FILE_TYPES),
        )
        .test('fileSize', msg('messageAttachment.size'), (files) =>
          fileSize(files, MESSAGE_UPLOAD_SIZE_LIMIT),
        ),
    }),
    rfqWithdrawReason: () => ({
      rfqWithdrawReason: Yup.string().required(msg('rfqWithdrawReason')),
    }),
    rfqWithdrawMoreInfo: () => ({
      rfqWithdrawMoreInfo: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .max(10000, msg('rfqWithdrawMoreInfo.max'))
        .required(msg('rfqWithdrawMoreInfo')),
    }),
    orderWithdrawReason: () => ({
      orderWithdrawReason: Yup.string().required(msg('orderWithdrawReason')),
    }),
    orderWithdrawMoreInfo: () => ({
      orderWithdrawMoreInfo: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .max(10000, msg('orderWithdrawMoreInfo.max'))
        .required(msg('orderWithdrawMoreInfo')),
    }),
    pluginModule: () => ({
      pluginModule: Yup.mixed()
        .required(msg('pluginModule'))
        .test('fileType', msg('code.fileType'), (files) =>
          fileType(files, FILE_UPLOAD_CODE_SUPPORTED_FORMATS),
        ),
    }),
    uploadPhoto: ({ name } = { name: 'uploadPhoto' }) => ({
      [name]: Yup.mixed()
        .test('fileType', msg('photo.fileType'), (files) =>
          fileType(files, FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS),
        )
        .test('fileSize', msg('photo.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        )
        .test(
          'productRequiresOne',
          msg('productImage.required'),
          (files) => !!files?.length || name !== 'images', // name used for product field only
        ),
    }),
    disputerAttachments: () => ({
      disputerAttachments: Yup.array()
        .test('fileType', msg('disputerAttachments.fileType'), (files) =>
          fileType(files, [
            ...FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS,
            ...DOCUMENT_UPLOAD_PHOTO_SUPPORTED_FORMATS,
          ]),
        )
        .test('fileSize', msg('disputerAttachments.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        )
        .max(5, msg('disputerAttachments.max')),
    }),
    productSpecifications: () => ({
      specifications: Yup.array().test(
        'empty',
        msg('specifications.empty'),
        (data) => {
          let result = true;
          data.forEach((item) => {
            if (!item.property || !item.value) result = false;
          });
          return result;
        },
      ),
    }),
    supportDocs: () => ({
      supportDocs: Yup.array()
        .test('fileType', msg('supportDocs.fileType'), (files) =>
          fileType(
            files.map((file) => file?.url ?? file),
            DOCUMENT_UPLOAD_PHOTO_SUPPORTED_FORMATS,
          ),
        )
        .test('fileSize', msg('disputerAttachments.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        )
        .max(3, msg('supportDocs.max')),
    }),
    businessName: () => ({
      businessName: Yup.string()
        .test('controlInputs', msg('businessName.controlInputs'), controlInputs)
        .required(msg('businessName'))
        .min(3, msg('min'))
        .max(70, msg('businessName.max')),
    }),
    businessShortDescription: () => ({
      businessShortDescription: Yup.string()
        .test(
          'controlInputs',
          msg('businessShortDescription.controlInputs'),
          controlInputs,
        )
        .required(msg('businessShortDescription'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    businessLogo: ({ name } = { name: 'businessLogo' }) => ({
      [name]: Yup.mixed()
        .test('requiredNonDefault', msg('businessLogo'), (files) =>
          nonDefaultImage(files, 'default_business_logo.png'),
        )
        .test('fileType', msg('businessLogo.fileType'), (files) =>
          fileType(files, DISPUTE_UPLOAD_FILE_TYPES),
        )
        .test('fileSize', msg('businessLogo.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        ),
    }),
    businessPitchVideo: () => ({
      businessPitchVideo: Yup.mixed()
        .test('fileType', msg('businessPitchVideo.fileType'), (files) =>
          fileType(files, VIDEO_UPLOAD_TYPES),
        )
        .test('fileSize', msg('businessPitchVideo.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_VIDEO_SIZE_LIMIT),
        ),
    }),
    businessHeaderImage: ({ name } = { name: 'businessHeaderImage' }) => ({
      [name]: Yup.mixed()
        .test('fileType', msg('businessHeaderImage.fileType'), (files) =>
          fileType(files, DISPUTE_UPLOAD_FILE_TYPES),
        )
        .test('fileSize', msg('businessHeaderImage.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        ),
    }),
    businessAboutDescription: () => ({
      businessAboutDescription: Yup.string()
        .test(
          'controlInputs',
          msg('businessAboutDescription.controlInputs'),
          controlInputs,
        )
        .required(msg('businessAboutDescription'))
        .test(
          'minWordCount',
          msg('businessAboutDescription.min'),
          (text) => text?.split(' ').length > 10,
        )
        .max(10000, msg('businessAboutDescription.max')),
    }),
    businessAboutImages: () => ({
      businessAboutImages: Yup.array()
        .test('fileType', msg('businessAboutImages.fileType'), (files) =>
          fileType(files, FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS),
        )
        .test('fileSize', msg('businessAboutImages.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        )
        .max(5, msg('businessAboutImages.max')),
    }),
    businessCertifications: () => ({
      businessCertifications: Yup.array()
        .test('fileType', msg('businessCertifications.fileType'), (files) =>
          fileType(files, FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS),
        )
        .test('fileSize', msg('businessCertifications.fileSize'), (files) =>
          fileSize(files, FILE_UPLOAD_PHOTO_SIZE_LIMIT),
        )
        .max(10, msg('businessCertifications.max')),
    }),
    businessFoundationYear: () => ({
      businessFoundationYear: Yup.string().required(
        msg('businessFoundationYear'),
      ),
    }),
    categoryEnglishName: () => ({
      categoryEnglishName: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('categoryEnglishName'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    categoryEnglishAbbreviation: () => ({
      categoryEnglishAbbreviation: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('categoryEnglishAbbreviation'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    categoryEnglishDescription: () => ({
      categoryEnglishDescription: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('categoryEnglishDescription'))
        .min(3, msg('min'))
        .max(100000, msg('categoryEnglishDescription.max')),
    }),
    categoryFrenchName: () => ({
      categoryFrenchName: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    categoryFrenchAbbreviation: () => ({
      categoryFrenchAbbreviation: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    categoryFrenchDescription: () => ({
      categoryFrenchDescription: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('categoryFrenchDescription.max')),
    }),
    categoryArabicName: () => ({
      categoryArabicName: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    categoryArabicAbbreviation: () => ({
      categoryArabicAbbreviation: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    categoryArabicDescription: () => ({
      categoryArabicDescription: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .min(3, msg('min'))
        .max(100000, msg('categoryArabicDescription.max')),
    }),
    startDate: () => ({
      startDate: Yup.date().required(msg('startDate')),
    }),
    endDate: () => ({
      endDate: Yup.date().required(msg('endDate')),
    }),
    expiry_date: () => ({
      expiry_date: Yup.date().required(msg('expiry_date')),
    }),
    bid_expiry_date: () => ({
      bid_expiry_date: Yup.date().required(msg('bid_expiry_date')),
    }),
    estimatedStartDate: () => ({
      startDate: Yup.date()
        .required(msg('estimatedStartDate.exist'))
        .min(new Date(), msg('estimatedStartDate.valid')),
    }),
    estimatedEndDate: () => ({
      endDate: Yup.date()
        .required(msg('estimatedEndDate.exist'))
        .when('startDate', (startDate, schema) => {
          return (
            startDate &&
            schema.min(new Date(startDate), msg('estimatedEndDate.validBegin'))
          );
        })
        .when('startDate', (startDate, schema) => {
          return (
            startDate &&
            schema.max(
              new Date(new Date().setDate(new Date().getDate(startDate) + 90)),
              msg('estimatedEndDate.validEnd'),
            )
          );
        }),
    }),
    emergencyEnglishName: () => ({
      emergencyEnglishName: Yup.string()
        .required(msg('emergencyEnglishName'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    emergencyEnglishDescription: () => ({
      emergencyEnglishDescription: Yup.string()
        .required(msg('emergencyEnglishDescription'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    itemTypeNameEnglish: () => ({
      itemTypeNameEnglish: Yup.string()
        .required(msg('itemTypeNameEnglish'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    itemTypeEnglishAbbreviation: () => ({
      itemTypeEnglishAbbreviation: Yup.string()
        .required(msg('itemTypeEnglishAbbreviation'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    itemTypeEnglishDescription: () => ({
      itemTypeEnglishDescription: Yup.string()
        .required(msg('itemTypeEnglishDescription'))
        .min(3, msg('min'))
        .max(100000, msg('itemTypeEnglishDescription.max')),
    }),
    itemTypeNameFrench: () => ({
      itemTypeNameFrench: Yup.string()
        .required(msg('itemTypeNameFrench'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    itemTypeFrenchAbbreviation: () => ({
      itemTypeFrenchAbbreviation: Yup.string()
        .required(msg('itemTypeFrenchAbbreviation'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    itemTypeFrenchDescription: () => ({
      itemTypeFrenchDescription: Yup.string()
        .required(msg('itemTypeFrenchDescription'))
        .min(3, msg('min'))
        .max(100000, msg('itemTypeFrenchDescription.max')),
    }),
    hs6Code: () => ({
      hs6Code: Yup.string()
        .required(msg('hs6Code'))
        .min(6, msg('hs6Code.length'))
        .max(6, msg('hs6Code.length'))
        .matches(/^\d+$/, msg('hs6Code.number')),
    }),
    unitOfMeasuring: () => ({
      unitOfMeasuring: Yup.string()
        .required(msg('unitOfMeasuring'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    itemTypeCategory: () => ({
      itemTypeCategory: Yup.string()
        .required(msg('itemTypeCategory'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    siteNameEnglish: () => ({
      siteNameEnglish: Yup.string().required(msg('required')),
    }),
    siteNameFrench: () => ({
      siteNameFrench: Yup.string().required(msg('required')),
    }),
    siteURL: () => ({
      siteURL: Yup.string().required(msg('required')),
    }),
    configFileLocation: () => ({
      configFileLocation: Yup.string().required(msg('required')),
    }),
    maximumUploadSize: () => ({
      maximumUploadSize: Yup.string().required(msg('required')),
    }),
    backupFrequency: () => ({
      backupFrequency: Yup.string().required(msg('required')),
    }),
    subscriptions: () => ({
      subscriptions: Yup.string().required(msg('subscriptions')),
    }),
    interestCategories: () => ({
      interestCategories: Yup.array().min(3, msg('interestCategories.min')),
    }),
    followingSellers: () => ({
      followingSellers: Yup.array().min(3, msg('followingSellers.min')),
    }),
    couponCode: () => ({
      couponCode: Yup.string().required(msg('required')).min(5).max(5),
    }),
    discount: () => ({
      discount: Yup.number().required(msg('required')).min(1).max(99),
    }),
    budgetLimit: () => ({
      discount: Yup.number().required(msg('required')),
    }),
    spentAmount: () => ({
      discount: Yup.number().required(msg('required')),
    }),
    targetedUsers: () => ({
      targetedUsers: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(msg('required')),
          }),
        )
        .required(msg('required'))
        .min(1),
    }),
    mapping: () => ({
      mapping: Yup.array().required(msg('required')).min(1),
    }),
    targetedProducts: () => ({
      targetedProducts: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(msg('required')),
          }),
        )
        .required(msg('required'))
        .min(1),
    }),
    rating: ({ name = 'overall' }) => ({
      [name]: Yup.mixed().test(
        'reviewStar',
        msg('required'),
        (value) => !!value,
      ),
    }),
    comment_title: ({ type = '' }) => ({
      [`${type}_comment_title`]: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('required'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    comment_description: ({ type = '' }) => ({
      [`${type}_comment_description`]: Yup.string()
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('required'))
        .min(3, msg('min'))
        .max(255, msg('max')),
    }),
    commentTextEnglish: () => ({
      commentTextEnglish: Yup.string()
        .required(msg('commentTextEnglish'))
        .test(
          'controlInputs',
          msg('commentTextEnglish.controlInputs'),
          controlInputs,
        )
        .min(3, msg('min'))
        .max(300, msg('max300')),
    }),
    invitedEmailList: () => ({
      invitedEmailList: Yup.array()
        .test(
          'nonZeroLength',
          msg('invitedEmailList.min'),
          (data) => data?.length > 0,
        )
        .test('areEmails', msg('invitedEmailList.invalidEmails'), (data) =>
          data.reduce(
            (result, stringLine) => result && isEmail(stringLine),
            true,
          ),
        ),
    }),
    reviewComment: () => ({
      reviewComment: Yup.string()
        .min(1, msg('min'))
        .test('controlInputs', msg('controlInputs'), controlInputs)
        .required(msg('reviewComment')),
    }),
    requiredCheck: ({ name = 'checked' } = {}) => ({
      [name]: Yup.bool().test(
        'isChecked',
        msg(`${name}.checkRequired`),
        (data) => !!data,
      ),
    }),
  };

  return validator;
}

export function isTag(tagText) {
  return typeof tagText === 'string' && tagText.length >= MIN_TAG_SIZE;
}

export function controlInputs(value) {
  const hasUrl = URL_TEXT_REGEX.test(value);
  const hasPhone = PHONE_NUMBER_TEXT_REGEX.test(value);
  const hasEmail = EMAIL_TEXT_REGEX.test(value);
  return !(hasUrl || hasPhone || hasEmail);
}

export function fileSize(files = '', limit) {
  return (Array.isArray(files) ? files : [files]).reduce(
    (result, file) => result && (file?.size || 0) <= limit,
    true,
  );
}

export function fileType(files = '', types) {
  return (Array.isArray(files) ? files : [files]).reduce(
    (result, file) =>
      result && (typeof file === 'string' || types.includes(file?.type)),
    true,
  );
}

export function nonDefaultImage(files = '', defaultName) {
  return (Array.isArray(files) ? files : [files]).reduce(
    (result, file) =>
      result &&
      (typeof file !== 'string' ||
        file.split('/').slice(-1)[0] !== defaultName),
    true,
  );
}

export function isEmail(emailText) {
  return EMAIL_REGEX.test(emailText);
}

export function isPhone(phoneText = '') {
  return PHONE_NUMBER_REGEX.test(phoneText);
}

function wholeCents(value) {
  const [, decimals] = String(value).split('.');
  if (!decimals) return true;
  return decimals.length <= 2;
}

function wholeNumber(value) {
  const [, decimals] = String(value).split('.');
  if (!decimals) return true;
  return decimals.length === 0;
}

export default useValidator;

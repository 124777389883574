// src/AppRouter.jsx
//
// Top-order routing for the application.

import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import MetaDecorator from './components/common/MetaDecorator';
import OldPage from './components/common/OldPage';
import Page from './components/common/Page';
import useBreakpoint from './hooks/useBreakpoint';
import PageTracker from './PageTracker';
import useLocale from './hooks/useLocale';
// import MaterialTheme from './components/wrappers/MaterialTheme';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const AdminRouter = lazy(() => import('./pages/admin/AdminRouter'));
const AdminPanel = lazy(() => import('./pages/admin/AdminPanel'));
const SellerPanel = lazy(() => import('./pages/SellerPanel'));
const BiosafetyLabs = lazy(() => import('./pages/BiosafetyLabs'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const MySubmittedDisputes = lazy(() => import('./pages/MySubmittedDisputes'));
const MyReceivedDisputes = lazy(() => import('./pages/MyReceivedDisputes'));
const MyPosts = lazy(() => import('./pages/MyPosts'));
const MyReplies = lazy(() => import('./pages/MyReplies'));
const MyProducts = lazy(() => import('./pages/MyProducts'));
const MyMessages = lazy(() => import('./pages/MyMessages'));
const MyCoupons = lazy(() => import('./pages/MyCoupons'));
const ProductCoupons = lazy(() => import('./pages/ProductCoupons'));
const MyReceivedOrders = lazy(() => import('./pages/MyReceivedOrders'));
const MySubmittedOrders = lazy(() => import('./pages/MySubmittedOrders'));
const ProductInfo = lazy(() => import('./pages/ProductInfo'));
const CategoryInfo = lazy(() => import('./pages/CategoryInfo'));
const PostInfo = lazy(() => import('./pages/PostInfo'));
const ExampleTable = lazy(() => import('./pages/ExampleTable'));
const Experts = lazy(() => import('./pages/Experts'));
const Homepage = lazy(() => import('./pages/Homepage'));
const NotFound = lazy(() => import('./pages/NotFound'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Regulations = lazy(() => import('./pages/Regulations'));
const Suppliers = lazy(() => import('./pages/Suppliers'));

// const UserProfile = lazy(() => import('./pages/UserProfile'));
const RegisterSupplier = lazy(() => import('./pages/RegisterSupplier'));
const BuyerSignup = lazy(() => import('./pages/BuyerSignup'));
const SellerSignup = lazy(() => import('./pages/SellerSignup'));
const Login = lazy(() => import('./pages/Login'));
const SupplyPosts = lazy(() => import('./pages/SupplyPosts'));
const DemandPosts = lazy(() => import('./pages/DemandPosts'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const AuthRouter = lazy(() => import('./pages/auth/AuthRouter'));
const EmailVerification = lazy(() => import('./pages/EmailVerification'));
const MyRFQ = lazy(() => import('./pages/MyRFQ'));
// const MyReviews = lazy(() => import('./pages/MyReviews'));
const Forecasting = lazy(() => import('./pages/Forecasting'));
// const Landing = lazy(() => import('./pages/Landing'));
const MyBills = lazy(() => import('./pages/MyBills'));
const MyAds = lazy(() => import('./pages/MyAds'));
const ReceviedRFQs = lazy(() => import('./pages/ReceviedRFQs'));
const Landing = lazy(() => import('./pages/Landing'));
const Market = lazy(() => import('./pages/Market'));
const Feedback = lazy(() => import('./pages/Feedback'));
const Network = lazy(() => import('./pages/Network'));
const Messages = lazy(() => import('./pages/Messages'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Orders = lazy(() => import('./pages/Orders'));
const Cart = lazy(() => import('./pages/Cart'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const BusinessProfile = lazy(() => import('./pages/BusinessProfile'));
const ControlPanel = lazy(() => import('./pages/ControlPanel'));
// const SellerDashboard = lazy(() =>
//   import('./components/landing/SellerDashboard'),
// );
const SellerDashboard = lazy(() => import('./pages/SellerDashboard'));
// const BuyerDashboard = lazy(() => import('./pages/BuyerDashboard'));
// const Settings = lazy(() => import('./pages/Settings'));
const StripePay = lazy(() => import('./components/bills/StripePay'));
const Search = lazy(() => import('./pages/Search'));
const BillingAndPayments = lazy(() =>
  import('./components/bills/BillingAndPayments'),
);
const StripeConnectReturn = lazy(() =>
  import('./components/bills/StripeConnectReturn'),
);
const StripeConnectAgain = lazy(() =>
  import('./components/bills/StripeConnectAgain'),
);
const ReviewsPage = lazy(() => import('./components/reviews/ReviewsPage'));
const SurveyPage = lazy(() => import('./pages/SurveyPage'));
const cookiePolicy = lazy(() =>
  import('./components/newUserLanding/CookiePolicy'),
);
const TermsAndServices = lazy(() => import('./pages/TermsAndServices'));
const AcceptUse = lazy(() => import('./pages/admin/AcceptUse'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const AccountTypeSelection = lazy(() => import('./pages/AccountTypeSelection'));
const AccountTypeSelectionSingle = lazy(() =>
  import('./pages/AccountTypeSelectionSingle'),
);
const AboutTradvo = lazy(() =>
  import('./components/newUserLanding/NewUserLanding'),
);

const AboutTradvoSeller = lazy(() =>
  import('./components/newUserLanding/NewUserLandingSeller'),
);

const AboutTradvoSellerPlans = lazy(() =>
  import('./components/newUserLanding/NewUserLandingSellerPlans'),
);

const SellerPlan = lazy(() => import('./pages/SellerPlan'));

const NewBuyerTempLanding = lazy(() => import('./pages/NewBuyerTempLanding'));
const NewSellerTempLanding = lazy(() => import('./pages/NewSellerTempLanding'));
const CareerPage = lazy(() => import('./pages/CareerPage'));

const FAQs = lazy(() => import('./pages/FAQs'));

const ViewUserProfilePage = lazy(() => import('./pages/ViewUserProfilePage'));

const cacheLtr = createCache({
  key: 'muiltr',
});

const cacheRtl = createCache({
  key: 'muirtl',
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});

function AppRouter() {
  const { isArabic } = useLocale();
  const { md } = useBreakpoint();

  return (
    <CacheProvider value={isArabic ? cacheRtl : cacheLtr}>
      {/* <MaterialTheme> */}
      <Router>
        <MetaDecorator
          title={
            'Tradvo - AI Powered platform where businesses and Individuals connect, engage, and shop.'
          }
          description={
            'Build your profile as a seller, and get followers of businesses and individuals with interest in your products or services. Engage with your supplier and get the best prices as a buyer.'
          }
        />
        <PageTracker />
        <Switch>
          <Page exact path="/" page={Landing} />
          <Page exact path="/about-tradvo" page={AboutTradvo} />
          <Page exact path="/about-tradvo-seller" page={AboutTradvoSeller} />
          <Page
            exact
            path="/about-tradvo-plans"
            page={AboutTradvoSellerPlans}
          />
          <Page
            exact
            path="/feedback/:conversationId"
            page={Feedback}
            isProtected
          />
          <Page exact path="/product/:productName" page={ProductInfo} />
          <Page exact path="/category/:categoryName" page={CategoryInfo} />
          <Page exact path="/post/:postTitle" page={PostInfo} />
          {/* <OldPage exact path="/" page={Homepage} /> */}
          <Page path="/market" page={Market} />
          <Page path="/network" page={Network} isProtected />
          <Page
            path="/messages/:threadId?"
            page={Messages}
            isProtected
            noFooter={md}
          />
          <Page path="/checkout/:conversationId" page={Checkout} isProtected />
          <Page
            path="/notifications/:group?"
            page={Notifications}
            isProtected
          />
          <Page path="/orders" page={Orders} componentId={'9'} isProtected />
          <Page path="/cart" page={Cart} isProtected />
          <Page path="/dashboard" page={Dashboard} isProtected adminOnly />
          <Page path="/profile" page={UserProfile} isProtected />
          <Page
            path="/billingAndPayments"
            page={BillingAndPayments}
            isProtected
          />
          <Page path="/business/:businessName/:tab?" page={BusinessProfile} />
          <Page path="/control-panel" page={ControlPanel} isProtected />
          {/* <Page path="/settings" page={Settings} /> */}
          <Page exact path="/register-supplier" page={RegisterSupplier} />
          <Page exact path="/account-type" page={AccountTypeSelection} />
          <Page
            exact
            path="/account-type-:type"
            page={AccountTypeSelectionSingle}
          />
          <Page exact path="/buyer-signup/:page?" page={BuyerSignup} />
          <Page exact path="/seller-signup/:page?" page={SellerSignup} />
          <Page exact path="/login" page={Login} noFooter />
          <Page
            exact
            path="/my-forecasting"
            page={Forecasting}
            isProtected
            componentId={'14'}
          />
          <Page
            //   exact
            path="/seller-dashboard"
            page={SellerDashboard}
            componentId={'8'}
          />
          {/* <Page
          exact
          path="/buyer-dashboard"
          page={BuyerDashboard}
          componentId={'8'}
        /> */}
          <Page path="/search" page={Search} />
          <Page
            exact
            path="/stripe-connect-return"
            page={StripeConnectReturn}
          />
          <Page exact path="/stripe-connect-again" page={StripeConnectAgain} />
          <Page path="/myReviews" page={ReviewsPage} componentId={'16'} />
          <Page path="/acceptable-use" page={AcceptUse} />
          <Page path="/terms-and-services" page={TermsAndServices} />
          <Page path="/site-cookies-policy" page={cookiePolicy} />
          <Page exact path="/verify-email/:token" page={EmailVerification} />
          {/* <OldPage exact path="/verify/:token" page={EmailVerification} /> */}
          <Page
            exact
            path="/reset-password/:token"
            page={ResetPassword}
            noFooter
          />
          <Page path="/tradvo-survey" page={SurveyPage} />
          <Page path="/privacy-page" page={PrivacyPage} />
          <Page path="/seller-plan" page={SellerPlan} requiredRoles={['2']} />

          <Page path="/new-buyer-success" page={NewBuyerTempLanding} />
          <Page path="/new-seller-success" page={NewSellerTempLanding} />
          <Page path="/faqs" page={FAQs} />
          <Page path="/careers" page={CareerPage} />
          <Page path="/view-user-profile-:userId" page={ViewUserProfilePage} />
          {/* <OldPage
          exact
          path="/verify/:token"
          page={EmailVerification}
          nonAdmin
        /> */}
          <OldPage exact path="/experts" page={Experts} componentId={'7'} />
          <OldPage
            exact
            path="/biosafety-labs"
            page={BiosafetyLabs}
            componentId={'5'}
          />
          <OldPage exact path="/suppliers" page={Suppliers} componentId={'2'} />
          <OldPage
            exact
            path="/regulations"
            page={Regulations}
            componentId={'4'}
          />
          <OldPage
            exact
            path="/supply-posts"
            page={SupplyPosts}
            componentId={'0'}
          />
          <OldPage
            exact
            path="/demand-posts"
            page={DemandPosts}
            componentId={'0'}
          />
          <OldPage
            exact
            path="/my-submitted-disputes"
            page={MySubmittedDisputes}
            isProtected
            componentId={'12'}
          />
          <OldPage
            exact
            path="/my-received-disputes"
            page={MyReceivedDisputes}
            isProtected
            componentId={'12'}
          />
          <OldPage
            exact
            path="/my-posts"
            page={MyPosts}
            componentId={'0'}
            isProtected
          />
          <OldPage
            exact
            path="/my-replies"
            page={MyReplies}
            componentId={'0'}
            isProtected
          />
          <OldPage
            exact
            path="/my-products"
            page={MyProducts}
            componentId={'8'}
            isProtected
          />
          <OldPage exact path="/my-coupons" page={MyCoupons} isProtected />
          <OldPage
            exact
            path="/product-coupons"
            page={ProductCoupons}
            isProtected
          />
          <OldPage
            exact
            path="/my-received-orders"
            page={MyReceivedOrders}
            isProtected
            componentId={'9'}
          />
          <OldPage
            exact
            path="/my-submitted-orders"
            page={MySubmittedOrders}
            isProtected
            componentId={'9'}
          />
          <OldPage
            exact
            path="/my-RFQs"
            page={MyRFQ}
            isProtected
            componentId={'10'}
          />
          {/* <OldPage
          exact
          path="/my-reviews"
          page={MyReviews}
          componentId={'8'}
          isProtected
        /> */}
          <OldPage
            exact
            path="/my-messages"
            page={MyMessages}
            componentId={null}
            isProtected
            noContainer
            noTopMargin
          />
          {/* <OldPage
          exact
          path="/my-forecasting"
          page={Forecasting}
          isProtected
          componentId={'8'}
        />
        /> */}
          <OldPage exact path="/dashboard" page={Dashboard} isProtected />
          <OldPage
            exact
            path="/my-promotion-ads"
            page={MyAds}
            isProtected
            componentId={'8'}
          />
          <OldPage
            exact
            path="/recevied-rfqs"
            page={ReceviedRFQs}
            isProtected
            componentId={'10'}
          />
          {/* <OldPage exact path="/dashboard" page={Dashboard} isProtected /> */}
          <OldPage exact path="/privacy-policy" page={PrivacyPolicy} />
          {/* <OldPage exact path="/terms-of-use" page={TermsOfUse} /> */}
          {/* <OldPage exact path="/profile" page={UserProfile} /> */}
          {/* <OldPage exact path="/register-supplier" page={RegisterSupplier} /> */}
          <OldPage exact path="/table" page={ExampleTable} noContainer />
          {/* <OldPage exact path="/reset-password/:token" page={ResetPassword} /> */}
          <OldPage path="/auth" page={AuthRouter} noContainer />
          <OldPage path="/admin" page={AdminRouter} noContainer />
          <OldPage
            exact
            path="/my-bills"
            page={MyBills}
            isProtected
            componentId={'8'}
          />
          <OldPage
            path="/admin-panel"
            page={AdminPanel}
            noContainer
            adminPanel
            noAppBar
          />
          <OldPage
            path="/seller-panel"
            page={SellerPanel}
            noContainer
            sellerPanel
            noAppBar
          />
          {/* <OldPage exact path="/old" page={Homepage} /> */}
          <OldPage exact path="/old" page={Homepage} />
          <OldPage exact path="/dummy-payment" page={StripePay} />
          {/* <OldPage path="*" page={NotFound} nonAdmin /> */}
          <Page path="*" page={NotFound} />
        </Switch>
      </Router>
      {/* </MaterialTheme> */}
    </CacheProvider>
  );
}

export default AppRouter;

// ContactModal modal - Uses Formik for form validation and yup for validation schemas.
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import ContactForm from './ContactForm';
import MuiButton from '../intl/MuiButton';
import HelpIcon from '@mui/icons-material/Help';
import ThemeWrapper from '../wrappers/ThemeWrapper';

function ContactModal({ buttonProps, sx: sxText, asIcon = false }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const { text, style } = makeProps();
  return (
    <>
      <MuiButton
        ref={finalRef}
        onClick={onOpen}
        {...(!asIcon && { ...text.getInTouch })}
        {
          ...(asIcon && { nonText: true })
          //startIcon:<HelpIcon {...style.icon} />
        }
        {...(buttonProps ? buttonProps : { ...style.getInTouch })}
      >
        <HelpIcon {...style.icon} />
      </MuiButton>
      <ThemeWrapper>
        <Modal
          motionPreset="slideInBottom"
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size="lg"
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ContactForm onClose={onClose} />
          </ModalContent>
        </Modal>
      </ThemeWrapper>
    </>
  );

  function makeProps() {
    return {
      text: {
        getInTouch: {
          id: 'footer.getInTouch',
          dm: 'Get In Touch',
        },
      },
      style: {
        getInTouch: {
          borderRadius: 0,
          width: asIcon ? '0.5rem' : 'fit-content',
          sx: {
            p: 0,
            mb: asIcon ? '0' : '5px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: 'transparent',
            '&:hover': { background: 'transparent' },
            width: asIcon ? '0.5rem' : 'fit-content',
          },
          sxText: {
            fontSize: '0.75rem',
            color: '#CFC5B4',
            ...sxText,
          },
        },
        icon: {
          sx: {
            ml: '0px',
            width: '1.1rem',
            height: '1.1rem',
            color: 'iconAccent.main',
            transition: 'color 300ms',
          },
        },
      },
    };
  }
}

export default ContactModal;

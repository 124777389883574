import { createSlice } from '@reduxjs/toolkit';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    experts: [],
    suppliers: [],
    biosafety: [],
    regulations: [],
  },
  reducers: {
    deleteRecord(state, action) {
      const { page, id } = action.payload;
      state[page] = state[page].filter((el) => el.id !== id);
    },
    updatePage(state, action) {
      state[action.payload.page] = action.payload.newPage;
    },
  },
});

export const { updatePage, deleteRecord } = pageSlice.actions;

export default pageSlice.reducer;

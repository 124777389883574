import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

function FormattedText({ fontSize, fontWeight, id, defaultMessage, ...rest }) {
  return (
    <Text
      fontSize={fontSize ? fontSize : 'md'}
      fontWeight={fontWeight || 'normal'}
      {...rest}
    >
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </Text>
  );
}

export default FormattedText;

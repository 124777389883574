import { useCallback, useEffect, useRef } from 'react';

const useIsMounted = () => {
  const componentIsMounted = useRef(false);
  useEffect(() => {
    componentIsMounted.current = true;
    return () => (componentIsMounted.current = false);
  }, []);

  const mounted = useCallback(() => {
    return componentIsMounted;
  }, []);

  return mounted();
};

export default useIsMounted;

// Register modal - Uses Formik for form validation and yup for validation schemas.

import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
//import RegisterForm from './RegisterForm';
//mport RegisterSupplierForm from './RegisterSupplierForm';
import WizardRegistration from './WizardRegistrationOld';
import WizardSupplierRegistration from './WizardSupplierRegistrationOld';

function RegisterModal({ isSolid, isBlack, style, supplier, id }) {
  const intl = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openerRef = useRef();

  const query = queryString.parse(window.location.search);
  const { register } = query;

  const [closedOnce, setClosedOnce] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (register) {
      onOpen();
    }
  }, [register, onOpen]);

  // const handleClose = () => {
  //   setResetForm(true);
  //   onClose();
  // };

  return (
    <React.Fragment>
      <Button
        variant={isSolid ? 'solid' : 'link'}
        style={style}
        colorScheme="blue"
        ref={openerRef} // Keep this component to pass in ref
        onClick={onOpen}
        size={matches ? 'xs' : ''}
      >
        {intl.formatMessage({ id: id || 'auth.register' })}
      </Button>
      <Modal
        motionPreset="slideInBottom"
        finalFocusRef={openerRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          {supplier ? (
            <WizardSupplierRegistration
              onClose={onClose}
              register={register}
              closedOnce={closedOnce}
              setClosedOnce={setClosedOnce}
            />
          ) : (
            <WizardRegistration
              onClose={onClose}
              register={register}
              closedOnce={closedOnce}
              setClosedOnce={setClosedOnce}
            />
          )}
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}

export default RegisterModal;

// src/components/Footer.jsx
//
// Default footer for pages.

import React from 'react';
// import Container from '../common/Container';
import ContactModal from './ContactModal';
import { Flex } from '@chakra-ui/react';
import { Box } from '@mui/material';
import MuiText from '../intl/MuiText';
import MuiLink from '../common/MuiLink';
import { FaLinkedin, FaFacebook } from 'react-icons/all';
import { colorPalette, parseColor } from '../../theme/materialTheme';
import useAuth from '../../hooks/useAuth';
import FacebookIFrame from './FacebookIFrame';
import { mergeProps } from '../../utils/mergeProps';
function Footer() {
  const { user, isAuthenticated } = useAuth();
  const isSeller = user?.roles === '2';
  const flexBasisA = ['90%', '80%', '46%', '46%', '25%'];

  const { text, style, title, link } = makeProps();
  return (
    <Box>
      <Box {...style.mainContent}>
        <Flex
          justifyContent={[
            'center',
            'center',
            'space-evenly',
            'space-evenly',
            'space-evenly',
          ]}
          //alignItems="flex-start"
          maxWidth="100%"
          flexWrap="wrap"
          display="flex"
        >
          <Box flexBasis={flexBasisA} width="9.625rem" mb="1.5rem">
            <Flex direction="column">
              {isSeller && <MuiLink {...link.yourStoreOnTradvo} />}
              <MuiText {...title.aboutUs} />
              {!isAuthenticated && <MuiLink {...link.aboutTradvo} />}
              <MuiLink {...link.faqs} />
              {!isAuthenticated && <MuiLink {...link.careers} />}
              {!isAuthenticated && <MuiLink {...link.listBusiness} />}
            </Flex>
          </Box>
          <Box flexBasis={flexBasisA} width="9.625rem" mb="1.5rem">
            <Flex direction="column">
              <MuiText {...title.policies} />
              <MuiLink {...link.privacy} />
              <MuiLink {...link.term} />
              <MuiLink {...link.use} />
              <MuiLink {...link.cookie} />
            </Flex>
          </Box>
          <Box flexBasis={flexBasisA} width="9.625rem" mb="1.5rem">
            <Flex direction="column">
              <MuiText {...title.services} />
              {!isAuthenticated && (
                <>
                  <MuiLink {...link.listBusinessPlans} />
                  {/* <MuiLink {...link.login} /> */}
                  <MuiLink {...link.signUp} />
                </>
              )}

              {isSeller && <MuiLink {...link.sellerPlan} />}
              <MuiLink {...link.learning} />
            </Flex>
          </Box>
          <Box flexBasis={flexBasisA} width="9.625rem" mb="1.5rem">
            <Flex direction="column">
              <MuiText {...title.contactUs} />
              {/* <MuiText {...text.addressLine} /> */}
              <ContactModal {...style.contactLink} />
              <Flex my="5px">
                <MuiLink {...link.facebookIcon} />
                <MuiLink {...link.linkedInIcon} />
                <ContactModal asIcon />
              </Flex>
              <FacebookIFrame />
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box {...style.bottomContent}>
        <MuiText {...text.copyright} />
      </Box>
    </Box>
  );
  function makeProps() {
    const linkStyle = (props) =>
      mergeProps(props, {
        sx: { fontSize: '0.75rem', mb: '5px', color: '#CFC5B4' },
      });
    const titleStyle = (props) =>
      mergeProps(props, {
        sx: {
          fontSize: '0.75rem',
          mb: '5px',
          color: 'secondary.main',
          fontWeight: 'bold',
        },
      });
    const iconLinkStyle = (props) =>
      mergeProps(props, {
        nonText: true,
        sx: { mr: '1rem' },
      });
    const iconStyle = (props) =>
      mergeProps(props, {
        style: {
          width: '1.1rem',
          height: '1.1rem',
          color: parseColor('iconAccent.main'),
        },
      });
    return {
      text: {
        contactUs: { id: 'footer.contactUs', dm: 'CONTACT US' },
        addressLine: {
          id: 'footer.address',
          dm: 'Kingston, Ontario, Canada',
          sx: {
            fontSize: '0.75rem',
            mb: '5px',
            color: '#c0d3f3',
          },
        },
        phone: {
          id: 'footer.phone',
          dm: 'Phone: +1 343-3333-069',
          href: 'tel:+1 343-3333-069',
          sx: { mb: '5px' },
          sxText: { fontSize: '0.75rem', color: '#c0d3f3' },
        },
        email: {
          id: 'footer.email',
          dm: 'Email: support@tradvo.com',
          href: 'mailto:support@tradvo.com',
          sx: { mb: '5px' },
          sxText: { fontSize: '0.75rem', color: '#c0d3f3' },
        },
        aboutUs: { id: 'footer.aboutUs', dm: 'ABOUT US' },
        aboutTradvo: { id: 'footer.aboutTradvo', dm: 'About Tradvo' },
        yourStoreOnTradvo: {
          id: 'footer.yourStoreWithTradvo',
          dm: 'Your Store with Tradvo',
        },
        careers: { id: 'footer.careers', dm: 'Careers' },
        getInTouch: { id: 'footer.contactCTA', dm: 'Get In Touch' },
        faqs: { id: 'footer.faqs', dm: 'FAQs' },
        policies: { id: 'footer.policies', dm: 'POLICIES' },
        privacy: { id: 'footer.privacyPolicy', dm: 'Privacy Policy' },
        term: { id: 'footer.terms', dm: 'Terms and Conditions' },
        use: { id: 'footer.use', dm: 'Acceptable Use' },
        cookie: { id: 'footer.cookie', dm: 'Cookie Policy' },
        services: { id: 'footer.services', dm: 'SERVICES' },
        listBusiness: { id: 'footer.listBusiness', dm: 'List A Business' },
        listBusinessPlans: {
          id: 'footer.listBusinessPlans',
          dm: 'Seller Pricing',
        },
        login: { id: 'footer.login', dm: 'Login' },
        sellerPlan: { id: 'footer.sellerPlan', dm: 'Seller Plans' },
        signUp: { id: 'footer.signup', dm: 'Choose an account' },
        learning: { id: 'footer.learning', dm: 'Learning Center' },
        copyright: {
          id: 'footer.copyright',
          dm: 'Copyright ©️ 2023 Tradvo Inc. All Rights Reserved.',
          sx: {
            fontSize: '0.75rem',
            mb: '10px',
            color: '#c0d3f3',
          },
        },
      },
      style: {
        footer: {
          sx: {
            width: '100%',
            backgroundColor: colorPalette.footer.main, //'#031C34',
          },
        },
        mainContent: {
          sx: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: colorPalette.footer.main, //'#0C2844',
            pl: '1.5rem',
            pt: '1.5rem',
            pb: '0rem',
          },
        },
        bottomContent: {
          sx: {
            textAlign: 'center',
            backgroundColor: colorPalette.footer.dark, //'#031C34',
            p: '1rem',
          },
        },
        title: {
          sx: {
            fontSize: '0.75rem',
            mb: '5px',
            color: 'secondary.main',
            fontWeight: 'bold',
          },
        },
        link: {
          sx: {
            fontSize: '0.75rem',
            mb: '5px',
            color: '#CFC5B4',
          },
        },
        iconLink: {
          nonText: true,
          sx: { mr: '1rem' },
        },

        contactLink: {
          sx: { color: '#c0d3f3' },
        },
      },
      title: {
        aboutUs: titleStyle({ id: 'footer.aboutUs', dm: 'ABOUT US' }),
        policies: titleStyle({ id: 'footer.policies', dm: 'POLICIES' }),
        services: titleStyle({ id: 'footer.services', dm: 'SERVICES' }),
        contactUs: titleStyle({ id: 'footer.contactUs', dm: 'CONTACT US' }),
      },
      link: {
        aboutTradvo: linkStyle({
          id: 'footer.aboutTradvo',
          dm: 'About Tradvo',
          to: '/about-tradvo',
        }),
        yourStoreOnTradvo: linkStyle({
          id: 'footer.yourStoreOnTradvo',
          dm: 'Your Store Benefits',
          to: '/about-tradvo-seller',
        }),
        careers: linkStyle({
          id: 'footer.careers',
          dm: 'Careers',
          to: '/careers',
        }),
        faqs: linkStyle({ id: 'footer.faqs', dm: 'FAQs', to: '/faqs' }),
        privacy: linkStyle({
          id: 'footer.privacyPolicy',
          dm: 'Privacy Policy',
          to: '/privacy-page',
        }),
        term: linkStyle({
          id: 'footer.terms',
          dm: 'Terms and Conditions',
          to: '/terms-and-services',
        }),
        use: linkStyle({
          id: 'footer.use',
          dm: 'Acceptable Use',
          to: '/acceptable-use',
        }),
        cookie: linkStyle({
          id: 'footer.cookie',
          dm: 'Cookie Policy',
          to: '/site-cookies-policy',
        }),
        listBusiness: linkStyle({
          id: 'footer.listBusiness',
          dm: 'List A Business',
          to: '/about-tradvo-seller',
        }),
        listBusinessPlans: linkStyle({
          id: 'footer.listBusinessPlans',
          dm: 'List A Business',
          to: '/about-tradvo-plans',
        }),
        login: linkStyle({ id: 'footer.login', dm: 'Login', to: '/login' }),
        signUp: linkStyle({
          id: 'footer.signup',
          dm: 'Choose an account',
          to: '/account-type',
        }),
        sellerPlan: linkStyle({
          id: 'footer.sellerPlan',
          dm: 'Seller Plans',
          to: '/seller-plan',
        }),
        learning: linkStyle({ id: 'footer.learning', dm: 'Learning Center' }),
        facebookIcon: iconLinkStyle({
          href: 'https://www.facebook.com/tradvo/',
          target: '_blank',
          children: <FaFacebook {...iconStyle()} />,
        }),
        linkedInIcon: iconLinkStyle({
          href: 'https://www.linkedin.com/company/tradvo-inc-canada',
          target: '_blank',
          children: <FaLinkedin {...iconStyle()} />,
        }),
      },
    };
  }
}

export default Footer;

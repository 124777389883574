import { createSlice } from '@reduxjs/toolkit';
// import French from '../languages/fr.json';
import English from '../languages/en.json';
import Arabic from '../languages/ar.json';

const intlSlice = createSlice({
  name: 'intl',
  initialState: {
    locale: localStorage.getItem('locale')
      ? localStorage.getItem('locale')
      : navigator.language === 'fr'
      ? 'fr'
      : navigator.language === 'ar'
      ? 'ar'
      : 'en',
    en: English,
    // fr: French,
    ar: Arabic,
    subdomainStoreId: null,
    subdomainStoreObject: null,
    subdomainValue: null,
    loadingStore: false,
  },
  reducers: {
    setLocale(state, action) {
      state.locale = action.payload;
    },
    setSubdomainStoreId(state, action) {
      state.subdomainStoreId = action.payload;
    },
    setSubdomainStoreObject(state, action) {
      state.subdomainStoreObject = action.payload;
    },
    setSubdomainValue(state, action) {
      state.subdomainValue = action.payload;
    },
    setisLoadingStore(state, action) {
      state.loadingStore = action.payload;
    },
    addEmergency(state, action) {
      state.en[`emergency.${action.payload.id}`] = action.payload.name.en;
      // state.fr[`emergency.${action.payload.id}`] = action.payload.name.fr;
      state.ar[`emergency.${action.payload.id}`] = action.payload.name.ar;
    },
    addCategoryTranslation(state, action) {
      state.en[`category.${action.payload._id}.name`] = action.payload.en_name;
      state.en[`category.${action.payload._id}.abbr`] = action.payload.en_abbr;
      state.en[`category.${action.payload._id}.description`] =
        action.payload.en_description;
      // state.fr[`category.${action.payload._id}.name`] = action.payload.fr_name;
      // state.fr[`category.${action.payload._id}.abbr`] = action.payload.fr_abbr;
      // state.fr[`category.${action.payload._id}.description`] =
      //   action.payload.fr_description;
      state.ar[`category.${action.payload._id}.name`] = action.payload.ar_name;
      state.ar[`category.${action.payload._id}.abbr`] = action.payload.ar_abbr;
      state.ar[`category.${action.payload._id}.description`] =
        action.payload.ar_description;
    },
  },
});

// Action creators
export const {
  setLocale,
  setSubdomainStoreId,
  setSubdomainStoreObject,
  setSubdomainValue,
  setisLoadingStore,
  addEmergency,
  addCategoryTranslation,
} = intlSlice.actions;

export default intlSlice.reducer;

import React from 'react';
import { mergeProps } from '../../utils/mergeProps';
import Flex from '../common/Flex';
import StoreImageBox from '../common/StoreImageBox';
import WrapOn from '../common/WrapOn';

const StoreImgLogo = ({
  seller,
  loading,
  avatarSize = '5rem',
  hasOffset,
  offsetProps,
  wrapProps,
  ...rest
}) => {
  const { style } = makeProps({ seller, loading, avatarSize, hasOffset });

  return (
    <WrapOn {...style.wrap(wrapProps)}>
      <WrapOn {...style.offset(offsetProps)}>
        <StoreImageBox {...style.logo(rest)} />
      </WrapOn>
    </WrapOn>
  );

  function makeProps({ seller, loading, avatarSize, hasOffset }) {
    const { logoImage } = seller || {};

    return {
      style: {
        wrap: (props) =>
          mergeProps(props, {
            Wrap: Flex,
            on: hasOffset,
            sx: {
              position: 'relative',
              height: `calc(${avatarSize} / 2)`,
            },
          }),
        offset: (props) =>
          mergeProps(props, {
            Wrap: Flex,
            on: hasOffset,
            sx: {
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          }),
        logo: (props) =>
          mergeProps(props, {
            image: logoImage,
            loading,
            namedLink: { seller },
            variant: 'sellerLogo',
            size: avatarSize,
          }),
      },
    };
  }
};

export default StoreImgLogo;

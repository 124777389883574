// src/index.js
//
// Entry point for React into the DOM. All Provider Wrappers belong here unless
// specific to a nested scope.
//
// CSSReset resets all default CSS to a neutral default so that the starting
// point is identical across all browsers.
//
// React.StrictMode is optional--it sometimes leads to odd bugs with some
// libraries, but otherwise is useful for the warnings and additional
// checks it provides.

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AppRouter from './AppRouter';
import IntlWrapper from './components/wrappers/IntlWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";
import { rootReducer } from './reducers/rootReducer';
import * as serviceWorker from './serviceWorker';
import AuthWrapper from './components/wrappers/AuthWrapper';
import { SocketProvider } from './components/wrappers/SocketProvider';
import { SnackbarProvider } from './components/wrappers/SnackbarProvider';
import DataFetchWrapper from './components/wrappers/DataFetchWrapper';
import MaterialTheme from './components/wrappers/MaterialTheme';
import BreakpointWrapper from './components/wrappers/BreakpointWrapper';
import HelmetProvider from './components/wrappers/HelmetProvider';
import TourWrapper from './components/wrappers/TourWrapper';
import FacebookPixelWrapper from './components/wrappers/FacebookPixelWrapper';
import SubscriptionProvider from './components/wrappers/SubscriptionProvider';
//import useLocale from './hooks/useLocale';
import TagManager from 'react-gtm-module';
//import { prefixer } from "stylis";

// const cacheLtr = createCache({
//   key: "muiltr"
// });

// const cacheRtl = createCache({
//   key: "muirtl",
//   // prefixer is the only stylis plugin by default, so when
//   // overriding the plugins you need to include it explicitly
//   // if you want to retain the auto-prefixing behavior.
//   stylisPlugins: [prefixer, rtlPlugin]
// });

const tagManagerArgs = { gtmId: 'GTM-K5NK89J' };
const customizedStoreMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: customizedStoreMiddleware,
});
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <IntlWrapper>
        {/* <CacheProvider value={cacheRtl}> */}
        <MaterialTheme>
          <BreakpointWrapper>
            <SnackbarProvider>
              <AuthWrapper>
                <DataFetchWrapper>
                  <SubscriptionProvider>
                    <SocketProvider>
                      <HelmetProvider>
                        <TourWrapper>
                          <FacebookPixelWrapper>
                            <AppRouter />
                          </FacebookPixelWrapper>
                        </TourWrapper>
                      </HelmetProvider>
                    </SocketProvider>
                  </SubscriptionProvider>
                </DataFetchWrapper>
              </AuthWrapper>
            </SnackbarProvider>
          </BreakpointWrapper>
        </MaterialTheme>
        {/* </CacheProvider> */}
      </IntlWrapper>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

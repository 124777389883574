import client from './client';

const tryClient = async (endpoint, options, errorHandler) => {
  try {
    const response = await client(endpoint, options);
    return {
      r: response,
      resp: response,
      response: response,
      e: null,
      err: null,
      error: null,
    };
  } catch (error) {
    if (errorHandler) errorHandler(error);
    return {
      r: null,
      resp: null,
      response: null,
      e: error,
      err: error,
      error: error,
    };
  }
};

export default tryClient;

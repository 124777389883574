import { useContext, useEffect } from 'react';
import { HelmetContext } from '../components/wrappers/HelmetProvider';
import useLocale from './useLocale';

const useHelmet = (options) => {
  const { title, description, debugMode } = options || {};
  const { attemptFormat } = useLocale();
  const context = useContext(HelmetContext);
  const { setTitle, setDescription } = context;

  const defaultTitle = attemptFormat({
    id: 'meta.title.default',
    dm:
      'Tradvo - AI Powered platform where businesses and Individuals connect, engage, and shop.',
  });

  const defaultDescription = attemptFormat({
    id: 'meta.description.default',
    dm:
      'Build your profile as a seller, and get followers of businesses and individuals with interest in your products or services. Engage with your supplier and get the best prices as a buyer.',
  });

  useEffect(() => {
    if (!title) return;

    const newTitle = title || defaultTitle;
    const newDescription = description || defaultDescription;
    if (debugMode) console.log({ newTitle, newDescription });
    setTitle(newTitle);
    setDescription(newDescription);

    return () => {
      setTitle(defaultTitle);
      setDescription(defaultDescription);
    };
  }, [
    title,
    description,
    debugMode,
    setTitle,
    setDescription,
    defaultTitle,
    defaultDescription,
  ]);

  return context;
};
export default useHelmet;

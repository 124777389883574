import React from 'react';
import Flex from '../common/Flex';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import MuiText from '../intl/MuiText';
import MuiButton from '../intl/MuiButton';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import useAuth from '../../hooks/useAuth';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const SellerRequestModalContent = ({ setSellerStartedOffer }) => {
  const { supplier } = useAuth();
  const { text, style } = makeProps({
    supplier,
    setSellerStartedOffer,
  });
  return (
    <Flex {...style.stack}>
      <CategoryOutlinedIcon {...style.icon} />
      <MuiText {...text.title} />
      <MuiText {...text.description} />
      <Flex {...style.buttonBox}>
        <MuiButton {...style.postOffer} />
        <MuiButton {...style.manageListings} />
      </Flex>
    </Flex>
  );

  function makeProps({ supplier, setSellerStartedOffer }) {
    return {
      text: {
        title: {
          id: 'seller.request.title',
          dm: 'You are a Seller',
          sx: {
            color: 'primary.main',
            fontSize: '2.25rem',
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        description: {
          id: 'seller.request.description',
          dm:
            'As a Seller, you answer requests. To increase the chances of receiving requests, you can post offers or increase your listings:',
          sx: {
            color: 'headingText.main',
            fontSize: '1rem',
            textAlign: 'center',
          },
        },
      },
      style: {
        stack: {
          direction: 'column',
          sx: {
            alignItems: 'center',
            gap: '2rem',
            width: '100%',
            maxWidth: { xs: '95vw', md: 'min(80vw, 50rem)' },
          },
        },
        icon: {
          sx: {
            height: '8rem',
            width: '8rem',
            color: 'secondary.main',
          },
        },
        buttonBox: {
          direction: { xs: 'column', md: 'row' },
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: { xs: 'stretch', md: 'center' },
            gap: { xs: '0.5rem', md: '1rem' },
          },
        },
        postOffer: {
          id: 'seller.request.postOffer',
          dm: 'Post an Offer',
          startIcon: <EditOutlinedIcon />,
          variant: 'outlined',
          fontSize: '0.875rem',
          sx: { py: '0.75rem', width: '100%', maxWidth: '25rem', mx: 'auto' },
          onClick: () => setSellerStartedOffer(true),
        },
        manageListings: {
          id: 'businessProfile.addNewProduct',
          dm: 'Manage Listings',
          startIcon: <AddBoxOutlinedIcon />,
          fontSize: '0.875rem',
          sx: { py: '0.75rem', width: '100%', maxWidth: '25rem', mx: 'auto' },
          namedLink: { supplier, append: '?tab=products&editProducts=true' },
        },
      },
    };
  }
};

export default SellerRequestModalContent;

import { Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import { FaFacebook, FaGoogle, FaLinkedin } from 'react-icons/all';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SocialLogin from 'react-social-login';
import {
  TOAST_DEFAULT_DURATION,
  TOAST_DEFAULT_IS_CLOSABLE,
  TOAST_DEFAULT_POSITION,
} from '../../data/constants';
import useRedirect from '../../hooks/useRedirect';
import useToaster from '../../hooks/useToaster';
import { login, addSupplier } from '../../reducers/authReducer';
import client from '../../utils/client';
import FormattedButton from '../intl/FormattedButton';

// Social authentication for frontend. Results of these APIs must
// be sent to backend for token verification

function SocialAuthButtons({ handleSetRegisterMessage, width }) {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const toaster = useToaster();
  const toast = useToast();
  const { formatMessage } = useIntl();

  const socialLogin = (data) => {
    const accessToken = data['access_token'];
    // const user = data.user;
    const { user, supplier } = data;

    localStorage.setItem('accessToken', accessToken);

    if (supplier) dispatch(addSupplier(supplier));
    dispatch(login(user));
    redirect();

    toaster('loginSuccess');
  };

  const handleFacebookLogin = async (payload) => {
    try {
      const { accessToken: code } = payload._token;
      const { data, status } = await client(
        `/socialauth/facebook/callback?${'code=' + code}`,
        {
          method: 'POST',
        },
      );

      if (status === 207) {
        return handleSetRegisterMessage
          ? handleSetRegisterMessage()
          : toaster('adminApproval');
      }

      socialLogin(data);
    } catch (e) {
      if (e.status === 404) {
        toast({
          title: e.message,
          description: formatMessage({
            id: 'auth.toast.error.description',
            defaultMessage: 'Please try again later.',
          }),
          status: 'error',
          position: TOAST_DEFAULT_POSITION,
          duration: TOAST_DEFAULT_DURATION,
          isClosable: TOAST_DEFAULT_IS_CLOSABLE,
        });
      } else {
        //toaster('error');
      }
    }
  };

  const handleFacebookLoginFailure = (payload) => {
    //toaster('error');
  };

  const handleLinkedInLogin = () => {
    const clientID = process.env.REACT_APP_LINKEDIN_AUTH_ID;
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
    window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientID}&redirect_uri=${frontendUrl}%2Fauth%2Flinkedin&scope=r_liteprofile%20r_emailaddress`,
      '_self',
    );
  };

  const handleGoogleLogin = async (payload) => {
    try {
      const { accessToken: code, idToken } = payload._token;
      const { data, status } = await client(
        `/socialauth/google/callback?${'code=' + code}${'&idToken=' + idToken}`,
        {
          method: 'POST',
        },
      );

      if (status === 207) {
        return handleSetRegisterMessage
          ? handleSetRegisterMessage()
          : toaster('adminApproval');
      }

      socialLogin(data);
    } catch (e) {
      if (e.status === 404) {
        toast({
          title: e.message,
          description: formatMessage({
            id: 'auth.toast.error.description',
            defaultMessage: 'Please try again later.',
          }),
          status: 'error',
          position: TOAST_DEFAULT_POSITION,
          duration: TOAST_DEFAULT_DURATION,
          isClosable: TOAST_DEFAULT_IS_CLOSABLE,
        });
      } else {
        //toaster('error');
      }
    }
  };

  const handleGoogleLoginFailure = (payload) => {
    //toaster('error');
  };

  return (
    <Stack spacing={2} pb={4} shouldWrapChildren={true} width={width}>
      <FormattedButton
        id="auth.social.linkedin"
        width="100%"
        onClick={handleLinkedInLogin}
        leftIcon={<FaLinkedin />}
        colorScheme="cyan"
      />
      <SocialAuthButton
        provider="google"
        appId={process.env.REACT_APP_GOOGLE_AUTH_ID}
        onLoginSuccess={handleGoogleLogin}
        onLoginFailure={handleGoogleLoginFailure}
        id="auth.social.google"
        width="100%"
        leftIcon={<FaGoogle />}
        colorScheme="red"
      />
      <SocialAuthButton
        provider="facebook"
        appId={process.env.REACT_APP_FACEBOOK_AUTH_ID}
        onLoginSuccess={handleFacebookLogin}
        onLoginFailure={handleFacebookLoginFailure}
        id="auth.social.facebook"
        width="100%"
        leftIcon={<FaFacebook />}
        colorScheme="blue"
      />
    </Stack>
  );
}

const SocialButton = ({ triggerLogin, ...rest }) => (
  <FormattedButton onClick={triggerLogin} {...rest} />
);

const SocialAuthButton = SocialLogin(SocialButton);

export default SocialAuthButtons;

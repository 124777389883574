// Forgot password form - Uses Formik for form validation and yup for
// validation schemas. Serve this page on the same dialog as the login modal.

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Stack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useValidator from '../../hooks/useValidator';
import client from '../../utils/client';
import FormattedButton from '../intl/FormattedButton';
import FormattedText from '../intl/FormattedText';

function ForgotPasswordOld({ onClose, setIsForgotPasswordOpen }) {
  const validator = useValidator();
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validationSchema = validator((schema) => ({
    ...schema.email(),
  }));

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values) => {
        const { email } = values;

        try {
          await client('/forgot-password', {
            body: { email },
          });

          setIsSubmitted(true);
        } catch (e) {
          setIsSubmitted(true);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalHeader>
            <FormattedText
              id="auth.password.recover"
              defaultMessage="Recover your password"
              fontSize="3xl"
              as="h2"
              mt={2}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isSubmitted ? (
              <Stack spacing={2} shouldWrapChildren={true}>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">
                        <FormattedText id="auth.email" defaultMessage="Email" />
                      </FormLabel>
                      <Input
                        {...field}
                        id="email"
                        placeholder={intl.formatMessage({
                          id: 'auth.email.placeholder',
                        })}
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
            ) : (
              <FormattedText
                id="auth.formatted"
                defaultMessage="If your inputted email exists, a recovery email has been sent to that address."
              />
            )}
          </ModalBody>
          <ModalFooter justifyContent="space-between" alignItems="center">
            <FormattedButton
              variant="link"
              fontWeight="400"
              color="gray.800"
              id="navigation.goback"
              onClick={() => setIsForgotPasswordOpen(false)}
            />
            <Flex>
              <FormattedButton
                id="navigation.cancel"
                defaultMessage="Cancel"
                mr={4}
                onClick={onClose}
              />
              <FormattedButton
                id="auth.recover"
                colorScheme="blue"
                type="submit"
                disabled={isSubmitted}
                isLoading={isSubmitting}
              />
            </Flex>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default ForgotPasswordOld;

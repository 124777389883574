import React from 'react';
import MuiText from '../intl/MuiText';
import Flex from './Flex';

const IconTitle = ({
  title,
  titleProps,
  icon,
  iconProps,
  boxProps,
  iconLast,
  endIcon,
  endIconProps,
}) => {
  const { style } = makeProps();
  return (
    <Flex {...style.titleBox(boxProps)}>
      {!iconLast && React.cloneElement(icon, style.titleIcon(iconProps))}
      <MuiText {...{ ...title, ...style.title(titleProps) }} />
      {!!iconLast && React.cloneElement(icon, style.titleIcon(iconProps))}
      {!!endIcon && React.cloneElement(endIcon, style.titleIcon(endIconProps))}
    </Flex>
  );

  function makeProps() {
    return {
      style: {
        titleBox: (props) => ({
          ...props,
          sx: {
            gap: '0.5rem',
            alignItems: 'center',
            width: '100%',
            ...props?.sx,
          },
        }),
        title: (props) => ({
          ...props,
          sx: {
            color: 'headingText.main',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            ...props?.sx,
          },
        }),
        titleIcon: (props) => ({
          ...props,
          sx: { color: 'headingText.main', fontSize: '1.5rem', ...props?.sx },
        }),
      },
    };
  }
};

export default IconTitle;

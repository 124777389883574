import React from 'react';
import useAuth from '../../hooks/useAuth';
import { mergeProps } from '../../utils/mergeProps';
import Flex from '../common/Flex';
import MuiLink from '../common/MuiLink';
import MuiText from '../intl/MuiText';
import ContactModal from './ContactModal';
// import FacebookIFrame from './FacebookIFrame';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import StoreImgLogo from '../sellers/StoreImgLogo';
import { useSelector } from 'react-redux';
import useParseBreakpointObject from '../../hooks/useParseBreakpointObject';
import useIntersection from '../../hooks/useIntersection';
// import FollowButton from '../businessProfile/FollowButton';
import TinyCard from '../businessProfile/TinyCard';
// import MessageSellerButton from '../RFQ/MessageSellerButton';

const StoreFooterContent = ({
  linkProps,
  titleProps,
  iconLinkProps,
  iconProps,
  containerProps,
  mainBoxProps,
  bottomBoxProps,
}) => {
  const { user, isAuthenticated } = useAuth();
  const isSeller = user?.roles === '2';
  const {
    subdomainStoreId,
    subdomainValue,
    subdomainStoreObject,
    loadingStore,
  } = useSelector(({ intl }) => intl);

  const parseBreakpoint = useParseBreakpointObject();
  const avatarSize = parseBreakpoint({ xs: '3rem', md: '4rem' });
  const { ref: popUpRef /*, isIntersecting */ } = useIntersection();
  const { planName } = subdomainStoreObject || {};

  const supplierId = subdomainStoreId;
  const loading = loadingStore;
  const { text, style, title, link } = makeProps({
    linkProps,
    titleProps,
    iconLinkProps,
    iconProps,
  });
  return (
    <Flex {...style.container(containerProps)}>
      <Flex {...style.mainContent(mainBoxProps)}>
        <Flex {...style.stack}>
          {/* <StoreImgLogo {...style.logo} />
          <MuiText {...style.name} /> */}
          {/* <Flex {...style.buttonArea}> */}
          {/* <FollowButton {...{ ...style.follow, subdomainStoreId }} />
            <MessageSellerButton
                {...{ seller: subdomainStoreObject, ...style.contact }}
              /> */}
          <TinyCard {...{ supplierId, loading }} />
          {/* </Flex> */}
          {/* <MuiText {...title.aboutUs} /> */}
          {/* {isSeller && <MuiLink {...link.yourStoreOnTradvo} />} */}
          {/* {!isSeller && <MuiLink {...link.aboutTradvo} />} */}

          {/* {!isAuthenticated && <MuiLink {...link.careers} />} */}
        </Flex>
        <Flex {...style.stack}>
          <MuiText {...title.policies} />
          <MuiLink {...link.privacy} />
          <MuiLink {...link.term} />
          <MuiLink {...link.use} />
          <MuiLink {...link.cookie} />
        </Flex>
        <Flex {...style.stack}>
          <MuiText {...title.services} />
          {!isAuthenticated && (
            <>
              {/* <MuiLink {...link.listBusiness} /> */}
              {/* <MuiLink {...link.listBusinessPlans} /> */}
              {/* <MuiLink {...link.login} /> */}
              <MuiLink {...link.signUp} />
              <MuiLink {...link.faqs} />
            </>
          )}
          {isSeller && <MuiLink {...link.sellerPlan} />}
          <MuiLink {...link.market} />
        </Flex>
        <Flex {...style.stack}>
          <MuiText {...title.contactUs} />
          {/* <MuiText {...text.addressLine} /> */}
          <ContactModal {...style.contact} />
          {
            //   <Flex my="5px">
            //     <MuiLink {...link.facebookIcon} />
            //     <MuiLink {...link.linkedInIcon} />
            //     <ContactModal asIcon />
            //   </Flex>
            //   <FacebookIFrame />
            // </Flex>
          }
        </Flex>
      </Flex>
      <Flex {...style.bottomContent(bottomBoxProps)}>
        <MuiText {...text.copyright} />
      </Flex>
    </Flex>
  );

  function makeProps({ linkProps, titleProps, iconLinkProps, iconProps }) {
    const linkStyle = (props) =>
      mergeProps(
        linkProps,
        mergeProps(props, {
          sx: { color: 'bodyText.main', lineHeight: '1.125rem' },
        }),
      );
    const titleStyle = (props) =>
      mergeProps(
        titleProps,
        mergeProps(props, {
          sx: { color: 'secondary.main', fontWeight: 'bold' },
        }),
      );
    const iconLinkStyle = (props) =>
      mergeProps(
        iconLinkProps,
        mergeProps(props, { nonText: true, sx: { mr: '1rem' } }),
      );
    const iconStyle = (props) =>
      mergeProps(
        iconProps,
        mergeProps(props, {
          sx: { width: '1.1rem', height: '1.1rem', color: 'iconAccent.main' },
        }),
      );
    return {
      text: {
        addressLine: linkStyle({
          id: 'footer.address',
          dm: 'Kingston, Ontario, Canada',
        }),
        copyright: {
          id: 'footer.copyright',
          dm: 'Copyright ©️ 2023 Tradvo Inc. All Rights Reserved.',
          sx: { fontSize: '0.75rem', mb: '10px', color: 'bodyText.main' },
        },
      },
      style: {
        container: (props) => mergeProps(props, { direction: 'column' }),
        mainContent: (props) =>
          mergeProps(props, {
            direction: 'column',
            sx: { width: '100%', gap: '0.75rem' },
          }),
        stack: { direction: 'column', sx: { gap: '0.125rem' } },
        bottomContent: (props) =>
          mergeProps(props, {
            sx: {
              p: '1rem',
              textAlign: 'center',
              backgroundColor: 'background.main',
            },
          }),
        // contact: linkStyle(),
        logo: {
          seller: subdomainStoreObject,
          loadingStore,
          avatarSize,
          ...(!loadingStore && { planName }),
          hasOffset: false,
          namedLink: undefined,
          wrapProps: {
            sx: {
              mb: '0rem',
              width: avatarSize,
              height: {
                xs: `calc(${avatarSize} / 2)`,
                md: `calc(2rem + calc(${avatarSize} / 2))`,
              },
              flexShrink: '0',
            },
          },
          offsetProps: { sx: { top: '0rem' } },
          boxProps: { ref: popUpRef },
          badgeSize: { xs: '0.8rem', md: '1.0rem' },
        },
        nameAndRating: {
          sx: {
            alignItems: 'center',
            gap: { xs: '0.1rem', md: '0.1rem' },
            justifyContent: { xs: 'space-between', md: 'flex-start' },
            width: '100%',
          },
        },
        follow: {
          variant: 'contained',
          followProps: { color: 'primary' },
          unfollowProps: { color: 'bodyText' },
          fontSize: '0.875rem',
          sx: { py: '0.55rem', width: '70%', borderRadius: '10px' },
          disabled: loadingStore,
        },
        contact: {
          modalButtonProps: {
            buttonProps: {
              variant: 'contained',
              fontSize: '0.875rem',
              sx: { py: '0.55rem', width: '70%', borderRadius: '10px' },
              disabled: loadingStore,
              color: 'primary',
            },
          },
        },
        buttonArea: {
          direction: { xs: 'column', sm: 'row', md: 'column', lg: 'row' },
          sx: { gap: { xs: '0.5rem', md: '0.5rem' } },
        },
      },
      title: {
        aboutUs: titleStyle({ id: 'footer.aboutUs', dm: 'ABOUT US' }),
        policies: titleStyle({ id: 'footer.policies', dm: 'POLICIES' }),
        services: titleStyle({ id: 'footer.services', dm: 'SERVICES' }),
        contactUs: titleStyle({ id: 'footer.contactUs', dm: 'CONTACT US' }),
      },
      link: {
        aboutTradvo: linkStyle({
          id: 'footer.aboutTradvo',
          dm: 'About Tradvo',
          to: '/about-tradvo',
        }),
        yourStoreOnTradvo: linkStyle({
          id: 'footer.yourStoreWithTradvo',
          dm: 'About Tradvo',
          to: '/about-tradvo-seller',
        }),
        careers: linkStyle({
          id: 'footer.careers',
          dm: 'Careers',
          to: '/careers',
        }),
        faqs: linkStyle({ id: 'footer.faqs', dm: 'FAQs', to: '/faqs' }),
        privacy: linkStyle({
          id: 'footer.privacyPolicy',
          dm: 'Privacy Policy',
          to: '/privacy-page',
        }),
        term: linkStyle({
          id: 'footer.terms',
          dm: 'Terms and Conditions',
          to: '/terms-and-services',
        }),
        use: linkStyle({
          id: 'footer.use',
          dm: 'Acceptable Use',
          to: '/acceptable-use',
        }),
        cookie: linkStyle({
          id: 'footer.cookie',
          dm: 'Cookie Policy',
          to: '/site-cookies-policy',
        }),
        listBusiness: linkStyle({
          id: 'footer.listBusiness',
          dm: 'List A Business',
          to: '/about-tradvo-seller',
        }),
        listBusinessPlans: linkStyle({
          id: 'footer.listBusinessPlans',
          dm: 'Seller Plans',
          to: '/about-tradvo-plans',
        }),
        login: linkStyle({ id: 'footer.login', dm: 'Login', to: '/login' }),
        signUp: linkStyle({
          id: 'footer.signup',
          dm: 'Choose an account',
          to: '/account-type',
        }),
        sellerPlan: linkStyle({
          id: 'footer.sellerPlan',
          dm: 'Seller Plans',
          to: '/seller-plan',
        }),
        market: linkStyle({
          id: !subdomainValue
            ? 'footer.marketplace'
            : 'navbar.linkIcon.marketplaceStore',
          dm: 'Marketplace',
          to: !subdomainValue ? '/market' : '/market/productsPage',
        }),
        facebookIcon: iconLinkStyle({
          href: 'https://www.facebook.com/tradvo/',
          target: '_blank',
          children: <FacebookRoundedIcon {...iconStyle()} />,
        }),
        linkedInIcon: iconLinkStyle({
          href: 'https://www.linkedin.com/company/tradvo-inc-canada',
          target: '_blank',
          children: <LinkedInIcon {...iconStyle()} />,
        }),
      },
    };
  }
};

export default StoreFooterContent;

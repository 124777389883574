import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { escape } from 'html-escaper';

function useRedirect() {
  // Redirect hook. If no arguments are passed to it, it will redirect to
  // the current url. Primarily used for redirecting to the current page, but
  // will also accept a custom url to redirect to.

  const history = useHistory();
  const location = useLocation();

  const queryStr = queryString.stringify({
    redirect: location.pathname,
  });

  return (route) =>
    history.push(`/auth/redirect/callback?${escape(route) || queryStr}`);
}

export default useRedirect;

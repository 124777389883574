// src/components/Page.jsx
//
// Wrapper component for basic routes. Allows configurations for optional
// appbar and footer, and modular styling.

import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import RedirectToHomepage from '../authentication/RedirectToHomepage';
import Container from './Container';
import Footer from '../footer/Footer';
import AppBar from '../header/AppBar';
import CompactDrawer from '../navbar/CompactDrawer';
import ScrollToTopButton from '../navigation/ScrollToTopButton';
import PageLoadingIcon from '../authentication/PageLoadingIcon';
import usePermissions from '../../hooks/usePermissions';
import ThemeWrapper from '../wrappers/ThemeWrapper';
import NewAdminMenuSection from '../navbar/NewAdminMenuSection';
import SellerMenuSection from '../navbar/SellerMenuSection';
import useAuth from '../../hooks/useAuth';

const ContentWrapper = styled(Box)`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
`;

const BottomRight = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
`;

function OldPage({
  path,
  noAppBar,
  noFooter,
  noContainer,
  adminPanel,
  sellerPanel,
  isProtected,
  page: Page,
  componentId,
  nonAdmin,
  ...rest
}) {
  const { isAuthenticated, isAdmin, isModerator, isLoading } = useAuth();
  const { viewPermitted } = usePermissions(componentId);

  const withContainer = (props) => (
    <Container>
      <Page key={props.location.search} {...props} />
    </Container>
  );

  const withoutContainer = (props) => <Page {...props} />;

  const renderRoute = () => {
    if (isLoading) {
      return <PageLoadingIcon />;
    }
    if ((componentId && !viewPermitted) || (!nonAdmin && !isAdmin))
      return <RedirectToHomepage toast="notViewPermitted" />;
    if (isProtected) {
      if (!isAuthenticated) {
        return <RedirectToHomepage />;
      }
      return (
        <Route
          {...rest}
          path={path}
          render={(props) =>
            noContainer ? withoutContainer(props) : withContainer(props)
          }
        />
      );
    } else {
      return (
        <Route
          {...rest}
          path={path}
          render={(props) =>
            noContainer ? withoutContainer(props) : withContainer(props)
          }
        />
      );
    }
  };

  return (
    <ThemeWrapper>
      <Box {...{ height: '100vh', overflowY: 'auto' }}>
        <Suspense fallback={<div />}>
          {!noAppBar && <AppBar />}
          <Flex
            mt={
              adminPanel || sellerPanel ? '0px' : { base: '92px', md: '48px' }
            }
          >
            {adminPanel ? (
              <NewAdminMenuSection
                showAdmin={isAuthenticated && (isAdmin || isModerator)}
              />
            ) : sellerPanel ? (
              <SellerMenuSection
                showAdmin={isAuthenticated && (isAdmin || isModerator)}
              />
            ) : (
              <CompactDrawer />
            )}
            <ContentWrapper
              as="main"
              id="main"
              ml={{ base: 0, lg: adminPanel || sellerPanel ? 0 : '55px' }}
              mt={adminPanel || sellerPanel ? '55px' : 0}
              w={{ base: '100%', lg: 'calc(100% - 55px)' }}
              minHeight={{
                base: 'calc(100vh - 92px)',
                sm: 'calc(100vh - 48px)',
              }}
            >
              {(adminPanel || sellerPanel) && <AppBar withPanel={true} />}
              {renderRoute()}
              {!noFooter && <Footer />}
            </ContentWrapper>
          </Flex>
          <BottomRight>
            <ScrollToTopButton />
          </BottomRight>
        </Suspense>
      </Box>
    </ThemeWrapper>
  );
}

export default OldPage;

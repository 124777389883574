import React from 'react';
import { Field } from 'formik';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { useFormikContext } from 'formik';
import MuiText from '../intl/MuiText';

const MuiSelect = ({
  // required
  name,
  options,
  // optional
  disabled,
  label,
  required,
  defaultValue = '',
  sx,
  sxSelect,
  accessor,
  labelAccessor = 'name',
  allowNone,
  noneLabel,
  onChange: givenOnChange = () => {},
  onBlur: givenOnBlur = () => {},
  onFocus: givenOnFocus = () => {},
  error: givenError,
  optionsLoading,
  ...rest
}) => {
  const {
    errors: { [name]: error } = {},
    touched: { [name]: isTouched } = {},
    handleBlur,
  } = useFormikContext();
  const { attemptFormat } = useLocale();

  const onBlur = (event) => {
    const { target = {} } = event;
    const { localName, id = '' } = target;
    const [splitId] = id.split('.');

    if (localName !== 'li' || splitId !== name) return;
    handleBlur(event);
    givenOnBlur(event);
  };

  const { text, style } = makeProps({ sx, sxSelect, required, optionsLoading });
  const formattedLabel = !!label && (
    <>
      {attemptFormat(label)}
      {!required && <MuiText {...text.optional} />}
    </>
  );
  return (
    <>
      {name && options && (
        <Field {...{ name }}>
          {({ field: { value, onChange }, form: { isSubmitting } }) => (
            <FormControl
              component="fieldset"
              id={`${name}.fieldset`}
              name={`${name}.fieldset`}
              error={givenError || (!!error && !!isTouched)}
              {...{ required, onBlur, onFocus: givenOnFocus }}
              {...style.control}
            >
              {!!formattedLabel && <InputLabel>{formattedLabel}</InputLabel>}
              <Select
                {...{
                  defaultValue,
                  name,
                  id: name,
                  value: optionsLoading ? '' : value,
                  onChange: (...changeArgs) => {
                    givenOnChange(...changeArgs);
                    onChange(...changeArgs);
                  },
                  label: formattedLabel,
                  disabled: disabled || optionsLoading || isSubmitting,
                  ...style.select,
                  ...rest,
                }}
              >
                {(allowNone
                  ? [
                      accessor
                        ? {
                            [accessor]: '',
                            [labelAccessor]:
                              attemptFormat(label) || name || noneLabel,
                          }
                        : '',
                      ...options,
                    ]
                  : options
                ).map((option) =>
                  accessor ? (
                    <MenuItem
                      key={option[accessor]}
                      value={option[accessor]}
                      id={`${name}.${option[accessor]}`}
                      name={`${name}.${option[accessor]}`}
                      {...(!option[accessor] && {
                        sx: { color: 'placeholderText.main' },
                      })}
                    >
                      {attemptFormat(option[labelAccessor])}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={option}
                      value={option}
                      id={`${name}.${option}`}
                      name={`${name}.${option}`}
                      {...(!option && {
                        sx: { color: 'placeholderText.main' },
                      })}
                    >
                      {option || attemptFormat(label)}
                    </MenuItem>
                  ),
                )}
              </Select>
              {!!isTouched && !!error && (
                <FormHelperText>{error}</FormHelperText>
              )}
            </FormControl>
          )}
        </Field>
      )}
    </>
  );
  function makeProps({ sx, sxSelect, required, optionsLoading }) {
    return {
      text: {
        optional: {
          id: 'form.label.optional',
          dm: '(Optional)',
          sx: {
            display: 'inline-block',
            ml: '0.375rem',
            fontSize: '0.75rem',
            height: '100%',
            verticalAlign: 'top',
          },
        },
      },
      style: {
        control: {
          sx: {
            backgroundColor: 'transparent',
            '& .MuiInputLabel-root': {
              top: '-7px',
              fontSize: '0.875rem',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(12px, -8px) scale(0.9)',
              },
            },
            '& .MuiInputLabel-shrink': { top: '0' },
            '& .MuiInputLabel-asterisk': {
              fontWeight: 'bold',
              color: 'error.main',
            },
            ...(required && {
              '& .MuiOutlinedInput-notchedOutline': {
                '& span': { paddingRight: '5px' },
              },
            }),
            ...sx,
          },
        },
        select: {
          ...(optionsLoading && {
            IconComponent: () => (
              <CircularProgress
                {...{
                  style: {
                    position: 'absolute',
                    top: '10px',
                    right: '8px',
                    width: '1.25rem',
                    height: '1.25rem',
                  },
                }}
              />
            ),
          }),
          sx: {
            color: 'headingText.main',
            '& .MuiSelect-select': { py: '8.5px', fontSize: '0.875rem' },
            ...sxSelect,
          },
        },
      },
    };
  }
};

export default MuiSelect;

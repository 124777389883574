import { Button, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { FALLBACK_IMAGE_AVATAR } from '../../data/constants';
import useAuth from '../../hooks/useAuth';

const UserProfileSection = ({ onClick }) => {
  const { user } = useAuth();
  const { firstName, fullName, profilePicture, lastVisitDate } = user || {};
  const intl = useIntl();
  return (
    <>
      <Flex flexDirection="column" align="center">
        <Flex mt={{ base: 0, xl: 3 }}>
          <Image
            height="100px"
            width="100px"
            objectFit="cover"
            src={profilePicture}
            fallbackSrc={FALLBACK_IMAGE_AVATAR}
            alt={firstName + 'photo'}
            onClick={onClick}
            cursor="pointer"
          />
        </Flex>
        {/* <Flex>
          <Text fontSize="md" mt={2}>
            {user.first_name +
              ' ' +
              user.last_name}
          </Text>
        </Flex> */}
        <Flex>
          <Button
            fontSize="md"
            variant="link"
            aria-label="View Profile"
            mr={0}
            onClick={onClick}
            colorScheme="blue"
            style={{ color: '#eee' }}
            p={3}
            // _hover={{ color: '#333', background: '#ccc' }}
          >
            {fullName}
          </Button>
        </Flex>
      </Flex>
      <Text fontSize="md" textAlign="center">
        {intl.formatMessage({ id: 'navbar.user.lastVisited' }) +
          (lastVisitDate.toDateString() || '-')}
      </Text>
    </>
  );
};

export default UserProfileSection;

import React, { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import LocalGroceryStoreTwoToneIcon from '@mui/icons-material/LocalGroceryStoreTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
//import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import IconLink from './IconLink';
import Logo from './Logo';
import LogoMobile from './LogoMobile';
import StoreLogo from './StoreLogo';
import ProfileIcon from './ProfileIcon';
import Flex from '../common/Flex';
import MuiContainer from '../common/MuiContainer';
import { useHistory } from 'react-router-dom';
import MuiChangeLanguage from './MuiChangeLanguage';
import BottomNavBar from './BottomNavBar';
import IconButton from '@mui/material/IconButton';
import { NavbarMessages } from '../../data/icons';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MuiButton from '../intl/MuiButton';
import { Link } from 'react-router-dom';
import useBreakpoint from '../../hooks/useBreakpoint';
import { Badge } from '@mui/material';
import useMessages from '../../hooks/useMessages';
import useNotifications from '../../hooks/useNotifications';
import SignupButton from '../common/SignupButton';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import useQueryState from '../../hooks/useQueryState';
import useAuth from '../../hooks/useAuth';
import MobileSearchBar from './MobileSearchBar';
import NavSearchBar from './NavSearchBar';
import { useSelector } from 'react-redux';

const NavBar = ({ bottomNavRef }) => {
  const { push } = useHistory();
  const { isLoading, isAuthenticated, isAdmin, user } = useAuth();

  const {
    subdomainValue,
    subdomainStoreId,
    subdomainStoreObject,
    loadingStore,
  } = useSelector(({ intl }) => intl);
  const isBuyer = user?.roles === '3';
  const { md, lg } = useBreakpoint();
  const few = useBreakpoint(825);
  const most = useBreakpoint(1000);
  const smallMobile = useBreakpoint(350);
  const [hideNotifications] = useQueryState('hideNotifications', false);
  const { totalUnread: unreadMessages } = useMessages();
  const { totalUnread } = useNotifications();
  const unreadNotifications = !hideNotifications ? totalUnread : 0;
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const searchInputRef = useRef(null);
  const [, setSearchText] = useQueryState('search', '', { encoded: true });
  const [, setSearchTag] = useQueryState('tag', '', { encoded: true });

  const business = subdomainStoreObject;

  const onSearchPage = window.location.pathname === '/search';
  const triggerSearch = (newSearchText) => {
    const trimmedText = newSearchText.trim();
    if (!trimmedText) return;
    setSearchTag('');
    if (onSearchPage) setSearchText(trimmedText);
    else push(`/search?search=${trimmedText}`);
  };

  const navLinks = makeLinks({
    md,
    few,
    most,
    unreadMessages,
    unreadNotifications,
    isBuyer,
    isAdmin,
  });

  const filteredNavLinks = navLinks.filter(
    ({ dropdown, loginRequired, viewRestricted }) =>
      !dropdown && (!loginRequired || isAuthenticated) && !viewRestricted,
  );

  const { style, button } = makeProps({
    md,
    smallMobile,
    isAuthenticated,
    triggerSearch,
  });
  return (
    <Box {...style.box}>
      <AppBar {...style.appBar}>
        <MuiContainer {...style.container}>
          <Toolbar {...style.toolbar}>
            {!subdomainValue ? (
              <Flex {...style.homeAndSearch}>
                {!md && <Logo />}
                {md && <LogoMobile />}
                {!md && <NavSearchBar {...style.search} />}
              </Flex>
            ) : (
              <Flex {...style.homeAndSearch}>
                {!md && (
                  <StoreLogo
                    {...{
                      business,
                      subdomainStoreId,
                      ...loadingStore,
                    }}
                  />
                )}
                {md && (
                  <StoreLogo
                    {...{
                      business,
                      subdomainStoreId,
                      ...loadingStore,
                    }}
                  />
                )}
                {!md && <NavSearchBar {...style.search} />}
              </Flex>
            )}
            {md ? (
              <Flex {...style.mobileIconBox}>
                <MuiChangeLanguage />
                <IconButton
                  {...{
                    onClick: () => {
                      setSearchBarOpen(true);
                      if (!searchInputRef?.current?.focus) return;
                      searchInputRef.current.focus();
                    },
                    ...style.searchButton,
                  }}
                >
                  <SearchIcon {...style.searchIcon} />
                </IconButton>
                {isAuthenticated ? (
                  <>
                    <Flex>
                      <IconButton component={Link} to="/notifications">
                        <Badge {...style.mobileNotificationBadge}>
                          <NotificationsNoneIcon {...style.bellIcon} />
                        </Badge>
                      </IconButton>
                    </Flex>
                    <Flex
                      {...(!isBuyer && { 'data-tour': 'sellerLanding-step-1' })}
                    >
                      <ProfileIcon {...{ navLinks }} />
                    </Flex>
                  </>
                ) : (
                  <MuiButton {...{ ...button.login, to: '/login' }} />
                )}
                <BottomNavBar {...{ bottomNavRef }} />
              </Flex>
            ) : (
              <>
                <Flex
                  {...{
                    'data-tour': `${
                      isBuyer ? 'buyer' : 'seller'
                    }Landing-step-3`,
                    sx: { mt: 'auto' },
                  }}
                >
                  {filteredNavLinks.map((iconLink, key) => (
                    <IconLink {...{ key, ...iconLink }} />
                  ))}
                  <Flex>
                    <ProfileIcon {...{ navLinks }} />
                  </Flex>
                  {!isLoading && !isAuthenticated && (
                    <Flex {...style.loginLinks}>
                      {!lg && <SignupButton {...button.signup} />}
                      <MuiButton {...{ ...button.login, to: '/login' }} />
                    </Flex>
                  )}
                </Flex>
                <MuiChangeLanguage />
              </>
            )}
          </Toolbar>
        </MuiContainer>
      </AppBar>
      <MobileSearchBar
        {...{ triggerSearch, searchBarOpen, setSearchBarOpen, searchInputRef }}
      />
    </Box>
  );

  function makeLinks({
    md,
    few,
    most,
    unreadMessages,
    unreadNotifications,
    isBuyer,
    isAdmin,
  }) {
    return [
      {
        text: { id: 'navbar.linkIcon.home', dm: 'Home' },
        path: '/',
        icon: HomeIcon,
        viewRestricted: few && !md,
      },
      {
        text: {
          id: !subdomainValue
            ? 'navbar.linkIcon.marketplace'
            : 'navbar.linkIcon.marketplaceStore',
          dm: 'Marketplace',
        },
        path: !subdomainValue ? '/market' : '/market/productsPage',
        icon: !subdomainValue
          ? StorefrontTwoToneIcon
          : LocalGroceryStoreTwoToneIcon,
        dropdown: few && !md && isAuthenticated,
      },
      {
        text: { id: 'navbar.linkIcon.network', dm: 'Network' },
        path: '/network',
        icon: GroupsTwoToneIcon,
        dropdown: most && !md,
        loginRequired: true,
      },
      {
        text: { id: 'navbar.linkIcon.orders', dm: 'Orders' },
        path: '/orders',
        icon: AssignmentTwoToneIcon,
        dropdown: most && !md,
        loginRequired: true,
      },
      {
        text: { id: 'navbar.linkIcon.threads', dm: 'Threads' },
        path: '/messages',
        icon: NavbarMessages,
        loginRequired: true,
        badgeContent: unreadMessages,
      },
      {
        text: { id: 'navbar.linkIcon.notifications', dm: 'Notifications' },
        path: '/notifications',
        icon: NotificationsTwoToneIcon,
        loginRequired: true,
        badgeContent: unreadNotifications,
      },
      {
        text: { id: 'navbar.navitem.home.myAccount', dm: 'Account Settings' },
        path: '/profile',
        icon: ManageAccountsTwoToneIcon,
        dropdown: true,
        loginRequired: true,
      },
      {
        text: { id: 'navbar.navitem.home.dashboard', dm: 'Dashboard' },
        path: '/seller-dashboard',
        icon: DashboardTwoToneIcon,
        dropdown: true,
        loginRequired: true,
        viewRestricted: isBuyer || isAdmin,
      },
      {
        text: { id: 'navbar.navitem.home.myInvitations', dm: 'My Invitations' },
        path: '/network/invitations',
        icon: PersonAddAltIcon,
        dropdown: true,
        loginRequired: true,
        viewRestricted: isBuyer || isAdmin,
      },
      {
        text: { id: 'navbar.navitem.home.myReviews', dm: 'My Reviews' },
        path: '/myReviews',
        icon: StarsTwoToneIcon,
        dropdown: true,
        loginRequired: true,
      },
      {
        text: { id: 'navbar.navitem.home.adminPanel', dm: 'Admin Panel' },
        path: '/admin-panel',
        icon: AdminPanelSettingsIcon,
        dropdown: true,
        loginRequired: true,
        viewRestricted: !isAdmin,
      },
    ].filter(({ path }) => path);
  }

  function makeProps({ md, smallMobile, isAuthenticated, triggerSearch }) {
    const iconSize = smallMobile ? '1.5rem' : '2rem';
    return {
      style: {
        box: { sx: { flexGrow: '1' } },
        container: { sxInner: { maxWidth: '70rem' } },
        appBar: {
          color: 'card',
          sx: { position: 'static', boxShadow: 'none' },
        },
        toolbar: {
          sx: {
            px: { xs: '0.75rem', md: '0' },
            width: '100%',
            alignItems: 'center',
            height: '3.75rem',
          },
        },
        homeAndSearch: {
          sx: {
            pr: isAuthenticated ? '0.5rem' : 0,
            width: '100%',
            height: '3.75rem',
          },
        },
        search: {
          triggerSearch,
          sxBox: { width: '100%', maxWidth: '30rem' },
          sxField: { width: '100%' },
        },
        mobileIconBox: { sx: { alignItems: 'center' } },
        mobileNotificationBadge: {
          badgeContent: unreadNotifications,
          color: 'error',
          max: 99,
        },
        loginLinks: {
          sx: {
            px: { xs: '0', lg: '1rem' },
            gap: '1rem',
            height: '100%',
            flexShrink: '0',
            alignItems: 'center',
          },
        },
        searchButton: { color: 'primary', sx: { mr: '0.5rem' } },
        searchIcon: { sx: { width: iconSize, height: iconSize } },
        bellIcon: { sx: { width: iconSize, height: iconSize } },
      },
      button: {
        signup: {
          fontSize: '0.75rem',
          sx: { width: 'fit-content', p: '0.5rem' },
        },
        login: {
          id: 'auth.signup.login',
          dm: 'Login',
          variant: 'outlined',
          fontSize: '0.875rem',
          sx: {
            width: 'fit-content',
            p: '0.375rem 0.875rem',
            ml: { xs: 'auto', md: '0' },
          },
        },
      },
    };
  }
};

export default NavBar;

import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import { Grid, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import WrapOn from './WrapOn';
import MuiNamedLink from './MuiNamedLink';
import { parseColor } from '../../theme/materialTheme';

const Flex = forwardRef(
  (
    {
      children,
      sx,
      loading,
      sxSkeleton,
      skeletonProps,
      customScroll,
      container,
      item,
      containerProps,
      itemProps,
      to,
      href,
      namedLink,
      append,
      tourName,
      tourStep,
      tourStepMulti,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const gridCondition = container || containerProps || item || itemProps;

    const customThumbColor = parseColor(
      customScroll?.color
        ? `${customScroll.color}.main`
        : customScroll?.thumbBackgroundColor || 'primary.main',
    );

    const customTrackColor = parseColor(
      customScroll?.color
        ? `${customScroll.color}.filter`
        : customScroll?.trackBackgroundColor || 'primary.filter',
    );

    const style = {
      box: {
        ...(tourName &&
          tourStep && {
            'data-tour': `${[tourName, 'step', tourStep, tourStepMulti]
              .filter((el) => el)
              .join('-')}`,
          }),
        ...(to && { component: Link, to }),
        ...(href && { component: 'a', href }),
        ...(namedLink && {
          component: MuiNamedLink,
          nonText: true,
          append,
          ...namedLink,
        }),
        direction: 'row',
        onClick,
        sx: {
          ...((onClick || to || href) && { cursor: 'pointer' }),
          ...(customScroll && {
            [rest.direction === 'column' ||
            ['column', 'column-reverse'].includes(sx?.flexDirection)
              ? 'overflowY'
              : 'overflowX']: 'auto',
            '&::-webkit-scrollbar': {
              width: customScroll?.width || '1rem',
              height: customScroll?.height || '1rem',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: customThumbColor,
              borderRadius: customScroll?.thumbBorderRadius || '5rem',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: customTrackColor,
              borderRadius: customScroll?.trackBorderRadius || '5rem',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: `${customThumbColor} ${customTrackColor}`,
          }),
          ...sx,
          ...(sx?.aspectRatio && {
            aspectRatio: `${sx?.aspectRatio}${
              typeof sx?.aspectRatio === 'number' ? '/1' : ''
            }`,
          }),
        },
        ...rest,
      },
    };

    const gridProps = {
      Wrap: Grid,
      container,
      ...containerProps,
      item,
      ...itemProps,
    };

    const skeleton = {
      box: {
        variant: 'rectangular',
        sx: {
          borderRadius: '10px',
          width: sx?.width || '100%',
          height: sx?.height || '100%',
          ...sx,
          ...(sx?.aspectRatio && {
            aspectRatio: `${sx?.aspectRatio}${
              typeof sx?.aspectRatio === 'number' ? '/1' : ''
            }`,
          }),
          ...sxSkeleton,
        },
        ...rest,
        ...skeletonProps,
      },
    };

    return loading ? (
      <Skeleton {...skeleton.box} />
    ) : (
      <WrapOn {...(ref && { ref })} {...{ on: gridCondition, ...gridProps }}>
        <Stack {...{ ...style.box, ...(ref && !gridCondition && { ref }) }}>
          {children}
        </Stack>
      </WrapOn>
    );
  },
);

export default Flex;

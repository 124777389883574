import React, { useState } from 'react';
import { Field } from 'formik';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { translate } from '../../utils/translate';
import TranslateIcon from '@mui/icons-material/Translate';
import HelpIcon from '@mui/icons-material/Help';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Flex from '../common/Flex';
import useLocale from '../../hooks/useLocale';
import MuiText from '../intl/MuiText';
import useSnackbar from '../../hooks/useSnackbar';
import { FormControl, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const MuiFormField = ({
  // required
  name,
  // optional
  label,
  required,
  hideOptional,
  disabled,
  translateFrom,
  findHs6Button,
  readOnly,
  hasCopy,
  warning,
  currency,
  currencyOptions,
  onChangeCurrency,
  sx,
  sxHelperText,
  inputProps,
  InputProps,
  placeholder,
  noPlaceholder,
  validate,
  multiline,
  noHelperText,
  dontShowError,
  type,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { palette } = useTheme();
  const { attemptFormat } = useLocale();
  const { snackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleTranslate = async (translateText, setFieldValue) => {
    setIsLoading(true);
    try {
      const translatedText = await translate(translateText);
      setFieldValue(name, translatedText);
    } catch (e) {
      snackbar({ message: 'translateFail' });
    }
    setIsLoading(false);
  };

  const copyToClipboard = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
      snackbar({ message: 'copySuccess' });
    } catch (e) {
      snackbar({ message: 'copyError' });
    }
  };

  const { text, style } = makeProps({
    palette,
    attemptFormat,
    isLoading,
    warning,
  });

  const TranslateButton = ({ translateFrom, form }) => (
    <InputAdornment position="end">
      <Tooltip title={text.translateTooltip}>
        <Flex {...style.translateBox}>
          <IconButton
            aria-label={text.translateButtonAria}
            color="card"
            onClick={() =>
              handleTranslate(form.values[translateFrom], form.setFieldValue)
            }
            {...(isLoading && { disabled: true })}
          >
            {isLoading ? (
              <CircularProgress size="1.5rem" color="card" />
            ) : (
              <TranslateIcon />
            )}
          </IconButton>
        </Flex>
      </Tooltip>
    </InputAdornment>
  );

  const Hs6Button = () => (
    <InputAdornment position="end">
      <Tooltip title={text.hs6Tooltip}>
        <Flex {...style.hs6Box}>
          <IconButton
            aria-label={text.hs6ButtonAria}
            color="card"
            onClick={() =>
              window.open(
                'https://www.canadapost.ca/cpotools/apps/wtz/business/findHsCode?execution=e1s1',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            <HelpIcon />
          </IconButton>
        </Flex>
      </Tooltip>
    </InputAdornment>
  );

  const CopyButton = ({ value }) => (
    <InputAdornment position="end">
      <Tooltip title={text.copyTooltip}>
        <Flex {...style.copyBox}>
          <IconButton
            aria-label={text.copyButtonAria}
            color="card"
            onClick={() => copyToClipboard(value)}
            {...((isLoading || disabled) && { disabled: true })}
          >
            {isLoading ? (
              <CircularProgress size="1.5rem" color="card" />
            ) : (
              <ContentCopyIcon />
            )}
          </IconButton>
        </Flex>
      </Tooltip>
    </InputAdornment>
  );

  const PasswordToggle = ({ isSubmitting }) => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword((current) => !current)}
        edge="end"
        disabled={isSubmitting}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Field {...{ name, validate }}>
      {({ field: { value, onChange }, form }) => (
        <TextField
          {...{
            name,
            id: name,
            type:
              type !== 'password' ? type : showPassword ? 'text' : 'password',
            sx: {
              position: 'relative',
              width: '100%',
              ...(currency && {
                '&>div': { px: '0' },
                '& .MuiInputBase-inputAdornedStart': {
                  p: '0 0.5rem 0 1.5rem',
                  height: 'auto',
                  borderRadius: '5px 0 0 5px !important',
                },
                '& .MuiSelect-select': { height: 'auto !important' },
              }),
              '& .MuiInputBase-input': {
                py: '8.5px',
                height: '2.5rem',
                fontSize: '0.875rem',
              },
              ...(multiline
                ? {
                    '& .MuiInputBase-root': { py: '0', pr: '0' },
                    '& textarea': {
                      '&::-webkit-scrollbar': { width: '1rem' },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'background.darker',
                        borderRadius: '5rem',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'primary.main',
                        borderRadius: '5rem',
                      },
                      scrollbarWidth: 'thin',
                      scrollbarColor: `${palette.primary.main} ${palette.background.darker}`,
                      pr: '0.5rem',
                    },
                  }
                : {
                    '& .MuiOutlinedInput-root': {
                      height: '2.5rem',
                      overflow: 'hidden',
                      // ...(sx.backgroundColor && {
                      //   backgroundColor: sx.backgroundColor,
                      //   borderRadius: 2,
                      // }),
                      ...sx,
                    },
                  }),
              '& .MuiInputLabel-asterisk': {
                fontWeight: 'bold',
                color: 'error.main',
              },
              '& .MuiInputLabel-root': {
                top: '-7px',
                fontSize: '0.875rem',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(12px, -8px) scale(0.9)',
                },
              },
              '& .MuiInputLabel-shrink': { top: '0' },
              ...(!!warning && {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'warning.main',
                },
              }),
              ...sx,
            },
            disabled: disabled || form.isSubmitting,
            value,
            onChange,
            multiline,
            required,
            ...rest,
          }}
          {...(!!label && {
            label: (
              <>
                {attemptFormat(label)}
                {!required && !hideOptional && <MuiText {...text.optional} />}
              </>
            ),
          })}
          {...(!noPlaceholder && {
            placeholder: attemptFormat(
              placeholder || { id: `forms.${name}.placeholder` },
            ),
          })}
          onBlur={form.handleBlur}
          error={!!form.errors[name] && !!form.touched[name] && !dontShowError}
          {...(!noHelperText && {
            helperText: text.helperText(form),
            FormHelperTextProps: style.helperText(sxHelperText),
          })}
          {...(!!warning && { color: 'warning' })}
          inputProps={{
            ...(currency && { step: '0.01', min: '0' }),
            ...inputProps,
          }}
          InputProps={{
            readOnly,
            ...(type === 'password' && {
              endAdornment: (
                <PasswordToggle {...{ isSubmitting: form?.isSubmitting }} />
              ),
            }),
            ...(translateFrom && {
              endAdornment: <TranslateButton {...{ translateFrom, form }} />,
            }),
            ...(findHs6Button && {
              endAdornment: <Hs6Button />,
            }),
            ...(hasCopy && {
              endAdornment: <CopyButton {...{ value }} />,
            }),
            ...(currency && {
              startAdornment: (
                <InputAdornment {...style.startAdornment}>
                  <MuiText sx={{ px: '0.23rem' }}>
                    {attemptFormat(currency.symbol)}
                  </MuiText>
                </InputAdornment>
              ),
              endAdornment: currencyOptions ? (
                <FormControl {...{ sx: { minWidth: 'fit-content' } }}>
                  <Select {...style.currencySelect(currency, onChangeCurrency)}>
                    {currencyOptions.map((currency) => (
                      <MenuItem {...style.currencySelectOption(currency)}>
                        {attemptFormat(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Flex {...style.currencyName}>
                  <MuiText>{attemptFormat(currency.name)}</MuiText>
                </Flex>
              ),
            }),
            ...InputProps,
          }}
        />
      )}
    </Field>
  );

  function makeProps({ palette, attemptFormat, isLoading, warning }) {
    return {
      text: {
        translateTooltip: attemptFormat({
          id: 'forms.translate',
          dm: 'Translate',
        }),
        translateButtonAria: attemptFormat({
          id: 'forms.translate',
          dm: 'Translate',
        }),
        hs6Tooltip: attemptFormat({
          id: 'admin.table.itemType.link.hs6code',
          dm: 'Find HS6 Code?',
        }),
        hs6ButtonAria: attemptFormat({
          id: 'admin.table.itemType.link.hs6code',
          dm: 'Find HS6 Code?',
        }),
        copyTooltip: attemptFormat({ id: 'forms.copy', dm: 'Copy' }),
        copyButtonAria: attemptFormat({ id: 'forms.copy', dm: 'Copy' }),
        helperText: (form) =>
          (!!form.touched[name] &&
            !!form.errors[name] &&
            !dontShowError &&
            form.errors[name]) ||
          (!!warning && attemptFormat(warning)),
        optional: {
          id: 'form.label.optional',
          dm: '(Optional)',
          sx: {
            display: 'inline-block',
            ml: '0.375rem',
            fontSize: '0.75rem',
            height: '100%',
            verticalAlign: 'top',
          },
        },
      },
      style: {
        translateBox: {
          sx: {
            backgroundColor: isLoading
              ? 'placeholderText.main'
              : 'primary.main',
            borderRadius: '10px',
          },
        },
        hs6Box: {
          sx: { backgroundColor: 'primary.main', borderRadius: '10px' },
        },
        copyBox: {
          sx: { backgroundColor: 'primary.main', borderRadius: '10px' },
        },
        helperText: (sxHelperText) => ({
          sx: {
            m: 0,
            px: '1.25rem',
            py: '0.5rem',
            ...(!!warning && { color: 'warning.main' }),
            ...sxHelperText,
          },
        }),
        currencyName: {
          sx: {
            px: '0.5rem',
            height: '2.5rem',
            backgroundColor: 'placeholderText.main',
            color: 'headingText.main',
            alignItems: 'center',
          },
        },
        startAdornment: {
          position: 'relative',
          sx: {
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '1px',
            height: '2.5rem',
            maxHeight: '2.5rem',
          },
        },
        currencySelect: ({ name }, onChangeCurrency) => ({
          sx: {
            borderRadius: '0 5px 5px 0',
            height: '2.5rem',
            backgroundColor: 'placeholderText.main',
            minWidth: 'max-content',
          },
          defaultValue: attemptFormat(name),
          value: attemptFormat(name),
          onChange: onChangeCurrency,
        }),
        currencySelectOption: ({ name }) => ({
          key: attemptFormat(name),
          value: attemptFormat(name),
        }),
      },
    };
  }
};

export default MuiFormField;

// LoginModal modal - Uses Formik for form validation and yup for validation schemas.

import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import LoginForm from './LoginFormOld';
import ForgotPassword from './ForgotPasswordOld.jsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function LoginModal({ isSolid, isBlack, style, id }) {
  const intl = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openerRef = useRef();
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (isOpen) setIsForgotPasswordOpen(false);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Button
        variant={isSolid ? 'solid' : 'link'}
        colorScheme="blue"
        style={style}
        ref={openerRef} // Keep this component to pass in ref
        onClick={onOpen}
        size={matches ? 'xs' : ''}
      >
        {intl.formatMessage({ id: id || 'auth.login' })}
      </Button>
      <Modal
        motionPreset="slideInBottom"
        finalFocusRef={openerRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size={{ base: 'md', md: 'xl' }}
      >
        <ModalOverlay />
        <ModalContent width="fit-content">
          {isForgotPasswordOpen ? (
            <ForgotPassword
              onClose={onClose}
              setIsForgotPasswordOpen={setIsForgotPasswordOpen}
            />
          ) : (
            <LoginForm setIsForgotPasswordOpen={setIsForgotPasswordOpen} />
          )}
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}

export default LoginModal;

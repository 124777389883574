import React from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import useAuth from '../../hooks/useAuth';
import Flex from '../common/Flex';
import MuiText from '../intl/MuiText';
import MuiButton from '../intl/MuiButton';
import { parseColor } from '../../theme/materialTheme';

const SellerFollowModal = ({ onClick }) => {
  const { supplier } = useAuth();

  const { text, style } = makeProps({ onClick, supplier });
  return (
    <Flex {...style.stack}>
      <CampaignIcon {...style.icon} />
      <MuiText {...text.title} />
      <MuiText {...text.pointOne} />
      <MuiText {...text.pointTwo} />
      <MuiButton {...style.invite} />
    </Flex>
  );

  function makeProps({ onClick, supplier }) {
    const { name } = supplier || {};
    const bold = { style: { fontWeight: 'bold' } };
    const primary = { style: { color: parseColor('primary.main') } };
    return {
      text: {
        title: {
          id: 'businessProfile.headerCard.invitePrompt.title',
          dm: 'Invite Followers to {BUSINESS}',
          replaceMap: { '{BUSINESS}': <span {...primary}>{name}</span> },
          sx: {
            textAlign: 'center',
            color: 'headingText.main',
            fontSize: '1.75rem',
            fontWeight: 'bold',
          },
        },
        pointOne: {
          id: 'businessProfile.headerCard.invitePrompt.followModal',
          dm:
            "As a Seller, your business page {BUSINESS} can get followers. Followers are directly notified about your business's offers, listings, and updates.",
          replaceMap: { '{BUSINESS}': <span {...primary}>{name}</span> },
          sx: {
            textAlign: 'center',
            color: 'headingText.main',
            fontSize: '1rem',
          },
        },
        pointTwo: {
          id: 'businessProfile.headerCard.invitePrompt.pointTwo',
          dm:
            'Easily invite followers through emails, direct links, or page badges.',
          replaceMap: {
            'emails,': <span {...bold}>emails,</span>,
            'links,': <span {...bold}>links,</span>,
            'badges.': <span {...bold}>badges.</span>,
            'e-mails,': <span {...bold}>e-mails,</span>,
            liens: <span {...bold}>liens</span>,
            badges: <span {...bold}>badges</span>,
          },
          sx: {
            textAlign: 'center',
            color: 'headingText.main',
            fontSize: '1.25rem',
          },
        },
      },
      style: {
        stack: {
          direction: 'column',
          sx: {
            p: '0.75rem 0.5rem',
            gap: '1rem',
            alignItems: 'center',
            fontSize: { xs: '5rem', md: '8rem' },
          },
        },
        icon: { sx: { color: 'secondary.main', fontSize: '8.5rem' } },
        invite: {
          id: 'businessProfile.headerCard.invitePrompt.button',
          dm: 'Invite Followers',
          onClick,
          fontSize: '1.25rem',
          sx: { p: '0.5rem 3rem' },
        },
      },
    };
  }
};

export default SellerFollowModal;

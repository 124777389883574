import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Divider,
  Grid,
  Flex,
} from '@chakra-ui/react';
import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// import { FALLBACK_IMAGE_AVATAR } from '../../data/constants';
import './CheckboxSelectionSection.css';
import {
  RiQuestionnaireLine,
  VscDebugAlt,
  GiNothingToSay,
} from 'react-icons/all';
import useBreakpoint from '../../hooks/useBreakpoint';
import MuiText from '../intl/MuiText';
import MuiLoading from '../common/MuiLoading';
const CheckboxGroupSection = ({
  id = '',
  name,
  type,
  data,
  multiline,
  required,
  min,
  max,
  isInline,
  spacing,
  options,
  accessor,
  selected,
  handleChange,
  isDisabled,
  nameless,
  center,
  wrapped,
  noSelectedCategory,
  loading,
  ...rest
}) => {
  const intl = useIntl();
  const setRoles = (name, isChecked) => {
    let rolesArr = [...selected];
    if (isChecked) {
      rolesArr.push(name);
    } else {
      rolesArr = rolesArr.filter((item) => item !== name);
    }
    handleChange(rolesArr);
  };
  const { locale } = useSelector((state) => state.intl);
  const { md } = useBreakpoint();
  const getIcon = (iconImages) => {
    if (iconImages === 'question') return <RiQuestionnaireLine size={70} />;
    if (iconImages === 'bug') return <VscDebugAlt size={70} />;
    if (iconImages === 'suggestion') return <GiNothingToSay size={70} />;
  };
  const columnNumber = options % 2 === 0 ? 4 : 5;
  const style = {
    loading: {
      size: '2rem',
      sx: { pt: '2rem' },
    },
  };
  return (
    <Field name={name}>
      {({ form }) => (
        <FormControl
          {...rest}
          isInvalid={form.errors[name] && form.touched[name]}
          display={center ? 'flex' : 'block'}
          flexDirection={center ? 'column' : 'row'}
          alignItems={center ? 'center' : 'flex-start'}
        >
          {type !== 'manageMessageType' && (
            <>
              <FormLabel htmlFor={name} paddingRight={center ? '0' : '0.75rem'}>
                <MuiText fontSize={md ? '0.875rem' : 'md'}>
                  {intl.formatMessage({
                    id: 'forms.' + name,
                    defaultMessage: name,
                  })}
                  {required && '*'}
                </MuiText>
              </FormLabel>
              <Divider />
            </>
          )}
          {/* for manage interests use purpose */}

          {type === 'manageInterests' ? (
            <MuiLoading {...{ loading: loading, ...style.loading }}>
              <Grid
                templateColumns={
                  md ? `repeat(3, 1fr)` : `repeat(${columnNumber}, 1fr)`
                }
                gap={4}
                marginTop="10px"
              >
                {options.map((item, index) => (
                  <div className="cont-main" key={index}>
                    <div className="cont-checkbox">
                      <input
                        className="interestInput"
                        type="checkbox"
                        id={`${item.name}-${name}`}
                        name={item[accessor]}
                        onChange={(e) =>
                          setRoles(e.target.name, e.target.checked)
                        }
                        value={item.id}
                        checked={selected.includes(item[accessor])}
                        disabled={
                          selected.length > 10 &&
                          !selected.includes(item[accessor])
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor={`${item.name}-${name}`}
                        className="interestLabel"
                      >
                        <img
                          src={item.categoryImages}
                          alt={item.categoryImages}
                          // fallbackSrc={FALLBACK_IMAGE_AVATAR}
                        />
                        <span className="cover-checkbox">
                          <svg viewBox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1" />
                          </svg>
                        </span>
                        <div className="categoryName">
                          {!nameless && item.name}
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
              </Grid>
            </MuiLoading>
          ) : type === 'manageMessageType' ? (
            /* for manage message type purpose */
            <Flex justifyContent="space-between">
              {options.map((item, index) => (
                <div className="cont-checkbox" key={index}>
                  <input
                    className="interestInput"
                    type="checkbox"
                    id={`${item.name}-${name}`}
                    name={item[accessor]}
                    onChange={(e) => handleChange(item[accessor])}
                    value={item.id}
                    checked={form.values.type === item[accessor]}
                  />
                  <label
                    htmlFor={`${item.name}-${name}`}
                    className="interestLabel"
                  >
                    <Flex justifyContent="center">
                      {getIcon(item.categoryImages)}
                    </Flex>
                    <span className="cover-checkbox">
                      <svg viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1" />
                      </svg>
                    </span>
                    <div className="categoryName">{!nameless && item.name}</div>
                  </label>
                </div>
              ))}
            </Flex>
          ) : (
            options.map((item, index) => (
              <Checkbox
                key={index}
                height="2.25rem"
                id={`${item.name}-${name}`}
                name={item[accessor]}
                isChecked={!isDisabled && selected.includes(item[accessor])}
                onChange={(e) => setRoles(e.target.name, e.target.checked)}
                value={item.id}
                isDisabled={isDisabled}
                mr={wrapped ? 4 : 0}
                ml={md ? '10px' : ''}
              >
                <Text fontSize={md ? '16px' : ''} marginLeft={md ? '-3px' : ''}>
                  {!nameless && item.name}
                </Text>
              </Checkbox>
            ))
          )}

          {/* for manage interests use purpose */}
          {type === 'manageInterests' ? (
            selected.length > 0 && selected.length < 3 ? (
              <Text color="red.500" paddingTop="10px">
                {locale === 'en'
                  ? '*Please select at least three categories of interests'
                  : locale === 'fr'
                  ? "*Veuillez sélectionner au moins trois catégories d'intérêts"
                  : '*يرجى تحديد ثلاث فئات على الأقل من الإهتمامات'}
              </Text>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {/* for manage interests use purpose */}
          {type === 'manageInterests' ? (
            selected.length > 10 ? (
              <Text color="red.500" paddingTop="10px">
                {locale === 'en'
                  ? '*You can only select up to ten categories of interest'
                  : locale === 'fr'
                  ? "*Vous ne pouvez sélectionner que jusqu'à dix catégories d'intérêt"
                  : '*يمكنك فقط تحديد ما يصل إلى عشر فئات من الإهتمامات'}
              </Text>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default CheckboxGroupSection;

import { Autocomplete, Chip, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import useLocale from '../../hooks/useLocale';
import { isEmail } from '../../hooks/useValidator';
import { parseColor } from '../../theme/materialTheme';
import { mergeProps } from '../../utils/mergeProps';
import Flex from '../common/Flex';
import MuiText from '../intl/MuiText';

// test1@email.com,
// test1@email.com,
// test1@email,
// 'test2@email.com'     "test3@email.com"
// test4@email.com, test5@email.com
// test6@email.com,
// test6@email
// 'test7@email.com'     "test8@email.com"
// test9@email.com, test10@email.com
// test11@email.com, test12@email.com

const EmailInput = ({
  name,
  placeholder,
  limit = 10,
  limitWarningProps,
  autocompleteProps,
  textInputProps,
  errorMessageProps,
}) => {
  const { attemptFormat } = useLocale();
  const [inputValue, setInputValue] = useState('');
  const {
    values,
    errors,
    touched,
    setTouched,
    validateForm,
    setFieldValue,
  } = useFormikContext();
  const { [name]: currentValue } = values;
  const { [name]: fieldError } = errors;
  const { [name]: fieldTouched } = touched;
  const limitReached = currentValue.length >= limit;
  const [errorMessage, setErrorMessage] = useState('');

  const clearField = () => {
    setInputValue('');
    setErrorMessage('');
    setTouched({ ...touched, [name]: false });
    setFieldValue(name, []);
  };

  const removeIndex = (inputArray, index) =>
    [...inputArray].filter((_, arrayIndex) => arrayIndex !== index);

  const removeTag = (index) => {
    setErrorMessage('');
    setTouched({ ...touched, [name]: true });
    setFieldValue(name, removeIndex(currentValue, index));
  };

  const updateInput = (validValues = []) =>
    setFieldValue(
      name,
      validValues.reduce((allValues, nextValue) => {
        if (allValues.length < limit && !allValues.includes(nextValue))
          allValues.push(nextValue);
        return allValues;
      }, currentValue),
    );

  const triggerChips = (value = '') =>
    [',', ' ', '\n'].includes(value.slice(-1));

  const validateValues = (value = '') =>
    value
      .trim()
      .replaceAll(/\s+/g, ',')
      .replaceAll(/['"]+/g, '')
      .split(',')
      .reduce(
        ([validValues, invalidValues], nextEmail) =>
          isEmail(nextEmail)
            ? [[...validValues, nextEmail], invalidValues.filter((el) => el)]
            : [validValues, [...invalidValues, nextEmail].filter((el) => el)],
        [[], []],
      );

  const handleValue = (value, options) => {
    const { trigger, touch, name } = options || {};
    if (value === '') setErrorMessage('');
    if (limitReached) {
      setErrorMessage('');
      setInputValue('');
      return;
    }
    if (!triggerChips(value) && !trigger) return setInputValue(value);
    const [validValues, invalidValues] = validateValues(value);

    const invalidText = invalidValues.join(', ');

    const errorText = attemptFormat(
      validValues.length
        ? invalidValues.length === 1
          ? {
              id: 'forms.error.invitedEmailList.multipleInput.oneInvalid',
              dm: 'One of the emails you entered was invalid',
            }
          : {
              id: 'forms.error.invitedEmailList.multipleInput.someInvalid',
              dm: 'Some of the emails you entered were invalid',
            }
        : invalidValues.length === 1
        ? {
            id: 'forms.error.invitedEmailList.singleInput',
            dm: 'The email you entered was invalid',
          }
        : {
            id: 'forms.error.invitedEmailList.multipleInput.allInvalid',
            dm: 'The emails you entered were invalid',
          },
    );

    setInputValue(
      validValues.length + invalidValues.length === 1 ? invalidText : '',
    );
    updateInput(validValues);
    setErrorMessage(!!invalidText ? errorText : '');
    if (touch) setTouched({ ...touched, [name]: true });
    setTimeout(() => validateForm(), 0);
  };

  const handleKeyDown = ({ code }) => {
    if (code !== 'Backspace' || inputValue || !currentValue.length) return;
    setInputValue([...currentValue].slice(-1)[0] + ' '); // space is added so last char isn't removed
    setFieldValue(name, [...currentValue].slice(0, -1));
  };

  const { text, style } = makeProps({
    limitReached,
    fieldError,
    fieldTouched,
    attemptFormat,
    errorMessage,
    clearField,
    removeTag,
    placeholder,
  });
  return (
    <Field {...{ name }}>
      {() => (
        <Flex {...{ direction: 'column' }}>
          <Autocomplete
            {...{
              inputValue,
              value: currentValue,
              ...style.autocomplete(autocompleteProps),
              renderInput: (params) => (
                <TextField
                  {...style.textInput(mergeProps(params, textInputProps))}
                />
              ),
              renderTags: (value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...style.chip(getTagProps({ index }), option, index)}
                  />
                )),
              onKeyDown: handleKeyDown,
              onInputChange: (_, value = '') => handleValue(value),
              onBlur: ({ target: { value } }) =>
                handleValue(value, { trigger: true, touch: true }),
              onChange: ({ target: { value } }) =>
                !!value && handleValue(value, { trigger: true }),
            }}
          />
          <MuiText {...text.error(errorMessageProps)} />
          {limitReached && !errorMessage && (
            <MuiText {...text.limitWarning(limitWarningProps)} />
          )}
        </Flex>
      )}
    </Field>
  );

  function makeProps({
    limitReached,
    fieldError,
    fieldTouched,
    attemptFormat,
    errorMessage,
    clearField,
    removeTag,
    placeholder,
  }) {
    return {
      text: {
        limitWarning: (props) =>
          mergeProps(props, {
            id: 'form.invitation.emailInput.max',
            dm: 'Maximum of 10 emails at a time',
            sx: { p: '0.25rem 0.5rem', color: 'warning.main' },
          }),
        error: (props) =>
          mergeProps(props, {
            ...(!(fieldError && fieldTouched) && { children: errorMessage }),
            sx: { p: '0.1875rem 0.875rem 0', color: 'warning.main' },
          }),
      },
      style: {
        label: (props) =>
          mergeProps(props, {
            sx: {
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color: 'headingText.main',
            },
          }),
        autocomplete: (props) =>
          mergeProps(props, {
            multiple: true,
            id: 'tags-filled',
            options: [],
            freeSolo: true,
            componentsProps: { clearIndicator: { onClick: clearField } },
            sx: {
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'background.light',
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
              ...(!!errorMessage &&
                !(fieldError && fieldTouched) && {
                  '&  .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${parseColor('warning.main')} !important`,
                  },
                  '&  .MuiInputLabel-root': { color: 'warning.main' },
                }),
            },
          }),
        textInput: (props) =>
          mergeProps(
            { ...props, disabled: limitReached || props?.disabled },
            {
              placeholder: attemptFormat(placeholder),
              multiline: true,
              sx: {
                '& textarea': {
                  width: 'calc(100% - 0.5rem) !important',
                  minHeight: '2.5rem',
                  p: '0.25rem !important',
                },
                '& label': {
                  transform: 'translate(14px, 12px) scale(1)',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(12px, -8px) scale(0.8)',
                  },
                },
              },
              ...(fieldTouched && {
                error: !!fieldError,
                helperText: fieldError,
              }),
              label: attemptFormat({
                id: 'form.invitation.emailInput.label',
                dm: 'Emails',
              }),
              variant: 'outlined',
            },
          ),
        chip: (props, option, index) => ({
          variant: 'outlined',
          label: option,
          ...props,
          sx: {
            backgroundColor: 'white',
            '&.MuiChip-root': { maxWidth: '17rem' },
            '& .MuiChip-deleteIcon': {
              color: 'primary.main',
              '&:hover': { color: 'primary.dark' },
            },
            ...props?.sx,
          },
          onDelete: () => removeTag(index),
        }),
      },
    };
  }
};

export default EmailInput;

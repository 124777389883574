import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import checkFunc from '../utils/checkFunc';
import { stringifyParams, stringToObject } from '../utils/queryString';

const useQueryState = (
  param,
  initialState = '',
  { debugMode, encoded } = {},
) => {
  const { push } = useHistory();
  const { search = '' } = window.location || {};

  const parseStringState = useCallback(
    (stringState) => {
      const isArray = Array.isArray(initialState);
      const isBoolean = typeof initialState === 'boolean';
      const isNumber = typeof initialState === 'number';

      return typeof stringState === 'undefined'
        ? stringState
        : isArray
        ? stringState?.split(',')
        : isBoolean
        ? stringState === 'true'
        : isNumber
        ? Number(stringState)
        : stringState;
    },
    [initialState],
  );

  const stringState = stringToObject(search)[param];
  const [state, setState] = useState(
    parseStringState(
      encoded && !!stringState ? decodeURIComponent(stringState) : stringState,
    ) ?? initialState,
  );
  const jsonInitialState = JSON.stringify(initialState);

  const setQuery = useCallback(
    (incomingState) => {
      const { pathname, search = '' } = window.location || {};
      const searchState = stringToObject(search)[param];
      const initialState = JSON.parse(jsonInitialState);

      const newState = checkFunc(
        incomingState,
        parseStringState(
          encoded && !!searchState
            ? decodeURIComponent(searchState)
            : searchState,
        ) ?? initialState,
      );
      const currentSearchObject = stringToObject(search);
      delete currentSearchObject[param];
      if (newState !== initialState)
        currentSearchObject[param] = encoded
          ? encodeURIComponent(newState)
          : newState;

      const newQueryString = stringifyParams(currentSearchObject);

      push(pathname + newQueryString);
    },
    [param, jsonInitialState, push, encoded, parseStringState],
  );

  useEffect(() => {
    const newValue = stringToObject(search)[param];
    setState(
      parseStringState(
        encoded && !!newValue ? decodeURIComponent(newValue) : newValue,
      ) ?? JSON.parse(jsonInitialState),
    );
  }, [param, search, jsonInitialState, parseStringState, encoded]);

  return [state, setQuery];
};

export default useQueryState;

import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    fontSize: 1,
    siteInfo: {},
    userRoles: [],
    siteComponents: [],
    rolesSettingsId: '',
    sellerPlans: {},
  },
  reducers: {
    increaseFontSize(state) {
      if (state.fontSize >= 3) return;
      state.fontSize += 0.25;
    },
    decreaseFontSize(state) {
      if (state.fontSize <= 0.5) return;
      state.fontSize -= 0.25;
    },
    setSiteInfo(state, action) {
      state.siteInfo = action.payload;
    },
    addSiteInfo(state, action) {
      state.siteInfo[action.payload] = action.payload;
    },
    setUserRoles(state, action) {
      state.userRoles = action.payload;
    },
    setSiteComponents(state, action) {
      state.siteComponents = action.payload;
    },
    setRolesSettingsId(state, action) {
      state.rolesSettingsId = action.payload;
    },
    setSellerPlans(state, action) {
      state.sellerPlans = action.payload;
    },
  },
});

export const {
  increaseFontSize,
  decreaseFontSize,
  setSiteInfo,
  addSiteInfo,
  setUserRoles,
  setSiteComponents,
  setRolesSettingsId,
  setSellerPlans,
} = settingsSlice.actions;

export default settingsSlice.reducer;

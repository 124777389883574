import { useCallback } from 'react';
import useNavSize from './useNavSize';

const useScrollToTop = ({ scrollRef, debugMode } = {}) => {
  const { pageRef } = useNavSize() || {};
  const page = scrollRef?.current || pageRef?.current;

  const scrollToTop = useCallback(
    (options) => {
      if (debugMode) console.log({ scroll: page });
      if (!page) return;
      page.scroll({ top: 0, ...options });
    },
    [page, debugMode],
  );

  const smoothScroll = useCallback(
    (options) => {
      if (debugMode) console.log({ scroll: page });
      if (!page) return;
      page.scroll({ top: 0, behavior: 'smooth', ...options });
    },
    [page, debugMode],
  );

  return { scrollToTop, smoothScroll };
};

export default useScrollToTop;

import React from 'react';
import { Tooltip } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import useToggle from '../../hooks/useToggle';
import MuiText from '../intl/MuiText';
import useScrollListener from '../../hooks/useScrollListener';

const MuiTooltip = ({
  children,
  title,
  textProps,
  clickToggle,
  initialState,
  persistOnScroll,
  ...rest
}) => {
  const [open, toggleOpen] = useToggle(initialState || false);
  const { attemptFormat } = useLocale();

  const enableListener = clickToggle && !persistOnScroll && open;
  useScrollListener(
    enableListener && { scrollListener: () => toggleOpen(false) },
  );

  const { style } = makeProps();
  return (
    <Tooltip
      {...{
        title: (
          <MuiText {...style.text(textProps)}>{attemptFormat(title)}</MuiText>
        ),
        placement: 'top',
        ...(clickToggle && {
          open,
          onClick: () => toggleOpen(),
          cursor: 'pointer',
        }),
        ...rest,
      }}
    >
      {children}
    </Tooltip>
  );

  function makeProps() {
    return {
      style: {
        text: (props) => ({
          ...props,
          sx: { ...props?.sx, fontSize: '1.00rem' },
        }),
      },
    };
  }
};

export default MuiTooltip;

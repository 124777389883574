// import { tagParser } from './formUtils';

import { formDataFormatter } from './formUtils';
import { databaseTotalMapper } from './quoteFunctions';

// For mapping data sent from frontend to backend to conform to database
// standards.

const common = {
  emergencies: 'emergency_ids',
  categories: 'category_ids',
  itemTypes: 'item_types',
  matchedPostIds: 'posts',
};

const address = {
  lineOne: 'location.street_number',
  lineTwo: 'location.street_name',
  city: 'location.city',
  province: 'location.province',
  postalCode: 'location.postal_code',
  country: 'location.country',
  // countryShort: 'location.country_short',
};

const shippingAddress = {
  lineOne: 'line_one',
  lineTwo: 'line_two',
  city: 'city',
  province: 'province',
  postalCode: 'postal_code',
};

const cartItem = {
  productId: 'product_id',
  color: 'color',
  price: 'price',
  productImage: 'product_image',
  productTitle: 'product_title',
  quantity: 'quantity',
};

const rfqsAddress = {
  streetNumber: 'shipping_address[street_number]',
  streetName: 'shipping_address[street_name]',
  city: 'shipping_address[city]',
  province: 'shipping_address[province]',
  postalCode: 'shipping_address[postal_code]',
  country: 'shipping_address[country]',
  countryShort: 'shipping_address[country_short]',
};

export const postToDatabase = {
  ...common,
  //...shippingAddress,
  id: 'id',
  postTitleEnglish: 'post_title',
  postTextEnglish: 'post_text',
  postTitleFrench: 'fr_post_title',
  postTextFrench: 'fr_post_text',
  itemQuantity: 'qty',
  postType: 'post_type',
  category: 'category_id',
  emergency: 'emergency_id',
  itemTypes: 'item_type_id',
  PhotoUrl: 'post_photo_url',
  products: 'product_id',
  unitPrice: 'unit_price',
  currency: 'default_currency',
};

const authorDetails = {
  id: 'user_id',
  name: 'author_name',
  logo: 'author_logo',
  rating: 'author_rating',
  ratingCount: 'author_review_count',
};
export const feedsToDatabase = {
  id: 'id',
  title: 'post_title',
  postTextEn: 'en_message',
  postTextFr: 'fr_message',
  postTag: 'type',
  createdDate: 'created_at',
  author: 'author',
  authorName: 'author_name',
  isDeleted: 'isDeleted',
  image: 'image' || 'post_image',
  ...authorDetails,
};

export const supplierToDatabase = {
  id: 'id',
  supplierName: 'name',
  bio: 'bio',
  email: 'email',
  contactPerson: 'contact_person',
  phone: 'phone',
  website: 'website',
  categoryNames: 'category_names',
  itemTypeNames: 'item_type_names',
  longDescription: 'long_description',
  shortDescription: 'short_description',
  logo: 'logo',
  headerImage: 'header_image',
  mediaImages: 'media_images',
  certifications: 'certification',
  foundationYear: 'foundation_year',
  employmentSize: 'employment_size',
  rating: 'rating',
  badges: 'badges',
  ...address,
  ...common,
};

export const productToDatabase = {
  id: 'id',
  title: 'title',
  description: 'description',
  uploadImages: 'images',
  uploadVideo: 'videos',
  category: 'category_id',
  emergency: 'emergency_ids',
  itemType: 'item_type_id',
  price: 'price',
  quantity: 'qty',
  status: 'status',
  disabled: 'disabled',
  supportDocs: 'product_support_docs',
  certifications: 'product_certifications',
  specifications: 'product_specifications',
  defaultCurrency: 'default_currency',
  ...common,
};

export const messageToDatabase = {
  messageText: 'message',
  conversationId: 'conversation_id',
  contactId: 'receiver_id',
  messageAttachment: 'message_attachment',
  productId: 'product_id',
  orderId: 'order_id',
};

export const ticketToDatabase = {
  type: 'type',
  subject: 'subject',
  message: 'message',
  pageURL: 'source_page_url',
  pageTitle: 'page_title',
  byUserId: 'by_user_id',
  email: 'user_email',
  name: 'user_name',
};

export const disputeToDatabase = {
  // id: 'id',
  disputerUser: 'disputer_user_id',
  disputedUser: 'disputed_user_id',
  order: 'order_id',
  orderStatus: 'order_status',
  orderDate: 'order_date',
  orderDetails: 'order_details',
  orderTotalCost: 'order_total_cost',
  transactionId: 'transaction_id',
  disputeDescription: 'dispute_description',
  disputerAttachments: 'disputer_attachments',
  disputeIssueType: 'dispute_issue_type',
  desiredResolution: 'desired_resolution',
  disputedUserId: 'disputed_user_id',
  status: 'status',
  counterOffers: 'counter_offers',
  isDeleted: 'isDeleted',
  createdDate: 'created_at',
  updatedDated: 'updated_at',
};

export const counterOfferToDatabase = {
  counterOfferDate: 'offer_date',
  counterOfferMessage: 'message',
  disputedCounterOfferStatus: 'offer_status',
  disputerResponseDate: 'disputer_response_date',
  disputerResponseMessage: 'disputer_response_message',
};

export const businessProfileToDatabase = {
  businessName: 'name',
  businessShortDescription: 'short_description',
  businessLogo: 'logo',
  businessHeaderImage: 'header_image',
  businessFoundationYear: 'foundation_year',
  businessLocation: 'location',
  // streetNumber: 'location.street_number',
  //   streetName: 'location.street_name',
  //   city: 'location.city',
  //   province: 'location.province',
  //   postalCode: 'location.postal_code',
  businessEmploymentSize: 'employment_size',
  businessAboutImages: 'media_images',
  businessAboutDescription: 'long_description',
  businessCertifications: 'certification',
  businessPitchVideo: 'video',
  email: 'email',
  contactEmail: 'contact_email',
  whatsappPhone: 'whatsapp_phone',
  facebookUrl: 'facebook_url',
  linkedInUrl: 'linkedin_url',
  contactPerson: 'contact_person',
  businessLogo_src: 'logo',
  businessLogo_croppedArea: 'logo_cropped_area',
  businessHeaderImage_src: 'header_image',
  businessHeaderImage_croppedArea: 'header_cropped_area',
};

export const orderToDatabase = {
  id: '_id',
  orderNumber: 'order_number',
  orderOpeningDate: 'order_opening_date',
  orderUpdateDate: 'order_update_date',
  orderClosureDate: 'order_closure_date',
  consumerId: 'consumer_id',
  supplierId: 'supplier_id',
  cartItems: 'cart_items',
  shippingAddress: 'shipping_address',
  shippingMethod: 'shipping_method',
  shippingCost: 'shipping_cost',
  trackingInfo: 'tracking_info',
  estimatedDeliveryDate: 'estimated_delivery_date',
  taxes: 'taxes',
  totalCost: 'total_cost',
  isConsumerConfirmedReceivingGoods: 'isConsumerConfirmedReceivingGoods',
  adIdGeneratedThisOrder: 'ad_id_generated_this_order',
  priority: 'priority',
  status: 'status',
  isExpired: 'isExpired',
  isCompleted: 'isCompleted',
  isDisputed: 'isDisputed',
  disputeId: 'dispute_id',
  isDeleted: 'isDeleted',
};

export const orderAgainKeys = [
  //   'orderClosureDate',
  'consumerId',
  'supplierId',
  'cartItems',
  'shippingAddress',
  'shippingMethod',
  'shippingCost',
  'taxes',
  'totalCost',
  'adIdGeneratedThisOrder',
  'priority',
];
export const RFQsToDatabase = {
  id: 'id',
  budget: 'budget',
  deliveryDate: 'delivery_date',
  phone: 'phone',
  notes: 'notes',
  quantity: 'quantity',
  supplier: 'suppliers',
  rfqsType: 'rfq_type',
  shippingPreferences: 'shipping_preferences',
  special_requirements: 'special_requirements',
  requestMessage: 'special_requirements',
  uploadAttachments: 'attachments',
  productId: 'product_id',
  categoryId: 'category_id',
  //   itemType: 'item_type_id',
  status: 'status',
  disabled: 'disabled',
  bids: 'bids',
  ...rfqsAddress,
};

export const RFQbidToDatabase = {
  id: 'rfq_id',
  productId: 'product_id',
  products: 'product_id',
  category: 'category_id',
  userId: 'supplier_user_id',
  bidQuantity: 'quantity',
  bidUnitPrice: 'unitprice',
  bidShipping: 'shipping_cost',
  bidTaxes: 'sale_taxes_applied',
  bidDeliveryDate: 'delivery_unique_id',
  shippingMethod: 'shipping_method',
  serviceFeePercent: 'service_fee_percentage',
  nonRefundableServiceFeePercent: 'non_refundable_fee_percentage',
  subtotal: 'subtotal',
  saleTaxesTotal: 'sale_taxes_total',
  serviceFee: 'service_fee',
  nonRefundableServiceFee: 'non_refundable_service_fee',
  totalSum: 'total_sum',
  supplierTotalPayout: 'supplier_total_payout',
  consumerTotalRefundable: 'consumer_total_refundable',
};

export const adToDatabase = {
  id: 'id',
  title: 'title',
  promotedProducts: 'promoted_products',
  emergency: 'emergency_id',
  budgetPerDay: 'budget_per_day',
  costPerClick: 'cost_per_click',
  startDate: 'start_date',
  endDate: 'end_date',
  lastBillingDate: 'last_billing_date',
  nextBillingDate: 'next_billing_date',
  lastBillingStatus: 'last_billing_status',
  status: 'status',
  disabled: 'disabled',
};

export const couponToDatabase = {
  id: 'id',
  couponCode: 'code',
  discount: 'amount',
  discountType: 'type',
  startDate: 'start_date',
  endDate: 'end_date',
  budgetLimit: 'budget_limit',
  spentAmount: 'spent_amount',
  targetedUsers: 'user_ids',
  targetedProducts: 'product_ids',
  isOneUsagePerUser: 'isOneUsagePerUser',
};

export const oldReviewToDatabase = {
  id: 'id',
  title: 'review_title',
  description: 'review_text',
  rating: 'rating',
  uploadImages: 'images',
  reviewType: 'review_type',
  relatedProduct: 'reviewed_product_name',
  relatedUserName: 'reviewed_user_name',
  status: 'status',
  disabled: 'disabled',
  ...common,
};
export const invoiceToDatabase = {
  id: 'id',
  invoiceNumber: 'invoice_number',
  userId: 'user_id',
  clientId: 'el.client_id',
  invoiceType: 'invoice_type',
  subjects: 'subject_id',
  issueDate: 'issue_date',
  dueDate: 'due_date',
  status: 'status',
};
export const transactionToDatabase = {
  id: 'id',
  payerId: 'payer_id',
  payer: 'payer_id', //TODO: payer_name
  invoiceNumber: 'invoice_id',
  totalAmount: 'amount',
  status: 'status',
  paymentMethod: 'payment_method',
  paymentDate: 'last_update',
  transactionNumber: 'transaction_number',
};
export const categoryToDatabase = {
  id: 'id',
  categoryEnglishName: 'en_name',
  categoryEnglishAbbreviation: 'en_abbr',
  categoryEnglishDescription: 'en_description',
  categoryFrenchName: 'fr_name',
  categoryFrenchDescription: 'fr_description',
  categoryFrenchAbbreviation: 'fr_abbr',
  categoryArabicName: 'ar_name',
  categoryArabicDescription: 'ar_description',
  categoryArabicAbbreviation: 'ar_abbr',
  categoryPhoto: 'image_url',
  parent_category_id: 'parent_category_id',
  isFeatured: 'isFeatured',
  isForecastingEnabled: 'isForecastingEnabled',
};

export const emergencyToDatabase = {
  id: 'id',
  emergencyEnglishName: 'en_title',
  emergencyEnglishDescription: 'en_description',
  emergencyFrenchName: 'fr_title',
  emergencyFrenchDescription: 'fr_description',
  imageUrl: 'image_url',
  startDate: 'start_date',
  endDate: 'end_date',
  twitter: 'twitter_handle',
  ...common,
};

export const itemTypeToDatabase = {
  id: 'id',
  itemTypeNameEnglish: 'en_name',
  itemTypeEnglishAbbreviation: 'en_abbr',
  itemTypeEnglishDescription: 'en_description',
  itemTypeNameFrench: 'fr_name',
  itemTypeFrenchAbbreviation: 'fr_abbr',
  itemTypeFrenchDescription: 'fr_description',
  itemPhoto: 'image_url',
  hs6Code: 'hs6_code',
  unitOfMeasuring: 'unit',
  itemTypeCategory: 'category_id',
};

export const expertToDatabase = {
  id: 'id',
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  organization: 'organization',
  fieldOfResearch: 'field_of_research',
  website: 'website',
  organizationPhoto: 'logo_url',
  ...address,
  ...common,
};

const userFormKeys = [
  'firstName',
  'lastName',
  'organization',
  'organizationType',
  'specifyOrganization',
  'email',
  'languageCode',
  'password',
  'passwordConfirm',
  'roles',
  'phone',
  'profilePicture',
  'subscriptions',
  'location',
  'status',
  'viewPermissions',
  'postPermissions',
  'managePermissions',
  'twoFactorEnable',
  'sameAsPrimaryAddress',
  'billingLocation',
];

export const userToDatabase = {
  id: 'id',
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  phone: 'phone',
  organization: 'organization',
  organizationType: 'organization_type',
  specifyOrganization: 'specify_organization',
  password: 'password',
  passwordConfirm: 'password_confirmation',
  photoUrl: 'photo_url',
  profilePicture: 'profile_picture',
  languageCode: 'language',
  language: 'language',
  roles: 'roles',
  subscriptions: 'subscriptions',
  viewPermissions: 'viewPermissions',
  postPermissions: 'postPermissions',
  managePermissions: 'managePermissions',
  twoFactorEnable: 'two_factor_enable',
  billingLocation: 'billing_location',
  sameAsPrimaryAddress: 'billing_location_same_as_primary_location',
  ...address,
  ...common,
};

const roleFormKeys = [
  'id',
  'roleNameEnglish',
  'roleDescriptionEnglish',
  'roleNameFrench',
  'roleDescriptionFrench',
  'status',
  'isDeleted',
  'viewPermissions',
  'postPermissions',
  'managePermissions',
  'creationDate',
  'updateDate',
];

export const roleToDatabase = {
  id: 'id',
  roleNameEnglish: 'name_en',
  roleDescriptionEnglish: 'description_en',
  roleNameFrench: 'name_fa',
  roleDescriptionFrench: 'description_fa',
  status: 'status',
  isDeleted: 'isDeleted',
  viewPermissions: 'viewPermissions',
  postPermissions: 'postPermissions',
  managePermissions: 'managePermissions',
  creationDate: 'creation_date',
  updateDate: 'update_date',
};

export const regulationToDatabase = {
  id: 'id',
  issuerNameEnglish: 'en_issuer_name',
  issuerNameFrench: 'fr_issuer_name',
  titleEnglish: 'en_title',
  titleFrench: 'fr_title',
  regulationTextEnglish: 'en_description',
  regulationTextFrench: 'fr_description',
  sourceUrlEnglish: 'en_source_url',
  sourceUrlFrench: 'fr_source_url',
  publishingDate: 'published_on',
  itemTypeNames: 'item_type_names',
  ...common,
};

export const biosafetyToDatabase = {
  id: 'id',
  labName: 'lab_name',
  labLevel: 'lab_level',
  organization: 'organization',
  contactPerson: 'contact_person',
  email: 'email',
  phone: 'phone',
  website: 'website',
  descriptionOfServices: 'services_offered',
  organizationPhoto: 'logo_url',
  ...address,
  ...common,
};

export const pluginToDatabase = {
  id: '_id',
  pluginName: 'plugin_name',
  scriptPath: 'script_path',
  dataSourceEnName: 'data_source_en_name',
  dataSourceFrName: 'data_source_fr_name',
  dataSourceUrl: 'data_source_url',
  loginUrl: 'login_url',
  currentState: 'current_state',
  collectionName: 'collection_name',
  //entriesFetched: 'entries_fetched',
  runningCadenceMins: 'running_cadence_mins',
  status: 'status',
  //lastRun: 'last_run',
  pluginModule: 'plugin_module',
  pluginFiles: 'plugin_files',
  username: 'username',
  password: 'password',
  mapping: 'mapping',
};

export const siteSettingsToDatabase = {
  siteNameEnglish: 'en_site_name',
  siteNameFrench: 'fr_site_name',
  siteURL: 'site_url',
  // imagesLocation: 'images_location',
  configFileLocation: 'config_file_location',
  maximumUploadSize: 'maximum_upload_size',
  backupFrequency: 'backup_frequency',
};

export const siteSettingsKeys = [
  'siteNameEnglish',
  'siteNameFrench',
  'siteURL',
  'configFileLocation',
  'maximumUploadSize',
  'backupFrequency',
];

function addressMapper(data) {
  const output = {};
  Object.keys(data).forEach((key) => {
    if (!data[key]) {
      return;
    }

    if (address[key]) {
      const value = address[key].split('.')[1];
      output[value] = data[key];
    } else {
      output[key] = data[key];
    }
  });

  return output;
}
function mappingMapper(data) {
  const output = {};
  for (let el of data) {
    let { local, source } = el;
    output[`${local}`] = source;
  }
  return output;
}
const shippingAddressMapper = (data) => {
  const output = {};
  Object.keys(data).forEach((key) => {
    if (!shippingAddress[key]) return;
    output[shippingAddress[key]] = data[key];
  });
  return output;
};

function rfqAddressMapper(data) {
  const output = {};
  Object.keys(data).forEach((key) => {
    if (!data[key]) return;
    if (!rfqsAddress[key]) output[key] = data[key];
    else output[rfqsAddress[key].split('.')[1]] = data[key];
  });
  return output;
}

export const databaseLocationMapper = (data) => ({
  street_number: data?.streetNumber || '',
  street_name: data?.streetName || '',
  city: data?.city || '',
  province: data?.province || '',
  postal_code: data?.postalCode || '',
  country: data?.country || '',
  country_short: data?.countryShort || '',
});

const cartItemMapper = (data) =>
  data.map((el) => {
    const output = {};
    Object.keys(el).forEach((key) => {
      if (!cartItem[key]) return;
      output[cartItem[key]] = el[key];
    });
    return output;
  });

function mapper(data, map, options) {
  const { keepFalse } = options || {};
  const output = {};
  Object.keys(data).forEach((key) => {
    // loop over every key in the input object
    let value = data[key];

    if (['location', 'shipping_address', 'billingLocation'].includes(key))
      value = addressMapper(data[key]);
    if (key === 'rfq_shipping_address') {
      key = 'shipping_address';
      value = rfqAddressMapper(data.rfq_shipping_address);
    }
    if (key === 'mapping') value = mappingMapper(data.mapping);
    if (key === 'businessLocation')
      value = databaseLocationMapper(data.businessLocation);
    if (key === 'shippingAddress')
      value = shippingAddressMapper(data.shippingAddress);
    if (key === 'cartItems') value = cartItemMapper(data.cartItems);
    if (key === 'itemTypes') value = value.map((el) => el._id);
    if (
      ['categories', 'emergencies', 'matchedPostIds', 'supplies'].includes(key)
    )
      value = value.map((el) => el.id);
    if (!value && !keepFalse) {
      if (key === 'sameAsPrimaryAddress') output[map[key]] = value;
      else return;
    } else if (map[key]) {
      // if field is found in the map, do the following
      output[map[key]] = value;
    } else {
      output[key] = value;
    }
  });

  return output;
}

export const postToDatabaseMapper = (data) => mapper(data, postToDatabase);

export const supplierToDatabaseMapper = (data) =>
  mapper(data, supplierToDatabase);

export const productToDatabaseMapper = (data) =>
  mapper(data, productToDatabase);

export const messageToDatabaseMapper = (data) =>
  mapper(data, messageToDatabase);

export const ticketToDatabaseMapper = (data) => mapper(data, ticketToDatabase);
export const disputeToDatabaseMapper = (data) =>
  mapper(data, disputeToDatabase);

export const counterOfferToDatabaseMapper = (data) =>
  mapper(data, counterOfferToDatabase);

export const businessProfileToDatabaseMapper = (data, options) =>
  mapper(data, businessProfileToDatabase, options);

export const RFQsToDatabaseMapper = (data) => mapper(data, RFQsToDatabase);

export const RFQbidToDatabaseMapper = (data) => mapper(data, RFQbidToDatabase);

export const adToDatabaseMapper = (data) => mapper(data, adToDatabase);
export const couponToDatabaseMapper = (data) => mapper(data, couponToDatabase);
export const oldReviewToDatabaseMapper = (data) =>
  mapper(data, oldReviewToDatabase);

export const categoryToDatabaseMapper = (data) =>
  mapper(data, categoryToDatabase);

export const emergencyToDatabaseMapper = (data) =>
  mapper(data, emergencyToDatabase);
export const itemTypeToDatabaseMapper = (data) =>
  mapper(data, itemTypeToDatabase);

export const regulationToDatabaseMapper = (data) => {
  try {
    return mapper(data, regulationToDatabase);
  } catch (e) {}
};

export const biosafetyToDatabaseMapper = (data) =>
  mapper(data, biosafetyToDatabase);

export const expertToDatabaseMapper = (data) => mapper(data, expertToDatabase);

export const pluginToDatabaseMapper = (data) => mapper(data, pluginToDatabase);

export const siteSettingsToDatabaseMapper = (data) =>
  mapper(data, siteSettingsToDatabase);

export const filterSiteSettings = (data) => {
  let payload = {};
  Object.keys(data)
    .filter((key) => siteSettingsKeys.includes(key))
    .forEach((key) => (payload[key] = data[key]));
  return payload;
};

export const userToDatabaseMapper = (data) => mapper(data, userToDatabase);

export const filterUserForm = (data) => {
  let payload = {};
  Object.keys(data).map((key) => {
    if (userFormKeys.includes(key)) {
      payload[key] = data[key];
    }
    return null;
  });
  return payload;
};

export const roleToDatabaseMapper = (data) => mapper(data, roleToDatabase);

export const filterRoleForm = (data) => {
  let payload = {};
  Object.keys(data).map((key) => {
    if (roleFormKeys.includes(key)) payload[key] = data[key];
    return null;
  });
  return payload;
};

export const orderToDatabaseMapper = (data) => mapper(data, orderToDatabase);

export const orderAgainMapper = (data) => {
  let payload = {};
  Object.keys(data).forEach((key) => {
    if (orderAgainKeys.includes(key)) payload[key] = data[key];
  });
  return orderToDatabaseMapper(payload);
};

// export const feedbackToDatabaseMapper =(data, type)=> mapper(data, feedbackToDatabase(type))

export const removeUndefined = (oldObject) =>
  Object.entries(oldObject).reduce((newObject, [key, value]) => {
    if (value !== undefined) newObject[key] = value;
    return newObject;
  }, {});

export const feedbackToDatabaseMapper = ({ reviewed, type, values, details }) =>
  removeUndefined({
    reviewed_id: reviewed.id,
    review_type: type,
    rating: values[`${type}_overall`],
    qualityRating: values[`${type}_quality`],
    communicationRating: values[`${type}_communication`],
    shippingRating: values[`${type}_shipping`],
    valueRating: values[`${type}_value`],
    dependabilityRating: values[`${type}_dependability`],
    review_title: values[`${type}_comment_title`],
    review_text: values[`${type}_comment_description`],
    photo_url: values[`${type}PhotoUrl`]?.[0],
    user_id: details.userId,
    reviewer_user_role: details.userRoles,
    order_id: details.orderId,
    conversation_id: details.conversationId,
  });

export const feedbackFormData = ({ reviewed, type, values, details }) =>
  formDataFormatter(
    feedbackToDatabaseMapper({ reviewed, type, values, details }),
  );

export const supplierStartRFQ = (data) => ({
  buyer_id: data.buyerId,
  rfq_type: data.rfqsType,
  suppliers: data.suppliers,
  category_id: data.category,
  product_id: data.products,
  quantity: data.bidQuantity,
  budget: data.budget,
  shipping_preferences: data.shippingPreferences,
  unitprice: data.bidUnitPrice,
  shipping_cost: data.bidShipping,
  shipping_method: data.shippingMethod,
  delivery_unique_id: data.shippingMethod,
  shipping_address: databaseLocationMapper(data.shippingAddress),
  ...databaseTotalMapper({ values: data }),
});

export const registerSellerToDatabase = (data, locale) => ({
  first_name: data?.firstName,
  last_name: data?.lastName,
  email: data?.email,
  emailConfirm: data?.emailConfirm,
  password: data?.password,
  password_confirmation: data?.passwordConfirm,
  roles: '2',
  organization: data?.organization,
  organization_type: data?.organizationType,
  two_factor_enable: false,
  language: locale,
});

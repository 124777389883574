import { Collapse, Box, Stack, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import NavItemToggle from './NavItemToggle';
import usePermissions from '../../hooks/usePermissions';

const UserMenuSection = ({ onClick, isOpen, showUserDashboard, onClose }) => {
  const intl = useIntl();
  const { canView } = usePermissions();
  const {
    posts: { pending: pendingPosts } = {},
    replies: { pending: pendingReplies } = {},
    products: { pending: pendingProducts } = {},
    coupons: { pending: pendingCoupons } = {},
    rfqs: { pending: pendingRFQs } = {},
    reviews: { pending: pendingReviews } = {},
    forecasting: { pending: pendingForecasting } = {},
    ads: { pending: pendingAds } = {},
  } = useSelector((state) => state.data.dashboardCount);

  const pendingMessages = 0;
  const pendingSubmittedDisputes = 0;
  const pendingreceivedDisputes = 0;
  const pendingReceviedRFQs = 0;
  const pendingReceivedOrders = 0;
  const pendingSubmittedOrders = 0;

  const links = [
    {
      path: 'dashboard',
      textId: 'dashboard',
      viewPermitted: true,
      count: 0,
    },
    {
      path: 'my-posts',
      textId: 'myposts',
      viewPermitted: canView('0'),
      count: pendingPosts,
    },
    {
      path: 'my-replies',
      textId: 'myreplies',
      viewPermitted: canView('0'),
      count: pendingReplies,
    },
    {
      path: 'my-products',
      textId: 'myproducts',
      viewPermitted: canView('8'),
      count: pendingProducts,
    },
    {
      path: 'my-submitted-disputes',
      textId: 'mysubmitteddisputes',
      viewPermitted: canView('12'),
      count: pendingSubmittedDisputes,
    },
    {
      path: 'my-received-disputes',
      textId: 'myreceiveddisputes',
      viewPermitted: canView('12'),
      count: pendingreceivedDisputes,
    },
    {
      path: 'my-coupons',
      textId: 'mycoupons',
      viewPermitted: canView('8'),
      //count: pendingCoupons,
    },
    {
      path: 'product-coupons',
      textId: 'productcoupons',
      viewPermitted: canView('8'),
      count: pendingCoupons,
    },
    {
      path: 'my-received-orders',
      textId: 'myreceivedorders',
      viewPermitted: canView('9'),
      count: pendingReceivedOrders,
    },
    {
      path: 'my-submitted-orders',
      textId: 'mysubmittedorders',
      viewPermitted: canView('9'),
      count: pendingSubmittedOrders,
    },
    {
      path: 'my-RFQs',
      textId: 'myRFQ',
      viewPermitted: canView('10'),
      count: pendingRFQs,
    },
    {
      path: 'my-reviews',
      textId: 'myreviews',
      viewPermitted: canView('8'),
      count: pendingReviews,
    },
    {
      path: 'my-messages',
      textId: 'mymessages',
      viewPermitted: true,
      count: pendingMessages,
    },
    {
      path: 'my-forecasting',
      textId: 'forecasting',
      viewPermitted: canView('8'),
      count: pendingForecasting,
    },
    {
      path: 'my-bills',
      textId: 'mybills',
      viewPermitted: canView('8'),
      count: 0,
    },
    {
      path: 'my-promotion-ads',
      textId: 'myads',
      viewPermitted: canView('8'),
      count: pendingAds,
    },
    {
      path: 'recevied-rfqs',
      textId: 'receviedRFQs',
      viewPermitted: canView('8'),
      count: pendingReceviedRFQs,
    },
  ];

  return (
    <>
      <Box
        onClick={onClick}
        cursor="pointer"
        _hover={{ bg: '#ccc', color: '#333' }}
        p={1}
      >
        <Box
          as="button"
          onClick={onClick}
          fontSize="md"
          aria-expanded={isOpen}
          ml={2}
          mb={0}
          mt={2}
        >
          <Stack isInline>
            <Text mt={1}>
              {showUserDashboard ? <BsCaretDownFill /> : <BsCaretRightFill />}
            </Text>
            <Text textAlign="left">
              {intl.formatMessage({
                id: 'navbar.navitem.category.userdashboard',
              })}
            </Text>
          </Stack>
        </Box>
      </Box>
      <Collapse in={showUserDashboard}>
        {links.map(({ path, textId, viewPermitted, count }) => (
          <Fragment key={textId}>
            {viewPermitted && (
              <NavItemToggle
                path={`/${path}`}
                onClose={onClose}
                linkName={intl.formatMessage({
                  id: `navbar.navitem.userdashboard.${textId}`,
                })}
                count={count === 0 ? '' : count}
              />
            )}
          </Fragment>
        ))}

        {/* <NavItemToggle
          path="/dashboard"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.dashboard',
          })}
        />

        <NavItemToggle
          path="/my-posts"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.myposts',
          })}
          count={pendingPosts === 0 ? '' : pendingPosts}
        />

        <NavItemToggle
          path="/my-replies"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.myreplies',
          })}
          onClose={onClose}
          count={pendingReplies === 0 ? '' : pendingReplies}
        />
        <NavItemToggle
          path="/my-products"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.myproducts',
          })}
          onClose={onClose}
          count={pendingProducts === 0 ? '' : pendingProducts}
        />
        <NavItemToggle
          path="/my-disputes"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.mydisputes',
          })}
          onClose={onClose}
          count={pendingDisputes === 0 ? '' : pendingDisputes}
          />
          
        <NavItemToggle
          path="/my-coupons"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.mycoupons',
          })}
          onClose={onClose}
          count={pendingProducts === 0 ? '' : pendingProducts}
         />
        <NavItemToggle
          path="/product-coupons"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.productcoupons',
          })}
          onClose={onClose}
          count={pendingProducts === 0 ? '' : pendingProducts}
         />
         <NavItemToggle 
          path="/my-orders"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.myorders',
          })}
          onClose={onClose}
          count={pendingOrders === 0 ? '' : pendingOrders}
        />
        <NavItemToggle
          path="/my-reviews"
          linkName={intl.formatMessage({
            id: 'navbar.navitem.userdashboard.myreviews',
          })}
          onClose={onClose}
          //count={pendingProducts === 0 ? '' : pendingProducts}
        /> */}
      </Collapse>
    </>
  );
};

export default UserMenuSection;

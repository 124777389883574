import React from 'react';

const FacebookIFrame = () => {
  return (
    <div
      className="fb-like"
      data-href="https://www.facebook.com/tradvo"
      data-width=""
      data-layout="button"
      data-action="like"
      data-size="small"
      data-share="true"
    ></div>
  );
};

export default FacebookIFrame;

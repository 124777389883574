import Cookies from 'js-cookie';
function client(
  endpoint,
  { body, formData, server, download, ...customConfig } = {},
) {
  const token =
    sessionStorage.getItem('accessToken') ||
    localStorage.getItem('accessToken') ||
    Cookies.get('accessToken');

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...(!formData && { 'Content-Type': 'application/json' }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...customConfig.headers,
    },
    ...(body && { body: JSON.stringify(body) }),
    ...(formData && { body: formData }),
  };

  const url =
    server === 'ai'
      ? process.env.REACT_APP_AI_URL + endpoint
      : endpoint[0] === '/'
      ? process.env.REACT_APP_BACKEND_URL + endpoint
      : endpoint;

  if (config.headers['Content-Type'] === 'application/pdf')
    return window
      .fetch(url, config)
      .then(async (response) => {
        const data = await response.blob();
        if (response.ok) return data;

        return Promise.reject(response);
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Invoice.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });

  if (config.headers['Content-Type'] === 'text/csv')
    return window.fetch(url, config).then(async (response) => {
      if (!response.ok) return Promise.reject(response);
      const data = await response.text();
      if (download)
        window.open(encodeURI('data:text/csv;charset=utf-8,' + data));
      return data;
    });

  return window.fetch(url, config).then(async (response) => {
    const data = await response.json();
    if (response.ok) return data;
    const noAuthUrl = '/login?authExpired=true';
    const { pathname, search } = window.location;
    const urlSections = [pathname, search];

    const onNoAuthUrl = urlSections.reduce((result, section) => {
      return result && noAuthUrl.includes(section);
    }, true);

    const noAuth = [
      'Auth token is missing.',
      'Auth token has expired.',
      'Auth token is invalid.',
    ].includes(data.message);

    if (noAuth && !onNoAuthUrl) window.location = noAuthUrl;

    return Promise.reject(data);
  });
}

export default client;

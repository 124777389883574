import { Flex, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import FormField from '../forms/FormField';
import FormattedText from '../intl/FormattedText';
import MuiButton from '../intl/MuiButton';
import useBreakpoint from '../../hooks/useBreakpoint';
import MuiText from '../intl/MuiText';
import { CircularProgress } from '@mui/material';
function OtpForm({
  handleSubmitTwoFactor,
  handleResendTwoFactor,
  isOtpModal,
  setIsOtpModal,
  isSendingCode,
}) {
  const intl = useIntl();
  const matches = useBreakpoint('md');
  const msg = (id) => {
    return intl.formatMessage({ id: 'forms.error.' + id });
  };

  const validationSchema = Yup.object({
    code: Yup.string().required(msg('code')),
  });

  const text = {
    verify: {
      id: 'auth.login.twoFactor.verify',
      dm: 'Verify',
    },
    resend: {
      id: 'auth.login.twoFactor.resend',
      dm: 'Resend',
    },
    processing: {
      id: 'auth.signup.processing',
      dm: 'Tradvo is now processing your request.',
    },
  };
  const style = {
    buttons: {
      justifyContent: 'space-between',
      alignItems: 'center',
      mt: '1rem',
    },
    verify: {
      sxText: { ml: 0 },
      fontSize: matches ? '0.75rem' : '1rem',
      sx: {
        height: '3.43rem',
        width: '10rem',
        padding: '0rem',
      },
    },
    resend: {
      variant: 'outlined',
      fontSize: matches ? '0.75rem' : '1rem',
      sx: {
        height: '3.43rem',
        width: '10rem',
        padding: '0rem',
      },
    },
    processingBox: {
      alignItems: 'center',
      sx: {
        ...(matches && { height: '3.43rem', width: '10rem' }),
      },
    },
    processing: {
      sx: {
        fontSize: matches ? '0.75rem' : '1rem',
        ml: '1rem',
        ...(matches && { height: '3.43rem', width: '10rem' }),
      },
    },
  };
  return (
    isOtpModal && (
      <Formik
        initialValues={{ code: '' }}
        onSubmit={async (values, actions) => {
          const { code } = values;
          let error = await handleSubmitTwoFactor(code);

          if (!error) {
            setIsOtpModal(false);
          }
          if (
            error === 'Sorry code is expired, Please request for code again'
          ) {
            actions.resetForm({
              values: { code: '' },
              errors: {
                code: intl.formatMessage({
                  id: 'forms.auth.expiredOptCode',
                  defaultMessage: error,
                }),
              },
              touched: { code: true },
            });
          }
          if (error === 'Sorry code is not matched') {
            actions.resetForm({
              values: { code: '' },
              errors: {
                code: intl.formatMessage({
                  id: 'forms.auth.wrongOptCode',
                  defaultMessage: error,
                }),
              },
              touched: { code: true },
            });
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Stack spacing={2} shouldWrapChildren={true}>
              <FormattedText
                id="auth.login.towFactor"
                defaultMessage="Two-factor authentication"
                fontSize="3xl"
                textAlign="center"
                as="h2"
                mt={2}
              />
              <FormattedText
                id="auth.login.towFactor.notice"
                defaultMessage="We sent an authentication code to your email. This code will expire after 10 minutes"
                fontSize="md"
                as="p"
                mt={0}
                mb={2}
              />
              <FormField name="code" required />
              {errors.code}

              <Flex {...style.buttons}>
                <MuiButton
                  {...{ ...text.verify, ...style.verify }}
                  loading={isSubmitting}
                  type="submit"
                />
                {!isSendingCode ? (
                  <MuiButton
                    {...{ ...text.resend, ...style.resend }}
                    onClick={() => {
                      handleResendTwoFactor();
                    }}
                  />
                ) : (
                  <Flex {...style.processingBox}>
                    <MuiText>
                      <CircularProgress size="1rem" />
                      <MuiText
                        {...{ ...text.processing, ...style.processing }}
                      />
                    </MuiText>
                  </Flex>
                )}

                {/* <FormattedButton
                  id="auth.login.twoFactor.verify"
                  colorScheme="blue"
                  type="submit"
                  isLoading={isSubmitting}
                  mt={1}
                />
                <FormattedButton
                  id="auth.login.twoFactor.resend"
                  mt={1}
                  onClick={() => {
                    handleResendTwoFactor();
                  }}
                /> */}
              </Flex>
            </Stack>
          </Form>
        )}
      </Formik>
    )
  );
}

export default OtpForm;

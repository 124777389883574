import React from 'react';
import { Button } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

function FormattedButton({ fontSize, id, defaultMessage, ...rest }) {
  const intl = useIntl();
  return (
    <Button fontSize={fontSize ? fontSize : 'md'} {...rest}>
      {intl.formatMessage({ id, defaultMessage })}
    </Button>
  );
}

export default FormattedButton;

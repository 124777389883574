import React, { useState, useEffect, useRef, useMemo } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Collapse, Grid, IconButton } from '@mui/material';
import MuiContainer from './MuiContainer';
import useLocale from '../../hooks/useLocale';
import MuiText from '../intl/MuiText';
import sleep from '../../utils/sleep';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import EmailResendModal from '../modals/EmailResendModal';
import InterestsModal from '../modals/InterestsModal';
import Flex from './Flex';
import LabelledInfo from './LabelledInfo';
import useAuth from '../../hooks/useAuth';
import useQueryState from '../../hooks/useQueryState';
import LocationModal from '../userprofile/LocationModal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NewInvitationModal from '../invitations/NewInvitationModal';

const ProgressBar = ({ progressBarRef }) => {
  const [hideProgress] = useQueryState('hideProgress', false);
  const { attemptFormat } = useLocale();
  const progressRef = useRef(100);
  const [displayProgress, setDisplayProgress] = useState(100);
  const { user, loading: userLoading, supplier, isAuthenticated } = useAuth();
  const { roles: userRoles } = user || {};
  const [notices, setNotices] = useState([]);
  const [noticeStep, setNoticeStep] = useState(0);
  const [collapseIn, setCollapseIn] = useState(false);
  const resendModalControl = useMuiModalControl();
  const { handleOpen: resendHandleOpen } = resendModalControl || {};
  const interestModalControl = useMuiModalControl();
  const { handleOpen: interestHandleOpen } = interestModalControl || {};
  const locationModalControl = useMuiModalControl();
  const { handleOpen: locationHandleOpen } = locationModalControl || {};
  const invitationModalControl = useMuiModalControl();
  const { handleOpen: invitationHandleOpen } = invitationModalControl || {};
  const [interestInitialStep, setInterestInitialStep] = useState(0);

  const numberOfNotices = notices.length;

  useEffect(() => {
    setNoticeStep(0);
  }, [numberOfNotices]);

  const userProgressions = useMemo(
    () =>
      [
        {
          type: 'email',
          roles: ['2', '3'],
          condition: ({ user }) => user.isEmailValidated,
          action: resendHandleOpen,
        },
        {
          type: 'followers',
          roles: ['2'],
          condition: ({ supplier }) => supplier?.followers >= 10,
          action: invitationHandleOpen,
        },
        {
          type: 'location',
          roles: ['3'],
          condition: ({ user }) =>
            Object.values(user.location).reduce(
              (hasValidLocation, locationField) =>
                hasValidLocation && !!locationField,
              true,
            ),
          action: locationHandleOpen,
        },
        // {
        //   type: 'stripeConnect',
        //   roles: ['2'],
        //   condition: ({user}) => user.stripeConnected,
        // },
        {
          type: 'interests',
          roles: ['3'],
          condition: ({ user }) => (user?.subscriptions?.length || 0) >= 3,
          action: () => {
            setInterestInitialStep(0);
            interestHandleOpen();
          },
        },
        {
          type: 'followSellers',
          roles: ['3'],
          condition: ({ user }) => (user?.followedSuppliers?.length || 0) >= 3,
          action: ({ user }) => {
            setInterestInitialStep(
              (user?.subscriptions?.length || 0) >= 3 ? 1 : 0,
            );
            interestHandleOpen();
          },
        },
      ].filter(({ roles }) => roles.includes(userRoles)),
    [
      userRoles,
      interestHandleOpen,
      resendHandleOpen,
      locationHandleOpen,
      invitationHandleOpen,
    ],
  );

  useEffect(() => {
    const delay = async () => {
      const showProgress = displayProgress < 100;
      if (collapseIn === showProgress) return;
      if (!showProgress) await sleep(1);
      setCollapseIn(showProgress);
    };
    delay();
  }, [collapseIn, displayProgress]);

  const currentProgress = progressRef.current;
  useEffect(() => {
    const timer = setInterval(() => {
      if (displayProgress < currentProgress)
        setDisplayProgress((prevProgress) =>
          prevProgress < currentProgress &&
          (prevProgress < 95 || currentProgress > 99)
            ? Math.floor(prevProgress / 5) * 5 + 5
            : prevProgress,
        );
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, [displayProgress, currentProgress]);

  useEffect(() => {
    if (!user) {
      setNotices([]);
      progressRef.current = 100;
      setDisplayProgress(100);
      return;
    }

    const newNotices = userProgressions.reduce(
      (noticeArray, { type, condition, action }) => {
        if (!condition({ user, supplier }))
          noticeArray.push({
            action,
            message: { id: `progressBar.notice.${type}`, dm: type },
          });
        return noticeArray;
      },
      [],
    );

    setNotices(newNotices);

    const progressPercent = userProgressions.length
      ? 100 *
        ((userProgressions.length - newNotices.length) /
          userProgressions.length)
      : 100;

    setDisplayProgress(
      progressRef.current === 100 && progressPercent !== 100
        ? 0
        : Math.ceil(progressRef.current / 5) * 5,
    );

    progressRef.current = progressPercent;
  }, [user, userProgressions, supplier]);

  const { text, style } = makeProps({
    user,
    attemptFormat,
    noticeStep,
    setNoticeStep,
    notices,
  });
  return hideProgress || userLoading || !isAuthenticated ? (
    <></>
  ) : (
    <>
      <Collapse in={collapseIn} unmountOnExit ref={progressBarRef}>
        <MuiContainer {...style.container}>
          <Grid {...style.gridContainer}>
            <Grid {...style.gridItem}>
              <Flex {...style.progressBox}>
                <LabelledInfo {...style.progressText(currentProgress)} />
                <LinearProgress {...style.progressBar(displayProgress)} />
              </Flex>
            </Grid>
            <Grid {...style.gridItem}>
              <Flex {...style.progressBox}>
                <MuiText
                  {...style.progressMessage(
                    notices[noticeStep] || text.completedMessage,
                  )}
                />
                {notices.length > 1 && (
                  <Flex>
                    <IconButton {...style.backButton}>
                      <ArrowBackIosNewIcon {...style.arrowIcon} />
                    </IconButton>
                    <IconButton {...style.nextButton}>
                      <ArrowForwardIosIcon {...style.arrowIcon} />
                    </IconButton>
                  </Flex>
                )}
              </Flex>
            </Grid>
          </Grid>
        </MuiContainer>
      </Collapse>
      <InterestsModal
        {...{
          modalControl: interestModalControl,
          initialStep: interestInitialStep,
          preloadOptions: collapseIn,
        }}
      />
      <EmailResendModal {...{ modalControl: resendModalControl }} />
      <LocationModal {...{ modalControl: locationModalControl }} />
      <NewInvitationModal {...{ modalControl: invitationModalControl }} />
    </>
  );
  function makeProps({
    user,
    attemptFormat,
    noticeStep,
    setNoticeStep,
    notices,
  }) {
    return {
      text: {
        completedMessage: {
          id: 'progressBar.notice.complete',
          dm: 'Congratulations! You have completed all the tasks.',
        },
      },
      style: {
        container: {
          sxInner: { p: { xs: '0.5rem', md: '0.5rem 0' } },
          sx: { height: 'unset', backgroundColor: 'primary.background' },
        },
        gridContainer: {
          container: true,
          spacing: { xs: '0', md: '1rem' },
        },
        gridItem: { item: true, xs: 12, md: 6 },
        progressBox: {
          sx: { height: '100%', gap: '0.25rem', alignItems: 'center' },
        },
        progressText: (value) => ({
          value: `${Math.round(value)}%`,
          valueProps: { sx: { verticalAlign: 'middle' } },
          label: {
            id: 'progressBar.progress.label',
            dm: 'Profile Completion:',
          },
          labelProps: {
            sx: {
              color: 'headingText.main',
              fontWeight: 'normal',
              verticalAlign: 'middle',
              textAlign: 'center',
            },
          },
          rowProps: {
            sx: {
              p: '0',
              width: 'unset',
              gap: '0.375rem',
              alignItems: 'center',
              fontSize: '1rem',
              flexShrink: '0',
            },
          },
        }),
        progressBar: (value) => ({
          value,
          variant: 'determinate',
          color: 'secondary',
          sx: { flexGrow: '1', flexShrink: '1', minWidth: '1rem' },
        }),
        progressMessage: ({ message, action }) => ({
          // TODO: this needs to be changed in the future
          dangerouslySetInnerHTML: { __html: attemptFormat(message) },
          onClick: () => action({ user }),
          sx: {
            fontSize: '0.875rem',
            fontWeight: 'bold',
            textAlign: 'center',
            mx: 'auto',
            display: 'block',
            '& a': { color: 'primary.main' },
          },
        }),
        backButton: {
          onClick: () =>
            setNoticeStep((current) => (current <= 0 ? 0 : current - 1)),
          disabled: noticeStep <= 0,
          sx: { p: '0.25rem' },
        },
        nextButton: {
          onClick: () =>
            setNoticeStep((current) =>
              current >= notices.length - 1 ? notices.length : current + 1,
            ),
          disabled: noticeStep >= notices.length - 1,
          sx: { p: '0.25rem' },
        },
        arrowIcon: { sx: { fontSize: '1rem' } },
      },
    };
  }
};
export default ProgressBar;

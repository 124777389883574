import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import AppBar from '@mui/material/AppBar';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import LocalGroceryStoreTwoToneIcon from '@mui/icons-material/LocalGroceryStoreTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import { NavbarMessages } from '../../data/icons';
import IconLink from './IconLink';
import { Toolbar, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import useMessages from '../../hooks/useMessages';
import SignupButton from '../common/SignupButton';
import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';

function BottomNavBar({ bottomNavRef }) {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { subdomainValue } = useSelector(({ intl }) => intl);

  const isSeller = user?.roles === '2';
  const { formatMessage } = useIntl();
  const { totalUnread: unreadMessages } = useMessages();

  const navLinks = [
    {
      text: formatMessage({
        id: 'navbar.linkIcon.home',
        defaultMessage: 'Home',
      }),
      path: '/',
      icon: HomeIcon,
    },
    {
      text: formatMessage({
        id: !subdomainValue
          ? 'navbar.linkIcon.marketplace'
          : 'navbar.linkIcon.marketplaceStore',
        defaultMessage: 'Marketplace',
      }),
      path: !subdomainValue ? '/market' : '/market/productsPage',
      icon: !subdomainValue
        ? StorefrontTwoToneIcon
        : LocalGroceryStoreTwoToneIcon,
    },
    {
      text: formatMessage({
        id: 'navbar.linkIcon.network',
        defaultMessage: 'Network',
      }),
      path: '/network',
      icon: GroupsTwoToneIcon,
      loginRequired: true,
    },
    {
      text: formatMessage({
        id: 'navbar.linkIcon.orders',
        defaultMessage: 'Orders',
      }),
      path: '/orders',
      icon: AssignmentTwoToneIcon,
      loginRequired: true,
    },
    {
      text: formatMessage({
        id: 'navbar.linkIcon.threads',
        defaultMessage: 'Threads',
      }),
      path: '/messages',
      icon: NavbarMessages,
      loginRequired: true,
      badgeContent: unreadMessages,
    },
  ];

  const style = {
    appBar: {
      color: 'card',
      position: 'fixed',
      sx: { top: 'auto', bottom: '0', zIndex: '5' },
    },
    toolbar: {
      sx: {
        px: { xs: 0 },
        alignItems: 'center',
        height: '4rem',
      },
    },
    grid: {
      'data-tour': isSeller ? 'sellerLanding-step-3' : 'buyerLanding-step-2',
      columns: 15,
      spacing: '0.5rem',
      sx: { width: '100%' },
      container: true,
    },
    signup: {
      item: true,
      itemProps: { xs: 9, display: 'flex' },
      fontSize: '0.75rem',
      sx: {
        width: 'fit-content',
        p: '0.5rem',
        my: 'auto',
        ml: 'auto',
        mr: '1rem',
      },
    },
  };

  return (
    <AppBar {...style.appBar} ref={bottomNavRef}>
      <Toolbar {...style.toolbar}>
        <Grid {...style.grid}>
          {navLinks
            .filter(
              ({ loginRequired, viewRestricted }) =>
                (!loginRequired || isAuthenticated) && !viewRestricted,
            )
            .map((iconLink, key) => (
              <Grid key={key} item xs={3}>
                <IconLink {...{ ...iconLink }} />
              </Grid>
            ))}
          {!isLoading && !isAuthenticated && <SignupButton {...style.signup} />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default BottomNavBar;

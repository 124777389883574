import React from 'react';
import { Modal } from '@mui/material';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import MuiButton from '../intl/MuiButton';
import MuiCard from './MuiCard';
import MuiModalCloseButton from './MuiModalCloseButton';
import RestrictedButton from './RestrictedButton';
import checkFunc from '../../utils/checkFunc';

// Wraps content to put in a modal when button is clicked
const MuiModalButton = ({
  children, // content to put in the modal
  // require
  // optional
  modalControl: givenModalControl, // useMuiModalControl() output
  modalContent, // (modalControl) => <ModalContent />
  modalProps,
  cardProps,
  sxCard,
  buttonProps,
  onClick,
  sxButton,
  buttonRef,
  noClose,
  closeProps,
  sxClose,
  scrollRef,
  noButton,
}) => {
  const defaultModalControl = useMuiModalControl();
  const modalControl = givenModalControl || defaultModalControl;
  const { open, handleOpen, handleClose } = modalControl;

  const { style } = makeProps({ sxCard, sxButton, sxClose });
  return (
    <>
      {!noButton && (
        <>
          {buttonProps?.restrictions ? (
            <RestrictedButton
              {...{
                ...style.button(buttonProps),
                onClick: () =>
                  (onClick || buttonProps?.onClick || handleOpen)(),
                buttonRef,
              }}
            />
          ) : (
            <MuiButton
              {...{
                ...style.button(buttonProps),
                onClick: () =>
                  (onClick || buttonProps?.onClick || handleOpen)(),
                ...(buttonRef && { ref: buttonRef }),
              }}
            />
          )}
        </>
      )}
      <Modal {...{ open, onClose: handleClose, ...modalProps }}>
        <MuiCard {...{ ...style.card(cardProps), ref: scrollRef }}>
          {!noClose && (
            <MuiModalCloseButton
              {...{ handleClose, ...style.close(closeProps) }}
            />
          )}
          {checkFunc(modalContent || children, modalControl)}
        </MuiCard>
      </Modal>
    </>
  );

  function makeProps({ sxCard, sxButton, sxClose }) {
    return {
      style: {
        card: (props) => ({
          ...props,
          customScroll: true,
          sx: {
            m: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 12,
            maxWidth: '95vw',
            maxHeight: '95vh',
            ...props?.sx,
            ...sxCard,
          },
        }),
        button: ({ buttonRef, modalControl, ...props } = {}) => ({
          ...(props?.restrictions && { modalControl, buttonRef }),
          ...props,
          sx: { ...props?.sx, ...sxButton },
        }),
        close: (props) => ({
          color: 'headingText',
          ...props,
          sx: {
            position: 'absolute',
            top: '0.25rem',
            right: '0.25rem',
            zIndex: 4,
            ...props?.sx,
            ...sxClose,
          },
        }),
      },
    };
  }
};

export default MuiModalButton;

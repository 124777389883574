import React from 'react';
import useAsyncState from '../../hooks/useAsyncState';
import Flex from '../common/Flex';
import MuiText from '../intl/MuiText';
import MuiFormField from './MuiFormField';
import MuiSelect from './MuiSelect';
import PostalCodeField from './PostalCodeField';
import useAuth from '../../hooks/useAuth';
import { COUNTRY_OPTIONS } from '../../data/constants';
import { useFormikContext } from 'formik';
import { Country, State } from 'country-state-city-translate';
import { useMemo } from 'react';
import { mergeProps } from '../../utils/mergeProps';
import useLocale from '../../hooks/useLocale';

const LocationSection = ({
  loadingState: givenLoadingState,
  title,
  noTitle,
  titleProps,
  stackProps,
  multiFieldProps,
  fieldProps,
}) => {
  const { user } = useAuth();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const { isEnglish } = useLocale();
  const countryCode = values.countryShort;
  const defaultLoadingState = useAsyncState(false);
  const locationLoadingState = givenLoadingState || defaultLoadingState;

  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().reduce(
        (countryArr, { isoCode, name, arName }) => {
          if (COUNTRY_OPTIONS.includes(isoCode)) {
            name = isEnglish ? name : arName;
            countryArr.push({ id: isoCode, name });
          }
          return countryArr;
        },
        [],
      ),
    [isEnglish],
  );

  const provinceOptions = useMemo(
    () =>
      State.getStatesOfCountry(countryCode).map(
        ({ isoCode, name, arName }) => ({
          id: isoCode,
          name: isEnglish ? name : arName,
        }),
      ),
    [countryCode, isEnglish],
  );

  const { text, style, field } = makeProps({
    user,
    locationLoadingState,
    countryOptions,
    provinceOptions,
    setFieldValue,
    setFieldTouched,
    fieldProps,
  });
  return (
    <Flex {...style.formStack(stackProps)}>
      {!noTitle && (title || <MuiText {...text.locationTitle(titleProps)} />)}
      <Flex {...style.multiFields(multiFieldProps)}>
        <MuiFormField {...field.streetNumber} />
        <MuiFormField {...field.streetName} />
      </Flex>
      <Flex {...style.multiFields(multiFieldProps)}>
        <PostalCodeField {...field.postalCode} />
        <MuiFormField {...field.city} />
      </Flex>
      <Flex {...style.multiFields(multiFieldProps)}>
        <MuiSelect {...field.country} />
        <MuiSelect {...field.province} />
      </Flex>
    </Flex>
  );

  function makeProps({
    user,
    locationLoadingState,
    countryOptions,
    provinceOptions,
    setFieldValue,
    setFieldTouched,
    fieldProps,
  }) {
    const { location } = user || {};
    const [locationLoading] = locationLoadingState || [];
    const fieldStyle = (props) =>
      mergeProps(
        props,
        mergeProps(fieldProps, { sx: { width: '100%', maxWidth: '25rem' } }),
      );
    return {
      text: {
        locationTitle: (props) =>
          mergeProps(props, {
            id: 'feed.post.requestButton.locationTitle',
            dm: 'Confirm Shipping Details',
            sx: { color: 'headingText.main', fontSize: '1rem' },
          }),
      },
      style: {
        formStack: (props) =>
          mergeProps(props, {
            direction: 'column',
            sx: {
              gap: '1rem',
              width: '100%',
              maxWidth: '40rem',
              mx: 'auto',
              py: '1rem',
            },
          }),
        multiFields: (props) =>
          mergeProps(props, {
            direction: { xs: 'column', md: 'row' },
            sx: {
              gap: '1rem',
              width: '100%',
              display: { xs: 'flex', md: 'grid' },
              gridTemplateColumns: { xs: 'unset', md: '1fr 1fr' },
              alignItems: { xs: 'center', md: 'flex-start' },
            },
          }),
      },
      field: {
        streetNumber: fieldStyle({
          name: 'streetNumber',
          label: { id: 'forms.streetNumber', dm: 'Street Number' },
          required: true,
        }),
        streetName: fieldStyle({
          name: 'streetName',
          label: { id: 'forms.streetName', dm: 'Street Name' },
          required: true,
        }),
        postalCode: fieldStyle({
          initialValue: location?.postalCode || '',
          required: true,
          loadingState: locationLoadingState,
        }),
        city: fieldStyle({
          name: 'city',
          label: { id: 'forms.city', dm: 'City' },
          required: true,
          disabled: locationLoading,
        }),
        province: fieldStyle({
          accessor: 'id',
          name: 'province',
          label: { id: 'forms.province', dm: 'Province' },
          options: provinceOptions,
          required: true,
          disabled: locationLoading || !provinceOptions.length,
        }),
        country: fieldStyle({
          accessor: 'id',
          options: countryOptions,
          name: 'countryShort',
          label: { id: 'forms.country', dm: 'Country' },
          disabled: locationLoading,
          onChange: ({ target }) => {
            setFieldValue('province', '');
            setFieldTouched('province', false);
            setFieldValue(
              'country',
              countryOptions.find(({ id }) => id === target.value)?.name,
            );
          },
          required: true,
        }),
      },
    };
  }
};

export default LocationSection;

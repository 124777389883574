import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useSnackbar from '../../hooks/useSnackbar';

const MuiRedirect = ({
  to = '/',
  message = 'mustLogin',
  options, // must be memoized
}) => {
  const { push } = useHistory();
  const { snackbar } = useSnackbar();

  useEffect(() => {
    snackbar({ message, options });
    push(to);
  }, [snackbar, message, options, push, to]);

  return <></>;
};

export default MuiRedirect;

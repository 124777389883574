import { CircularProgress, Divider, Grid } from '@mui/material';
import React from 'react';
import WrapOn from './WrapOn';
import Flex from './Flex';

const MuiLoading = ({
  children,
  loading = true,
  loadingContent, // (index) => <Content {...{index}} />
  loadingGap = '0',
  sxLoadingContentWrapper,
  loadingContentCount = 1,
  loadingGrid,
  noFlex,
  wrapperDiv,
  sx,
  size, //use px or rem for now
  sxCircle,
  indexStart = 0,
  dividers,
  dividerProps,
  ...rest
}) => {
  const loadingContentArray = Array(loadingContentCount).fill('');

  const style = {
    outerFlex: {
      Wrap: Flex,
      direction: 'column',
      sx: { width: '100%', alignItems: 'center', ...sx },
      ...rest,
    },
    circle: { size, sx: { color: 'primary.main', ...sxCircle } },
    grid: { item: true, ...loadingGrid },
    innerFlex: {
      sx: {
        flexDirection: 'column',
        width: '100%',
        ...sxLoadingContentWrapper,
      },
    },
    box: { ...wrapperDiv },
  };

  const showDivider = (index, length) => !!dividers && index !== length - 1;
  return !loading ? (
    <>{!!children && children}</>
  ) : (
    <WrapOn {...{ on: !(noFlex || loadingGrid), ...style.outerFlex }}>
      {!loadingContent ? (
        <CircularProgress {...style.circle} />
      ) : (
        loadingContentArray.map((_, key, { length }) =>
          loadingGrid ? (
            <Grid {...{ key, ...style.grid }}>
              {loadingContent(key + indexStart)}
              {showDivider(key, length) && <Divider {...dividerProps} />}
            </Grid>
          ) : !noFlex ? (
            <Flex {...{ key, ...style.innerFlex }}>
              {loadingContent(key + indexStart)}
              {showDivider(key, length) && <Divider {...dividerProps} />}
            </Flex>
          ) : (
            <div {...{ key, ...style.box }}>
              {loadingContent(key + indexStart)}
              {showDivider(key, length) && <Divider {...dividerProps} />}
            </div>
          ),
        )
      )}
    </WrapOn>
  );
};

export default MuiLoading;

import { useState, useCallback } from 'react';
import useIsMounted from './useIsMounted';

const useAsyncState = (defaultValue) => {
  const [asyncValue, setAsyncValue] = useState(defaultValue);
  const isMounted = useIsMounted();

  const setValue = useCallback(
    (newValue) => {
      if (isMounted.current) setAsyncValue(newValue);
    },
    [isMounted],
  );

  return [asyncValue, setValue];
};

export default useAsyncState;

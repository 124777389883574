import React from 'react';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import { useDispatch } from 'react-redux';
import { checkMe } from '../../reducers/authReducer';
import MuiModal from '../common/MuiModal';
import MuiMultiStep, { MuiFormStep } from '../forms/MuiMultiStep';
import useValidator from '../../hooks/useValidator';
import useAuth from '../../hooks/useAuth';
import { useMemo } from 'react';
import useGetData from '../../hooks/useGetData';
import { useCallback } from 'react';
import {
  singleCategoryMapper,
  supplierArrayMapper,
} from '../../utils/fetchMapper';
import MuiCardSelector from '../forms/MuiCardSelector';
import CategoryInterestCard from '../category/CategoryInterestCard';
import Flex from '../common/Flex';
import MuiText from '../intl/MuiText';
import SellerInterestCard from '../sellers/SellerInterestCard';
import tryClient from '../../utils/tryClient';
import useSnackbar from '../../hooks/useSnackbar';
import useAsyncState from '../../hooks/useAsyncState';
import { mergeProps } from '../../utils/mergeProps';
import { useEffect } from 'react';
import { useState } from 'react';

const InterestsModal = ({
  modalControl: givenModalControl,
  initialStep,
  preloadOptions,
}) => {
  const { user } = useAuth();
  const isSeller = user?.roles === '2';
  const dispatch = useDispatch();
  const validator = useValidator();
  const { snackbar } = useSnackbar();
  const defaultModalControl = useMuiModalControl();
  const modalControl = givenModalControl || defaultModalControl;
  const { open } = modalControl;
  const [submittingCategories, setSubmittingCategories] = useAsyncState(false);
  const [hasOpened, setHasOpened] = useState(false);
  useEffect(() => {
    if (!open) return;
    setHasOpened(true);
  }, [open]);
  const loadOptions = preloadOptions || hasOpened;

  const interestCategories = useMemo(
    () => (user?.subscriptions || []).map(({ id }) => id).sort(),
    [user],
  );

  const followingSellers = useMemo(
    () => (user?.followedSuppliers || []).map(({ id }) => id).sort(),
    [user],
  );

  const { data: categories, loading: categoriesLoading } = useGetData({
    endpoint: loadOptions && '/categories',
    initialParams: { perpage: 100 },
    dataHandler: useCallback(
      ({ data }) =>
        data.data
          .reduce((dataArray, category) => {
            if (!category.parent_category_id)
              dataArray.push(singleCategoryMapper(category));
            return dataArray;
          }, [])
          .slice(0, 20),
      [],
    ),
  });

  const {
    data: suppliers,
    loading: suppliersLoading,
    refresh: refreshSuppliers,
  } = useGetData({
    endpoint: loadOptions && '/recommend/supplier',
    dataMapper: supplierArrayMapper,
    initialParams: { perpage: 16 },
  });

  const initialValues = {
    interestCategories,
    followingSellers,
  };

  const validationSchema = validator((schema) => ({
    ...schema.interestCategories(),
    ...schema.followingSellers(),
  }));

  const handleSubmitCategories = async ({ values, errors }) => {
    const hasError = errors.interestCategories;
    const value = values.interestCategories;
    if (hasError || value.join() === initialValues.interestCategories.join())
      return isSeller && modalControl.forceClose();
    setSubmittingCategories(true);
    const { err } = await tryClient(`/users/subscriptions/update`, {
      body: { subscriptions: value.map((id) => ({ id })) },
      method: 'PATCH',
    });
    if (err) {
      snackbar({ message: 'error' });
      setSubmittingCategories(false);
      return { err };
    }
    refreshSuppliers();
    await dispatch(checkMe());
    setSubmittingCategories(false);
    if (isSeller) modalControl.forceClose();
  };

  const handleSubmit = async (values) => {
    const newFollowSellers = [...values.followingSellers].filter(
      (id) => !initialValues.followingSellers.includes(id),
    );

    await newFollowSellers.reduce(async (prevPromise, id) => {
      await prevPromise;
      return tryClient(`/users/follow/${id}`, { method: 'POST' }, () =>
        snackbar({ message: 'errorFollow' }),
      );
    }, Promise.resolve());

    await dispatch(checkMe());
    modalControl.handleClose();
  };

  const { text, style } = makeProps({
    modalControl,
    initialStep,
    initialValues,
    validationSchema,
    handleSubmit,
    submittingCategories,
    handleSubmitCategories,
  });
  return (
    <MuiModal {...style.modal}>
      <MuiMultiStep {...style.form}>
        <MuiFormStep>
          <Flex {...style.formStack}>
            <MuiText {...text.categoryTitle} />
            <MuiText {...text.categorySubtitle} />
            <MuiCardSelector
              {...{
                name: 'interestCategories',
                options: categories,
                optionsLoading: categoriesLoading,
                card: (category, selected) => (
                  <CategoryInterestCard {...{ category, selected }} />
                ),
              }}
            />
          </Flex>
        </MuiFormStep>
        <MuiFormStep>
          <Flex {...style.formStack}>
            <MuiText {...text.sellerTitle} />
            <MuiText {...text.sellerSubtitle} />
            <MuiCardSelector
              {...{
                name: 'followingSellers',
                options: suppliers,
                optionsLoading: suppliersLoading,
                card: (seller, selected) => (
                  <SellerInterestCard {...{ seller, selected }} />
                ),
              }}
            />
          </Flex>
        </MuiFormStep>
      </MuiMultiStep>
    </MuiModal>
  );
  function makeProps({
    modalControl,
    initialStep,
    initialValues,
    validationSchema,
    handleSubmit,
    submittingCategories,
    handleSubmitCategories,
  }) {
    const title = (props) =>
      mergeProps(props, {
        sx: { fontWeight: 'bold', fontSize: '1.125rem', textAlign: 'center' },
      });
    const subtitle = (props) =>
      mergeProps(props, { sx: { fontSize: '0.875rem' } });
    return {
      text: {
        categoryTitle: title({
          id: 'user.manageSubscription.category.title',
          dm: 'Manage Interests',
        }),
        categorySubtitle: subtitle({
          id: 'user.manageSubscription.category.subtitle',
          dm: 'Please select at least 3 categories of interest',
        }),
        sellerTitle: title({
          id: 'user.manageSubscription.seller.title',
          dm: 'Suggested For You to Follow',
        }),
        sellerSubtitle: subtitle({
          id: 'user.manageSubscription.seller.subtitle',
          dm: 'Please follow at least 3 Businesses',
        }),
      },
      style: {
        modal: {
          modalControl,
          cardProps: {
            sx: { minWidth: 'min(95vw, 45rem)', width: { xs: 'auto' } },
          },
        },
        form: {
          initialValues,
          validationSchema,
          onSubmit: handleSubmit,
          initialStep,
          modalControl,
          preventExit: true,
          enableReinitialize: true,
          buttonProps: {
            nextProps: (step) => ({
              ...(step === 0 && {
                id: 'user.manageSubscription.category.submit',
                dm: 'Submit Categories',
                variant: 'formButton',
                loading: submittingCategories,
                onClick: (_, form) => handleSubmitCategories(form),
              }),
            }),
            backProps: (step) => ({
              ...(step === 1 && {
                id: 'user.manageSubscription.category.change',
                dm: 'Change Categories',
              }),
            }),
            submitProps: {
              id: 'user.manageSubscription.seller.submit',
              dm: 'Follow',
            },
          },
        },
        formStack: {
          direction: 'column',
          sx: { color: 'headingText.main', gap: '1rem', mb: '1rem' },
        },
      },
    };
  }
};

export default InterestsModal;

import { useCallback, useRef, useState } from 'react';
import checkFunc from '../utils/checkFunc';
import useResizeListener from './useResizeListener';

const useIntersection = ({
  onIntersect = (entries) => {},
  options = (node) => ({}),
  ignore = false, // prevent adding observer
  initialIntersecting = true, // option boolean
  initialAbove = false,
  initialBelow = false,
  singleAction = false,
  debugMode = false,
  debugName = '',
  resizeRefresh = false,
} = {}) => {
  const isDebug = debugMode || debugName;
  const observer = useRef(null);
  const nodeRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(initialIntersecting);
  const [isAbove, setIsAbove] = useState(initialAbove);
  const [isBelow, setIsBelow] = useState(initialBelow);
  const [refresh, setRefresh] = useState(false);

  useResizeListener({
    resizeListener: useCallback(
      () => resizeRefresh && setRefresh((current) => !current),
      [resizeRefresh],
    ),
  });

  const checkAbove = useCallback(
    ({ isIntersecting, boundingClientRect, rootBounds } = {}) =>
      !isIntersecting && boundingClientRect?.y < rootBounds?.y,
    [],
  );

  const checkBelow = useCallback(
    ({ isIntersecting, boundingClientRect, rootBounds } = {}) =>
      !isIntersecting && boundingClientRect?.y > rootBounds?.y,
    [],
  );

  const intersectionRef = useCallback(
    (node) => {
      if (observer.current) {
        if (isDebug) console.log(debugName, 'disconnect previous observer');
        observer.current.disconnect();
      }
      if (ignore)
        return isDebug && console.log(debugName, 'ignore intersection');
      nodeRef.current = node;
      observer.current = new IntersectionObserver(
        (entries) => {
          if (isDebug)
            console.log(debugName, {
              entries,
              ...entries.map((en) => ({
                isIntersecting: en.isIntersecting,
                isAbove: checkAbove(en),
                isBelow: checkBelow(en),
              })),
            });
          const [entry] = entries;
          setIsIntersecting(entry?.isIntersecting);
          setIsAbove(checkAbove(entry));
          setIsBelow(checkBelow(entry));
          onIntersect(entries);
        },
        { ...(node && checkFunc(options, node)) },
      );
      if (!node) return isDebug && console.log(debugName, 'no node');
      if (isDebug) console.log('observe:', { node, refreshValue: refresh });
      observer.current.observe(node);
    },
    [
      refresh,
      checkAbove,
      checkBelow,
      onIntersect,
      ignore,
      options,
      debugName,
      isDebug,
    ],
  );

  return {
    ref: intersectionRef,
    nodeRef,
    isIntersecting,
    isAbove,
    isBelow,
    refresh: useCallback(() => setRefresh((current) => !current), []),
  };
};

export default useIntersection;

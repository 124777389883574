import React from 'react';
import MuiButton from '../intl/MuiButton';
import { TourProvider } from '@reactour/tour';

const TourWrapper = ({ children }) => {
  const { text, style } = makeProps();
  const nextButton = ({
    currentStep,
    stepsLength,
    setIsOpen,
    setCurrentStep,
  }) => {
    const last = currentStep === stepsLength - 1;
    return (
      <MuiButton
        {...{
          ...text.next(last),
          ...style.buttons,
          onClick: () =>
            last
              ? setIsOpen(false)
              : setCurrentStep((s) => (s === stepsLength - 1 ? 0 : s + 1)),
        }}
      />
    );
  };

  const prevButton = ({ currentStep, setCurrentStep }) => {
    const first = currentStep === 0;
    return (
      <MuiButton
        {...{
          disabled: first,
          onClick: () => setCurrentStep((s) => s - 1),
          ...text.prev,
          ...style.buttons,
        }}
      />
    );
  };

  const badgeContent = ({ totalSteps, currentStep }) =>
    `${currentStep + 1}/${totalSteps}`;

  return (
    <TourProvider
      {...{ nextButton, prevButton, badgeContent }}
      {...{ styles: { maskArea: (base) => ({ ...base, rx: 10 }) } }}
    >
      {children}
    </TourProvider>
  );

  function makeProps() {
    return {
      text: {
        next: (last) =>
          last
            ? { id: 'sellerTour.close', dm: 'Done' }
            : { id: 'sellerTour.next', dm: 'Next' },

        prev: { id: 'sellerTour.prev', dm: 'Previous' },
      },
      style: { buttons: { variant: 'outlined' } },
    };
  }
};

export default TourWrapper;

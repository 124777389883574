import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkMe, setIsLoading } from '../../reducers/authReducer';
import Cookies from 'js-cookie';
import useAuth from '../../hooks/useAuth';

function AuthWrapper({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    const accessToken =
      sessionStorage.getItem('accessToken') ||
      localStorage.getItem('accessToken') ||
      Cookies.get('accessToken');

    if (!accessToken) {
      dispatch(setIsLoading(false));
      return;
    }
    if (user) return;
    dispatch(checkMe());
  }, [isAuthenticated, dispatch, user]);

  return <>{children}</>;
}

export default AuthWrapper;

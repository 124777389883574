import { createTheme } from '@mui/material/styles';

export const colorPalette = {
  primary: {
    main: '#0069CF',
    dark: '#025ab3',
    light: '#0078f0',
    filter: '#0069cf19',
    filterDark: '#0069cf49',
    background: '#d1e8ff',
    particle: '#0d47a1',
  },
  darkerPrimary: {
    main: '#003972',
    dark: '#002142',
    light: '#004a93',
    filter: '#00397219',
    background: '#9dc6ee',
  },
  primaryPale: {
    main: '#0069CF1C',
    dark: '#004e9b1c',
    light: '#57a7f71c',
  },
  primaryPaleOpaque: {
    main: '#b3d9ff',
    dark: '#78b5f1',
    light: '#c4e1ff',
  },
  primaryPaleTest: {
    main: '#FF9800',
    dark: '#FD9600',
    light: '#ffbc5a',
    filter: '#FF980033',
    filterLight: '#FF98001A',
    darker: '#f59402',
    background: '#fffaf2',
  },
  secondary: {
    main: '#FF9800',
    // main: '#ff8400',
    // dark: '#FD9600',
    dark: '#f28500',
    light: '#F4B975',
    filter: '#ff980019',
    filterLight: '#FF98001A',
    darker: '#f59402',
    background: '#fffaf2',
  },
  starter: {
    main: '#4495e6',
    dark: '#4d99e5',
    light: '#4495e6',
    filter: '#4495e633',
    filterLight: '#4495e619',
  },
  complementary: {
    main: '#0AAFCA',
    dark: '#039fb7',
    light: '#34d9f2',
    filter: '#0AAFCA33',
    filterLight: '#0aafca19',
  },
  error: {
    main: '#E53737',
    dark: '#C92D2D',
    light: '#F09595',
    filter: '#e5373719',
    background: '#fff0f0',
  },
  warning: {
    main: '#ed6c02',
    dark: '#d76100',
    light: '#fc8627',
  },
  success: {
    main: '#3CBF3B',
    dark: '#17ac17',
    light: '#8AD889',
    filter: '#3bbf3b19',
    background: '#e6ffe6',
  },
  bodyText: {
    main: '#62768A',
    dark: '#46484b',
    light: '#84878d',
    filter: '#62768A19',
    background: '#d9dce0',
  },
  labelText: {
    main: '#A3A6B4',
    dark: '#757780',
    light: '#c2c4cf',
    filter: '#A3A6B419',
  },
  valueText: {
    main: '#4D4F5C',
    dark: '#2c2e35',
    light: '#707281',
  },
  iconAccent: {
    main: '#81a2e5',
    dark: '#81a2e5',
    light: '#81a2e5',
  },
  pieChart: { fill: '#8884d8' },
  icons: { main: '#848FA8', filter: '#848FA819' },
  footer: { main: '#0C2844', dark: '#031C34', light: '#012F5E' },
  footerLink: {
    main: '#CFC5B4',
  },
  borders: { light: '#D6DAE0' },
  background: {
    light: '#F2F7FD ',
    main: '#F9FBFF',
    dark: '#F5F8FD',
    darker: '#e8eef8',
  },
  numbers: { main: '#A3A6B4' },
  buttons: { main: '#C0D3F3' },
  card: { main: '#FFF', dark: '#f0f0f0', light: '#FFF', filter: '#ffffffcc' },
  yellow: {
    main: '#FFCB45',
    dark: '#FDB717',
    light: '#FFD56A',
    background: '#fffcf4',
    filter: '#d8b91d19',
  },
  favourite: { main: '#E64F4F', light: '#ef7f7f', filter: '#e64f4f19' },
  headingText: {
    main: '#0C2844',
    light: '#334353',
    secondary: '#2C5282',
    background: '#BBBEC6',
    filter: '#0C284419',
    // darkFilter: '#00000075',
    darkFilter: '#030b1369',
  },
  iconsText: { main: '#BBBEC6', light: '#dfe2eb', filter: '#BBBEC619' },
  placeholderText: {
    main: '#D0D2D6',
    dark: '#aaabaf',
    light: '#eceef1',
    filter: '#D0D2D619',
  },
  text: {
    heading: '#262626',
    body: '#66696F',
    icons: '#BBBEC6',
    placeholder: '#D0D2D6',
  },
  purple: { main: '#a200ff', dark: '#63009c', light: '#bb44ff' },
  facebook: { main: '#2374e1', dark: '#0165E1', light: '#17A9FD' },
};
export const parseColor = (colorText) => {
  const [base, variant] = (colorText || '').split('.');
  return colorPalette?.[base]?.[variant] || colorText;
};

export const parseStyle = (styleObj) =>
  !styleObj
    ? ''
    : typeof styleObj === 'string'
    ? styleObj
    : Object.entries(styleObj)
        .map(([key, value]) =>
          [
            key
              .split('')
              .reduce(
                (renamedKey, nextLetter) =>
                  renamedKey +
                  (nextLetter === nextLetter.toUpperCase()
                    ? '-' + nextLetter.toLowerCase()
                    : nextLetter),
                '',
              ),
            value,
          ].join(':'),
        )
        .join(';');

export const boxShadows = {
  button: {
    icon: "'0px 0px 30px rgba(0, 0, 0, 0.1)'",
    small: '0px 20px 19px -14px rgba(0, 0, 0, 0.25)',
    medium: '0px 16px 30px rgba(0, 0, 0, 0.2)',
    large: '0px 45px 30px -9px rgba(0, 0, 0, 0.1)',
  },
  card: {
    small: '0px 4px 30px -5px rgba(0, 0, 0, 0.1)',
    medium: ' 0px 27px 60px -19px rgba(0, 0, 0, 0.1)',
    large: '-16px 57px 60px rgba(0, 0, 0, 0.05)',
  },
  link: {
    active: '0px 4px 60px -19px rgba(0, 0, 0, 0.1)',
  },
  menu: {
    small: '0px 9px 30px rgba(0, 0, 0, 0.1)',
    medium: '0px 3px 6px rgba(0, 0, 0, 0.161)',
    large: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  input: {
    message: '0px 0px 30px rgba(92, 99, 105, 0.15)',
  },
};

export const gradients = {
  default: `linear-gradient(94.93deg, ${colorPalette.primary.main} 2.62%, ${colorPalette.secondary.dark} 100%)`,
  default90: `linear-gradient(90deg, ${colorPalette.primary.main} 2.62%, ${colorPalette.secondary.dark} 100%)`,
  defaultHover: `linear-gradient(94.93deg, ${colorPalette.primary.dark} 2.62%, ${colorPalette.secondary.darker} 100%)`,
  gray: `linear-gradient(94.93deg, rgba(50, 87, 168, 0.1) 2.62%, rgba(255, 188, 90, 0.1) 100%)`,
  grayHover: `linear-gradient(94.93deg, rgba(50, 87, 168, 0.2) 2.62%, rgba(255, 188, 90, 0.2) 100%)`,
};

export const gradientButtonStyles = {
  default: {
    color: '#FFF',
    background: gradients.default,
    '&:hover': { background: gradients.defaultHover },
  },
  gray: {
    color: colorPalette.text.body,
    background: gradients.gray,
    '&:hover': { background: gradients.grayHover },
  },
  grey: {
    color: colorPalette.text.body,
    background: gradients.gray,
    '&:hover': { background: gradients.grayHover },
  },
};

const theme = createTheme({
  palette: { type: 'light', ...colorPalette },
  breakpoints: {
    values: {
      xs: 0,
      verySmallPhone: 360,
      narrowPhone: 380,
      sm: 420,
      md: 672,
      lg: 868,
      maxContent: 1050,
      xl: 1120,
      xxl: 1470,
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: { height: 10, borderRadius: 5 },
        colorPrimary: { backgroundColor: colorPalette.placeholderText.main },
        barColorPrimary: { backgroundColor: colorPalette.primary.main },
      },
    },
    MuiButton: {
      defaultProps: { disableElevation: true },
      variants: [
        {
          props: { variant: 'gradient' },
          style: {
            color: '#FFF',
            background: gradients.default,
            '&:hover': { background: gradients.defaultHover },
          },
        },
        {
          props: { variant: 'gradient', color: 'gray' },
          style: {
            color: colorPalette.text.body,
            background: gradients.gray,
            '&:hover': { background: gradients.grayHover },
          },
        },
        {
          props: { variant: 'gradient', color: 'grey' },
          style: {
            color: colorPalette.text.body,
            background: gradients.gray,
            '&:hover': { background: gradients.grayHover },
          },
        },
      ],
    },
  },
  typography: { button: { textTransform: 'none' } },
  shape: { borderRadius: 5 },
  page: { maxWidth: '63rem' },
});

export default theme;

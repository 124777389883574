import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetData from '../../hooks/useGetData';
import {
  setDashboardCount,
  setNonRefundableServiceFeePercent,
  setServiceFeePercent,
  setStatistics,
} from '../../reducers/dataReducer';
import {
  setSiteInfo,
  setUserRoles,
  setSiteComponents,
  setSellerPlans,
} from '../../reducers/settingsReducer';
import {
  rolesMapper,
  sellerPlanArrayMapper,
  siteComponentMapper,
} from '../../utils/fetchMapper';
import client from '../../utils/client';
import useAuth from '../../hooks/useAuth';

const DataFetchWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    // user,
    isAdmin,
    isModerator,
  } = useAuth();

  const convertToObject = useCallback(
    (data, key = '_id') =>
      data.reduce((newObject, el) => {
        newObject[el[key]] = el;
        return newObject;
      }, {}),
    [],
  );

  useGetData({
    endpoint: '/siteinfo',
    dataHandler: useCallback(
      ({ data }) => {
        dispatch(
          setSellerPlans(
            convertToObject(sellerPlanArrayMapper(data?.seller_plans), 'name'),
          ),
        );
        dispatch(
          setSiteInfo({
            siteNameEnglish: data?.en_site_name,
            siteNameFrench: data?.fr_site_name,
          }),
        );
        dispatch(setUserRoles(rolesMapper(data?.roles || [])));
        dispatch(setServiceFeePercent(data?.service_fee_percentage || 0));
        dispatch(
          setNonRefundableServiceFeePercent(
            data?.non_refundable_fee_percentage || 0,
          ),
        );
        dispatch(
          setSiteComponents(data?.site_components.map(siteComponentMapper)),
        );
      },
      [dispatch, convertToObject],
    ),
  });

  useEffect(() => {
    const fetchStatistics = async () => {
      const collections = [
        { name: 'posts', componentId: '0' },
        { name: 'products', componentId: '8' },
        { name: 'users', componentId: '1' },
        { name: 'suppliers', componentId: '2' },
        { name: 'experts', componentId: '7' },
        { name: 'safetylabs', componentId: '5' },
        { name: 'regulations', componentId: '4' },
        { name: 'reviews', componentId: '16' },
        { name: 'tickets', componentId: '25' },
        { name: 'conversations', componentId: '24' },
      ];
      try {
        const data = await Promise.all(
          collections.map(({ name }) =>
            client(`/admin/statistics/${name}-basic`),
          ),
        );
        const respData = data.map((item, index) => {
          item = item.data;
          item.name = collections[index].name;
          item.componentId = collections[index].componentId;
          return item;
        });
        dispatch(setStatistics(convertToObject(respData, 'name')));
      } catch (e) {}
    };
    if (!isAuthenticated || !(isAdmin || isModerator)) return;
    fetchStatistics();
    const cacheRefresh = setInterval(() => {
      // Refresh data every 10 minutes
      fetchStatistics();
    }, 10 * 60 * 1000);
    return () => {
      clearInterval(cacheRefresh);
    };
  }, [dispatch, isAuthenticated, isAdmin, isModerator, convertToObject]);

  useEffect(() => {
    let cacheRefresh;
    const fetchDashboardCount = async () => {
      try {
        const resp = await Promise.all(
          [
            client('/statistics/posts'),
            (isAdmin || isModerator) &&
              client(`/admin/statistics/products-basic`),
          ].filter((el) => el),
        );
        let products = { products: resp[1].data };
        let updateData = Object.assign(resp[0].data, products);
        dispatch(setDashboardCount({ ...updateData }));
      } catch (e) {}
    };

    if (isAuthenticated) {
      fetchDashboardCount();
      cacheRefresh = setInterval(() => {
        // Refresh data every 10 minutes
        fetchDashboardCount();
      }, 10 * 60 * 1000);
    }
    return () => {
      clearInterval(cacheRefresh);
    };
  }, [dispatch, isAdmin, isModerator, isAuthenticated]);

  return <>{children}</>;
};

export default DataFetchWrapper;

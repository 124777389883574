import { Collapse, Box, Stack, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import NavItemToggle from './NavItemToggle';
import { useSelector } from 'react-redux';
import usePermissions from '../../hooks/usePermissions';
import useAuth from '../../hooks/useAuth';

const AdminMenuSection = ({ onClick, isOpen, onClose, showAdmin }) => {
  const intl = useIntl();
  const { isAdmin } = useAuth();
  const { canView } = usePermissions();
  const { pending: pendingPosts } = useSelector(
    ({ data }) => data.statistics.posts || {},
  );
  const { pending: pendingUsers } = useSelector(
    ({ data }) => data.statistics.users || {},
  );
  const { pending: pendingSuppliers } = useSelector(
    ({ data }) => data.statistics.suppliers || {},
  );
  const { pending: pendingProducts } = useSelector(
    ({ data }) => data.statistics.products || {},
  );
  const { pending: pendingCoupons } = useSelector(
    ({ data }) => data.statistics.coupons || {},
  );
  const { pending: pendingReviews } = useSelector(
    ({ data }) => data.statistics.reviews || {},
  );
  const { pending: pendingAds } = useSelector(
    ({ data }) => data.statistics.ads || {},
  );
  const { pending: pendingExperts } = useSelector(
    ({ data }) => data.statistics.experts || {},
  );
  const { pending: pendingRegulations } = useSelector(
    ({ data }) => data.statistics.regulations || {},
  );
  const { pending: pendingSafetylabs } = useSelector(
    ({ data }) => data.statistics.safetylabs || {},
  );
  const { pending: pendingRequestedQuotations } = useSelector(
    ({ data }) => data.statistics.requestedQuotations || {},
  );

  const pendingDisputes = 0;
  const pendingOrders = 0;

  const links = [
    {
      path: '',
      textId: 'dashboard',
      viewPermitted: true,
      count: 0,
    },
    {
      path: 'emergencies',
      textId: 'emergencies',
      viewPermitted: canView('17'),
      count: 0,
    },
    {
      path: 'categories',
      textId: 'categories',
      viewPermitted: canView('3'),
      count: 0,
    },
    {
      path: 'item-types',
      textId: 'itemtypes',
      viewPermitted: canView('6'),
      count: 0,
    },
    {
      path: 'users',
      textId: 'users',
      viewPermitted: canView('1') && isAdmin,
      count: pendingUsers,
    },
    {
      path: 'business-profiles',
      textId: 'suppliers',
      viewPermitted: canView('2'),
      count: pendingSuppliers,
    },
    {
      path: 'products',
      textId: 'products',
      viewPermitted: canView('8'),
      count: pendingProducts,
    },
    {
      path: 'disputes',
      textId: 'disputes',
      viewPermitted: canView('12'),
      count: pendingDisputes,
    },
    {
      path: 'coupons',
      textId: 'coupons',
      viewPermitted: canView('8'),
      count: pendingCoupons,
    },
    {
      path: 'orders',
      textId: 'orders',
      viewPermitted: canView('9'),
      count: pendingOrders,
    },
    {
      path: 'reviews',
      textId: 'reviews',
      viewPermitted: canView('8'),
      count: pendingReviews,
    },
    {
      path: 'tickets',
      textId: 'tickets',
      viewPermitted: canView('8'),
      count: 0, //pendingReviews,
    },
    {
      path: 'payments',
      textId: 'payments',
      viewPermitted: canView('8'),
      //count: pendingBills,
    },
    {
      path: 'promotion-ads',
      textId: 'ads',
      viewPermitted: canView('8'),
      count: pendingAds,
    },
    {
      path: 'posts',
      textId: 'posts',
      viewPermitted: canView('0'),
      count: pendingPosts,
    },
    {
      path: 'experts',
      textId: 'experts',
      viewPermitted: canView('7'),
      count: pendingExperts,
    },
    {
      path: 'biosafety-labs',
      textId: 'biosafetylabs',
      viewPermitted: canView('5'),
      count: pendingSafetylabs,
    },
    {
      path: 'regulations',
      textId: 'regulations',
      viewPermitted: canView('4'),
      count: pendingRegulations,
    },
    {
      path: 'messages',
      textId: 'messages',
      viewPermitted: isAdmin,
      count: 0,
    },
    {
      path: 'quotations',
      textId: 'quotations',
      viewPermitted: canView('10'),
      count: pendingRequestedQuotations,
    },
    {
      path: 'plugins',
      textId: 'plugin',
      viewPermitted: canView('13') && isAdmin,
      count: 0,
    },
    {
      path: 'site-settings',
      textId: 'sitesettings',
      viewPermitted: canView('18') && isAdmin,
      count: 0,
    },
  ];

  return (
    <>
      <Box
        onClick={onClick}
        cursor="pointer"
        _hover={{ bg: '#ccc', color: '#333' }}
        p={1}
        my={0}
      >
        <Box
          as="button"
          onClick={onClick}
          fontSize="md"
          aria-expanded={isOpen}
          ml={2}
          mb={0}
          mt={2}
        >
          <Stack isInline>
            <Text mt={1}>
              {showAdmin ? <BsCaretDownFill /> : <BsCaretRightFill />}
            </Text>
            <Text textAlign="left">
              {intl.formatMessage({
                id: 'navbar.navitem.category.admindashboard',
              })}
            </Text>
          </Stack>
        </Box>
      </Box>
      <Collapse in={showAdmin}>
        {links.map(({ path, textId, viewPermitted, count }) => (
          <Fragment key={textId}>
            {viewPermitted && (
              <NavItemToggle
                path={`/admin/${path}`}
                onClose={onClose}
                linkName={intl.formatMessage({
                  id: `navbar.navitem.admin.${textId}`,
                })}
                count={count === 0 ? '' : count}
              />
            )}
          </Fragment>
        ))}
        {/* <NavItemToggle
          path="/admin"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.dashboard',
          })}
        />
        <NavItemToggle
          path="/admin/emergencies"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.emergencies',
          })}
        />

        <NavItemToggle
          path="/admin/categories"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.categories',
          })}
        />
        <NavItemToggle
          path="/admin/item-types"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.itemtypes',
          })}
        />
        {isAdmin && (
          <NavItemToggle
            path="/admin/users"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.admin.users',
            })}
            count={pendingUsers === 0 ? '' : pendingUsers}
          />
        )}

        <NavItemToggle
          path="/admin/suppliers"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.suppliers',
          })}
          count={pendingSuppliers === 0 ? '' : pendingSuppliers}
        />

        <NavItemToggle
          path="/admin/products"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.products',
          })}
          count={pendingProducts === 0 ? '' : pendingProducts}
        />

        <NavItemToggle
          path="/admin/disputes"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.disputes',
          })}
          count={pendingDisputes === 0 ? '' : pendingDisputes}
        />
        <NavItemToggle
          path="/admin/coupons"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.coupons',
          })}
          count={pendingProducts === 0 ? '' : pendingProducts}
        />


        <NavItemToggle
          path="/admin/reviews"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.reviews',
          })}
          // count={pendingProducts === 0 ? '' : pendingProducts}
        />
        
        <NavItemToggle
          path="/admin/posts"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.posts',
          })}
          count={pendingPosts === 0 ? '' : pendingPosts}
        />

        <NavItemToggle
          path="/admin/experts"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.experts',
          })}
          count={pendingExperts === 0 ? '' : pendingExperts}
        />

        <NavItemToggle
          path="/admin/biosafety-labs"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.biosafetylabs',
          })}
          count={pendingSafetylabs === 0 ? '' : pendingSafetylabs}
        />

        <NavItemToggle
          path="/admin/regulations"
          onClose={onClose}
          linkName={intl.formatMessage({
            id: 'navbar.navitem.admin.regulations',
          })}
          count={pendingRegulations === 0 ? '' : pendingRegulations}
        />
        {isAdmin && (
          <>
            <NavItemToggle
              path="/admin/plugins"
              onClose={onClose}
              linkName={intl.formatMessage({
                id: 'navbar.navitem.admin.plugin',
              })}
            />
            <NavItemToggle
              path="/admin/site-settings"
              onClose={onClose}
              linkName={intl.formatMessage({
                id: 'navbar.navitem.admin.sitesettings',
              })}
            />
          </>
        )} */}
      </Collapse>
    </>
  );
};

export default AdminMenuSection;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flex from '../common/Flex';
import MuiSelect from '../forms/MuiSelect';
import MuiText from '../intl/MuiText';
import useValidator from '../../hooks/useValidator';
import MuiFormField from '../forms/MuiFormField';
import MuiButton from '../intl/MuiButton';
import useAsyncState from '../../hooks/useAsyncState';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import { useSnackbar } from '../wrappers/SnackbarProvider';
import { postToDatabaseMapper } from '../../utils/clientMapper';
import { formDataFormatter } from '../../utils/formUtils';
import tryClient from '../../utils/tryClient';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { CURRENCIES } from '../../data/constants';
import MuiTagsField from '../forms/MuiTagsField';
import MuiMultiStep, { MuiFormStep } from '../forms/MuiMultiStep';
import MuiLink from '../common/MuiLink';
import useAuth from '../../hooks/useAuth';
import useSubscriptionPlan from '../../hooks/useSubscriptionPlan';
import { checkMe } from '../../reducers/authReducer';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import NewInvitationForm from '../invitations/NewInvitationForm';
import OfferLimitBlocker from './OfferLimitBlocker';
import useLocale from '../../hooks/useLocale';
import MuiLoading from '../common/MuiLoading';

const MuiOfferForm = ({
  message = '',
  modalControl: givenModalControl,
  loading: externalLoading,
}) => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const { snackbar } = useSnackbar();
  const [skippedNoFollowers, setSkippedNoFollowers] = useState(false);
  const [inviteFollowers, setInviteFollowers] = useState(false);
  const [loading, setLoading] = useAsyncState(false); //hide for now
  const defaultModalControl = useMuiModalControl();
  const modalControl = givenModalControl || defaultModalControl;
  const { handleClose, open } = modalControl;
  // const { locale } = useSelector((state) => state.intl);
  const { isEnglish, isFrench } = useLocale();
  const auth = useAuth();
  const { user, supplier = {} } = auth || {};
  const categories = auth?.categories || [];
  const listings = auth?.listings || [];
  const { name, followers } = supplier || {};

  useEffect(() => {
    if (open) return;
    setSkippedNoFollowers(false);
    setInviteFollowers(false);
  }, [open]);

  const { plan } = useSubscriptionPlan();
  const { maxMonthlyOffers } = plan?.limits || {};
  const { activeOfferPosts } = user || {};
  const isBasic = plan?.type ? plan.type === 'basic' : true;
  const limitExceeded = activeOfferPosts >= maxMonthlyOffers;

  const categoriesArray = categories.map((category) => ({
    id: category.id,
    name: isEnglish
      ? category['enName']
      : isFrench
      ? category['frName']
      : category['arName'],
  }));

  const selectedProduct = (id) => listings.find((listing) => listing.id === id);

  const initialValues = {
    postType: 'Supply',
    postTitleEnglish: '',
    postTextEnglish: message,
    category: '',
    // tags: '',
    tags: [],
    itemQuantity: '' || 0,
    products: '',
    unitPrice: '' || 0,
    currency: CURRENCIES[0]?.id || 'CAD',
  };

  const validationSchema = validator((schema) => ({
    ...schema.postTitleEnglish(),
    ...schema.postTextEnglish(),
    ...schema.tags(),
    ...schema.itemQuantity(),
    ...schema.postType(),
    ...schema.category(),
    ...schema.products({ isRequired: true }),
    ...schema.unitPrice(),
  }));

  const handleSubmit = async (values) => {
    const formattedValues = postToDatabaseMapper(values);
    const formData = formDataFormatter(formattedValues);
    setLoading(true);
    const { err } = await tryClient('/posts', { method: 'POST', formData });
    setLoading(false);
    if (err) return snackbar({ message: 'error' });
    dispatch(checkMe());
    snackbar({ message: 'offerPostSuccess' });
    handleClose();
  };

  const { text, style, button } = makeProps({
    loading,
    supplier,
    modalControl,
    isBasic,
  });
  return externalLoading ? (
    <MuiLoading {...style.loading} />
  ) : limitExceeded ? (
    <OfferLimitBlocker />
  ) : !listings?.length ? (
    <Flex {...style.noListingStack}>
      <CategoryOutlinedIcon {...style.listingsIcon} />
      <MuiText {...text.noListingsTitle} />
      <MuiText {...text.noListingsDescription} />
      <MuiText {...text.pleaseAdd} />
      <Flex {...style.buttonBox}>
        <MuiButton {...{ ...button.cancel, onClick: handleClose }} />
        <MuiButton {...button.addNewProduct} />
      </Flex>
    </Flex>
  ) : !followers && !skippedNoFollowers && !inviteFollowers ? (
    <Flex {...style.noListingStack}>
      <PersonAddAltIcon {...style.inviteIcon} />
      <MuiText {...text.businessInvitationTitle} />
      <MuiText {...text.businessInvitationMessage} />
      <Flex {...style.buttonBox}>
        <MuiButton
          {...button.postAnyway}
          {...{ onClick: () => setSkippedNoFollowers(true) }}
        />
        <MuiButton
          {...button.inviteFollowers}
          {...{ onClick: () => setInviteFollowers(true) }}
        />
      </Flex>
    </Flex>
  ) : !followers && !skippedNoFollowers ? (
    <Flex {...style.noInviteStack}>
      <MuiText {...text.inviteTitle} />
      <NewInvitationForm
        {...{ modalControl, dontClose: true }}
        {...{ afterSubmit: () => setSkippedNoFollowers(true) }}
      />
      <Flex {...style.buttonBox}>
        <MuiButton
          {...button.postAnyway}
          {...{ onClick: () => setSkippedNoFollowers(true) }}
        />
      </Flex>
    </Flex>
  ) : (
    <Flex {...style.postFormStack}>
      <Flex {...style.titleStack}>
        <MuiText {...text.title} />
        <MuiText {...style.subtitle}>
          <MuiText {...text.emailMessage} />
          <MuiLink {...text.planLink} />
        </MuiText>
      </Flex>
      <MuiMultiStep
        {...style.multiStep}
        {...{ initialValues, validationSchema, onSubmit: handleSubmit }}
      >
        <MuiFormStep>
          {({ values, setFieldValue, setTouched, touched }) => (
            <Flex {...style.inputArea}>
              <MuiSelect
                {...{ ...text.categorySelector, ...style.categorySelector }}
                {...{
                  accessor: 'id',
                  required: true,
                  options: categoriesArray,
                  onChange: () => {
                    setFieldValue('products', '');
                    setFieldValue('itemQuantity', 1);
                    setFieldValue('unitPrice', 0);
                    setFieldValue('currency', CURRENCIES[0]?.id || 'CAD');
                    setTouched({
                      ...touched,
                      products: false,
                      itemQuantity: false,
                      unitPrice: false,
                    });
                  },
                }}
              />
              <MuiSelect
                {...{ ...text.productSelector, ...style.categorySelector }}
                {...{
                  accessor: 'id',
                  required: true,
                  disabled: !values.category,
                  options: listings.filter(
                    ({ category }) => values.category === category,
                  ),
                  onChange: ({ target }) => {
                    const { quantity, price, defaultCurrency } =
                      selectedProduct(target.value) || {};
                    setFieldValue('itemQuantity', Number(quantity) || 1);
                    setFieldValue('unitPrice', Number(price) || 0);
                    setFieldValue(
                      'currency',
                      CURRENCIES.find(({ id }) => id === defaultCurrency)?.id ||
                        'CAD',
                    );
                    setTouched({
                      ...touched,
                      itemQuantity: false,
                      unitPrice: false,
                    });
                  },
                  labelAccessor: 'title',
                }}
              />
              <Flex
                direction={{ xs: 'column', md: 'row' }}
                sx={{ width: '100%', gap: '1rem' }}
              >
                <MuiFormField {...{ ...text.quantity, ...style.formField }} />
                <MuiFormField
                  {...{
                    ...text.price,
                    ...style.formField,
                    currency:
                      CURRENCIES.find(({ id }) => id === values.currency) ||
                      CURRENCIES[0],
                  }}
                />
              </Flex>
              <MuiFormField {...{ ...text.postTitle }} />
              <MuiFormField {...{ ...text.postText, ...style.postText }} />
              <MuiTagsField />
            </Flex>
          )}
        </MuiFormStep>
      </MuiMultiStep>
    </Flex>
  );

  function makeProps({ loading, supplier, modalControl, isBasic }) {
    const bold = { style: { fontWeight: 'bold', fontSize: 'inherit' } };
    const primary = {
      sxText: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'primary.main',
      },
    };
    return {
      text: {
        title: {
          id: 'posts.offerPost.title',
          dm: 'Special Offer to Followers',
          sx: { fontSize: '1.5rem' },
        },
        categorySelector: {
          name: 'category',
          label: { id: 'posts.offerPost.categorySelector', dm: 'Category' },
        },
        productSelector: {
          name: 'products',
          label: { id: 'posts.offerPost.productsSelector', dm: 'Listings' },
        },
        quantity: {
          name: 'itemQuantity',
          label: {
            id: 'posts.offerPost.itemQuantity',
            dm: 'MOQ (Minimum Order Quantity)',
          },
          required: true,
        },
        price: {
          name: 'unitPrice',
          label: { id: 'posts.offerPost.unitPrice', dm: 'Unit Price' },
          required: true,
        },
        postTitle: {
          name: 'postTitleEnglish',
          label: { id: 'posts.offerPost.postTitleEnglish', dm: 'Post Title' },
          required: true,
        },
        postText: {
          name: 'postTextEnglish',
          label: {
            id: 'posts.offerPost.postTextEnglish',
            dm: 'Post Description',
          },
          required: true,
        },
        businessInvitationMessage: {
          id: 'posts.offerPost.businessInvitationMessage',
          dm:
            'Your business followers will be emailed on your offer posts. However, your business {BUSINESS} has currently no followers to be emailed about your special offers.',
          sx: {
            color: 'headingText.main',
            fontSize: '1rem',
            textAlign: 'center',
          },
          replaceMap: { '{BUSINESS}': <span {...bold}>{name}</span> },
        },
        businessInvitationTitle: {
          id: 'posts.offerPost.noFollowers',
          dm: 'No Followers',
          sx: {
            color: 'primary.main',
            fontSize: '2.25rem',
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        inviteTitle: {
          id: 'invitations.form.title',
          dm: 'Invite Followers',
          sx: {
            fontSize: '1.5rem',
            color: 'headingText.main',
            fontWeight: 'bold',
          },
        },
        emailMessage: isBasic
          ? {
              id: 'posts.offerPost.emailMessage.basic',
              dm:
                '{UPGRADE_LINK} your plan to send offer emails to your followers.',
              sx: { fontSize: '0.75rem', fontWeight: 'inherit' },
              replaceMap: {
                '{UPGRADE_LINK}': (
                  <MuiLink
                    {...{
                      id: 'posts.offerPost.planLink.basic',
                      dm: 'Upgrade',
                      to: '/seller-plan',
                      ...primary,
                    }}
                  />
                ),
              },
            }
          : {
              id: 'posts.offerPost.emailMessage',
              dm:
                'Offer emails will be sent to your followers based on your {PLAN_LINK}',
              sx: { fontSize: '0.75rem', fontWeight: 'inherit' },
              replaceMap: {
                '{PLAN_LINK}': (
                  <MuiLink
                    {...{
                      id: 'posts.offerPost.planLink',
                      dm: 'account plan',
                      to: '/seller-plan',
                      ...primary,
                    }}
                  />
                ),
              },
            },
        noListingsTitle: {
          id: 'posts.offerPost.noListings.title',
          dm: 'No Active Listings',
          sx: {
            color: 'primary.main',
            fontSize: '2.25rem',
            fontWeight: 'bold',
            textAlign: 'center',
          },
        },
        noListingsDescription: {
          id: 'posts.offerPost.noListings.description',
          dm:
            'Special offers are made on your store listed products or services. You currently have no approved listings associated with your store',
          sx: {
            color: 'headingText.main',
            fontSize: '1rem',
            textAlign: 'center',
          },
        },
        pleaseAdd: {
          id: 'posts.offerPost.noListings.pleaseAdd',
          dm: 'Please add at least one listing',
          sx: {
            color: 'headingText.main',
            fontSize: '1.25rem',
            textAlign: 'center',
          },
        },
      },
      style: {
        loading: { sx: { my: { xs: '4rem', md: '8rem' } } },
        subtitle: { sx: { fontSize: '0.875rem' } },
        titleStack: {
          direction: 'column',
          sx: { alignItems: 'flex-start', gap: '0.125rem' },
        },
        noListingStack: {
          direction: 'column',
          sx: {
            alignItems: 'center',
            gap: '2rem',
            width: '100%',
            maxWidth: { xs: '95vw', md: 'min(80vw, 40rem)' },
          },
        },
        postFormStack: {
          direction: 'column',
          sx: {
            alignItems: 'center',
            gap: '2rem',
            width: '100%',
            maxWidth: { xs: '95vw', md: 'min(80vw, 40rem)' },
          },
        },
        noInviteStack: {
          direction: 'column',
          sx: {
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
            maxWidth: { xs: '95vw', md: 'min(95vw, 50rem)' },
          },
        },
        listingsIcon: {
          sx: { height: '8rem', width: '8rem', color: 'secondary.main' },
        },
        inviteIcon: {
          sx: { height: '8rem', width: '8rem', color: 'secondary.main' },
        },
        buttonBox: {
          direction: { xs: 'column', md: 'row' },
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: { xs: 'stretch', md: 'center' },
            gap: { xs: '0.5rem', md: '1rem' },
          },
        },
        categorySelector: { sx: { width: '100%' } },
        multiStep: {
          modalControl,
          enableReinitialize: true,
          preventExit: true,
        },
        inputArea: {
          direction: 'column',
          sx: {
            gap: '1rem',
            mb: '1rem',
            alignItems: { xs: 'stretch', md: 'center' },
            justifyContent: 'space-between',
          },
        },
        formField: {
          noPlaceholder: true,
          type: 'number',
          inputProps: { min: '0' },
          sx: { width: '100%' },
        },
        postText: { multiline: true, rows: 3, sx: { width: '100%' } },
      },
      button: {
        cancel: {
          id: 'posts.offerPost.cancel',
          dm: 'Cancel',
          variant: 'formButtonOutlined',
          disabled: loading,
          sx: { width: '100%', maxWidth: '25rem', mx: 'auto' },
        },
        addNewProduct: {
          id: 'businessProfile.addNewProduct',
          dm: 'Manage Listings',
          startIcon: <AddBoxOutlinedIcon />,
          disabled: loading,
          fontSize: '0.875rem',
          sx: { py: '0.75rem', width: '100%', maxWidth: '25rem', mx: 'auto' },
          namedLink: { supplier, append: '?tab=products' },
        },
        inviteFollowers: {
          id: 'invitations.form.buttonText',
          dm: 'Invite Followers',
          variant: 'formButton',
          sx: {
            fontSize: '1rem',
            width: '100%',
            maxWidth: '25rem',
            mx: 'auto',
          },
        },
        postAnyway: {
          id: 'posts.offerPost.postAnyway',
          dm: 'Post Anyway',
          variant: 'formButtonOutlined',
          sx: {
            fontSize: '1rem',
            width: '100%',
            maxWidth: '25rem',
            mx: 'auto',
          },
        },
      },
    };
  }
};

export default MuiOfferForm;

import useAuth from './useAuth';

function usePermissions(component) {
  const { permissions } = useAuth();
  const { view, post, manage } = permissions || {};

  const canView = (component) => view.includes(component);
  const canPost = (component) => post.includes(component);
  const canManage = (component) => manage.includes(component);

  if (component === undefined) return { canView, canPost, canManage };
  return {
    canView,
    canPost,
    canManage,
    viewPermitted: canView(component),
    postPermitted: canPost(component),
    managePermitted: canManage(component),
  };
}

export default usePermissions;

import { Flex, Select } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentEmergencyId } from '../../reducers/dataReducer';
import { useIntl } from 'react-intl';
import useGetData from '../../hooks/useGetData';
import { convertArrayToObject } from '../../utils/convertArrayToObject';

function EmergencySelector({ ...rest }) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { data: emergencies, loading } = useGetData({
    endpoint: '/emergencies',
    initialValue: {},
    dataMapper: convertArrayToObject,
  });
  const { currentEmergencyId } = useSelector((state) => state.data);
  const { locale } = useSelector((state) => state.intl);

  const handleChange = (e) => {
    dispatch(setCurrentEmergencyId(e.target.value));
  };

  return (
    <Flex {...rest} minHeight="40px">
      <Select
        aria-label={formatMessage({
          id: 'navbar.emergency.filter.selectemergency',
          defaultMessage: 'Select Emergency',
        })}
        backgroundColor="newBlack.500"
        color="white"
        variant="filled"
        onChange={handleChange}
        value={currentEmergencyId}
        isDisabled={loading}
        _focus={{
          boxShadow: '0 0 0 3px rgba(66,153,225,0.6)',
          backgroundColor: 'newBlack.500',
          '& option': {
            backgroundColor: 'newBlack.500',
          },
        }}
        _hover={{
          backgroundColor: 'newBlack.300',
        }}
      >
        <option value="null" disabled>
          Select emergency
        </option>
        {Object.values(emergencies).map((emergency) => (
          <option value={emergency._id} key={emergency._id}>
            {emergency[locale + '_title']}
          </option>
        ))}
      </Select>
    </Flex>
  );
}

export default EmergencySelector;

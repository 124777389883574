import { Collapse, Box, Stack, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import NavItemToggle from './NavItemToggle';
import usePermissions from '../../hooks/usePermissions';

const HomeMenuSection = ({ onClick, onClose, isOpen, showHome }) => {
  const intl = useIntl();
  const { canView } = usePermissions();

  const links = [
    {
      path: '',
      textId: 'dashboard',
      viewPermitted: true,
    },
    {
      path: 'demand-posts',
      textId: 'demandposts',
      viewPermitted: canView('0'),
    },
    {
      path: 'supply-posts',
      textId: 'supplyposts',
      viewPermitted: canView('0'),
    },
    {
      path: 'suppliers',
      textId: 'suppliers',
      viewPermitted: canView('2'),
    },
    {
      path: 'experts',
      textId: 'experts',
      viewPermitted: canView('7'),
    },
    {
      path: 'biosafety-labs',
      textId: 'biosafetylabs',
      viewPermitted: canView('5'),
    },
    {
      path: 'regulations',
      textId: 'regulations',
      viewPermitted: canView('4'),
    },
  ];

  return (
    <>
      <Box
        onClick={onClick}
        cursor="pointer"
        _hover={{ bg: '#ccc', color: '#333' }}
        p={1}
      >
        <Box
          as="button"
          onClick={onClick}
          aria-expanded={isOpen}
          fontSize="md"
          ml={2}
          mb={0}
          mt={2}
        >
          <Stack isInline>
            <Text mt={1}>
              {showHome ? <BsCaretDownFill /> : <BsCaretRightFill />}
            </Text>
            <Text textAlign="left">
              {intl.formatMessage({ id: 'navbar.navitem.category.home' })}
            </Text>
          </Stack>
        </Box>
      </Box>
      <Collapse in={showHome} width="100%">
        <ul>
          {links.map(({ path, textId, viewPermitted }) => (
            <Fragment key={textId}>
              {viewPermitted && (
                <NavItemToggle
                  path={`/${path}`}
                  onClose={onClose}
                  linkName={intl.formatMessage({
                    id: `navbar.navitem.home.${textId}`,
                  })}
                />
              )}
            </Fragment>
          ))}
          {/* <NavItemToggle
            path="/"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.dashboard',
            })}
          />
          <NavItemToggle
            path="/demand-posts"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.demandposts',
            })}
          />
          <NavItemToggle
            path="/supply-posts"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.supplyposts',
            })}
          />
          <NavItemToggle
            path="/suppliers"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.suppliers',
            })}
          />
          <NavItemToggle
            path="/experts"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.experts',
            })}
          />
          <NavItemToggle
            path="/biosafety-labs"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.biosafetylabs',
            })}
          />
          <NavItemToggle
            path="/regulations"
            onClose={onClose}
            linkName={intl.formatMessage({
              id: 'navbar.navitem.home.regulations',
            })}
          /> */}
        </ul>
      </Collapse>
    </>
  );
};

export default HomeMenuSection;

import React, { Suspense, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import NavBar from '../header/NavBar';
import usePermissions from '../../hooks/usePermissions';
import useAsyncState from '../../hooks/useAsyncState';
import PageLoadingIcon from '../authentication/PageLoadingIcon';
import ThemeWrapper from '../wrappers/ThemeWrapper';
import ProgressBar from './ProgressBar';
import { Route, useLocation } from 'react-router-dom';
import Flex from '../common/Flex';
import useBreakpoint from '../../hooks/useBreakpoint';
import MuiLoading from './MuiLoading';
import useScrollToTop from '../../hooks/useScrollToTop';
import { refHeight } from '../../utils/nodeInfo';
import SellerUpgradeBanner from '../header/SellerUpgradeBanner';
import useAuth from '../../hooks/useAuth';
import MuiRedirect from '../authentication/MuiRedirect';
import useResizeRef from '../../hooks/useResizeRef';
import MuiFooter from '../footer/MuiFooter';

export const NavSizeContext = React.createContext();
export const ScrollContext = React.createContext();

const Page = ({
  page: Page,
  componentId,
  isProtected,
  noNavBar,
  noFooter,
  path,
  adminOnly,
  excludedRoles = [],
  requiredRoles, // = []
  ...rest
}) => {
  const disableScrollRef = useRef(false);
  const [scrollOptions, setScrollOptions] = useAsyncState({});
  const { isAuthenticated, isLoading, isAdmin, user } = useAuth();
  const { viewPermitted } = usePermissions(componentId);
  const {
    sizeRef: headerRef,
    sizeNode: headerNode,
    refresh: headerRefresh,
    sizeState: headerSize,
  } = useResizeRef();
  const navHeight = refHeight(headerRef);
  const pageRef = useRef(null);
  const upgradeBannerRef = useRef(null);
  const progressBarRef = useRef(null);
  const { md } = useBreakpoint();
  const bottomNavRef = useRef(null);
  const bottomNavHeight = refHeight(bottomNavRef);
  const footerRef = useRef(null);
  const { pathname } = useLocation();
  const { scrollToTop } = useScrollToTop({ scrollRef: pageRef });
  const [pageBottomPadding, setPageBottomPadding] = useAsyncState(0); // #px

  useEffect(() => {
    if (disableScrollRef.current) return;
    scrollToTop({ ...scrollOptions });
  }, [pathname, scrollToTop, scrollOptions]);

  const { style } = makeProps({
    md,
    navHeight,
    bottomNavHeight,
    pageBottomPadding,
  });

  return (
    <NavSizeContext.Provider
      value={{
        headerRef: { current: headerNode },
        pageRef,
        bottomNavRef,
        footerRef,
        setPageBottomPadding,
        upgradeBannerRef,
        progressBarRef,
        headerRefresh,
        headerSize,
      }}
    >
      <ScrollContext.Provider
        value={{ disableScrollRef, scrollOptions, setScrollOptions }}
      >
        <Flex {...style.pageContainer} ref={pageRef}>
          <Box {...style.header} ref={headerRef}>
            <SellerUpgradeBanner {...{ upgradeBannerRef, headerRefresh }} />
            <NavBar {...{ bottomNavRef }} />
            <ProgressBar {...{ progressBarRef }} />
          </Box>
          <Box {...style.bodyBox}>
            {isLoading ? (
              <ThemeWrapper>
                <PageLoadingIcon />
              </ThemeWrapper>
            ) : (componentId && !viewPermitted) || (adminOnly && !isAdmin) ? (
              <MuiRedirect {...{ message: 'notViewPermitted' }} />
            ) : isProtected && !isAuthenticated ? (
              <MuiRedirect />
            ) : excludedRoles.includes(user?.roles) ? (
              <MuiRedirect {...{ message: `excludeRole${user?.roles}` }} />
            ) : requiredRoles &&
              !(requiredRoles || []).includes(user?.roles) ? (
              <MuiRedirect
                {...{ message: `requiredRole${requiredRoles?.[0]}` }}
              />
            ) : (
              <Suspense fallback={<MuiLoading {...style.fallbackLoading} />}>
                <Route
                  {...{ path, ...rest }}
                  render={(props) => <Page {...props} />}
                />
              </Suspense>
            )}
          </Box>
          <MuiFooter {...{ noFooter, footerRef }} />
        </Flex>
      </ScrollContext.Provider>
    </NavSizeContext.Provider>
  );

  function makeProps({ md, navHeight, bottomNavHeight, pageBottomPadding }) {
    return {
      style: {
        pageContainer: {
          direction: 'column',
          sx: {
            backgroundColor: 'background.main',
            height: '100vh',
            overflowY: 'auto',
            ...(md && {
              pb: `${(bottomNavHeight || 64) + pageBottomPadding}px`,
            }),
          },
        },
        header: {
          sx: {
            flex: '0',
            position: 'sticky',
            top: '0',
            zIndex: '5',
            backgroundColor: 'card.main',
          },
        },
        bodyBox: { sx: { flex: 'auto' } },
        fallbackLoading: {
          sx: { minHeight: `calc(100vh - ${navHeight}px  - 8rem)` },
          sxCircle: { my: 'auto' },
        },
      },
    };
  }
};

export default Page;

import { useEffect } from 'react';

const useResizeListener = ({
  resizeListener, // must be memoized
  debugMode = false,
}) => {
  useEffect(() => {
    if (debugMode) console.log('Add Listener');
    window.addEventListener('resize', resizeListener);
    return () => {
      if (debugMode) console.log('Remove Listener');
      window.removeEventListener('resize', resizeListener);
    };
  }, [resizeListener, debugMode]);
};

export default useResizeListener;

export const tagParser = (tags) => {
  if (tags === '') {
    return null;
  }

  const output = [];
  tags.split(',').forEach((tag) => {
    output.push(tag.trim());
  });

  return output;
};

export const appendArray = (formData, array, key) =>
  !!array &&
  array.forEach((value, index) => formData.append(`${key}[${index}]`, value));

export const appendObject = (formData, object, objKey) =>
  !!object &&
  Object.entries(object).forEach(([key, value]) => {
    formData.append(`${objKey}[${key}]`, value);
  });

export const appendArrayOfObjects = (formData, objectArray, arrayKey) =>
  !!objectArray &&
  objectArray.forEach(
    (objectEntry, index) =>
      !!objectEntry &&
      Object.entries(objectEntry).forEach(([key, value]) =>
        formData.append(`${arrayKey}[${index}][${key}]`, `${value}`),
      ),
  );

export const formDataFormatter = (values) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    if (
      [
        'tags',
        'categories',
        'emergencies',
        'posts',
        'item_types',
        'promoted_products',
        'product_ids',
        'user_ids',
      ].includes(key)
    ) {
      appendArray(formData, value, key);
    } else if (['location', 'shipping_address'].includes(key)) {
      appendObject(formData, value, key);
    } else if (key === 'uploadPhoto') {
      formData.append('photo_url', value);
    } else if (key === 'photo_url') {
      formData.append('photo_url', value);
    } else if (key === 'post_photo_url') {
      formData.append('photo_url', value?.[0]);
    } else if (key === 'product_specifications') {
      if (value.length) appendArrayOfObjects(formData, value, key);
      // value.forEach(({ property, value }, index) => {
      //   formData.append(
      //     `product_specifications[${index}][property]`,
      //     `${property}`,
      //   );
      //   formData.append(
      //     `product_specifications[${index}][value]`,
      //     `${value}`,
      //   );
      // });
      else formData.append(`product_specifications`, []);
    } else if (key === 'product_certifications') {
      if (value.length) appendArray(formData, value, key);
      else formData.append(`product_certifications`, []);
    }
    // else if (key === 'mapping') {
    //   formData.append('mapping', JSON.parse(JSON.stringify(value)));
    // }
    else if (key === 'pluginModule') {
      formData.append('plugin_module', value);
    } else if (['images', 'media_images', 'certification'].includes(key)) {
      Array.prototype.forEach.call(value, function (file, index) {
        formData.append(`${key}[${index}]`, file);
      });
    } else if (key === 'product_support_docs') {
      Array.prototype.forEach.call(value, (file, index) => {
        formData.append(`product_support_docs[${index}]`, file);
      });
    } else if (key === 'disputer_attachments') {
      Array.prototype.forEach.call(value, (file, index) => {
        formData.append(`disputer_attachments[${index}]`, file);
      });
    } else if (key === 'message_attachment') {
      Array.prototype.forEach.call(value, (file) => {
        formData.append(`attachment`, file);
      });
    } else if (key === 'counter_offers') {
      Array.prototype.forEach.call(value, (offer, index) => {
        appendObject(formData, offer, `${key}[${index}]`);
      });
    } else if (key === 'cart_items') {
      value.forEach((item, index) => {
        appendObject(formData, item, `cart_items[${index}]`);
      });
    } else if (key === 'certification') {
      value.forEach((file, index) =>
        formData.append(`certification[${index}]`, file),
      );
    } else if (key === 'media_images') {
      value.forEach((file, index) =>
        formData.append(`media_images[${index}]`, file),
      );
    } else if (key === 'sale_taxes_applied') {
      value.forEach((tax, index) =>
        appendObject(formData, tax, `sale_taxes_applied[${index}]`),
      );
    } else if (key === 'logo') {
      formData.append(`logo`, value);
    } else if (key === 'header_image') {
      formData.append(`header_image`, value);
    } else if (key === 'start_date' || key === 'end_date') {
      let date = new Date(value);
      formData.append(key, date.toISOString());
    } else if (key === 'isOneUsagePerUser') {
      formData.append(key, value === true ? 1 : 0);
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

export const RFQformDataFormatter = (values) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    if (
      ['tags', 'categories', 'emergencies', 'posts', 'item_types'].includes(key)
    ) {
      appendArray(formData, value, key);
    } else if (key === 'sale_taxes_applied') {
      appendArrayOfObjects(formData, value, key);
    } else if (key === 'shipping_address') {
      appendObject(formData, value, key);
    } else if (key === 'suppliers') {
      appendArray(formData, [value], key);
    } else if (key === 'uploadPhoto') {
      formData.append('photo_url', value);
    } else if (key === 'pluginModule') {
      formData.append('plugin_module', value);
    } else if (key === 'images') {
      Array.prototype.forEach.call(value, function (file, index) {
        formData.append(`images[${index}]`, file);
        formData.append(`attachments[${index}]`, file);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

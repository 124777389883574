const isObject = (a) => !!a && a.constructor === Object;

export const mergeProps = (moreProps, defaults) => ({
  ...defaults,
  ...moreProps,
  ...Object.entries(defaults || {}).reduce((subProps, [key, value]) => {
    if (isObject(value)) subProps[key] = mergeProps(moreProps?.[key], value);
    return subProps;
  }, {}),
});

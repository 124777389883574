import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { supplierMapper } from '../../utils/fetchMapper';
import tryClient from '../../utils/tryClient';
import useAuth from '../../hooks/useAuth';
import {
  setLocale,
  setSubdomainStoreId,
  setSubdomainStoreObject,
  setSubdomainValue,
  setisLoadingStore,
} from '../../reducers/intlReducer';

function IntlWrapper({ children }) {
  // Regular Expression for testing whether there is a subdomain
  const regexCheckSubdomain = /^(http(s)?:\/\/)?([a-z0-9]+\.){1}[a-z]+\.[a-z]{2,}\/?.*$/;

  const dispatch = useDispatch();

  // Set language to arabic
  if (window.location.href.includes('Setlang=ar')) {
    // document.documentElement.dir = 'rtl';

    localStorage.setItem('locale', 'ar');
    // document.body.style.float = 'right';
    // document.html.style.float = 'right';
    dispatch(setLocale('ar'));
  }

  const currentURL = new URL(window.location.href);
  const hasSubdomain = regexCheckSubdomain.test(currentURL);

  // Check if you are on a subdomain store site
  if (hasSubdomain) {
    // Splitting the url to get the store subdomain value
    const hostnameArr = currentURL.hostname.split('.');
    const subdomainValue =
      hostnameArr[0] === 'www' ? hostnameArr[1] : hostnameArr[0];

    // Storing the Subdomain ID to React Redux
    dispatch(setSubdomainValue(subdomainValue));
  } else {
    // Freeing the Redux Store
    dispatch(setSubdomainStoreId(null));
    dispatch(setSubdomainStoreObject(null));
    dispatch(setisLoadingStore(false));
    dispatch(setSubdomainValue(null));
  }

  const { locale, en, fr, ar, subdomainValue } = useSelector(
    ({ intl }) => intl,
  );
  const { isAuthenticated, user } = useAuth();
  const { languageCode } = user || {};
  const dir = locale.includes('ar') ? 'rtl' : 'ltr';
  document.documentElement.dir = dir;
  document.body.setAttribute('dir', dir);

  useEffect(() => {
    async function fetchStoreAPI() {
      // Loading the Subdomain store info and store  it in Redux Store
      dispatch(setisLoadingStore(true));

      let tempObj = await tryClient(
        `/suppliers/findBySubdomain/${subdomainValue}`,
      );
      let storeObj = null;
      if (tempObj) {
        storeObj = supplierMapper(tempObj?.response?.data[0]);
      }

      dispatch(setSubdomainStoreId(storeObj?.id));
      dispatch(setSubdomainStoreObject(storeObj));
      dispatch(setisLoadingStore(false));
    }
    if (subdomainValue) {
      fetchStoreAPI();
    }
  }, [dispatch, subdomainValue]);

  useEffect(() => {
    window.document.documentElement.lang = locale;
  }, [locale]);

  useEffect(() => {
    if (isAuthenticated && languageCode) {
      // dispatch(setLocale(languageCode));
    }
  }, [dispatch, isAuthenticated, languageCode]);

  return (
    <IntlProvider
      {...{
        locale,
        defaultLocale: 'en',
        messages: locale === 'en' ? en : locale === 'fr' ? fr : ar,
      }}
    >
      {children}
    </IntlProvider>
  );
}

export default IntlWrapper;

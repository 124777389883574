import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useAsyncState from '../../hooks/useAsyncState';
import useAuth from '../../hooks/useAuth';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import useSnackbar from '../../hooks/useSnackbar';
import { checkMe } from '../../reducers/authReducer';
import client from '../../utils/client';
import { mergeProps } from '../../utils/mergeProps';
import FollowSignupModal from '../authentication/FollowSignupModal';
import RestrictedButton from '../common/RestrictedButton';
import NewInvitationForm from '../invitations/NewInvitationForm';
import SellerFollowModal from '../sellers/SellerFollowModal';

const FollowButton = ({
  supplierId,
  id,
  seller,
  followProps = {},
  unfollowProps = {},
  modalControl: givenModalControl,
  startOpen,
  ...props
}) => {
  const { user, isAuthenticated } = useAuth();
  const { snackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useAsyncState(false);
  const defaultModalControl = useMuiModalControl(
    !isAuthenticated && !!startOpen,
  );
  const signupModalControl = givenModalControl || defaultModalControl;
  const inviteModalControl = useMuiModalControl();
  const { open: inviteOpen } = inviteModalControl;
  const [sellerStartedInvite, setSellerStartedInvite] = useState(false);

  useEffect(() => {
    if (inviteOpen) return;
    setSellerStartedInvite(false);
  }, [inviteOpen]);

  const { roles, followedSuppliers } = user || {};
  const followId = seller?.id || id || supplierId;
  const isFollowing = followedSuppliers?.map(({ id }) => id).includes(followId);

  const handleFollow = async () => {
    if (!followId) return;
    const endpoint = `/users/${isFollowing ? 'un' : ''}follow/${followId}`;
    setLoading(true);
    try {
      await client(endpoint, { method: 'POST' });
      await dispatch(checkMe());
    } catch (_) {
      snackbar({ message: 'errorFollow', options: { isFollowing } });
    }
    setLoading(false);
  };

  const { text, style } = makeProps({
    isFollowing,
    followProps,
    unfollowProps,
    props,
    signupModalControl,
    sellerStartedInvite,
    setSellerStartedInvite,
    inviteModalControl,
  });
  return (
    <>
      <RestrictedButton
        {...{ ...text, ...style, loading }}
        onClick={handleFollow}
      />
      <FollowSignupModal
        {...{ modalControl: signupModalControl, seller, followId }}
      />
    </>
  );

  function makeProps({
    isFollowing,
    followProps,
    unfollowProps,
    props,
    signupModalControl,
    sellerStartedInvite,
    setSellerStartedInvite,
    inviteModalControl,
  }) {
    const whiteContainedText = ({ variant } = {}) =>
      variant === 'contained' && { sxText: { color: 'card.main' } };

    return {
      text: isFollowing
        ? { id: 'unfollow.button', dm: 'Follow' }
        : { id: 'follow.button', dm: 'Unfollow' },
      style: mergeProps(
        mergeProps(isFollowing ? unfollowProps : followProps, props),
        {
          variant: 'outlined',
          modalControl: inviteModalControl,
          ...(sellerStartedInvite && {
            overRideModalProps: {
              cardProps: {
                sx: { width: '100%', maxWidth: 'min(95vw, 50rem)' },
              },
              modalBody: (
                <NewInvitationForm {...{ modalControl: inviteModalControl }} />
              ),
            },
          }),
          sx: { width: '100%', fontSize: '1rem', alignItems: 'center' },
          ...whiteContainedText(props),
          ...(isFollowing
            ? { color: 'bodyText', ...whiteContainedText(unfollowProps) }
            : {
                ...whiteContainedText(followProps),
                restrictions: [
                  {
                    condition: isAuthenticated,
                    action: signupModalControl.handleOpen,
                  },
                  {
                    condition: roles === '3',
                    modal: {
                      modalBody: (
                        <SellerFollowModal
                          {...{ onClick: () => setSellerStartedInvite(true) }}
                        />
                      ),
                    },
                  },
                ],
              }),
        },
      ),
    };
  }
};

export default FollowButton;

import React from 'react';
import MuiLink from '../common/MuiLink';
import mainLogo from '../../data/iconFiles/main-logo.svg';
import useLocale from '../../hooks/useLocale';

const Logo = () => {
  const { attemptFormat } = useLocale();

  const text = {
    aria: attemptFormat({
      id: 'tradvo.logoAria',
      dm: 'Website Logo',
    }),
    alt: attemptFormat({
      id: 'tradvo.logoLink',
      dm: 'Tradvo Logo and home link',
    }),
  };

  const style = {
    link: {
      nonText: true,
      sx: {
        display: 'flex',
        alignItems: 'center',
        mr: '1rem',
        flexShrink: '0',
      },
    },
    logo: { style: { width: '6.375rem' } },
  };

  return (
    <MuiLink to="/" aria-label={text.aria} {...style.link}>
      <img src={mainLogo} alt={text.alt} {...style.logo} />
    </MuiLink>
  );
};

export default Logo;

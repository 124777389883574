import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import useAuth from '../../hooks/useAuth';
import MessageProvider from './MessageProvider';
import NotificationProvider from './NotificationProvider';

const SocketContext = React.createContext();

const socketUrl = process.env.REACT_APP_SOCKET_URL;
const socketPath = process.env.REACT_APP_SOCKET_PATH;
//console.log(socketUrl);
//console.log(socketPath);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth();
  const id = user?.id;
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (!isAuthenticated) return;
    const newSocket = io(socketUrl, {
      path: socketPath,
      transports: ['websocket'],
    });
    // const newSocket = io(socketUrl);
    //console.log('connecting');
    newSocket.on('connect', () => {
      //  console.log('connected');
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, [isAuthenticated]);

  useEffect(() => {
    // console.log('setting');
    if (socket && id) {
      //  console.log('setting if');
      socket.emit('set-online', { sender_id: id });
    }
  }, [socket, id]);

  return (
    <SocketContext.Provider value={{ socket }}>
      <NotificationProvider>
        <MessageProvider>{children}</MessageProvider>
      </NotificationProvider>
    </SocketContext.Provider>
  );
};

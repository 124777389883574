import React, { forwardRef } from 'react';
import { Grid, Skeleton } from '@mui/material';
import Flex from './Flex';

const MuiCard = forwardRef(
  (
    {
      children,
      sx,
      loading,
      sxSkeleton,
      skeletonProps,
      container,
      containerProps,
      item,
      itemProps,
      cardRef,
      ...rest
    },
    ref,
  ) => {
    const style = {
      card: {
        sx: {
          borderRadius: '10px',
          backgroundColor: 'card.main',
          mb: '1rem',
          px: '1.5rem',
          py: '2rem',
          ...sx,
        },
        ...rest,
      },
      skeleton: {
        variant: 'rectangular',
        sx: {
          borderRadius: '10px',
          width: sx?.width || '100%',
          height: sx?.height || '100%',
          ...sx,
          boxShadow: 'none',
          ...sxSkeleton,
        },
        ...skeletonProps,
      },
    };

    return loading ? (
      <Skeleton {...style.skeleton} />
    ) : container || containerProps ? (
      <Grid
        {...{
          container,
          ...containerProps,
          ...style.card,
          ...(ref && { ref }),
        }}
      >
        {children}
      </Grid>
    ) : item || itemProps ? (
      <Grid
        {...{
          item: true,
          ...itemProps,
          ...(ref && { ref }),
          ...(cardRef && { ref: cardRef }),
        }}
      >
        <Flex {...{ direction: 'column', ...style.card }}>{children}</Flex>
      </Grid>
    ) : (
      <Flex
        {...{
          direction: 'column',
          ...style.card,
          ...(ref && { ref }),
          ...(cardRef && { ref: cardRef }),
        }}
      >
        {children}
      </Flex>
    );
  },
);

export default MuiCard;

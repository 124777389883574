import React, { useRef } from 'react';
import { CircularProgress, InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';
import { POSTAL_CODE_REGEX, ZIP_CODE_REGEX } from '../../data/constants';
import useAsyncState from '../../hooks/useAsyncState';
import useLocale from '../../hooks/useLocale';
import { mergeProps } from '../../utils/mergeProps';
import tryClient from '../../utils/tryClient';
import MuiFormField from './MuiFormField';

const PostalCodeField = ({
  initialValue,
  loadingState: givenLoadingState,
  name = 'postalCode',
  errorMessage: givenErrorMessage,
  updateMap = {
    city: 'city',
    province_short: 'province',
    country: 'country',
    country_short: 'countryShort',
  },
  ...rest
}) => {
  const { attemptFormat } = useLocale();
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const internalLoadingState = useAsyncState(false);
  const [locationLoading, setLocationLoading] =
    givenLoadingState || internalLoadingState;
  const [warning, setWarning] = useAsyncState('');
  const postalCodeRef = useRef(initialValue || '');

  const updateWarning = (newWarning) =>
    setWarning(attemptFormat(newWarning || ''));

  const validatePostalCode = async (value) => {
    if (value === '') return updateWarning();
    if (value === postalCodeRef.current) return;

    postalCodeRef.current = value;
    const postalMatch = POSTAL_CODE_REGEX.test(value);
    const zipMatch = ZIP_CODE_REGEX.test(value);

    if (!postalMatch && !zipMatch) return updateWarning();

    setLocationLoading(true);
    const { resp, err } = await tryClient('/get-address/postal-code', {
      method: 'POST',
      body: { postal_code: value },
    });
    setLocationLoading(false);
    updateWarning(
      err && {
        id: 'forms.postalCode.warning',
        dm: 'Enter Correct Post/ZIP Code to automatically find your address',
      },
    );
    setFieldTouched(name, true);
    const location = resp?.data?.details;
    if (location)
      Object.entries(updateMap).forEach(([key, field]) =>
        setFieldValue(field, location[key] || ''),
      );
  };

  const { style } = makeProps({ locationLoading, name, setFieldValue });
  return (
    <MuiFormField
      {...style.field({ validate: validatePostalCode, warning, ...rest })}
    />
  );

  function makeProps({ locationLoading, name, setFieldValue }) {
    return {
      style: {
        field: (props) =>
          mergeProps(props, {
            name,
            label: { id: `forms.${name}`, dm: name },
            onChange: ({ target }) =>
              setFieldValue(name, target?.value?.toUpperCase() || ''),
            ...(locationLoading && {
              InputProps: {
                endAdornment: (
                  <InputAdornment {...{ position: 'end' }}>
                    <CircularProgress
                      {...{ size: '1.25rem', color: 'primary' }}
                    />
                  </InputAdornment>
                ),
              },
            }),
          }),
      },
    };
  }
};

export default PostalCodeField;

import { useState, useCallback } from 'react';

function useToggle(initialVal = false) {
  const [state, setState] = useState(initialVal);

  const toggle = useCallback((input) => {
    setState((current) => input ?? !current);
  }, []);

  return [state, toggle];
}

export default useToggle;

import React, { useCallback } from 'react';
import Flex from '../common/Flex';
import MuiCard from '../common/MuiCard';
// import MuiText from '../intl/MuiText';
import FollowButton from './FollowButton';
import MuiNamedLink from '../common/MuiNamedLink';
import useBreakpoint from '../../hooks/useBreakpoint';
import ImageBox from '../common/ImageBox';
import { supplierMapper } from '../../utils/fetchMapper';
import useGetData from '../../hooks/useGetData';
// import LocationText from '../common/LocationText';
// import MuiRating from '../common/MuiRating';
import MessageSellerButton from '../RFQ/MessageSellerButton';

const TinyCard = ({
  business,
  seller,
  supplier,
  avatarSize = 8.5,
  loading: givenLoading,
  supplierId,
}) => {
  const { md } = useBreakpoint();
  const givenBusiness = business || seller || supplier;

  const { data, loading: apiLoading } = useGetData({
    endpoint: !givenBusiness && supplierId && `/suppliers/${supplierId}`,
    dataHandler: useCallback(({ data }) => supplierMapper(data), []),
  });
  const actualBusiness = givenBusiness || data;
  const {
    logoImage,
    name,
    // location = {},
    // employmentSize,
    id,
    // rating,
    // ratingCount,
    planName,
  } = actualBusiness;

  const loading = givenLoading || apiLoading;

  const { style } = makeProps({
    loading,
    md,
    avatarSize,
    actualBusiness,
    planName,
  });
  return (
    <MuiCard {...style.card}>
      <Flex {...style.imageAndInfo}>
        <ImageBox {...{ ...style.image, image: logoImage }} />
        <Flex {...style.infoStack}>
          <MuiNamedLink {...{ business: actualBusiness, ...style.name }}>
            {name}
          </MuiNamedLink>
          {/* <LocationText
            {...{
              location,
              loading,
              locationTextBoxProps: { sx: { mr: 'auto' } },
            }}
            hideStreet
          /> */}
          {/* <MuiRating {...{ rating, ratingCount, ...style.rating }} /> */}
          {/* <MuiText {...style.size}>
            {`${employmentSize}`}
            <MuiText {...text.size} {...style.sizeLabel} />
          </MuiText> */}
        </Flex>
      </Flex>
      <Flex {...style.buttonArea}>
        <FollowButton {...{ ...style.follow, id }} />
        <MessageSellerButton
          {...{ seller: actualBusiness, ...style.contact }}
        />
      </Flex>
    </MuiCard>
  );

  function makeProps({ loading, md, avatarSize, actualBusiness, planName }) {
    return {
      // text: {
      //   foundation: {
      //     id: 'businessProfile.headerCard.foundationYear',
      //     dm: 'Founded in',
      //   },
      //   size: {
      //     id: 'businessProfile.headerCard.employmentSize',
      //     dm: 'employees',
      //   },
      // },
      style: {
        card: { sx: { py: '0.2rem', gap: '0.1rem', m: 0 } },
        imageAndInfo: { sx: { gap: '0.7rem' } },
        infoStack: {
          direction: 'column',
          sx: { gap: '0.4rem', flexGrow: '1', flexShrink: '1' },
        },
        image: {
          size: { xs: '2rem', sm: '2.5rem', md: '3rem', lg: '4rem' },
          variant: 'sellerLogo',
          planName,
          badgeSize: '0.8rem',
          namedLink: { business: actualBusiness, loading },
          ...avatarSize,
        },
        name: {
          lines: 2,
          hoverColor: 'primary.main',
          sxText: {
            color: 'headingText.main',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          },
          loading,
          sxSkeleton: { width: '50%' },
        },
        rating: { loading },
        sizeLabel: { sx: { ml: '0.5rem', fontSize: '0.75rem' } },
        size: {
          sx: { color: 'bodyText.main', fontSize: '0.75rem' },
          loading,
          sxSkeleton: { width: '35%' },
        },
        follow: {
          variant: 'contained',
          followProps: { color: 'primary' },
          unfollowProps: { color: 'bodyText' },
          fontSize: '0.775rem',
          sx: { py: '0.25rem', width: '80%', borderRadius: '10px' },
          disabled: loading,
        },
        contact: {
          modalButtonProps: {
            buttonProps: {
              variant: 'outlined',
              fontSize: '0.775rem',
              sx: {
                py: '0.25rem',
                px: '2.25rem',
                width: '80%',
                borderRadius: '10px',
              },
              disabled: loading,
            },
          },
        },
        tooltip: { sx: { fontSize: '1.125rem' } },
        icon: {
          sx: { color: 'secondary.main', width: '2rem', height: '2rem' },
        },
        buttonArea: {
          direction: { xs: 'column', sm: 'row', md: 'column', lg: 'row' },
          sx: { gap: { xs: '0.1rem', md: '0.2rem' } },
        },
      },
    };
  }
};

export default TinyCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import useHelmet from '../../hooks/useHelmet';
import useLocale from '../../hooks/useLocale';

const MetaDecorator = ({
  title: givenTitle,
  description: givenDescription,
  ogTags = [],
  otherMetaTags = [],
}) => {
  const { attemptFormat } = useLocale();
  const options = givenTitle
    ? { title: givenTitle, description: givenDescription }
    : undefined;

  const { title, description } = useHelmet(options);

  return (
    <Helmet>
      <title>{attemptFormat(givenTitle || title)}</title>
      <meta
        name="description"
        content={attemptFormat(
          givenTitle ? givenDescription || description : description,
        )}
      />
      {ogTags.map(({ property, content }) => (
        <meta
          {...{
            key: property,
            property: `og:${property}`,
            content: attemptFormat(content),
          }}
        />
      ))}
      {otherMetaTags.map(({ name, content }) => (
        <meta {...{ key: name, name, content: attemptFormat(content) }} />
      ))}
    </Helmet>
  );
};

MetaDecorator.prototype = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default MetaDecorator;

import { Box, Stack, Text, Icon, Collapse } from '@chakra-ui/react';
import React, { Fragment, useState } from 'react';
import {
  BsCaretDownFill,
  BsCaretRightFill,
  AiFillTag,
  MdMonetizationOn,
  MdDashboard,
  MdStore,
  MdLightbulbOutline,
} from 'react-icons/all';
import { useIntl } from 'react-intl';
import NavItemToggle from './NavItemToggle';
import { useSelector } from 'react-redux';
import usePermissions from '../../hooks/usePermissions';

const SellerMenuSection = ({ showAdmin }) => {
  const intl = useIntl();
  const { canView } = usePermissions();
  const [activePath, setActionPath] = useState(null);

  /* NavItem toggle */
  const [showContent, setShowContent] = useState(true);
  const [showMarketPlace, setShowMarketPlace] = useState(true);
  const [showInsights, setShowInsights] = useState(true);
  const [showBilling, setShowBilling] = useState(true);

  const handleToggleContent = () => setShowContent(!showContent);
  const handleToggleMarketPlace = () => setShowMarketPlace(!showMarketPlace);
  const handleToggleInsights = () => setShowInsights(!showInsights);
  const handleToggleBilling = () => setShowBilling(!showBilling);

  const handleTabIsOpen = (path) => {
    //Update current path
    setActionPath(path);
    if (content.map((e) => e.path).includes(path)) {
      setShowContent(true);
      setShowMarketPlace(false);
      setShowInsights(false);
      setShowBilling(false);
    } else if (marketplace.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(true);
      setShowInsights(false);
      setShowBilling(false);
    } else if (insights.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(false);
      setShowInsights(true);
      setShowBilling(false);
    } else if (billing.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(false);
      setShowInsights(false);
      setShowBilling(true);
    } else {
      setShowContent(true);
      setShowMarketPlace(true);
      setShowInsights(true);
      setShowBilling(true);
    }
  };
  const { pending: pendingPosts } = useSelector(
    (state) => state.data.statistics.posts || {},
  );
  const { pending: pendingProducts } = useSelector(
    (state) => state.data.statistics.products || {},
  );

  //TODO: add statistics when have statistics
  // const { pending: pendingReviews } = useSelector(
  //   (state) => state.data.statistics.reviews || {},
  // );
  // const { pending: pendingMessages } = useSelector(
  //   (state) => state.data.statistics.messages || {},
  // );
  // const { pending: pendingDisputes } = useSelector(
  //   (state) => state.data.statistics.disputes || {},
  // );
  // const { pending: pendingOrders } = useSelector(
  //   (state) => state.data.statistics.orders || {},
  // );
  // const { pending: pendingRFQs } = useSelector(
  //   (state) => state.data.statistics.rfqs || {},
  // );
  // const { pending: pendingCoupons } = useSelector(
  //   (state) => state.data.statistics.coupons || {},
  // );
  // const { pending: pendingInvoices } = useSelector(
  //   (state) => state.data.statistics.invoices || {},
  // );
  // const { pending: pendingTransactions } = useSelector(
  //   (state) => state.data.statistics.transactions || {},
  // );
  const dashboard = {
    path: '',
    textId: 'dashboard',
    viewPermitted: true,
    count: 0,
  };
  const content = [
    {
      path: 'my-posts',
      textId: 'posts',
      viewPermitted: canView('0'),
      count: pendingPosts,
    },
    {
      path: 'my-replies',
      textId: 'replies',
      viewPermitted: canView('3'), //TODO: no site_components yet
      count: 0,
    },
    {
      path: 'my-messages',
      textId: 'messages',
      viewPermitted: canView('6'), //TODO: no site_components yet
      count: 0,
    },
    {
      path: 'my-ads',
      textId: 'ads',
      viewPermitted: canView('11'),
      count: 0, //pendingAds,
    },
  ];
  const marketplace = [
    {
      path: 'my-products',
      textId: 'products',
      viewPermitted: canView('8'),
      count: pendingProducts,
    },
    {
      path: 'my-orders',
      textId: 'orders',
      viewPermitted: canView('9'),
      count: 0, //pendingOrders,
    },
    {
      path: 'my-reviews',
      textId: 'reviews',
      viewPermitted: canView('16'),
      count: 0, //pendingReviews,
    },
    {
      path: 'my-disputes',
      textId: 'disputes',
      viewPermitted: canView('12'),
      count: 0, //pendingDisputes,
    },
    {
      path: 'my-RFQs',
      textId: 'rfqs',
      viewPermitted: canView('10'),
      count: 0, //pendingRFQs,
    },
    {
      path: 'my-coupons',
      textId: 'coupons',
      viewPermitted: canView('8'), //TODO: no site_components yet
      count: 0, //pendingCoupons,
    },
  ];
  const insights = [
    {
      path: 'my-forecasting',
      textId: 'forecasting',
      viewPermitted: canView('8'), //TODO: no site_components yet
      count: 0,
    },
  ];
  const billing = [
    {
      path: 'my-invoices',
      textId: 'invoices',
      viewPermitted: canView('8'), //TODO: no site_components yet
      count: 0, //pendingInvoices,
    },
    {
      path: 'my-transactions',
      textId: 'transactions',
      viewPermitted: canView('8'), //TODO: no site_components yet
      count: 0, //pendingTransactions,
    },
    {
      path: 'my-payment-method',
      textId: 'paymentMethod',
      viewPermitted: canView('8'), //TODO: no site_components yet
      count: 0,
    },
  ];
  return (
    showAdmin && (
      <>
        <Box
          as="nav"
          role="navigation"
          bg="blue.700"
          color="white"
          p={3}
          pl={2}
        >
          <Box fontSize="md" ml={0} mb={0}>
            <Stack isInline>
              <Icon mt={3} as={MdDashboard} w={6} h={6} />
              <Box>
                {dashboard.viewPermitted && (
                  <NavItemToggle
                    path={`/seller-panel/${dashboard.path}`}
                    tab={dashboard.path}
                    handleTabIsOpen={handleTabIsOpen}
                    isOpen={activePath === 'dashboard' ? true : false}
                    linkName={intl.formatMessage({
                      id: `navbar.navitem.seller.${dashboard.textId}`,
                    })}
                    count={dashboard.count === 0 ? '' : dashboard.count}
                    style={{
                      ml: '0px',
                      mt: '4px',
                      pl: '0px',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Box>
          <Box fontSize="md" mt={0}>
            <Stack isInline>
              <Icon as={MdStore} w={6} h={6} />
              <Box flex={4} onClick={handleToggleMarketPlace}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.marketplace',
                    defaultMessage: 'Marketplace',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={1} onClick={handleToggleMarketPlace}>
                {showMarketPlace ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showMarketPlace}>
              {marketplace.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/seller-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.seller.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6, color: 'white' }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline width="100%">
              <Icon as={AiFillTag} w={6} h={6} />
              <Box flex={4} onClick={handleToggleContent}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.content',
                    defaultMessage: 'Content',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={1} onClick={handleToggleContent}>
                {showContent ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showContent}>
              {content.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/seller-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.seller.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6, color: 'white' }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline>
              <Icon as={MdLightbulbOutline} w={6} h={6} />
              <Box flex={4} onClick={handleToggleInsights}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.insights',
                    defaultMessage: 'Insights',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={1} onClick={handleToggleInsights}>
                {showInsights ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showInsights}>
              {insights.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/seller-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.seller.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6, color: 'white' }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline>
              <Icon as={MdMonetizationOn} w={6} h={6} />
              <Box flex={4} onClick={handleToggleBilling}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.billing',
                    defaultMessage: 'Billing',
                  })}
                </Text>
              </Box>
              <Box flex={1} pb={0} onClick={handleToggleBilling}>
                {showBilling ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showBilling}>
              {billing.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/seller-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.seller.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6, color: 'white' }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
        </Box>
      </>
    )
  );
};

export default SellerMenuSection;

import React, { forwardRef } from 'react';
import MuiLink from './MuiLink';
import { formatNamedLink } from '../../utils/formatNamedLink';

const MuiNamedLink = forwardRef(
  (
    {
      product,
      listing,
      category,
      business,
      supplier,
      seller,
      post,
      feed,
      userProfile,
      tagLink,
      to,
      append,
      ...rest
    },
    ref,
  ) => {
    const formattedLink = formatNamedLink({
      product,
      listing,
      category,
      business,
      supplier,
      seller,
      post,
      feed,
      userProfile,
      tagLink,
      to,
      append,
    });

    return (
      <MuiLink
        {...{
          ...(formattedLink && { to: formattedLink }),
          ...(ref && { ref }),
          ...rest,
        }}
      />
    );
  },
);

export default MuiNamedLink;

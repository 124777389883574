import { createSlice } from '@reduxjs/toolkit';
import client from '../utils/client';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    currentEmergencyId: '',
    emergencies: {},
    itemTypes: {},
    categories: {},
    defaultPermissions: {},
    siteComponents: [],
    roles: [],
    users: [],
    products: [],
    statistics: {},
    suppliers: {},
    dashboardCount: {},
    searchText: '',
    serviceFeePercent: 0,
    nonRefundableServiceFeePercent: 0,
    isLoading: true,
  },
  reducers: {
    addEmergency(state, action) {
      state.emergencies[action.payload._id] = action.payload;
      state.emergencies[
        action.payload._id
      ].lastUpdated = new Date().toISOString();
    },
    addCategory(state, action) {
      state.categories[action.payload._id] = action.payload;
      state.categories[
        action.payload._id
      ].lastUpdated = new Date().toISOString();
    },
    setEmergencies(state, action) {
      state.emergencies = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setDefaultPermissions(state, action) {
      state.defaultPermissions = action.payload;
    },
    setSiteComponents(state, action) {
      state.siteComponents = action.payload;
    },
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setItemTypes(state, action) {
      state.itemTypes = action.payload;
    },
    addItemType(state, action) {
      state.itemTypes[action.payload._id] = action.payload;
    },
    // addSupplier(state, action) {
    //   state.suppliers[action.payload._id] = action.payload;
    // },
    setSupplier(state, action) {
      state.suppliers = action.payload;
    },
    setEmergency(state, action) {
      state.currentEmergencyId = action.payload;
    },
    addStatistics(state, action) {
      state.statistics[action.payload.name] = action.payload;
    },
    setStatistics(state, action) {
      state.statistics = action.payload;
    },
    addDashboardCount(state, action) {
      state.dashboardCount[action.payload] = action.payload;
    },
    setDashboardCount(state, action) {
      state.dashboardCount = action.payload;
      state.isLoading = false;
    },
    setCurrentEmergencyId(state, action) {
      state.currentEmergencyId = action.payload;
    },
    setGlobleSearchText(state, action) {
      state.searchText = action.payload;
    },
    setServiceFeePercent(state, action) {
      state.serviceFeePercent = action.payload;
    },
    setNonRefundableServiceFeePercent(state, action) {
      state.nonRefundableServiceFeePercent = action.payload;
    },
  },
});

export const {
  addEmergency,
  addCategory,
  setItemTypes,
  setCategories,
  setDefaultPermissions,
  setSiteComponents,
  setRoles,
  setUsers,
  setProducts,
  setEmergencies,
  addItemType,
  setEmergency,
  setStatistics,
  addStatistics,
  setDashboardCount,
  addDashboardCount,
  setCurrentEmergencyId,
  // addSupplier,
  setSupplier,
  setGlobleSearchText,
  setServiceFeePercent,
  setNonRefundableServiceFeePercent,
} = dataSlice.actions;

export default dataSlice.reducer;

export const fetchStatisticsByCollection = (collection) => async (dispatch) => {
  try {
    let promiseArr = [() => client(`/admin/statistics/${collection}-basic`)];
    if (
      collection === 'posts' ||
      collection === 'replies' ||
      collection === 'products'
    ) {
      promiseArr.push(() => client(`/statistics/posts`));
    }
    const resp = await Promise.all(promiseArr.map((item) => item()));
    const collectionData = resp[0];
    const userCountData = resp[1];
    collectionData.data.name = collection;
    // const { data } = await client(`/admin/statistics/${collection}-basic`);
    dispatch(addStatistics(collectionData.data));
    if (userCountData) {
      //dispatch(setDashboardCount(userCountData.data));
      let products = { products: collectionData.data };
      let updateData = Object.assign(userCountData.data, products);
      dispatch(setDashboardCount({ ...updateData }));
    }
  } catch (e) {}
};

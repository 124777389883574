// Wrapper component for the theme provider. Must be wrapped to enable
// access to the Redux store for dynamic theme changing.

import React from 'react'; //   useState, //  useEffect,
import {
  ChakraProvider,
  //   extendTheme,
  // CSSReset,
  //   ThemeProvider,
} from '@chakra-ui/react';
import {
  ColorModeProvider,
  ColorModeScript,
  LightMode,
} from '@chakra-ui/color-mode';
import { useSelector } from 'react-redux';
import defaultTheme from '../../theme/index';
import { fontSizeMapper } from '../../utils/fontSizeMapper';

function ThemeWrapper({ children, newTheme }) {
  const { fontSize } = useSelector((state) => state.settings);
  const theme = newTheme
    ? { ...newTheme, fontSizes: fontSizeMapper(fontSize) }
    : { ...defaultTheme, fontSizes: fontSizeMapper(fontSize) };

  //   const [theme, setTheme] = useState(newTheme || defaultTheme);

  //   useEffect(() => {
  //     const newTheme = { ...theme, fontSizes: fontSizeMapper(fontSize) };
  //     // newTheme.fontSizes = fontSizeMapper(fontSize);
  //     setTheme(extendTheme(newTheme));
  //     // eslint-disable-next-line
  //   }, [fontSize]);

  return (
    // <ThemeProvider theme={theme}>
    <ChakraProvider {...{ theme }}>
      <ColorModeProvider options={{ useSystsemColorMode: false }}>
        {/* <CSSReset /> */}
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <LightMode>{children}</LightMode>
      </ColorModeProvider>
    </ChakraProvider>
    // </ThemeProvider>
  );
}

export default ThemeWrapper;

import { Modal } from '@mui/material';
import React from 'react';
import MuiCard from './MuiCard';
import MuiModalCloseButton from './MuiModalCloseButton';
import MuiText from '../intl/MuiText';
import useLocale from '../../hooks/useLocale';
import Flex from './Flex';

const MuiModal = ({
  // required
  modalControl, // useMuiModalControl() output
  // optional
  modalProps,
  noClose,
  noBackdropClose,
  closeProps,
  cardProps,
  title, // {string, {id,dm}, <TitleComponent/>}
  titleProps,
  modalBody, // <DetailsComponent />
  children,
  backgroundBlur = '5px',
  blurBoxProps,
}) => {
  const { attemptFormat } = useLocale();
  const { style } = makeProps({ modalControl, noBackdropClose });
  return (
    <>
      <Modal {...style.modal(modalProps)}>
        <MuiCard {...style.card(cardProps)}>
          {!noClose && (
            <MuiModalCloseButton {...style.close(closeProps, modalProps)} />
          )}
          {!!title && (
            <MuiText {...style.title(titleProps)}>
              {attemptFormat(title)}
            </MuiText>
          )}
          {modalBody || children || <></>}
        </MuiCard>
      </Modal>
      {!!backgroundBlur && modalControl.open && (
        <Flex {...style.backgroundBlur(backgroundBlur, blurBoxProps)} />
      )}
    </>
  );

  function makeProps({ modalControl, noBackdropClose }) {
    const { open, handleClose } = modalControl || {};

    return {
      style: {
        modal: (props) => ({
          open,
          onClose: (...closeArgs) => {
            if (noBackdropClose && closeArgs[1] === 'backdropClick') return;
            handleClose(...closeArgs);
          },
          ...props,
        }),
        close: (props, modalProps) => ({
          handleClose: modalProps?.onClose || handleClose,
          ...props,
          sx: {
            position: 'absolute',
            top: '0.25rem',
            right: '0.25rem',
            zIndex: 2,
            ...props?.sx,
          },
        }),
        card: (props) => ({
          customScroll: true,
          ...props,
          sx: {
            m: 0,
            p: { xs: '1rem', md: '2rem 1.5rem' },
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 12,
            width: '0%',
            minWidth: 'min(95vw, 30rem)',
            maxWidth: '95vw',
            maxHeight: '95vh',
            ...props?.sx,
          },
        }),
        title: (props) => ({
          ...props,
          sx: { ...props?.sx },
        }),
        backgroundBlur: (backgroundBlur, props) => ({
          ...props,
          sx: {
            position: 'fixed',
            inset: '0',
            backdropFilter: `blur(${backgroundBlur})`,
            zIndex: 10,
            ...props?.sx,
          },
        }),
      },
    };
  }
};

export default MuiModal;

// src/theme/index.js
//
// Default theme for Chakra UI. Any edits to this file will be applied
// universally across the application.

import { theme } from '@chakra-ui/react';

import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  xs: '420px',
  sm: '672px',
  md: '868px',
  lg: '1120px',
  xl: '1470px',
});

const overrides = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    ...theme.colors,
    background: '#f3f3f3',
    newBlack: {
      50: '#fbf0f2',
      100: '#dcd8d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#282626',
      900: '#150a0d',
    },
    blue: {
      50: '#e1f4ff',
      100: '#b8dbfa',
      200: '#8ec3f1',
      300: '#62abea',
      400: '#0078f0',
      500: '#0069CF',
      600: '#0069CF',
      700: '#025ab3',
      800: '#032846',
      900: '#000e1c',
    },
    // blue: {
    //   50: '#e1f4ff',
    //   100: '#b8dbfa',
    //   200: '#8ec3f1',
    //   300: '#62abea',
    //   400: '#3a93e3',
    //   500: '#2379ca',
    //   600: '#195e9e',
    //   700: '#0e4371',
    //   800: '#032846',
    //   900: '#000e1c',
    // },
    red: {
      50: '#ffe5e5',
      100: '#fbbaba',
      200: '#f28e8e',
      300: '#eb6161',
      400: '#e43535',
      500: '#ca1b1b',
      600: '#9e1314',
      700: '#710c0e',
      800: '#460506',
      900: '#1e0000',
    },
    green: {
      50: '#e1feef',
      100: '#bcf3d6',
      200: '#94e9bb',
      300: '#6ce0a1',
      400: '#45d787',
      500: '#1F844D',
      600: '#1D7C48',
      700: '#13693b',
      800: '#064022',
      900: '#001707',
    },
  },
  breakpoints,
  fonts: {
    heading: 'Helvetica, sans-serif',
    body: 'Helvetica, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  //   components: { Modal: {} },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
};

const customTheme = extendTheme(overrides);

export default customTheme;

// const breakpoints = ['420px', '672px', '868px', '1120px', '1470px'];
// breakpoints.xs = breakpoints[0];
// breakpoints.sm = breakpoints[1];
// breakpoints.md = breakpoints[2];
// breakpoints.lg = breakpoints[3];
// breakpoints.xl = breakpoints[4];

// export default {
//   ...theme,
//   colors: {
//     ...theme.colors,
//     background: '#f3f3f3',
//     newBlack: {
//       50: '#fbf0f2',
//       100: '#dcd8d9',
//       200: '#bfbfbf',
//       300: '#a6a6a6',
//       400: '#8c8c8c',
//       500: '#737373',
//       600: '#595959',
//       700: '#404040',
//       800: '#282626',
//       900: '#150a0d',
//     },
//     blue: {
//       50: '#e1f4ff',
//       100: '#b8dbfa',
//       200: '#8ec3f1',
//       300: '#62abea',
//       400: '#3a93e3',
//       500: '#2379ca',
//       600: '#195e9e',
//       700: '#0e4371',
//       800: '#032846',
//       900: '#000e1c',
//     },
//     red: {
//       50: '#ffe5e5',
//       100: '#fbbaba',
//       200: '#f28e8e',
//       300: '#eb6161',
//       400: '#e43535',
//       500: '#ca1b1b',
//       600: '#9e1314',
//       700: '#710c0e',
//       800: '#460506',
//       900: '#1e0000',
//     },
//     green: {
//       50: '#e1feef',
//       100: '#bcf3d6',
//       200: '#94e9bb',
//       300: '#6ce0a1',
//       400: '#45d787',
//       500: '#1F844D',
//       600: '#1D7C48',
//       700: '#13693b',
//       800: '#064022',
//       900: '#001707',
//     },
//   },
//   breakpoints,
//   fonts: {
//     heading: 'Helvetica, sans-serif',
//     body: 'Helvetica, sans-serif',
//     mono: 'Menlo, monospace',
//   },
//   fontSizes: {
//     xs: '0.75rem',
//     sm: '0.875rem',
//     md: '1rem',
//     lg: '1.125rem',
//     xl: '1.25rem',
//     '2xl': '1.5rem',
//     '3xl': '1.875rem',
//     '4xl': '2.25rem',
//     '5xl': '3rem',
//     '6xl': '4rem',
//   },
//   fontWeights: {
//     normal: 400,
//     medium: 500,
//     bold: 700,
//   },
// };

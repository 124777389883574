import React from 'react';
import { Collapse } from '@mui/material';
import { SUBSCRIPTION_PLANS as plans } from '../../data/constants';
import useAuth from '../../hooks/useAuth';
import useQueryState from '../../hooks/useQueryState';
import useSubscriptionPlan from '../../hooks/useSubscriptionPlan';
import MuiContainer from '../common/MuiContainer';
import MuiText from '../intl/MuiText';
import useLocale from '../../hooks/useLocale';
import Flex from '../common/Flex';
import MuiButton from '../intl/MuiButton';
import useBreakpoint from '../../hooks/useBreakpoint';
import { useEffect } from 'react';

const SellerUpgradeBanner = ({ upgradeBannerRef, headerRefresh }) => {
  const { verySmallPhone } = useBreakpoint();
  const formatOptions = { minimumFractionDigits: undefined };
  const [hideProgress] = useQueryState('hideProgress', false);
  const { plan, loading, setBannerMoved } = useSubscriptionPlan();
  const { formatCost } = useLocale();
  const { supplier } = useAuth();
  const planPage = '/seller-plan';
  const onPlanPage = window.location.pathname === planPage;
  const costPerMonth =
    plans.find(({ cost }) => !!cost?.month)?.cost?.month || 0;
  const showBanner =
    !hideProgress &&
    !!supplier &&
    !loading &&
    !plan?.planFee &&
    !!costPerMonth &&
    !onPlanPage;

  useEffect(() => {
    if (!loading) setBannerMoved(!showBanner);
  }, [loading, showBanner, setBannerMoved]);

  const formattedCost = formatCost(costPerMonth / 100, formatOptions) || '$0';

  const { text, style } = makeProps({
    formattedCost,
    planPage,
    verySmallPhone,
  });
  return (
    <Collapse
      {...{
        in: showBanner,
        ref: upgradeBannerRef,
        addEndListener: () =>
          setTimeout(() => {
            headerRefresh();
            setBannerMoved(true);
          }, 300),
      }}
    >
      <MuiContainer {...style.container}>
        <Flex {...style.flexContainer}>
          <MuiText {...text.upgradeMessage} />
          <MuiButton {...style.upgrade} />
        </Flex>
      </MuiContainer>
    </Collapse>
  );

  function makeProps({ formattedCost, planPage, verySmallPhone }) {
    const fontSize = { xs: '0.875rem', md: '1rem' };
    const bold = { ...{ sx: { fontWeight: 'bold', fontSize: 'inherit' } } };
    return {
      text: {
        upgradeMessage: {
          ...(verySmallPhone
            ? {
                id: 'seller.upgradeBanner.message.mobile',
                dm: 'Promote business & get customers, {PRICE}',
              }
            : {
                id: 'seller.upgradeBanner.message',
                dm: 'Boost your business & get sales, start at {PRICE}',
              }),
          replaceMap: {
            '{PRICE}': (
              <MuiText
                {...{
                  sx: { fontSize: 'inherit', fontWeight: 'bold' },
                  hint: {
                    id: 'seller.upgradeBanner.message.tooltip',
                    dm:
                      'Your page contact details are hidden from visitors, and you cannot answer requests. Verify in your business to get sales!',
                    tooltipProps: {
                      textProps: {
                        replaceMap: {
                          verify: <MuiText {...bold}>verify</MuiText>,
                          cachées: <MuiText {...bold}>cachées</MuiText>,
                          cannot: <MuiText {...bold}>cannot</MuiText>,
                          ne: <MuiText {...bold}>ne</MuiText>,
                        },
                      },
                    },
                    iconProps: { sx: { color: 'card.main' } },
                  },
                }}
              >
                {formattedCost}
              </MuiText>
            ),
          },
          sx: { color: 'card.main', fontSize },
        },
      },
      style: {
        container: {
          sx: { backgroundColor: 'primary.main', height: 'unset' },
        },
        flexContainer: {
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: { xs: '0.5rem', md: '0.5rem 0' },
            gap: { xs: '0.25rem', md: '2rem' },
          },
        },
        upgrade: {
          ...(verySmallPhone
            ? {
                id: 'seller.upgradeBanner.upgrade.mobile',
                dm: 'Upgrade',
              }
            : {
                id: 'seller.upgradeBanner.upgrade',
                dm: 'Upgrade',
              }),

          to: planPage,
          fontSize,
          color: 'secondary',
          textColor: 'card.main',
          sx: {
            p: '0.375rem 1.25rem',
            flexShrink: '0',
            ml: { xs: 'auto', md: '0' },
          },
          sxText: { fontWeight: 'bold' },
        },
      },
    };
  }
};

export default SellerUpgradeBanner;

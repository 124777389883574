import { Modal } from '@mui/material';
import React from 'react';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import MuiCard from '../common/MuiCard';
import MuiModalCloseButton from '../common/MuiModalCloseButton';
import MuiText from '../intl/MuiText';
import emailPicture from '../../data/images/emailPicture.png';
import Flex from '../common/Flex';
import MuiButton from '../intl/MuiButton';
import tryClient from '../../utils/tryClient';
import useAsyncState from '../../hooks/useAsyncState';
import useSnackbar from '../../hooks/useSnackbar';

import sleep from '../../utils/sleep';
import useAuth from '../../hooks/useAuth';

const EmailResendModal = ({ modalControl, modalProps }) => {
  const defaultModalControl = useMuiModalControl();
  const { open, handleClose } = modalControl || defaultModalControl;
  const { user } = useAuth();
  const { snackbar } = useSnackbar();
  const [loading, setLoading] = useAsyncState(false);
  const [shownMessages, setShownMessages] = useAsyncState(false);
  const [alreadyVerified, setAlreadyVerified] = useAsyncState(false);
  const [timeLimit, setTimeLimit] = useAsyncState(false);
  const handleResend = async () => {
    setLoading(true);
    const { err } = await tryClient(`/email/resend-verification`);
    setLoading(false);
    if (!err) {
      setShownMessages(true);
      snackbar({ message: 'resendEmailSuccess' });
      await sleep(2);
      return modalControl.handleClose();
    }
    if (err.status === 412) {
      setAlreadyVerified(true);
      return snackbar({ message: 'emailAlreadyVerified' });
    }
    if (err.status === 404) {
      setTimeLimit(true);
      return snackbar({ message: 'emailTimeLimit' });
    }

    if (err) return snackbar({ message: 'error' });
  };

  const { text, style } = makeProps();
  return (
    <Modal {...{ open, onClose: handleClose, ...modalProps }}>
      <MuiCard {...{ ...style.card }}>
        <MuiModalCloseButton {...{ handleClose, ...style.close }} />
        <img src={emailPicture} alt="email-logo" {...style.emailLogo} />
        <MuiText {...{ ...text.title, ...style.title }} />
        <MuiText {...{ ...text.longText, ...style.longText }} />
        <MuiText {...{ ...text.shortText, ...style.shortText }} />
        {shownMessages && !alreadyVerified && (
          <>
            <MuiText {...style.commonText}>
              <MuiText {...{ ...text.successMessage }} />
              <MuiText {...style.shortText}>{user?.email}</MuiText>
              <MuiText {...{ ...text.lastPart }} />
            </MuiText>
          </>
        )}
        {alreadyVerified && (
          <MuiText {...{ ...text.alreadyVerified, ...style.commonText }} />
        )}
        {timeLimit && (
          <MuiText {...{ ...text.timeLimit, ...style.timeLimit }} />
        )}
        <Flex {...style.buttonArea}>
          <MuiButton
            {...{
              ...text.resend,
              ...style.resend,
              onClick: handleResend,
              loading: loading,
            }}
          />
          <MuiButton
            {...{ ...text.cancel, ...style.cancel, onClick: handleClose }}
          />
        </Flex>
      </MuiCard>
    </Modal>
  );

  function makeProps() {
    return {
      text: {
        title: {
          id: 'email.verify.title',
          dm: 'Verify Your Email',
        },
        longText: {
          id: 'email.verify.long',
          dm:
            'If you did not receive a verification email yet, you can request a new one',
        },
        shortText: {
          id: 'email.verify.short',
          dm:
            'Note: Please check the Junk/Spam folder as it may go there falsely',
        },
        resend: {
          id: 'email.resendButton',
          dm: 'Resend',
        },
        cancel: { id: 'email.cancelButton', dm: 'Cancel' },
        successMessage: {
          id: 'email.modal.successMessage',
          dm: `A new verification email has been sent to your email `,
        },
        lastPart: {
          id: 'email.modal.successMessage.lastPart',
          dm:
            ' please check your email. The link in the email will expire in 24 hours.',
        },
        alreadyVerified: {
          id: 'email.modal.alreadyVerified',
          dm: 'Your email has already been verified, please close the modal',
        },
        timeLimit: {
          id: 'email.modal.timeLimit',
          dm:
            'Please wait 10 minutes after your last email verification re-send request',
        },
      },
      style: {
        card: {
          customScroll: true,
          sx: {
            m: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 12,
            maxWidth: { xs: '95vw', md: 'min(60%, 40rem)' },
            maxHeight: '95vh',
            p: { xs: '1rem 0.5rem', md: '2rem' },
            gap: '1rem',
            width: '100%',
            // maxWidth: { xs: '95vw', md: 'min(60%, 40rem)' },
          },
        },
        close: {
          color: 'headingText',
          sx: {
            position: 'absolute',
            top: '0.25rem',
            right: '0.25rem',
            zIndex: 2,
          },
        },
        title: {
          variant: 'pageTitle',
          sx: {
            textAlign: 'center',
          },
        },
        longText: {
          fontSize: '0.875rem',
          sx: {
            textAlign: 'center',
          },
        },
        shortText: {
          sx: {
            textAlign: 'center',
            fontWeight: 'bold',
          },
        },
        commonText: {
          sx: {
            textAlign: 'center',
          },
        },
        emailLogo: {
          style: { width: '8rem', maxWidth: '100%', margin: '0 auto' },
        },
        buttonArea: {
          sx: {
            justifyContent: 'space-around',
            pt: '0.75rem',
          },
        },
        resend: {
          fontSize: '0.875rem',
          sx: {
            px: '3rem',
            py: '1rem',
          },
        },
        cancel: {
          fontSize: '0.875rem',
          outlined: true,
          sx: {
            px: '3rem',
            py: '1rem',
          },
        },
        timeLimit: {
          sx: {
            textAlign: 'center',
            color: 'red',
          },
        },
      },
    };
  }
};

export default EmailResendModal;

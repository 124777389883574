import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { stringifyParams } from '../utils/queryString';
import { overlap } from '../utils/object';
import tryClient from '../utils/tryClient';
import useSnackbar from './useSnackbar';
import checkFunc from '../utils/checkFunc';

const reducer = (
  state,
  {
    type,
    payload: {
      add,
      data,
      meta,
      // more,
      error,
      params = {},
      filterParam,
      filterValue,
      filterLabel,
    } = {},
  },
) => {
  //   console.log({
  //     type,
  //     add,
  //     data,
  //     meta,
  //     error,
  //     params,
  //     filterParam,
  //     filterValue,
  //     filterLabel,
  //   });
  return {
    ...state,
    ...{
      setData: {
        data: add
          ? [...state.data, ...checkFunc(data, state.data)]
          : checkFunc(data, state.data),
        ...(meta && {
          meta: checkFunc(meta, state.meta),
          params: overlap(state.params, checkFunc(meta, state.meta)),
        }),
      },
      // startLoading: { [more ? 'moreLoading' : 'loading']: true },
      startLoading: {
        ...((!state.moreLoading || state.params?.page === 1) && {
          loading: true,
        }),
      },
      stopLoading: { loading: false, moreLoading: false },
      setError: { error },
      updateParam: {
        params: overlap(state.params, checkFunc(params, state.params)),
      },
      addParam: {
        params: { ...state.params, params: checkFunc(params, state.params) },
      },
      nextPage: {
        ...(state.params.page < state.meta.pages && {
          params: { ...state.params, page: state.params.page + 1 },
          loading: true,
        }),
      },
      scrollNext: {
        ...(state.params.page < state.meta.pages && {
          params: { ...state.params, page: state.params.page + 1 },
          moreLoading: true,
        }),
      },
      refresh: {
        refresh: !state.refresh,
        loading: true,
        ...(state.clearData && { data: state.initialValue }),
      },
      reset: {
        refresh: !state.refresh,
        loading: true,
        params: state.initialParams,
        ...(state.clearData && { data: state.initialValue }),
      },
      setFilter: {
        filters: {
          ...state.filters,
          [filterParam]: {
            label: filterLabel,
            value: filterValue,
          },
        },
      },
      removeFilter: {
        filters: { ...state.filters, [filterParam]: null },
      },
    }[type],
  };
};

const useGetData = ({
  server,
  endpoint,
  initialParams,
  queryString,
  dataHandler, // must be memoized
  dataMapper,
  options, // must be memoized
  initialValue = [],
  successMessage,
  successToastOptions,
  successHandler, // must be memoized
  errorMessage = 'error',
  errorToastOptions, // must be an object returned in a useMemo,
  errorHandler, // must be memoized
  debugMode,
  noPages,
  pages,
  toastForNone,
  scrollLoadOffset, // must be px or %
  handleDone,
  interval,
  scrollingMode,
  dontAbort,
  dontClear,
  checkSet,
}) => {
  const [
    { data, loading, moreLoading, error, meta, params, refresh, filters },
    dispatch,
  ] = useReducer(reducer, {
    data: initialValue,
    initialValue,
    initialParams: {
      ...(((!noPages && initialParams) || pages) && { page: 1, perpage: 10 }),
      ...initialParams,
    },
    loading: true,
    moreLoading: false,
    error: null,
    meta: {
      field: 'created_at',
      page: 1,
      pages: 1,
      perpage: 10,
      sort: 'desc',
      total: 1,
    },
    params: {
      ...(((!noPages && initialParams) || pages) && { page: 1, perpage: 10 }),
      ...initialParams,
    },
    filters: {},
    refresh: false,
    clearData: !dontClear,
  });
  const { snackbar } = useSnackbar();
  const add = useRef(false);
  const string = stringifyParams(params);
  const query = queryString ?? string ?? '';
  //   const [refresh, setRefresh] = useState(false);
  const paramJsonString = JSON.stringify(params);

  const handleSuccess = useCallback(
    (resp) => {
      const data = dataMapper
        ? dataMapper(resp.data.data)
        : dataHandler
        ? dataHandler(resp, JSON.parse(paramJsonString))
        : resp.data;

      if (debugMode) console.log({ resp, mapped: data });
      const { meta } = resp.data;
      dispatch({
        type: 'setData',
        payload: { data, add: add.current || scrollingMode, meta },
      });

      add.current = false;
      if (successHandler) successHandler(resp);
      else if (successMessage)
        snackbar({
          message: successMessage,
          options: successToastOptions || {},
        });
    },
    [
      paramJsonString,
      dispatch,
      debugMode,
      scrollingMode,
      dataHandler,
      dataMapper,
      successHandler,
      successMessage,
      successToastOptions,
      snackbar,
    ],
  );

  const catchError = useCallback(
    (error) => {
      if (debugMode) console.log({ error });
      if (errorHandler) errorHandler(error);
      else if (errorMessage && (toastForNone || error.status !== 404))
        snackbar({ message: errorMessage, options: errorToastOptions || {} });
      dispatch({ type: 'setError', payload: { error } });
    },
    [
      dispatch,
      debugMode,
      errorHandler,
      errorMessage,
      errorToastOptions,
      snackbar,
      toastForNone,
    ],
  );

  const getData = useCallback(
    async (abortController) => {
      if (!endpoint) return dispatch({ type: 'stopLoading' });
      //   dispatch({ type: 'startLoading', payload: { more: add.current } });
      dispatch({ type: 'startLoading' });
      const url = `${endpoint}${query}`;
      if (debugMode)
        console.log({
          ...{ url, ...(server && { server }), ...(options && { options }) },
        });
      const { resp, err } = await tryClient(url, {
        signal: abortController?.signal,
        server,
        ...options,
      });
      if (err?.name === 'AbortError') {
        add.current = false;
        if (debugMode) console.log('API Call Aborted');
        return;
      }
      if (err) catchError(err);
      else handleSuccess(resp);
      if (handleDone) handleDone(resp, err);
      dispatch({ type: 'stopLoading' });
    },
    [
      dispatch,
      endpoint,
      server,
      debugMode,
      options,
      query,
      handleSuccess,
      catchError,
      handleDone,
    ],
  );

  useEffect(() => {
    const abortController = new AbortController();
    const asyncGetData = async (abortController) => {
      await getData(abortController);
    };

    asyncGetData(abortController);
    const refreshInterval =
      interval && setInterval(() => asyncGetData(abortController), interval);
    if (debugMode && interval) console.log({ interval, refreshInterval });
    return () => {
      if (interval) clearInterval(refreshInterval);
      if (!dontAbort) {
        abortController.abort();
        // if (!add.current) dispatch({ type: 'stopLoading' });
        // if (add.current) add.current = false;
      }
    };
  }, [refresh, getData, interval, debugMode, dontAbort]);

  const scrollNext = useCallback(() => {
    if (loading || moreLoading || add.current) return;
    add.current = true;
    if (debugMode) console.log('updating page number');
    dispatch({ type: 'scrollNext' });
  }, [debugMode, dispatch, loading, moreLoading]);

  const loadMore = useCallback(() => {
    const { page } = params;
    const { pages } = meta;
    if (page >= pages || !page)
      return debugMode && console.log('already at last page', { page, pages });
    scrollNext();
  }, [debugMode, scrollNext, meta, params]);

  const [lastData, setLastData] = useState(false);

  const lastDataObserver = new IntersectionObserver(
    (entries) => {
      const lastCard = entries[0];
      if (!lastCard.isIntersecting || add.current) return;
      if (debugMode) console.log('intersecting');
      loadMore();
      lastDataObserver.unobserve(lastCard.target);
    },
    { rootMargin: scrollLoadOffset ?? '1500px' },
  );

  const lastDataRef = useCallback(
    (node) => {
      if (node && debugMode) console.log({ node });
      if (node) setLastData(node);
    },
    [debugMode, setLastData],
  );

  useEffect(() => {
    if (lastData) lastDataObserver.observe(lastData);
  }, [lastData, lastDataObserver]);

  const setData = useCallback(
    (data) => dispatch({ type: 'setData', payload: { data } }),
    [dispatch],
  );

  const updateParam = useCallback(
    (params) => dispatch({ type: 'updateParam', payload: { params } }),
    [dispatch],
  );

  const addParam = useCallback(
    (params) => dispatch({ type: 'addParam', payload: { params } }),
    [dispatch],
  );

  const nextPage = useCallback(() => dispatch({ type: 'nextPage' }), [
    dispatch,
  ]);

  const prevPage = useCallback(() => dispatch({ type: 'prevPage' }), [
    dispatch,
  ]);

  const refreshCallback = useCallback(() => {
    if (debugMode) console.log('refresh');
    dispatch({ type: 'refresh' });
  }, [debugMode]);

  const resetCallback = useCallback(() => {
    if (debugMode) console.log('reset');
    dispatch({ type: 'reset' });
  }, [debugMode]);

  const jsonInitialParams = JSON.stringify(initialParams || {});
  const memoInitialParams = useMemo(() => JSON.parse(jsonInitialParams), [
    jsonInitialParams,
  ]);

  const hasNext = (params.page || 1) < (meta.pages || 1);

  const setFilter = useCallback(
    ({ param: filterParam, label: filterLabel, value: filterValue }) =>
      dispatch({
        type: 'setFilter',
        payload: { filterParam, filterLabel, filterValue },
      }),
    [],
  );

  const removeFilter = useCallback(
    ({ param: filterParam }) =>
      dispatch({ type: 'removeFilter', payload: { filterParam } }),
    [],
  );

  const jsonFilters = JSON.stringify(filters || {});
  const memoFilters = useMemo(() => JSON.parse(jsonFilters), [jsonFilters]);

  const hasFilters = useMemo(
    () =>
      Object.values(memoFilters).reduce(
        (hasFilter, nextFilter) => hasFilter || nextFilter !== null,
        false,
      ),
    [memoFilters],
  );

  const filtersSet = useMemo(
    () =>
      (checkSet || []).reduce(
        (hasFilters, nextFilter) =>
          hasFilters || ![null, undefined].includes(memoFilters[nextFilter]),
        false,
      ),
    [checkSet, memoFilters],
  );

  const output = useMemo(
    () => ({
      data,
      setData,
      lastDataRef,
      meta,
      nextPage,
      prevPage,
      scrollNext,
      loading,
      loadMore,
      moreLoading,
      error,
      //   refresh: () => setRefresh((current) => !current),
      refresh: refreshCallback,
      reset: resetCallback,
      params,
      initialParams: memoInitialParams,
      updateParam,
      addParam,
      string,
      hasNext,
      setFilter,
      filters: memoFilters,
      removeFilter,
      hasFilters,
      filtersSet,
    }),
    [
      data,
      setData,
      lastDataRef,
      meta,
      nextPage,
      prevPage,
      scrollNext,
      loading,
      loadMore,
      moreLoading,
      error,
      params,
      updateParam,
      addParam,
      string,
      refreshCallback,
      resetCallback,
      hasNext,
      memoInitialParams,
      setFilter,
      memoFilters,
      removeFilter,
      hasFilters,
      filtersSet,
    ],
  );
  return output;
};

export default useGetData;

import React, { useContext, useCallback, useEffect } from 'react';
import useGetData from '../../hooks/useGetData';
import { useSocket } from './SocketProvider';
import useAsyncState from '../../hooks/useAsyncState';
import { socketNotificationMapper } from '../../utils/fetchMapper';
import useAuth from '../../hooks/useAuth';

export const NotificationContext = React.createContext();

export const useNotification = () => useContext(NotificationContext);

const debugMode = !true;

const NotificationProvider = ({ children }) => {
  const { socket } = useSocket();
  const [totalUnread, setTotalUnread] = useAsyncState(0);
  const [receivedCount, setReceivedCount] = useAsyncState(0);
  const { isAuthenticated } = useAuth();

  useGetData({
    endpoint: isAuthenticated && '/notifications-total-unseen',
    dataHandler: useCallback(
      ({ data }) => setTotalUnread(data.notifications_count),
      [setTotalUnread],
    ),
    debugMode,
  });

  const receiveNotification = useCallback(
    (serverNotificationData) => {
      if (debugMode) console.log({ serverNotificationData });
      const { enMessage, isSeen } =
        socketNotificationMapper(serverNotificationData) || {};
      if (!enMessage || isSeen) return;
      setReceivedCount((current) => current + 1);
      setTotalUnread((current) => current + 1);
    },
    [setReceivedCount, setTotalUnread],
  );

  useEffect(() => {
    if (!socket) return;
    socket.on('siteWideNotification', receiveNotification);
    return () => socket.off('siteWideNotification', receiveNotification);
  }, [socket, receiveNotification]);

  return (
    <NotificationContext.Provider
      value={{
        totalUnread,
        setTotalUnread,
        receivedCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

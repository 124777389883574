// For mapping data returned from backend to conform to frontend standards

import { dateFormatter } from './dateFormatter';
import {
  FALLBACK_IMAGE_AVATAR,
  PAID_SUBSCRIPTIONS_PLANS,
  SUBSCRIPTION_PLANS,
  TAX_OPTIONS,
} from '../data/constants';

export const fullName = (
  el,
  { first = 'first_name', last = 'last_name' } = {},
) => [el?.[first], el?.[last]].filter((el) => el).join(' ');

export const dateMapper = (dateString) =>
  !dateString
    ? null
    : new Date(
        typeof dateString === 'string'
          ? dateString
          : Number(dateString?.['$date']?.['$numberLong']),
      );

export const provinceMapper = (province = '') =>
  ({
    newfoundlandandlabrador: 'NL',
    princeedwardisland: 'PE',
    novascotia: 'NS',
    newbrunswick: 'NB',
    quebec: 'QC',
    québec: 'QC',
    ontario: 'ON',
    manitoba: 'MB',
    saskatchewan: 'SK',
    alberta: 'AB',
    britishcolumbia: 'BC',
    yukon: 'YT',
    northwestterritories: 'NT',
    nunavut: 'NU',
  }?.[province?.split(' ')?.join('')?.toLowerCase()] || province);

export const locationMapper = (el, { lines, replaceProvince = true } = {}) => ({
  ...(lines
    ? {
        lineOne: el?.street_number || '',
        lineTwo: el?.street_name || '',
      }
    : {
        streetNumber: el?.street_number || '',
        streetName: el?.street_name || '',
      }),
  city: el?.city || '',
  postalCode: el?.postal_code || el?.postalCode || '',
  province: replaceProvince ? provinceMapper(el?.province) : el?.province || '',
  country: el?.country || '',
  countryShort: el?.country_short || '',
});

export const croppedAreaMapper = (croppedArea) =>
  croppedArea?.x === undefined
    ? null
    : {
        x: Number(croppedArea?.x),
        y: Number(croppedArea?.y),
        width: Number(croppedArea?.width),
        height: Number(croppedArea?.height),
      };

export const imageMapper = (el) =>
  !el.src
    ? null
    : {
        src: el.src,
        alt: el.alt,
        altEn: el.altEn,
        altFr: el.altFr,
        croppedArea: croppedAreaMapper(el.croppedArea),
      };

export const videoMapper = (el) =>
  !el.src
    ? null
    : {
        src: el.src,
        alt: el.alt,
        altEn: el.altEn,
        altFr: el.altFr,
      };

const traceMapper = (trace) =>
  (trace || []).map((traceStep) => {
    const [[status, date] = []] = Object.entries(traceStep) || [];
    return { status: status.toLowerCase(), date: new Date(date) };
  });

const arrayMapper = (data, mapper) =>
  !Array.isArray(data) ? [] : mapper ? data.map(mapper) : data;

const planNameMapper = (plan) =>
  plan?.split(' ')?.[0]?.toLowerCase() || 'basic';

export const supplierMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el?._id,
        supplierId: el?.supplier_id || '',
        userId: el?.user_id,
        name: el?.name,
        email: el?.email,
        bio: el?.bio || '',
        categories: el?.category_ids || [],
        categoryNames: el?.category_names || [],
        emergencies: el?.emergency_ids || [],
        itemTypes: el?.item_type_ids || [],
        itemTypeNames: el?.item_type_names || [],
        supplierName: el?.name || '',
        avatar: el?.photo_url || '',
        tags: el?.tags || [],
        matchedPostIds: el?.post_ids || [],
        contactPerson: el?.contact_person,
        shortDescription: el?.short_description,
        logo: el?.logo,
        logoImage: imageMapper({
          src: el?.logo,
          altEn: `${el?.name} Business Logo`,
          altFr: `Logo commercial ${el?.name}`,
          croppedArea: el?.logo_cropped_area,
        }),
        headerImage: el?.header_image,
        bannerImage: imageMapper({
          src: el?.header_image,
          altEn: `${el?.name} Business Banner Image`,
          altFr: `Image de bannière commerciale de ${el?.name}`,
          croppedArea: el?.header_cropped_area,
        }),
        foundationYear: String(el?.foundation_year || ''),
        location: locationMapper(el?.location),
        products: productArrayMapper(
          arrayMapper(el?.products, (product) => ({
            ...product,
            supplier_id: el?._id,
            supplier_name: el?.name,
            supplier_logo: el?.logo,
            supplier_logo_cropped_area: el?.logo_cropped_area,
          })),
        ),
        listings: listingArrayMapper(
          arrayMapper(el?.products, (listing) => ({
            ...listing,
            supplier_id: el?._id,
            supplier_name: el?.name,
            supplier_logo: el?.logo,
            supplier_logo_cropped_area: el?.logo_cropped_area,
          })),
        ),
        employmentSize:
          typeof el?.employment_size === 'string' ? el?.employment_size : '<10',
        aboutImages: el?.media_images || [],
        mediaImages: el?.media_images || [],
        mediaImageArray: arrayMapper(el?.media_images, (image, index) =>
          imageMapper({
            src: typeof image === 'string' ? image : image?.source,
            altEn:
              image?.alt_en ||
              `${el?.name || 'Business'} media image number ${index + 1}`,
            altFr:
              image?.alt_fr ||
              `Image des médias ${el?.name || 'Entreprise'} numéro ${
                index + 1
              }`,
            croppedArea: croppedAreaMapper(image?.cropped_area),
          }),
        ),
        certificationImageArray: arrayMapper(
          el?.certification,
          (image, index) =>
            imageMapper({
              src: typeof image === 'string' ? image : image?.source,
              altEn:
                image?.alt_en ||
                `${el?.name || 'Business'} certification ${index + 1}`,
              altFr:
                image?.alt_fr ||
                `Certification ${el?.name || 'Entreprise'} ${index + 1}`,
              croppedArea: croppedAreaMapper(image?.cropped_area),
            }),
        ),
        aboutDescription: el?.long_description,
        certifications: el?.certification || [],
        pitchVideo: videoMapper({
          src: el?.video,
          altEn: `${el?.name || 'Business'} Pitch Video`,
          altFR: `Video de pitch ${el?.name || 'Entreprise'}`,
        }),
        followers: el?.followers,
        following: el?.following,
        badges: el?.badges || [],
        rating: el?.rating || 0,
        reviews: el?.reviews,
        ratingCount: el?.review_count || 0,
        postsCount: el?.postsCount,
        productsCount: el?.productsCount,
        website: el?.website || '',
        phone: el?.phone || '',
        contactEmail: el?.contact_email || '',
        whatsappPhone: el?.whatsapp_phone || '',
        facebookUrl: el?.facebook_url || '',
        linkedInUrl: el?.linkedin_url || '',
        status: el?.status || '',
        published: el?.isPublish,
        isDeleted: el?.isDeleted,
        subdomain: el?.subdomain,
        reviewComment: el?.review_comment || '',
        reviewCommentedAt: dateMapper(el?.review_commented_at),
        planName: planNameMapper(el?.subscription_plan_name),
        createdAt: dateMapper(el?.created_at),
        updatedAt: dateMapper(el?.updated_at),
      };

export const supplierArrayMapper = (data) => arrayMapper(data, supplierMapper);

export const singleCategoryMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        enName: el.en_name || '',
        frName: el.fr_name || '',
        arName: el.ar_name || '',
        categoryEnglishName: el.en_name || '',
        categoryEnglishAbbreviation: el.en_abbr || '',
        categoryEnglishDescription: el.en_description || '',
        categoryFrenchName: el.fr_name || '',
        categoryFrenchAbbreviation: el.fr_abbr || '',
        categoryFrenchDescription: el.fr_description || '',
        categoryArabicName: el.ar_name || '',
        categoryArabicAbbreviation: el.ar_abbr || '',
        categoryArabicDescription: el.ar_description || '',
        imageUrl: el.image_url || '',
        image: imageMapper({
          src: el.image_url,
          altEn: `${el?.en_name} Image`,
          altFr: `Image de ${el?.fr_name}`,
          croppedArea: el?.image_cropped_area,
        }),
        isDeleted: el.isDeleted,
        parent_category_id: el.parent_category_id || '',
        isFeatured: el.isFeatured || false,
        isForecastingEnabled: el.isForecastingEnabled || false,
      };

export const CategoriesMapper = (data) => data.map(singleCategoryMapper);

export const categoriesMapper = CategoriesMapper;

export const taxMapper = ({ name, percentage, value }) => ({
  ...(TAX_OPTIONS.map(({ id }) => id).includes(name)
    ? { id: name }
    : { id: 'OTHER', specifiedName: name }),
  percent: Number(percentage),
  value: Number(value),
});

export const ticketsMapper = (data) =>
  data.map((el) => ({
    id: el._id,
    type: el.type || '',
    subject: el.subject || '',
    message: el.message || '',
    pageURL: el.source_page_url || '',
    pageTitle: el.page_title || '',
    byUserId: el.by_user_id || '',
    email: el.user_email || '',
    name: el.user_name || '',
    createdDate: el.created_at || '',
    updatedDate: el.updated_at || '',
    status: el.status || '',
  }));

const listingImageArrayMapper = (images, title) =>
  arrayMapper(images, (image, index) =>
    imageMapper({
      src: typeof image === 'string' ? image : image?.source,
      altEn: image?.alt_en || `${title || 'Product'} image number ${index + 1}`,
      altFr: image?.alt_fr || `Image ${title || 'Produit'} numéro ${index + 1}`,
      croppedArea: croppedAreaMapper(image.cropped_area),
    }),
  );

export const listingMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el?._id,
        title: el?.title || '',
        description: el?.description || '',
        images: Array.isArray(el?.images) ? el?.images : [el?.images] || [],
        imageArray: listingImageArrayMapper(el?.images, el?.title),
        videoUrl: el?.videos || '',
        tags: el?.tags || [],
        price: Number(el?.price) || {
          id: 'product.price.negotiable',
          dm: 'Negotiable',
        },
        prices: el?.prices?.length
          ? el?.prices
              .map(({ price, minimum_order_qty }) => ({
                price,
                minOrderQty: minimum_order_qty,
              }))
              .sort((a, b) => a.minOrderQty - b.minOrderQty)
          : [],
        quantity: [0, 1].includes(Number(el?.qty))
          ? { id: 'product.moq.any', dm: 'Any' }
          : Number(el?.qty),
        sku: el?.sku || '',
        status: el?.status || '',
        userId: el?.user_id || '',
        autherName: el?.author_name || '',
        authorName: el?.author_name || '',
        category: el?.category_id || '',
        //parent_category_id:el.category_id || '',
        categoryName: el?.category_name || '',
        categoryObject: !(el?.category_id && el?.category_name)
          ? null
          : { id: el?.category_id, name: el?.category_name },
        supplierId: el?.supplier_id || '',
        supplierName: el?.supplier_name || '',
        supplierLogo: el?.supplier_logo || '',
        supplier: supplierMapper({
          _id: el?.supplier_id || '',
          name: el?.supplier_name || '',
          logo: el?.supplier_logo || '',
          logo_cropped_area: el?.supplier_logo_cropped_area,
        }),
        certifications: el?.product_certifications || [],
        specifications: el?.product_specifications || [],
        supportDocs: Array.isArray(el?.product_support_docs)
          ? el?.product_support_docs
          : [],
        emergency: el?.emergency_ids || '',
        itemType: el?.item_type_id || '',
        defaultCurrency: el?.default_currency || 'CAD',
        createdDate: el?.created_at || '',
        updatedDate: el?.updated_at || '',
        isDeleted: el?.isDeleted,
        published: el?.published,
        liveId: el?.product_id || '',
        productId: el?.product_id || '',
        listingId: el?.product_id || '',
      };

export const listingArrayMapper = (data) => arrayMapper(data, listingMapper);

export const productMapper = listingMapper;
export const productArrayMapper = listingArrayMapper;

export const bidMapper = (bid) => ({
  id: bid._id,
  product_id: bid.product_id || '',
  productId: bid.product_id || '',
  supplier_id: bid.supplier_id || '',
  supplierId: bid.supplier_id || '',
  rfq_id: bid.rfq_id || '',
  rfqId: bid.rfq_id || '',
  quantity: bid.quantity || 0,
  biddingPrice: bid.biddingPrice || 0,
  shipping_method: bid.shipping_method || '',
  shippingMethod: bid.shipping_method || '',
  shipping_cost: bid.shipping_cost || 0,
  shippingCost: bid.shipping_cost || 0,
  deliveryDate: String(bid.delivery_unique_id) || '',
  estimated_delivery_date: dateFormatter(bid.estimated_delivery_date) || '',
  bid_expiry_date: dateFormatter(bid.bid_expiry_date) || '',
  status: bid.bid_status,
  isDeleted: bid.isDeleted,
  isWinningBid: bid.isWinningBid,
  isCancel: bid.isCancel,
  isCanceled: bid.isCanceled,
  isRejected: bid.isRejected,
  createdDate: bid.bid_creation_date || '',
  consumerTotalRefundable: bid.consumer_total_refundable || 0,
  nonRefundableFeePercentage: bid.non_refundable_fee_percentage || 0,
  nonRefundableServiceFee: bid.non_refundable_service_fee || 0,
  saleTaxesApplied: arrayMapper(bid.sale_taxes_applied, taxMapper),
  saleTaxesTotal: bid.sale_taxes_total || 0,
  sellerMessage: bid.seller_message || '',
  serviceFee: bid.service_fee || 0,
  serviceFeePercentage: bid.service_fee_percentage || 0,
  supplierTotalPayout: bid.supplier_total_payout || 0,
  supplierUserId: bid.supplier_user_id || '',
  totalSum: bid.total_sum || 0,
  subtotal: bid.subtotal || 0,
  unitPrice: bid.unitprice || 0,
  userId: bid.user_id || '',
});

export const rfqMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        quantity: el.quantity || '',
        budget: Number(el.budget) || '',
        rfqsType: el.rfq_type || '',
        category_id: el.category_id || '',
        category: singleCategoryMapper(el.category) || {},
        description: el.post_description || '',
        product_id: el.product_id || '',
        special_requirements: el.special_requirements || '',
        specialRequirements: el.special_requirements || '',
        supplier: el.suppliers || [],
        shipping_address: {
          street_number: el.shipping_address?.street_number || '',
          street_name: el.shipping_address?.street_name || '',
          ...locationMapper(el.shipping_address),
        },
        statusTrace: traceMapper(el.status_change_trace),
        product: listingMapper(el?.product),
        bids: arrayMapper(el.bids, bidMapper),
        rfqUser: el.owned_by || '',
        shippingPreferences: el.shipping_preferences || '',
        deliveryDate: el.shipping_preferences || '',
        images: el.attachments || [],
        itemType: el.item_type_id || '',
        status: (el.isClosed ? 'closed' : el.status) || '',
        defaultCurrency: el.default_currency || 'CAD',
        createdDate: dateMapper(el.created_at),
        updatedDate: el.updated_at || '',
        expiry_date: el.expiry_date ? dateFormatter(el.expiry_date) : '',
        isDeleted: el.isDeleted,
        isCancel: el.isCancel,
        isClosed: el.isClosed,
        isExpired: el.isExpired,
        isRejected: el.isRejected,
      };

export const rfqArrayMapper = (data) => arrayMapper(data, rfqMapper);

export const categoryArrayWithListingMapper = (data) =>
  arrayMapper(data, ({ products, ...category }) => ({
    category: singleCategoryMapper(category),
    listings: listingArrayMapper(products),
  }));

export const couponsMapper = (data) =>
  data.map((el) => ({
    id: el._id,
    couponCode: el.code || '',
    userName: `${el.owner.first_name} ${el.owner.last_name}` || '',
    targetedProductsList: el.products || [],
    targetedProducts: el.product_ids || '',
    targetedUsersList: el.users || [],
    targetedUsers: el.user_ids || '',
    startDate: el.start_date ? dateFormatter(el.start_date) : '',
    endDate: el.end_date ? dateFormatter(el.end_date) : '',
    discount: el.amount || '',
    discountType: el.type || '',
    budgetLimit: el.budget_limit || '',
    spentAmount: el.spent_amount || '',
    isOneUsagePerUser: el.isOneUsagePerUser,
    isExpired: el.expired,
    isPublished: el.published,
    isActive: el.actice,
    createdDate: dateFormatter(el.created_at) || '',
    updatedDate: dateFormatter(el.updated_at) || '',
    redeems: el.redeems || '',
  }));

export const disputeUser = (user) =>
  !user?._id
    ? null
    : {
        id: user?._id,
        fullName: fullName(user),
        firstName: user?.first_name,
        lastName: user?.last_name,
        // fullName:
        profilePicture: user?.profile_picture,
        email: user?.email,
      };

export const disputeMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        disputerUser: disputeUser(el?.disputer) || {},
        disputedUser: disputeUser(el?.disputed) || {},
        order: el.order_id || '',
        orderId: el.order_id || '',
        orderNumber: el.order_number || '',
        orderStatus: el.order_status,
        orderDate: new Date(el.order_date),
        orderDetail:
          arrayMapper(el.order_details, (item) => ({
            listing: listingMapper({
              _id: item.product_id,
              title: item.product_title,
              images: item.product_image,
            }),
            quantity: item.quantity,
            price: item.price,
          }))[0] || null,
        orderDetails: arrayMapper(el.order_details, (item) => ({
          productId: item.product_id,
          productTitle: item.product_title,
          quantity: item.quantity,
          price: item.price,
        })),
        orderTotalCost: el.order_total_cost,
        transactionId: el.transaction_id,
        disputeDescription: el.dispute_description || '',
        disputerAttachments: arrayMapper(el.disputer_attachments, (file) => ({
          ...file,
          type: 'image/png',
        })),
        disputeIssueType: el.dispute_issue_type,
        desiredResolution: el.desired_resolution,
        status: el.isDeleted ? 'deleted' : el.status,
        counterOffers: arrayMapper(el.counter_offers, (item) => ({
          id: item._id,
          counterOfferMessage: item.message,
          counterOfferDate: new Date(item.offer_date),
          disputerResponseMessage: item.disputer_response_message || '',
          disputerResponseDate: item.disputer_response_date
            ? new Date(item.disputer_response_date)
            : '',
          disputedCounterOfferStatus: item.offer_status,
          counterOfferType: item.type,
          isDeleted: item.isDeleted,
        })),
        counterStatus: counterStatusChecker(
          el.counter_offers.filter(({ isDeleted }) => !isDeleted),
        ),
        isDeleted: el.isDeleted,
        createdDate: new Date(el.created_at),
        updatedDate: new Date(el.updated_at),
      };

export const disputeArrayMapper = (data) => arrayMapper(data, disputeMapper);

export const invoicesMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        invoiceNumber: el.invoice_number || '',
        client: !el.client?._id
          ? null
          : {
              id: el.client?._id,
              firstName: el.client?.first_name || '',
              lastName: el.client?.last_name || '',
              fullName: fullName(el.client),
              organization: el.client?.organization || '',
            },
        supplier: supplierMapper(el.supplier),
        product: productMapper(el.product),
        userId: el.user_id || '',
        userName: `${el.user_first_name} ${el.user_last_name}` || '',
        clientId: el.client_id || '',
        clientName: `${el.client_first_name} ${el.client_last_name}` || '',
        subjects: el.subject_id || '',
        orderNumber: el.order_number || '',
        invoiceType: el.invoice_type || '',
        issueDate: el.issue_date ? dateFormatter(el.issue_date) : '',
        dueDate: el.due_date ? dateFormatter(el.due_date) : '',
        status: el.status || '',
        billingAddress: el.billing_address || {},
        shippingAddress: el.shipping_address || {},
        items: el.items || [],
        taxPercent: el.tax_percent || '',
        subTotal: el.sub_total || '',
        //   currency: el.currency || '',
        currency: el.default_currency || 'CAD',
        defaultCurrency: el.default_currency || 'CAD',
        transcationId: el.transaction_id || '',
        transactionId: el.transaction_id || '',
        isExpired: el.isExpired,
        isDeleted: el.isDeleted,
        invoiceIssueDate: dateMapper(el.issue_date),
        invoiceDueDate: dateMapper(el.due_date),
        quantity: el.quantity || 0,
        shippingMethod: el.shipping_method || '',
        shippingCost: el.shipping_cost || 0,
        consumerTotalRefundable: el.consumer_total_refundable || 0,
        nonRefundableFeePercentage: el.non_refundable_fee_percentage || 0,
        nonRefundableServiceFee: el.non_refundable_service_fee || 0,
        saleTaxesApplied: (el.sale_taxes_applied || []).map(taxMapper),
        saleTaxesTotal: el.sale_taxes_total || 0,
        serviceFee: el.service_fee || 0,
        serviceFeePercentage: el.service_fee_percentage || 0,
        supplierTotalPayout: el.supplier_total_payout || 0,
        totalSum: el.total_sum || 0,
        subtotal: el.sub_total || 0,
        unitPrice: el.unit_price || 0,
      };

export const invoiceArrayMapper = (data) => arrayMapper(data, invoicesMapper);

export const transactionsMapper = (data) => {
  return data.map((el) => {
    const paymentDate = el.last_update ? dateFormatter(el.last_update) : '';
    return {
      id: el._id,
      payerId: el.payer_id || '',
      payer: `${el.payer_first_name} ${el.payer_last_name}` || '',
      invoiceNumber: el.invoice_id || '',
      totalAmount: el.amount || '',
      status: el.status || [],
      paymentMethod: el.payment_method || '',
      paymentDate: paymentDate,
      transactionNumber: el.transaction_number || '',
      isDeleted: el.isDeleted,
      defaultCurrency: el.default_currency || 'CAD',
      transactionPaymentDate: dateMapper(el.last_update),
    };
  });
};

export const singleOrderMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        orderNumber: el.order_number,
        orderOpeningDate: dateMapper(el.created_at),
        orderUpdateDate: dateMapper(el.updated_at || el.order_update_date),
        consumerId: el.consumer_id,
        supplierId: el.supplier_id,
        rfqId: el.rfq_id,
        consumer: {
          id: el.consumer_id || el?.consumer?._id || '',
          firstName: el.consumer_first_name || '',
          lastName: el.consumer_last_name || '',
          email: el.consumer_email || el?.consumer?.email || '',
          organization: el.consumer_organization || '',
          organizationType: el.consumer_organization_type || '',
        },
        supplier: {
          id: el.supplier?._id || el.supplier_id,
          firstName: el.supplier_first_name,
          lastName: el.supplier_last_name,
          contactPerson: el.supplier_contact_person,
          name: el.supplier?.name || el.supplier_name,
          email: el.supplier?.email || el.supplier_email,
          organization: el.supplier?.name || el.supplier_organization,
          organizationType: el.supplier_organization_type,
          logo: el.supplier?.logo || el.supplier?.photo_url,
        },
        shippingAddress: {
          lineOne: el.shipping_address?.line_one,
          lineTwo: el.shipping_address?.line_two,
          ...locationMapper(el.shipping_address),
        },
        shippingMethod: el.shipping_method,
        shippingCost: el.shipping_cost,
        trackingInfo: el.tracking_info,
        estimatedDeliveryDate: dateMapper(el.estimated_delivery_date),
        taxes: el.taxes,
        defaultCurrency: el.default_currency || 'CAD',
        cartItems: arrayMapper(el.cart_items, (item) => ({
          productId: item.product_id,
          productTitle: item.product_title,
          productImage: item.product_image,
          color: item.color,
          quantity: item.quantity,
          price: item.price,
        })),
        products: productArrayMapper(el.product),
        totalCost: el.total_cost,
        statusTrace: traceMapper(el.status_change_trace),
        isConsumerConfirmedReceivingGoods: el.isConsumerConfirmedReceivingGoods,
        adIdGeneratedThisOrder: el.ad_id_generated_this_order,
        priority: el.priority,
        status: el.isDeleted
          ? 'deleted'
          : el.isCompleted
          ? 'completed'
          : el.isDisputed
          ? 'disputed'
          : el.isExpired
          ? 'expired'
          : el.status,
        isExpired: el.isExpired,
        isCompleted: el.isCompleted,
        isDisputed: el.isDisputed,
        disputeId: el.dispute_id,
        isDeleted: el.isDeleted,
      };

export const ordersMapper = (data) => arrayMapper(data, singleOrderMapper);

export const adsMapper = (data) => {
  return data.map((el) => {
    const startDate = el.start_date ? dateFormatter(el.start_date) : '';
    const endDate = el.start_date ? dateFormatter(el.end_date) : '';
    const lastBillingDate = el.last_billing_date
      ? dateFormatter(el.last_billing_date)
      : '';
    const nextBillingDate = el.next_billing_date
      ? dateFormatter(el.next_billing_date)
      : '';
    return {
      id: el._id,
      title: el.title || '',
      promotedProducts: el.promoted_products || [],
      categories: el.categories || [],
      emergency: el.emergency_id || '',
      budgetPerDay: el.budget_per_day || '',
      costPerClick: el.cost_per_click || '',
      startDate: startDate,
      endDate: endDate,
      lastBillingDate: lastBillingDate,
      nextBillingDate: nextBillingDate,
      lastBillingStatus: el.last_billing_status || '',
      bills: el.billing_details || [],
      totalAmountSpent: el.total_amount_spent || '',
      status: el.status || '',
      isExpired: el.is_expired,
      isenabled: el.enabled,
      isDeleted: el.isDeleted,
    };
  });
};

export const reviewMapper = (el) => ({
  id: el._id,
  author: !el?.user_id
    ? null
    : {
        id: el.user_id,
        name: el.author_name || '',
        image: el.author_image || '',
        profileImage: imageMapper({
          src: el.author_image,
          altEn: `${el.author_name} Profile Picture`,
          altFr: `Photo de profil ${el.author_name}`,
          croppedArea: el.author_image_cropped_area,
        }),
      },
  ratings: [
    { type: 'overall', value: Number(el.rating) },
    { type: 'quality', value: Number(el.qualityRating) },
    { type: 'communication', value: Number(el.communicationRating) },
    { type: 'value', value: Number(el.valueRating) },
    { type: 'dependability', value: Number(el.dependabilityRating) },
    { type: 'shipping', value: Number(el.shippingRating) },
  ].filter(({ value }) => !Number.isNaN(value)),
  conversation: el.conversation_id || '',
  order: el.order_id || '',
  type: el.review_type,
  title: el.review_title,
  photo: el.photo_url || '',
  text: el.review_text,
  reviewed: {
    id: el.reviewed_id,
    ...(el.review_type === 'product'
      ? {
          title: el.reviewed_product_name || '',
          images: el.reviewed_product_images || [],
        }
      : {
          name: el.reviewed_user_name || '',
          picture: el.reviewed_user_image || '',
          profileImage: imageMapper({
            src: el.reviewed_user_image,
            altEn: `${el.reviewed_user_name} Profile Picture`,
            altFr: `Photo de profil ${el.reviewed_user_name}`,
            croppedArea: el.reviewed_user_image_cropped_area,
          }),
        }),
  },
  productSupplier: supplierMapper({
    _id: el.reviewed_product_supplier_id,
    name: el.reviewed_product_supplier_name,
    logo: el.reviewed_product_supplier_logo,
    subscription_plan_name: el.reviewed_product_supplier_subscription_plan_name,
    logo_cropped_area: el.reviewed_product_supplier_logo_cropped_area,
  }),
  status: el.status || '',
  pending: el.pending || false,
  orderDate: dateMapper(el.order_opening_date),
  createdAt: dateMapper(el.created_at),
  updatedAt: dateMapper(el.updated_at),
});

export const reviewArrayMapper = (data) => arrayMapper(data, reviewMapper);

export const reviewsMapper = (data) => {
  return data.map((el) => {
    let imagesArray = Array.isArray(el.photo_url)
      ? el.photo_url
      : [el.photo_url];
    return {
      id: el._id,
      title: el.review_title || '',
      rating: el.rating || '',
      description: el.review_text || '',
      images: imagesArray || [],
      tags: el.tags || [],
      reviewType: el.review_type || '',
      relatedProductId: el.reviewed_product_id || '',
      relatedProduct: el.reviewed_product_name || '',
      relatedProductImages: el.reviewed_product_images || [],
      relatedUserId: el.reviewed_user_id || '',
      relatedUserName: el.reviewed_user_name || '',
      reviewedUserImage: el.reviewed_user_image || '',
      //relatedOrder: el.reviewed_order_number || '',
      status: el.status || '',
      userId: el.user_id || '',
      reviewerName: el.author_name || '',
      reviewerImage: el.author_image || '',
      createdDate: dateFormatter(el.created_at) || '',
      reviewCreateDate: dateMapper(el.created_at),
      qualityRating: el.qualityRating || 0,
      communicationRating: el.communicationRating || 0,
      valueRating: el.valueRating || 0,
      dependabilityRating: el.dependabilityRating || 0,
      updatedDate: el.updated_at || '',
      isDeleted: el.isDeleted,
    };
  });
};

export const messageMapper = (el) =>
  !el?._id
    ? null
    : {
        conversationId: el?.conversation_id || '',
        messageId: el?._id,
        messageTimestamp: new Date(el?.message_timestamp),
        senderId: el?.sender_id,
        receiverId: el?.receiver_id,
        messageText: el?.message_text || '',
        messageAttachment: el?.message_attachment?.link
          ? [el?.message_attachment]
          : [],
        product: !(el?.product_id && el?.product)
          ? null
          : {
              id: el?.product_id,
              name: el?.product.title,
              description: el?.product.description,
              images: el?.product.images,
              price: el?.product.price,
            },
        order: !el?.order_id
          ? null
          : {
              id: el?.order_id,
              number: el?.order?.order_number,
              deliveryDate: new Date(el?.order?.estimated_delivery_date),
              cost: el?.order?.total_cost,
              status: el?.order?.status,
            },
        status: el?.status,
        isSeen: el?.isSeen,
        isDeleted: el?.isDeleted,
        isEdited: el?.isEdited,
        isAutoGenerated: el?.isAutoGenerated,
        isAdminGenerated: el?.isAdminGenerated,
      };

export const messageArrayMapper = (data, userId) =>
  (data || []).reduce((messageArray, nextMessage) => {
    const { isDeleted, receiver_id, sender_id } = nextMessage || {};
    if (!isDeleted && [sender_id, receiver_id].includes(userId))
      messageArray.push(messageMapper(nextMessage));
    return messageArray;
  }, []);

export const contactUserMapper = (user, id) => ({
  id,
  name: fullName(user),
  picture: user?.profile_picture || FALLBACK_IMAGE_AVATAR,
  location: locationMapper(user?.location),
  profileImage: imageMapper({
    src: user.profile_picture,
    altEn: `${fullName(user)} Profile Picture`,
    altFr: `Photo de profil ${fullName(user)}`,
    croppedArea: user.cropped_area,
  }),
});

export const threadMapper = (el, userId) =>
  !el?._id
    ? null
    : {
        id: el._id,
        contactId: el.sender_id === userId ? el.receiver_id : el.sender_id,
        profile: supplierMapper(el?.profile),
        contactUser:
          el.sender_id === userId
            ? contactUserMapper(el?.receiver, el?.receiver_id)
            : contactUserMapper(el?.sender, el?.sender_id),
        dispute: disputeMapper(el.disputes),
        order: singleOrderMapper(el.order),
        product: !el?.product
          ? null
          : {
              ...productMapper(el.product),
              categoryObject: !(
                el.product?.category?._id && el.product?.category?.en_name
              )
                ? null
                : {
                    id: el.product?.category?._id,
                    name: el.product?.category?.en_name,
                  },
              category: {
                id: el.product?.category?._id,
                englishName: el.product?.category?.en_name,
                frenchName: el.product?.category?.fr_name,
                image: el.product?.category?.image_url,
              },
            },
        rfq: rfqMapper(el.rfq),
        messages: messageArrayMapper(el.messages, userId),
        numberUnread: el.unseen,
        isDeleted: el.isDeleted,
        invoice: invoicesMapper(el.invoice),
        isReviewedBySeller:
          el.isReviewdBySeller || el.isReviewedBySeller || false,
        isReviewedByBuyer: el.isReviewedByBuyer || false,
        createdDate: dateMapper(el.created_at),
        status:
          (el.rfq?.isCanceled
            ? 'rfqCancelled'
            : el.isClosed
            ? 'closed'
            : el.isDisputed
            ? el.dispute_status || 'disputed'
            : el.status) || '',
        ...(el.isDisputed && { nonDisputeStatus: el.status }),
      };

export const threadArrayMapper = (data, userId) =>
  (data || []).reduce((threadArray, nextThread) => {
    const { sender_id, receiver_id, isDeleted } = nextThread || {};
    if ([sender_id, receiver_id].includes(userId) && !isDeleted)
      threadArray.push(threadMapper(nextThread, userId));
    return threadArray;
  }, []);

const inactiveThreadStatuses = [
  'closedSeller',
  'closedBuyer',
  'closed',
  'resolved',
  'refundPayment',
  'rfqCanceled',
  'rfqCancelled',
  'rfqCanceledBySeller',
  'sellerLeftReview',
  'buyerLeftReview',
];

export const markHiddenThreads = (threadArray, threadLimit) =>
  threadArray
    .sort((a, b) => a.createdDate - b.createdDate) // oldest first
    .reduce(
      ([threads, active], nextThread) => {
        const isClosed = inactiveThreadStatuses.includes(nextThread.status);
        const newActive = active + (!isClosed ? 1 : 0);
        threads.push({
          ...nextThread,
          isHidden:
            !(threadLimit === -1 || threadLimit === undefined) &&
            !isClosed &&
            newActive > threadLimit,
        });
        return [threads, newActive];
      },
      [[], 0],
    );

export const adminContactsMapper = (data) =>
  data.map((el) => ({
    id: el._id,
    messages: el.messages.map(messageMapper),
    numberUnread: el.unseen,
    receiver: {
      name: el.receiver.first_name + ' ' + el.receiver.last_name,
      picture: el.receiver.profile_picture || FALLBACK_IMAGE_AVATAR,
      roles: el.receiver.roles || '',
      id: el.receiver_id,
    },
    sender: {
      name: el.sender.first_name + ' ' + el.sender.last_name,
      picture: el.sender.profile_picture || FALLBACK_IMAGE_AVATAR,
      roles: el.sender.roles || '',
      id: el.sender_id,
    },
    isDeleted: el.isDeleted,
    dispute: disputeMapper(el.disputes),
    order: singleOrderMapper(el.order),
    product: productMapper(el.product),
    rfq: rfqMapper(el.rfq),
    invoice: invoicesMapper(el.invoice),
    profile: {
      id: el?.profile?._id,
      rating: el?.profile?.rating || 0,
      name: el?.profile?.name || '',
      contactPerson: el?.profile?.contact_person || '',
      logo: el?.profile?.logo || '',
      badges: el?.profile?.badges || [],
      headerImage: el?.profile?.header_image || [],
      shortDescription: el?.profile?.short_description || [],
      location: locationMapper(el?.profile?.location),
    },
    status:
      (el.isClosed
        ? 'closed'
        : el.isDisputed
        ? el.dispute_status || 'disputed'
        : el.status) || '',
  }));

export const postsMapper = (data, users = {}) => {
  return data.map((el) => {
    const user = users[el.user_id] || {};
    return {
      id: el._id,
      aggregatedFrom: el.aggregated_from || '',
      postTextEnglish: el.post_text || '',
      postTextFrench: el.fr_post_text || '',
      postTitleEnglish: el.post_title || '',
      postTitleFrench: el.fr_post_title || '',
      replyTextEnglish: el.reply_text || '',
      replyTextFrench: el.fr_reply_text || '',
      location: el.location || {},
      userId: el.user_id || '',
      emergency: el.emergency_id || '',
      PhotoUrl: el.photo_url || '',
      postType: el.post_type || '',
      parentPostType: el.parent_post_type || '',
      status: statusChecker(el.status, el.isDeleted),
      replyToId: el.reply_to_id || '',
      isReplyRead: el.is_reply_read || '',
      matchingPosts: el.matching_posts || [],
      tags: el.tags || [],
      views: el.view_count || 0,
      repliesCount: el.replies_count || 0,
      author: user ? user.first_name + ' ' + user.last_name : 'Null',
      itemTypes: [el.item_type_id] || '',
      category: el.category_id || '',
      itemQuantity: el.qty || 0,
      ...locationMapper(el.location, { lines: true }),
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      email: user.email || '',
      products: el.product_id || '',
      unitPrice: el.unit_price || 0,
      createdDate: el.created_at || '',
      updatedDate: el.updated_at || '',
      isDeleted: el.isDeleted,
      isSendNotificationToAuthor:
        el?.isSendNotificationToAuthor?.toString() || '',
    };
  });
};

export const expertsMapper = (data) => {
  return data.map((el) => ({
    id: el._id,
    firstName: el.first_name,
    lastName: el.last_name,
    avatar: el.logo_url || '',
    emergencies: el['emergency_ids'] || [],
    fieldOfResearch: el.field_of_research || '',
    organization: el.organization || '',
    organizationLogo: el.logo_url || '',
    email: el.email,
    phone: el.phone || '',
    website: el.website || '',
    tags: el.tags || [],
    matchedPostIds: el.post_ids || [],
    ...locationMapper(el.location, { lines: true }),
    status: statusChecker(el.status, el.isDeleted),
    author: el.user_id || '',
    isDeleted: el.isDeleted,
  }));
};

export const sellerConnectionMapper = (data) =>
  data.map((el) => ({
    id: el._id,
    contactPerson: el.contact_person,
    name: el.name,
    logo: el.logo,
  }));

export const itemTypesMapper = (data) => {
  return data.map((el) => ({
    id: el._id,
    itemTypeNameEnglish: el.en_name || '',
    itemTypeEnglishAbbreviation: el.en_abbr || '',
    itemTypeEnglishDescription: el.en_description || '',
    itemTypeNameFrench: el.fr_name || '',
    itemTypeFrenchAbbreviation: el.fr_abbr || '',
    itemTypeFrenchDescription: el.fr_description || '',
    imageUrl: el.image_url || '',
    hs6Code: el.hs6_code || '',
    unitOfMeasuring: el.unit || '',
    itemTypeCategory: el.category_id || '',
    isDeleted: el.isDeleted,
  }));
};

export const emergenciesMapper = (data) => {
  return data.map((el) => {
    const startDate = el.start_date ? dateFormatter(el.start_date) : '';
    const endDate = el.end_date ? dateFormatter(el.end_date) : '';
    return {
      id: el._id,
      emergencyEnglishName: el.en_title || '',
      emergencyEnglishDescription: el.en_description || '',
      emergencyFrenchName: el.fr_title || '',
      emergencyFrenchDescription: el.fr_description || '',
      imageUrl: el.image_url || '',
      startDate: startDate,
      endDate: endDate,
      twitter: el.twitter_handle || '',
      status: statusChecker(el.status, el.isDeleted),
      isDeleted: el.isDeleted,
    };
  });
};

export const regulationsMapper = (data) => {
  return data.map((el) => {
    const publishingDate = el.published_on
      ? dateFormatter(el.published_on)
      : '';
    return {
      id: el._id,
      issuerNameEnglish: el.en_issuer_name,
      issuerNameFrench: el.fr_issuer_name || '',
      titleEnglish: el.en_title,
      titleFrench: el.fr_title || '',
      tags: el.tags || [],
      status: statusChecker(el.status, el.isDeleted),
      regulationTextEnglish: el.en_description || '',
      regulationTextFrench: el.fr_description || '',
      sourceUrlEnglish: el.en_source_url || '',
      sourceUrlFrench: el.fr_source_url || '',
      publishingDate: publishingDate,
      emergencies: el['emergency_ids'] || [],
      itemTypes: el['item_type_ids'] || [],
      itemTypeNames: el['item_type_names'] || [],
      matchedPostIds: el['post_ids'] || [],
      author: el.user_id || '',
      isDeleted: el.isDeleted,
    };
  });
};

export const biosafetyMapper = (data) => {
  return data.map((el) => ({
    id: el._id,
    labName: el.lab_name || '',
    labLevel: el.lab_level,
    organization: el.organization || '',
    organizationLogo: el.logo_url || '',
    contactPerson: el.contact_person || '',
    email: el.email || '',
    phone: el.phone || '',
    website: el.website || '',
    descriptionOfServices: el.services_offered || '',
    tags: el.tags || [],
    ...locationMapper(el.location, { lines: true }),
    status: statusChecker(el.status, el.isDeleted),
    emergencies: el.emergency_ids || [],
    author: el.user_id || '',
    isDeleted: el.isDeleted,
  }));
};

export const userMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        firstName: el.first_name,
        lastName: el.last_name,
        fullName: fullName(el),
        photoUrl: el.photo_url || '',
        profilePicture: el.profile_picture || '',
        profileImage: imageMapper({
          src: el.profile_picture,
          altEn: `${fullName(el)} Profile Picture`,
          altFr: `Photo de profil ${fullName(el)}`,
          croppedArea: el.cropped_area,
        }),
        phone: el.phone || '',
        phoneCountry: el.phone_country_code || '',
        language: languageMapper(el.language) || 'en',
        languageCode: el.language || 'en',
        roles: el.roles,
        email: el.email,
        organization: el.organization,
        organizationType: el.organization_type || '',
        specifyOrganization: el.specify_organization || '',
        emailVerifiedAt: el.email_verified_at,
        isEmailValidated: el.isEmailValidated,
        lastIpAddress: el.lastActivityIp,
        lastVisitDate: el.lastActivity ? dateFormatter(el.lastActivity) : '',
        location: locationMapper(el.location, { lines: true }),
        billingLocation: locationMapper(el.billing_location, { lines: true }),
        subscriptions: el.subscriptions || [],
        emailSubscriptions: el.email_subscriptions || {},
        categories: el.categoriesOfInterest
          ? el.categoriesOfInterest.map((category) =>
              singleCategoryMapper(category),
            )
          : el.category_ids || [],
        status: statusChecker(el.status, el.isDeleted),
        isDeleted: el.isDeleted,
        viewPermissions: el.viewPermissions || [],
        postPermissions: el.postPermissions || [],
        managePermissions: el.managePermissions || [],
        twoFactorEnable: el.two_factor_enable || false,
        sameAsPrimaryAddress: el.billing_location_same_as_primary_location,
        accessKeys: (el.access_key || []).map((key) => ({
          token: key.token,
          secret: key.secret,
          createdAt: dateMapper(key.created_at),
          isDeleted: key.isDeleted,
        })),
        disputesCount: el.disputes_count || 0,
        ordersCount: el.orders_count || 0,
        rfqsCount: el.rfqs_count || 0,
        spentCount: el.spended_count || 0,
        rating: el.rating || 0,
        reviewCount: el.review_count || 0,
        stripeConnected: el.stripe_account_connected || false,
        stripeId: el.stripe_connect_account_id || '',
        followedSuppliers: (el.followed_suppliers || []).map((followed) => ({
          id: followed.supplier_id,
          supplier: supplierMapper(followed.supplier),
          date: dateMapper(followed.follow_date),
        })),
        checkoutId: el.checkout_subscription_session_id,
        savedProducts: (el.saved_products || []).map((saved) => ({
          productId: saved.product_id,
          supplierId: saved.supplier_id,
          date: dateMapper(saved.save_date),
        })),
        feedsCount: el.feedsCount || 0,
        followedSuppliersCount: el.followedSuppliersCount || 0,
        createdDate: new Date(el.created_at),
        supplierAccount: supplierMapper(el.supplierAccount),
        activeConversations: el.active_conversations_count || 0,
        activeOfferPosts: el.active_offer_posts_count || 0,
      };

export const userArrayMapper = (data) => arrayMapper(data, userMapper);

export const likedByMapper = (data) =>
  arrayMapper(data, (el) =>
    !el?.user_id
      ? null
      : { userId: el?.user_id, likeDate: dateMapper(el?.like_date) },
  );

export const commentMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        logo: el.author_logo || '',
        name: el.author_name || '',
        location: locationMapper(el.author_location),
        rating: el.author_rating || 0,
        ratingCount: el.author_review_count || 0,
        defaultCurrency: el.default_currency || 'CAD',
        enMessage: el.en_message || '',
        frMessage: el.fr_message || '',
        entityId: el.entity_id || '',
        entityType: el.entity_type || '',
        image: el.image || '',
        likedBy: likedByMapper(el.liked_by),
        likedCount: el.like_count || 0,
        link: el.link || '',
        parentFeedId: el.parent_feed_id || '',
        seenBy: el.seen_by || [],
        seenCount: el.seen_count || 0,
        tags: el.tags || [],
        type: el.type || '',
        userId: el.user_id,
        video: el.video || '',
        author: {
          id: el.user_id,
          name: el.author_name || '',
          logo: el.author_logo || '',
          roles: el.author_role || '',
          rating: el.author_rating || 0,
          ratingCount: el.author_review_count || 0,
          location: locationMapper(el.author_location),
          profileImage: imageMapper({
            src: el?.author_logo,
            altEn: `${el?.author_name} Profile Picture`,
            altFr: `Photo de profil ${el?.author_name}`,
            croppedArea: el.author_logo_cropped_area,
          }),
        },
        isDeleted: el.isDeleted,
        createdDate: new Date(el.created_at),
        updatedDate: new Date(el.updated_at),
      };

export const commentArrayMapper = (data) => arrayMapper(data, commentMapper);

export const feedMapper = (el) =>
  !el?._id
    ? null
    : {
        id: el._id,
        userId: el.user_id || '',
        postTextEn: el.en_message || '',
        postTextFr: el.fr_message || '',
        entity: el.entity_id || '',
        entityType: el.entity_type || '',
        link: el.link || '',
        tags: el.tags || [],
        postTag: el.type || '',
        video: el.video || '',
        listing: listingMapper({
          _id: el.product_id || '',
          title: el.product_title || '',
          description: el.product_description || '',
          price: el.post_unit_price || el.product_price || '',
          qty: el.post_qty || el.product_moq || '',
          images: [el.image || el.post_image].filter((el) => el),
          supplier_id: el.supplier_id || '',
          supplier_name: el.supplier_name || '',
          supplier_logo: el.supplier_logo || '',
          default_currency: el.default_currency || 'CAD',
          category_id: el?.post_category_id || '',
          category_name: el?.post_category_name || '',
        }),
        productId: el.product_id || '',
        productTitle: el.product_title || '',
        productMoq: el.product_moq || '',
        productDescription: el.product_description || '',
        productPrice: el.product_price || '',
        defaultCurrency: el.default_currency || 'CAD',
        city: el.location?.city || '',
        province: el.location?.province || '',
        supplierName: el.supplier_name || '',
        supplierLogo: el.supplier_logo || '',
        supplierId: el.supplier_id || '',
        supplierCity: el.supplier_city || '',
        supplierProvince: el.supplier_province || '',
        supplier: supplierMapper({
          _id: el?.supplier_id,
          name: el?.supplier_name,
          logo: el?.supplier_logo,
          logo_cropped_area: el?.supplier_logo_cropped_area,
          subscription_plan_name: el?.supplier_subscription_plan_name,
          location: {
            city: el?.supplier_city,
            province: el?.supplier_province,
          },
        }),
        author: {
          id: el.user_id,
          name: el.author_name || '',
          logo: el.author_logo || '',
          rating: el.author_rating || 0,
          ratingCount: el.author_review_count || 0,
          location: locationMapper(el.author_location),
          profileImage: imageMapper({
            src: el?.author_logo,
            altEn: `${el?.author_name} Profile Picture`,
            altFr: `Photo de profil ${el?.author_name}`,
            croppedArea: el.author_logo_cropped_area,
          }),
        },
        rfq: rfqMapper(el.rfq),
        title: el.post_title || '',
        unitPrice: el.post_unit_price || '',
        quantity: el.post_qty || '',
        image: el.image || el.post_image || '',
        postImage: imageMapper({
          src: el?.image,
          alt: el?.post_title || '',
          croppedArea: el.post_image_cropped_area,
        }),
        description: el.post_description || '',
        category: { id: el.post_category_id, name: el.post_category_name },
        likedBy: likedByMapper(el.liked_by),
        seenBy: el.seen_by || [],
        likeCount: Number(el.like_count || 0),
        commentCount: Number(el.comments_count || 0),
        parentFeedId: el.parent_feed_id || null,
        isDeleted: el.isDeleted || false,
        createdDate: dateMapper(el.created_at),
        updatedDate: dateMapper(el.updated_at),
      };

export const feedArrayMapper = (data) => arrayMapper(data, feedMapper);

export const siteSettingsMapper = (data) => {
  return {
    siteNameEnglish: data.en_site_name,
    siteNameFrench: data.fr_site_name,
    siteURL: data.site_url,
    // imagesLocation: data.images_location,
    configFileLocation: data.config_file_location,
    maximumUploadSize: data.maximum_upload_size,
    backupFrequency: data.backup_frequency,
  };
};

export const rolesMapper = (data) =>
  data?.map((el) => ({
    id: el.id,
    roleNameEnglish: el.name_en,
    roleNameFrench: el.name_fa,
    roleDescriptionEnglish: el.description_en,
    roleDescriptionFrench: el.description_fa,
    status: el.isDeleted ? 'deleted' : el.status ? 'enabled' : 'disabled',
    isDeleted: el.isDeleted,
    viewPermissions: el.viewPermissions,
    postPermissions: el.postPermissions,
    managePermissions: el.managePermissions,
    creationDate: el.creation_date,
    updateDate: el.update_date,
  }));

export const siteComponentMapper = (data) => ({
  id: data.id,
  componentNameEnglish: data.name_en,
  componentNameFrench: data.name_fr,
});

export const pluginMapper = (data) => {
  return data.map((el) => {
    const lastRun = el.last_run ? dateFormatter(el.last_run * 1000) : 'Never';
    var runningFrequency = [];
    runningFrequency['1440'] = 'Daily';
    runningFrequency['10080'] = 'Weekly';
    runningFrequency['43200'] = 'Monthly';

    return {
      id: el._id,
      pluginName: el.plugin_name,
      scriptPath: el.script_path,
      dataSourceEnName: el.data_source_en_name,
      dataSourceFrName: el.data_source_fr_name,
      dataSourceUrl: el.data_source_url,
      currentState: el.current_state,
      collectionName: el.collection_name,
      entriesFetched: el.entries_fetched,
      runningCadenceMins: el.running_cadence_mins.toString(),
      runningFrequency:
        runningFrequency[el.running_cadence_mins.toString()] ||
        el.running_cadence_mins,
      lastRun: lastRun,
      pluginModule: el.plugin_module,
      pluginFiles: el.plugin_files,
      status: el.status,
      isDeleted: el.is_deleted,
      username: el.username,
      password: el.password,
    };
  });
};

export const notificationMapper = (el, userId) => ({
  id: el._id,
  enMessage: el.en_message || '',
  frMessage: el.fr_message || '',
  arMessage: el.ar_message || '',
  group: el.group || [],
  icon: el.icon?.profile_picture ?? el.icon?.logo ?? el.icon ?? '',
  iconImage: imageMapper({
    src: el.icon?.profile_picture ?? el.icon?.logo ?? el.icon,
    alt: el.name,
    croppedArea: el.icon?.cropped_area ?? el.icon?.logo_cropped_area,
  }),
  isUsingDefaultImage: el.isUsingDefaultImage,
  link: el.link || '',
  name: el.name || '',
  isSeen: (el.isSeenBy || [])?.includes(userId),
  isClicked: (el.isClickedBy || [])?.includes(userId),
  createdAt: dateMapper(el.created_at),
  updatedAt: dateMapper(el.updated_at),
  fromUserId: el.from_user_id || '',
  toUserId: el.to_user_id || '',
  threadId: el.thread_id || '',
  supplier: supplierMapper(el.supplier),
  supplierId: el.supplier_id,
  post: feedMapper(el.post),
  postId: el.post_id || '',
  product: listingMapper(el.product),
  productId: el.product_id,
});

export const notificationArrayMapper = (data, userId) =>
  arrayMapper(data, (el) => notificationMapper(el, userId));

export const socketNotificationMapper = ({
  eventObj: obj,
  receiver_id,
  sender_id,
}) => ({
  id: obj._id,
  enMessage: obj.en_message || '',
  frMessage: obj.fr_message || '',
  arMessage: obj.ar_message || '',
  //   enMessage: obj?.message_template?.en_template || '',
  //   frMessage: obj?.message_template?.fr_template || '',
  group: obj.group || [],
  icon: obj.icon || '',
  iconImage: imageMapper({
    src: obj.icon?.profile_picture ?? obj.icon,
    alt: obj.name,
    croppedArea: obj.icon?.cropped_area,
  }),
  isSeen: false,
  isUsingDefaultImage: obj.isUsingDefaultImage,
  link: obj.link || '',
  name: obj.name,
  seenBy: obj.seen_by,
  updatedAt: dateMapper(obj.updated_at),
  createdAt: dateMapper(obj.created_at),
  fromUserId: sender_id || '',
  toUserId: receiver_id || '',
});

export const paymentMethodMapper = (el) => ({
  id: el.id || null,
  brand: el.brand || '',
  expMonth: el.exp_month || null,
  expYear: el.exp_year || null,
  lastFour: el.last_four || null,
  isDefault: el.is_default || false,
});

export const paymentMethodArrayMapper = (data) =>
  arrayMapper(data, paymentMethodMapper);

export const inviteMapper = (el) => ({
  id: el?._id,
  supplierId: el?.business_id || '',
  invitedEmail: el?.invited_email || '',
  userId: el?.user_id || '',
  invitedUserRole: el?.invited_user_role || '',
  method: el?.method || '',
  status: el?.status || '',
  token: el?.token || '',
  type: el?.type || '',
  approvalDate: dateMapper(el?.approval_date),
  createdAt: dateMapper(el?.created_at),
  updatedAt: dateMapper(el?.updated_at),
  expireAt: dateMapper(el?.expires_in),
});

export const inviteArrayMapper = (data) => arrayMapper(data, inviteMapper);

const escrowNameMap = (name) =>
  fullName(name, { first: 'buyer_first_name', last: 'buyer_last_name' });

export const escrowMapper = (el) => ({
  id: el._id,
  creationDate: el.creation_date || '',
  escrowHoldDate: el.escrow_hold_date || '',
  escrowReleaseDate: el.escrow_release_date || '',
  escrowStatus: el.escrow_status || '',
  totalAmount: el.amount || '',
  status: el.status || [],
  fromAccount: el.from_account || '',
  order: singleOrderMapper(el.order_details),
  invoice: invoicesMapper(el.invoice_details),
  products: el.products_list ? [...el.products_list] : [],
  transactionNumber: el.transaction_number || '',
  defaultCurrency: el.default_currency || '',
  supplier: supplierMapper(el.supplier_details),
  buyer: !el.buyer_id
    ? null
    : {
        id: el.buyer_id,
        name: el.buyer_first_name + ' ' + el.buyer_last_name,
        profilePicture: el.buyer_profile_picture,
        profileImage: imageMapper({
          src: el.buyer_profile_picture,
          altEn: `${escrowNameMap(el)} Profile Picture`,
          altFr: `Photo de profil ${escrowNameMap(el)}`,
          croppedArea: el.buyer_profile_cropped_area,
        }),
      },
  statusTrace: traceMapper(el.status_change_trace),
  escrowStatusTrace: traceMapper(el.escrow_status_change_trace),
});

export const escrowArrayMapper = (data) => arrayMapper(data, escrowMapper);

// function provinceMapper(province){
//   switch (province){
//     case 'Newfoundland and Labrador':
//       return 'NL';
//       break;
//     case 'Prince Edward Island':
//       return 'PE';
//       break;
//     case 'Nova Scotia':
//       return 'NS';
//       break;
//     case 'New Brunswick':
//       return 'NB';
//       break;
//     case 'Quebec':
//       return 'QC';
//       break;
//     case 'Ontario':
//       return 'ON';
//       break;
//     case 'Manitoba':
//       return 'MB';
//       break;
//     case 'Saskatchewan':
//       return 'SK';
//       break;
//     case 'Alberta':
//       return 'AB';
//       break;
//     case 'British Columbia':
//       return 'BC';
//       break;
//     case 'Yukon':
//       return 'YT';
//       break;
//     case 'Northwest Territories':
//       return 'NT';
//       break;
//     case 'Nunavut':
//       return 'NU';
//       break;
//     default:
//       break;
// }
// }

function languageMapper(languageCode) {
  if (languageCode === 'en') return 'English';
  else if (languageCode === 'fr') return 'French';
  else if (languageCode === 'ar') return 'Arabic';
  else return ' ';
}

function statusChecker(status, isDeleted) {
  if (isDeleted) return 'deleted';
  else return status;
}

const counterStatusChecker = (offers) =>
  !offers.length ? 'none' : [...offers].pop().offer_status || 'new';

export const noMapper = (data) => data;

export const sellerPlanMapper = (el) =>
  !el?.name
    ? null
    : {
        name: (el?.name || '').toLowerCase(),
        maxEmailsPerOffer: el?.MaxEmailsPerOffer,
        maxMonthlyOffers: el?.MaxMonthlyOffers,
        maxOpenThreads: el?.MaxOpenThreads,
        planTransactionFee: el?.PlanTransactionFee,
        publicContactDetails: el?.PublicContactDetails,
      };

export const sellerPlanArrayMapper = (data) =>
  arrayMapper(data, sellerPlanMapper);

export const planByPrice = (amount, interval) =>
  SUBSCRIPTION_PLANS.find(({ cost }) => cost?.[interval || 'month'] === amount)
    ?.id || 'basic';

export const subscriptionPlanMapper = (data) => {
  const basicPlan = { type: 'basic' };

  const { payment_method: { card } = {}, subscriptions } = data;
  const [currentSub] = subscriptions?.data || [];
  if (!currentSub) return basicPlan;

  const { plan: { amount, interval } = {} } = currentSub || {};
  const currency = currentSub?.plan?.currency?.toUpperCase() || 'USD';
  const { brand, last4 } = card;

  const endDate = dateMapper(currentSub?.current_period_end);
  if (!endDate || endDate < new Date()) return basicPlan;

  const type = planByPrice(amount, interval);
  return {
    type,
    endDate,
    interval,
    currency,
    startDate: dateMapper(currentSub?.current_period_start),
    renewDate: dateMapper(currentSub?.created),
    planFee: amount / 100,
    cardBrand: brand,
    cardLast4Digit: last4,
    isPaidSub: PAID_SUBSCRIPTIONS_PLANS.includes(type),
  };
};

export function subtotal({ values }) {
  return Number(
    (
      Number(values?.['bidUnitPrice'] || 0) *
      Number(values?.['bidQuantity'] || 0)
    ).toFixed(2),
  );
}

export function taxValue({ values }, percent) {
  return Number(((subtotal({ values }) * percent) / 100).toFixed(2));
}

export function taxTotal({ values }) {
  return Number(
    values['bidTaxes']
      .reduce(
        (taxSum, { percent }) =>
          taxSum + Number(((subtotal({ values }) * percent) / 100).toFixed(2)),
        0,
      )
      .toFixed(2),
  );
}

export function sellerPayout({ values }) {
  return Number(
    (
      subtotal({ values }) +
      taxTotal({ values }) +
      Number(values['bidShipping'])
    ).toFixed(2),
  );
}

export function serviceTotal({ values }) {
  return Number(
    ((sellerPayout({ values }) * values.serviceFeePercent) / 100).toFixed(2),
  );
}

export function nonRefundableFee({ values }, nonRefundablePercent) {
  return Number(
    ((sellerPayout({ values }) * nonRefundablePercent) / 100).toFixed(2),
  );
}

export function totalPayment({ values }) {
  return Number(
    (sellerPayout({ values }) + serviceTotal({ values })).toFixed(2),
  );
}

export function consumerRefundable({ values }, nonRefundablePercent) {
  return Number(
    (
      totalPayment({ values }) -
      nonRefundableFee({ values }, nonRefundablePercent)
    ).toFixed(2),
  );
}

export function databaseTotalMapper({ values }) {
  return {
    sale_taxes_applied: (values?.bidTaxes || []).map(
      ({ id, percent, specifiedName }) => ({
        name: specifiedName || id,
        percentage: percent,
        value: taxValue({ values }, percent),
      }),
    ),
    subtotal: subtotal({ values }),
    sale_taxes_total: taxTotal({ values }),
    service_fee: serviceTotal({ values }),
    non_refundable_service_fee: nonRefundableFee(
      { values },
      values?.nonRefundableServiceFeePercent,
    ),
    total_sum: totalPayment({ values }),
    supplier_total_payout: sellerPayout({ values }),
    consumer_total_refundable: consumerRefundable(
      { values },
      values?.nonRefundableServiceFeePercent,
    ),
    service_fee_percentage: values?.serviceFeePercent,
    non_refundable_fee_percentage: values?.nonRefundableServiceFeePercent,
  };
}

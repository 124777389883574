import { useCallback } from 'react';
import ReactGA from 'react-ga4';

// eventTracker takes GA object as input: { category = '', action = '', label = '', debugMode: false }
// category defaults to path
// action required to capture analytics event

const useEventTracker = () => {
  const { pathname } = window.location;
  const eventTracker = useCallback(
    (GA) => {
      const { category = pathname, debugMode = false, action, label = '' } =
        GA || {};
      if (!action) return;

      const eventPayload = { category, action, label };
      if (debugMode) console.log(eventPayload);
      ReactGA.event(eventPayload);
    },
    [pathname],
  );
  return eventTracker;
};

export default useEventTracker;

import React from 'react';
import MuiText from './MuiText';
import Flex from '../common/Flex';
import Skeleton from '@mui/material/Skeleton';

const MuiTag = ({
  id,
  dm,
  onClick,
  defaultMessage,
  color,
  textColor = 'white',
  children,
  sx,
  sxText,
  loading,
  sxSkeleton,
  ...rest
}) => {
  if (defaultMessage) dm = defaultMessage;

  const style = {
    box: {
      sx: {
        px: '1rem',
        py: '0.25rem',
        borderRadius: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color || 'primary.main',
        ...sx,
      },
    },
    text: { color: textColor, sx: sxText, ...rest },
    skeleton: {
      variant: 'rectangular',
      sx: { borderRadius: '5px', maxWidth: 'unset', ...sxSkeleton },
    },
  };

  return loading ? (
    <Skeleton {...style.skeleton}>
      <Flex {...style.box}>
        <MuiText {...style.text}>.</MuiText>
      </Flex>
    </Skeleton>
  ) : (
    <Flex {...style.box}>
      <MuiText {...{ id, dm, onClick, ...style.text }}>{children}</MuiText>
    </Flex>
  );
};

export default MuiTag;

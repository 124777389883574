// takes something that might be a function, and the input for the potential function
// if the first argument is a function, it returns the function calling the given inputs,
// if not a function it just returns the first argument

const checkFunc = (possibleFunc, ...funcInputs) =>
  typeof possibleFunc === 'function'
    ? possibleFunc(...funcInputs)
    : possibleFunc;

export default checkFunc;

import { useCallback } from 'react';
import { useContext } from 'react';
import { BreakpointContext } from '../components/wrappers/BreakpointWrapper';

const breakpointNames = [
  'xs',
  'verySmallPhone',
  'narrowPhone',
  'sm',
  'md',
  'lg',
  'maxContent',
  'xl',
  'xxl',
];

const useParseBreakpointObject = () => {
  const { up } = useContext(BreakpointContext);

  const parseBreakpoint = useCallback(
    (valueObj) =>
      typeof valueObj !== 'object'
        ? valueObj
        : breakpointNames.reduce(
            (currentValue, nextBreakpoint) =>
              up[nextBreakpoint]
                ? valueObj[nextBreakpoint] ?? currentValue
                : currentValue,
            '',
          ),
    [up],
  );

  return parseBreakpoint;
};

export default useParseBreakpointObject;

import React, { createContext, useCallback, useMemo } from 'react';
// import ReactPixel from 'react-facebook-pixel';

export const FacebookPixelContext = createContext();

//https://developers.facebook.com/docs/meta-pixel/reference
export const standardEvents = [
  'AddPaymentInfo', // optional data keys: content_category, content_ids, contents, currency, value
  'AddToCart', // required for Advantage+ Catalog Ads: content_type, contents. optional data keys: content_ids, content_name, content_type, contents, currency, value
  'AddToWishlist', // optional data keys: content_name, content_category, content_ids, contents, currency, value
  'CompleteRegistration', // optional data keys: content_name, currency, status, value
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout', // optional data keys: content_category, content_ids, contents, currency, num_items, value
  'Lead', // optional data keys: content_category, content_name, currency, value
  'Purchase', // required: currency, value. required for Advantage+ Catalog Ads: content_type and contents, or content_ids. optional data keys: content_ids, content_name, content_type, contents, currency, num_items, value
  'Schedule',
  'Search', // optional data keys: content_category, content_ids, contents, currency, search_string, value
  'StartTrial', // optional data keys: currency, predicted_ltv, value
  'SubmitApplication',
  'Subscribe', // optional data keys: currency, predicted_ltv, value
  'ViewContent', // required for Advantage+ Catalog Ads: content_type and contents, or content_ids. optional data keys: content_ids, content_category, content_name, content_type, contents, currency, value
];

const FacebookPixelWrapper = ({ children }) => {
  const pixelId = '769944267491988';

  //   useEffect(() => {
  //     const advancedMatching = {};
  //     const options = { autoConfig: true, debug: false };

  //     ReactPixel.init(pixelId, advancedMatching, options);
  //     ReactPixel.pageView();
  //   }, [pixelId]);

  const pixelEvent = useCallback(
    () => {},
    [],
    // ({ id = pixelId, event, data }) => {
    //   if (!event) return;
    //   const eventType =
    //     'trackSingle' + (!standardEvents.includes(event) ? 'Custom' : '');

    //   ReactPixel[eventType](id, event, data);
    // },
    // [pixelId],
  );

  const value = useMemo(
    () => ({
      //  facebookPixel: ReactPixel,
      pixelId,
      pixelEvent,
    }),
    [pixelId, pixelEvent],
  );
  return (
    <FacebookPixelContext.Provider {...{ value }}>
      {children}
    </FacebookPixelContext.Provider>
  );
};

export default FacebookPixelWrapper;

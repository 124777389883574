// src/components/AppBar.jsx
//
// Default header for pages. Contains the navigation menu, site logo, and
// search and filter functionalities.

import { Box, Flex, Heading, Link, Stack } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useToaster from '../../hooks/useToaster';
import { logout } from '../../reducers/authReducer';
import LoginModal from '../authentication/LoginModal';
import RegisterModal from '../authentication/RegisterModal';
import FormattedButton from '../intl/FormattedButton';
import FormattedText from '../intl/FormattedText';
import ExpandedDrawer from '../navbar/ExpandedDrawer';
import ChangeLanguage from './ChangeLanguage';
import EmergencySelector from './EmergencySelector';
import FontResizer from './FontResizer';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { FaHome, AiOutlineLogout } from 'react-icons/all';
import useAuth from '../../hooks/useAuth';

function AppBar({ withPanel }) {
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const { isLoading, isAuthenticated } = useAuth();
  const { siteNameEnglish, siteNameFrench } = useSelector(
    ({ settings }) => settings.siteInfo,
  );
  const { locale } = useSelector(({ intl }) => intl);

  const handleLogout = () => {
    push('/');
    dispatch(logout());
    toaster('logout');
  };

  const navigateHome = () => {
    push('/');
  };

  return (
    <Flex
      as="header"
      shadow="sm"
      px={2}
      py={1}
      width="100%"
      minHeight="48px"
      zIndex="sticky"
      position={withPanel ? 'fixed' : 'fixed'}
      top={0}
      align={{ base: 'flex-start', md: 'center' }}
      backgroundColor="#fff"
      direction={{ base: 'column', md: 'row' }}
    >
      <Flex justifyContent="space-between" flex={1}>
        {/*Menu and Logo*/}
        <Flex align="center">
          {!withPanel && (
            <Box ml={0} fontWeight="bold">
              <ExpandedDrawer btnIcon={'\u2630'} />
            </Box>
          )}
          {!withPanel && (
            <Heading
              fontSize={{ base: 'md', sm: 'lg' }}
              as="h1"
              maxW="400px"
              ml={{ base: 4, sm: 6 }}
              mr={4}
              fontWeight={500}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {locale === 'en' ? (
                <Link onClick={navigateHome}>{siteNameEnglish}</Link>
              ) : locale === 'fr' ? (
                <Link onClick={navigateHome}>{siteNameFrench}</Link>
              ) : (
                <FormattedText id="appbar.logo" defaultMessage="Tradvo" />
              )}
            </Heading>
          )}
          <Box
            as={Link}
            display={{ base: 'none', md: 'block' }}
            href="#main"
            left="425px"
            position="absolute"
            transform="translateY(-100px)"
            _focus={{
              transform: 'translateY(0%)',
            }}
          >
            {formatMessage({
              id: 'navigation.skip',
              defaultMessage: 'Skip to main',
            })}
          </Box>
        </Flex>

        {/*Search and Accessibility*/}
        <Stack
          flex={1}
          display="flex"
          justifyContent={{ base: 'flex-end', lg: 'center' }}
          isInline
          spacing={{ base: 0, lg: 4 }}
        >
          {/*<SearchBar display={{ base: 'none', sm: 'flex' }} />*/}
          {!withPanel && (
            <EmergencySelector display={{ base: 'none', md: 'flex' }} />
          )}
          <FontResizer display={{ base: 'none', lg: 'flex' }} />
          <ChangeLanguage display={{ base: 'none', lg: 'flex' }} />
        </Stack>

        {/*Authentication*/}
        <Box
          display={{ base: 'none', lg: 'flex' }}
          alignItems="center"
          justifyContent="flex-end"
        >
          {!isLoading && isAuthenticated ? (
            <Stack
              isInline
              shouldWrapChildren
              spacing={2}
              mx={2}
              alignItems="center"
              position="fixed"
              right={2}
            >
              {withPanel && <FaHome color="#737373" size="25px" />}
              {withPanel && (
                <FormattedButton
                  variant="link"
                  colorScheme="blue"
                  onClick={navigateHome}
                  id="auth.home"
                  defaultMessage="Home"
                  mr={4}
                />
              )}
              {withPanel && <AiOutlineLogout color="#737373" size="25px" />}
              <FormattedButton
                variant="link"
                colorScheme="blue"
                onClick={handleLogout}
                id="auth.logout"
                defaultMessage="Logout"
                mr={4}
              />
            </Stack>
          ) : (
            <Stack
              isInline
              shouldWrapChildren
              spacing={4}
              mx={2}
              alignItems="center"
            >
              <RegisterModal />
              <LoginModal />
              <FormattedButton
                colorScheme="blue"
                id="appbar.sell"
                size="sm"
                onClick={() => push('/register-supplier')}
              />
            </Stack>
          )}
        </Box>
      </Flex>
      <Flex
        display={{ base: 'flex', md: 'none' }}
        mt={1}
        width="100%"
        justify="flex-end"
      >
        <Box
          as={Link}
          href="#main"
          left="8px"
          position="absolute"
          transform="translateY(-200px)"
          _focus={{
            transform: 'translateY(8px)',
          }}
        >
          {formatMessage({
            id: 'navigation.skip',
            defaultMessage: 'Skip to main content',
          })}
        </Box>
        <EmergencySelector />
      </Flex>
    </Flex>
  );
}

export default AppBar;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  listingArrayMapper,
  categoriesMapper,
  supplierMapper,
  userMapper,
} from '../utils/fetchMapper';

const useAuth = ({ debugMode } = {}) => {
  const auth = useSelector(({ auth }) => auth);

  return useMemo(() => {
    const mappedAuth = {
      ...auth,
      user: auth?.user ? userMapper(auth.user) : null,
      supplier: auth?.supplier ? supplierMapper(auth.supplier) : null,
      categories: auth?.categories ? categoriesMapper(auth.categories) : null,
      listings: auth?.listings ? listingArrayMapper(auth.listings) : null,
      supplierLoading: auth?.isSupplierLoading,
    };
    if (debugMode) console.log({ auth: mappedAuth });
    return mappedAuth;
  }, [auth, debugMode]);
};

export default useAuth;

import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ORGANIZATION_TYPES } from '../../data/constants';
import useBreakpoint from '../../hooks/useBreakpoint';
import useGetData from '../../hooks/useGetData';
import useLocale from '../../hooks/useLocale';
import useRecaptcha from '../../hooks/useRecaptcha';
import useSnackbar from '../../hooks/useSnackbar';
import useValidator from '../../hooks/useValidator';
import { login } from '../../reducers/authReducer';
import { supplierMapper } from '../../utils/fetchMapper';
import tryClient from '../../utils/tryClient';
import Flex from '../common/Flex';
import ImageBox from '../common/ImageBox';
import MuiLink from '../common/MuiLink';
import MuiModal from '../common/MuiModal';
import MuiCheckbox from '../forms/MuiCheckbox';
import MuiFormField from '../forms/MuiFormField';
import MuiMultiStep, { MuiFormStep } from '../forms/MuiMultiStep';
import MuiSelect from '../forms/MuiSelect';
import MuiText from '../intl/MuiText';

const FollowSignupModal = ({ modalControl, followId, seller }) => {
  const recaptchaVerification = useRecaptcha();
  const consumerRoleId = '3';
  const { md } = useBreakpoint();
  const { attemptFormat, nameSort, isEnglish, isFrench, locale } = useLocale();
  const validator = useValidator();
  const { snackbar } = useSnackbar();
  const dispatch = useDispatch();

  const typeOptions = useMemo(
    () => [
      ...nameSort(
        ORGANIZATION_TYPES.filter(({ roles }) =>
          roles.includes(consumerRoleId),
        ),
      ),
      { id: 'other', name: isEnglish ? 'Other' : isFrench ? 'Autre' : 'أخرى' },
    ],
    [nameSort, isEnglish, isFrench, consumerRoleId],
  );

  const { data, loading } = useGetData({
    endpoint:
      modalControl.open && followId && !seller && `/suppliers/${followId}`,
    dataHandler: useCallback(({ data }) => supplierMapper(data), []),
    initialValue: {},
  });

  const { logoImage, name, planName } = seller || data;
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    organizationType: '',
    password: '',
    termsChecked: false,
  };

  const validationSchema = validator((schema) => ({
    ...schema.firstName(),
    ...schema.lastName(),
    ...schema.email(),
    ...schema.organization(),
    ...schema.organizationType({ required: false }),
    ...schema.password(),
    ...schema.requiredCheck({ name: 'termsChecked' }),
  }));

  const resetForm = (err, values, { setTouched, setValues, setErrors }) => {
    setValues({ ...values, password: '' }, false);
    if (err.status !== 422 || !err.error?.email)
      return snackbar({ message: 'registerError' });

    setTouched({ email: true }, false);
    setErrors({
      email: attemptFormat({
        id: 'auth.register.userExists',
        dm: 'That email address is already taken',
      }),
    });
  };

  const handleSubmit = async (values, actions) => {
    const newUser = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      emailConfirm: values.email,
      password: values.password,
      password_confirmation: values.password,
      roles: consumerRoleId,
      organization: values.organization,
      organizationType: values.organizationType,
      two_factor_enable: false,
      language: locale,
      ...(followId && { business_id: followId }),
    };

    if (!(await recaptchaVerification())) return;

    const { e: registerErr } = await tryClient('/register', {
      method: 'POST',
      body: newUser,
    });

    if (registerErr) return resetForm(registerErr, values, actions);

    const { email, password } = newUser;
    const { err } = await dispatch(login({ email, password }));
    if (err) snackbar({ message: 'loginError' });
    snackbar({ message: 'loginSuccess' });
    modalControl.handleClose();
  };

  const { text, style, field } = makeProps({
    md,
    modalControl,
    name,
    loading,
    planName,
  });
  return (
    <MuiModal {...style.modal}>
      <MuiMultiStep
        {...style.form}
        {...{ initialValues, validationSchema, onSubmit: handleSubmit }}
      >
        <MuiFormStep>
          <Flex {...style.formStack}>
            {followId && <MuiText {...text.followBusiness} />}
            <ImageBox {...{ ...style.image, image: logoImage }} />
            <Flex {...style.multiFields}>
              <MuiFormField {...field.firstName} />
              <MuiFormField {...field.lastName} />
            </Flex>
            <Flex {...style.multiFields}>
              <MuiFormField {...field.organization} />
              <MuiSelect
                {...{ ...field.organizationType, options: typeOptions }}
              />
            </Flex>
            <MuiFormField {...field.email} />
            <MuiFormField {...field.password} />
            <MuiCheckbox {...field.termsChecked} />
          </Flex>
        </MuiFormStep>
      </MuiMultiStep>
    </MuiModal>
  );

  function makeProps({ md, modalControl, name, loading, planName }) {
    const replaceLink = { target: '_blank', sxText: { fontSize: 'inherit' } };

    return {
      text: {
        followBusiness: {
          id: 'auth.signup.followBusiness',
          dm: 'Create an account to follow {BUSINESS}',
          loading,
          sxSkeleton: { width: '45%', mx: 'auto' },
          ...(md && {
            skeletonLines: 2,
            groupSkeletons: { direction: 'column', width: '100%' },
          }),
          sx: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: md ? 'flex-start' : 'center',
            px: '0.5rem',
            gap: '0.25rem',
            fontSize: '1rem',
            color: 'headingText.main',
            mx: 'auto',
          },
          replaceMap: {
            '{BUSINESS}': (
              <MuiText {...{ sx: { fontSize: 'inherit', fontWeight: 'bold' } }}>
                {name}
              </MuiText>
            ),
          },
        },
      },
      style: {
        modal: {
          modalControl,
        },
        form: {
          modalControl,
          preventExit: true,
        },
        formStack: {
          direction: 'column',
          sx: {
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
            maxWidth: '40rem',
            mx: 'auto',
            py: '1rem',
          },
        },
        multiFields: {
          direction: { xs: 'column', md: 'row' },
          sx: {
            gap: '1rem',
            width: '100%',
            display: { xs: 'flex', md: 'grid' },
            gridTemplateColumns: { xs: 'unset', md: '1fr 1fr' },
            alignItems: { xs: 'center', md: 'flex-start' },
          },
        },
        image: {
          loading,
          size: md ? '4rem' : '8.5rem',
          variant: 'sellerLogo',
          planName,
          boxProps: { sx: { borderRadius: '5px' } },
        },
      },
      field: {
        firstName: {
          name: 'firstName',
          label: { id: 'forms.firstName', dm: 'First Name' },
          noPlaceholder: true,
          sx: { maxWidth: '25rem' },
          required: true,
        },
        lastName: {
          name: 'lastName',
          label: { id: 'forms.lastName', dm: 'Last Name' },
          noPlaceholder: true,
          sx: { maxWidth: '25rem' },
          required: true,
        },
        organization: {
          name: 'organization',
          label: { id: 'forms.organization', dm: 'Business Name' },
          noPlaceholder: true,
          sx: { width: '100%', maxWidth: '25rem' },
        },
        organizationType: {
          name: 'organizationType',
          label: { id: 'forms.organizationType', dm: 'Business Type' },
          accessor: 'id',
          labelAccessor: 'name',
          sx: { width: '100%', maxWidth: '25rem' },
        },
        email: {
          name: 'email',
          label: { id: 'forms.email', dm: 'Email' },
          sx: { width: '100%' },
          required: true,
        },
        password: {
          name: 'password',
          label: { id: 'forms.password', dm: 'password' },
          sx: { width: '100%' },
          type: 'password',
          required: true,
        },
        termsChecked: {
          name: 'termsChecked',
          label: (
            <MuiText
              {...{
                id: 'auth.signup.seller.acceptTerms',
                dm: 'I accept the {TERMS} and {PRIVACY} *',
                sx: { fontSize: '0.875rem' },
                replaceMap: {
                  '{TERMS}': (
                    <MuiLink
                      {...{
                        to: '/terms-and-services',
                        id: 'auth.signup.seller.termsLink',
                        dm: 'Tradvo Terms of Use',
                        ...replaceLink,
                      }}
                    />
                  ),
                  '{PRIVACY}': (
                    <MuiLink
                      {...{
                        to: '/privacy-page',
                        id: 'auth.signup.seller.privacyLink',
                        dm: 'Privacy Policy',
                        ...replaceLink,
                      }}
                    />
                  ),
                  '*': <span {...{ style: { color: 'red' } }}>*</span>,
                },
              }}
            />
          ),
        },
      },
    };
  }
};

export default FollowSignupModal;

// Fixed scroll button. Sends user back to the top.

import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';
import { scrollToTop } from '../../utils/scrollToTop';

function ScrollToTopButton() {
  const { formatMessage } = useIntl();

  const tooltipLabel = formatMessage({
    id: 'navigation.scrollToTop',
    defaultMessage: 'Scroll to Top',
  });

  return (
    <Tooltip aria-label={tooltipLabel} label={tooltipLabel} placement="left">
      <IconButton
        shadow="sm"
        variant="solid"
        colorScheme="blue"
        borderRadius="100%"
        size="lg"
        aria-label={tooltipLabel}
        icon={<ArrowUpIcon />}
        onClick={scrollToTop}
      />
    </Tooltip>
  );
}

export default ScrollToTopButton;

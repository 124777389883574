import React, { useEffect } from 'react';
import { Modal } from '@mui/material';
import { Prompt } from 'react-router-dom';
import useExitPrompt from '../../hooks/useExitPrompt';
import useAsyncState from '../../hooks/useAsyncState';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import MuiCard from '../common/MuiCard';
import MuiText from '../intl/MuiText';
import Flex from '../common/Flex';
import MuiButton from '../intl/MuiButton';
import useLocale from '../../hooks/useLocale';

const PreventExit = ({
  // required
  dirty = false, // unless controlling manually with prevent
  isSubmitting,
  modalControl, // when using in a modal
  resetForm,
  // optional
  onCancel,
  ignore,
  prevent,
  message,
  debugMode = false,
  // for styling
  warningModalProps,
  warningCardProps,
  warningTitleProps,
  warningMessageProps,
  warningButtonAreaProps,
  warningCancelProps,
  warningAcceptProps,
}) => {
  const { attemptFormat } = useLocale();
  const [accepted, setAccepted] = useAsyncState(false);
  const warningModalControl = useMuiModalControl();
  const { open, handleOpen } = warningModalControl;
  const {
    setCanClose,
    closeAttempted,
    setCloseAttempted,
    canClose,
    handleClose,
  } = modalControl || {};
  const setShowExitPrompt = useExitPrompt(false);
  const { pathname } = window.location;

  useEffect(() => {
    if (resetForm) resetForm();
  }, [pathname, resetForm]);

  useEffect(() => {
    setShowExitPrompt(prevent ?? dirty);
    if (setCanClose) setCanClose(!(prevent ?? dirty));
    if (debugMode)
      console.log({ willPrevent: prevent ?? dirty, prevent, dirty });
  }, [dirty, prevent, setShowExitPrompt, setCanClose, debugMode]);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
      if (debugMode) console.log({ willPrevent: false });
    };
  }, [setShowExitPrompt, debugMode]);

  useEffect(() => {
    if (!closeAttempted) return;
    if (!dirty) {
      if (onCancel) onCancel();
      setCloseAttempted(false);
      return;
    }
    if (debugMode) console.log('closeAttempted');
    if (handleOpen) handleOpen();
  }, [
    closeAttempted,
    setCloseAttempted,
    dirty,
    handleOpen,
    debugMode,
    onCancel,
  ]);

  useEffect(() => {
    if (open || !setCloseAttempted) return;
    if (debugMode) console.log({ resetCloseAttempt: true });
    setCloseAttempted(false);
  }, [open, setShowExitPrompt, setCloseAttempted, debugMode]);

  useEffect(() => {
    if (!(accepted && canClose) && !ignore) return;
    if (debugMode) console.log('closeWarningModal');
    if (resetForm) resetForm();
    if (onCancel) onCancel();
    if (handleClose) handleClose();
  }, [accepted, canClose, handleClose, resetForm, onCancel, ignore, debugMode]);

  useEffect(() => {
    if (!isSubmitting) return;
    setShowExitPrompt(false);
    if (setCanClose) setCanClose(true);
    if (debugMode) console.log({ willPrevent: false });
  }, [isSubmitting, setShowExitPrompt, setCanClose, debugMode]);

  const defaultMessage = {
    id: 'preventExit.defaultMessage',
    dm: 'There are unsaved changes, are you sure you want to leave?',
  };

  const { text, style, button } = makeProps({
    warningModalControl,
    modalControl,
    setAccepted,
    setShowExitPrompt,
    resetForm,
  });
  return (
    <>
      <Prompt
        when={!ignore && (prevent ?? dirty)}
        message={attemptFormat(message || defaultMessage)}
      />
      <Modal {...style.modal(warningModalProps)}>
        <MuiCard {...style.card(warningCardProps)}>
          <MuiText {...text.title(warningTitleProps)} />
          <MuiText {...text.message(warningMessageProps)} />
          <Flex {...style.buttonArea(warningButtonAreaProps)}>
            <MuiButton {...button.cancel(warningCancelProps)} />
            <MuiButton {...button.accept(warningAcceptProps)} />
          </Flex>
        </MuiCard>
      </Modal>
    </>
  );
  function makeProps({
    warningModalControl,
    modalControl,
    setAccepted,
    setShowExitPrompt,
    resetForm,
  }) {
    return {
      text: {
        title: (props) => ({
          id: 'form.preventExit.confirmMessage',
          dm: 'Are you sure?',
          ...props,
          sx: {
            fontSize: '1.5rem',
            ...props?.sx,
          },
        }),
        message: (props) => ({
          id: 'form.preventExit.loseInfo',
          dm: 'Unsubmitted information will be lost',
          ...props,
          sx: {
            fontSize: '1rem',
            ...props?.sx,
          },
        }),
      },
      style: {
        modal: (props) => ({
          open: warningModalControl.open,
          onClose: warningModalControl.handleClose,
          ...props,
        }),
        card: (props) => ({
          customScroll: true,
          ...props,
          sx: {
            m: 0,
            p: { xs: '1rem 0.5rem', md: '2rem' },
            gap: '1rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 12,
            width: '100%',
            maxWidth: { xs: '95vw', md: 'min(50%, 30rem)' },
            maxHeight: '95vh',
            ...props?.sx,
          },
        }),
        buttonArea: (props) => ({
          ...props,
          sx: {
            mt: '2rem',
            width: '100%',
            gap: '1rem',
            justifyContent: 'space-between',
            ...props?.sx,
          },
        }),
      },
      button: {
        cancel: (props) => ({
          id: 'form.preventExit.keepEdit',
          dm: 'Keep Editing',
          variant: 'formButtonOutlined',
          onClick: warningModalControl.handleClose,
          ...props,
          sx: { ...props?.sx },
        }),
        accept: (props) => ({
          id: 'form.preventExit.confirmLeave',
          dm: 'Confirm',
          variant: 'formButton',
          onClick: () => {
            setAccepted(true);
            setShowExitPrompt(false);
            if (resetForm) resetForm();
            modalControl.setCanClose(true);
            warningModalControl.handleClose();
          },
          ...props,
          sx: { ...props?.sx },
        }),
      },
    };
  }
};

export default PreventExit;

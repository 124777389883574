import { DrawerHeader, Stack } from '@chakra-ui/react';
import React from 'react';
import ChangeLanguage from '../header/ChangeLanguage';
import FontResizer from '../header/FontResizer';

const HeaderSection = ({ isInline }) => {
  return (
    <DrawerHeader
      background="#333"
      color="#333"
      display={{ base: 'block', xl: 'none' }}
    >
      <Stack
        flex={1}
        display="flex"
        justifyContent="left"
        spacing={3}
        isInline={isInline}
      >
        <FontResizer />
        <ChangeLanguage height="auto" />
      </Stack>
    </DrawerHeader>
  );
};

export default HeaderSection;

// Register form for the Register modal. Allows social auth or traditional
// account registration.

import {
  TOAST_DEFAULT_DURATION,
  TOAST_DEFAULT_IS_CLOSABLE,
  TOAST_DEFAULT_POSITION,
} from '../../data/constants';
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
  Textarea,
  Button,
} from '@chakra-ui/react';
import FormattedText from '../intl/FormattedText';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
// import { useDispatch } from 'react-redux';
import { ticketToDatabaseMapper } from '../../utils/clientMapper';
import { useIntl } from 'react-intl';
import client from '../../utils/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckboxSelectionSection from '../admin/CheckboxSelectionSection';
import useRecaptcha from '../../hooks/useRecaptcha';
import useAuth from '../../hooks/useAuth';
import useLocale from '../../hooks/useLocale';

function ContactForm({ onClose }) {
  const recaptchaVerification = useRecaptcha();
  const intl = useIntl();
  const toast = useToast();
  const { isArabic } = useLocale();
  const [reCaptchaError, setReCaptchaError] = useState('');
  const { user } = useAuth();
  // const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const validationSchema = Yup.object({
    name: Yup.string().required(intl.formatMessage({ id: 'contact.required' })),
    email: Yup.string()
      .required(intl.formatMessage({ id: 'auth.error.email.required' }))
      .email(intl.formatMessage({ id: 'auth.error.email.valid' })),
    subject: Yup.string().required(
      intl.formatMessage({ id: 'contact.required' }),
    ),
    message: Yup.string()
      .required(intl.formatMessage({ id: 'contact.required' }))
      .min(3, intl.formatMessage({ id: 'contact.minMessage' }))
      .max(255, intl.formatMessage({ id: 'contact.maxMessage' })),
  });
  const currentUrl = window.location.href;
  const title = document.querySelector('title').innerText;

  return (
    <Formik
      initialValues={{
        name: user ? user.fullName : '',
        email: user ? user.email : '',
        subject: '',
        message: '',
        type: null,
        pageURL: currentUrl,
        pageTitle: title || '',
        byUserId: user ? user.id : '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (!(await recaptchaVerification()))
          return setReCaptchaError(
            intl.formatMessage({
              id: 'forms.error.reCaptcha',
              defaultMessage:
                'Something went wrong. Please try again in a few minutes.',
            }),
          );
        // const { name, email, subject, message } = values;
        const formattedValues = ticketToDatabaseMapper(values);

        try {
          // verify reCaptcha token
          await client('/tickets', {
            method: 'POST',
            body: { ...formattedValues },
          });
        } catch (e) {}

        try {
          await client('/contact-us', {
            method: 'POST',
            body: { ...values },
          });
          onClose();
          toast({
            title: intl.formatMessage({
              id: 'contact.sent.success',
              defaultMessage: 'Email sent',
            }),
            position: TOAST_DEFAULT_POSITION,
            status: 'success',
            duration: TOAST_DEFAULT_DURATION,
            isClosable: TOAST_DEFAULT_IS_CLOSABLE,
          });
        } catch (err) {
          toast({
            title: intl.formatMessage({
              id: 'contact.sent.fail',
              defaultMessage: 'Email failed to send',
            }),
            position: TOAST_DEFAULT_POSITION,
            status: 'error',
            duration: TOAST_DEFAULT_DURATION,
            isClosable: TOAST_DEFAULT_IS_CLOSABLE,
          });
        }
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <ModalBody>
            <ModalHeader>
              <FormattedText
                id="footer.contactCTA"
                defaultMessage="Get in touch"
                fontSize={matches ? 'md' : '2xl'}
                as="h2"
                textAlign="center"
                mt={2}
              />
            </ModalHeader>
            <ModalCloseButton />

            {/* Input Fields */}
            <Stack spacing={2} shouldWrapChildren={true}>
              <CheckboxSelectionSection
                spacing={3}
                isInline={true}
                name="type"
                id="type"
                type="manageMessageType"
                options={[
                  {
                    id: 'tickets.type.questionHead',
                    name: isArabic ? 'اسأل عن شئ' : 'Ask a Question',
                    categoryImages: 'question',
                  },
                  {
                    id: 'tickets.type.bugHead',
                    name: isArabic ? 'إخطار عن مشكلة' : 'Report a Bug',
                    categoryImages: 'bug',
                  },
                  {
                    id: 'tickets.type.suggestionHead',
                    name: isArabic ? 'لديك إقتراح' : 'Give Suggestion',
                    categoryImages: 'suggestion',
                  },
                ]}
                handleChange={(value) => setFieldValue('type', value)}
                accessor="id"
              />
              {values.type && (
                <React.Fragment>
                  {!user && (
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel htmlFor="name">
                            <FormattedText
                              id="contact.name"
                              defaultMessage={isArabic ? 'الاسم' : 'Name'}
                              fontSize={matches ? 'sm' : ''}
                            />
                          </FormLabel>
                          <Input
                            {...field}
                            height="auto"
                            type="text"
                            id="name"
                            placeholder={intl.formatMessage({
                              id: 'contact.name.placeholder',
                              defaultMessage: isArabic
                                ? 'رجاءا ادخل اسمك'
                                : 'Please enter your name',
                            })}
                            color="#000"
                            lineHeight="2.5"
                            mb="5px"
                            fontSize={matches ? '16px' : ''}
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {!user && (
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel htmlFor="email">
                            <FormattedText
                              id="contact.email"
                              defaultMessage={
                                isArabic ? 'البريد الإلكتروني' : 'Email'
                              }
                              fontSize={matches ? 'sm' : ''}
                            />
                          </FormLabel>
                          <Input
                            {...field}
                            height="auto"
                            type="text"
                            id="email"
                            placeholder={intl.formatMessage({
                              id: 'contact.email.placeholder',
                              defaultMessage: 'john.doe@gmail.com',
                            })}
                            color="#000"
                            lineHeight="2.5"
                            mb="5px"
                            fontSize={matches ? '16px' : ''}
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  <Field name="subject">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.subject && form.touched.subject}
                      >
                        {/* {values.type === 'askQuestion' &&  */}
                        <FormLabel htmlFor="subject">
                          <FormattedText
                            id={`contact.subject.${values.type}`}
                            defaultMessage={
                              isArabic ? ' العنوان (وصف قصير) ' : 'Subject'
                            }
                            fontSize={matches ? 'sm' : ''}
                          />
                        </FormLabel>
                        {/* } */}
                        <Input
                          {...field}
                          height="auto"
                          type="text"
                          id="subject"
                          placeholder={intl.formatMessage({
                            id: `contact.subject.${values.type}`,
                            defaultMessage: isArabic
                              ? 'العنوان (وصف قصير للرسالة)'
                              : 'Subject',
                          })}
                          color="#000"
                          lineHeight="2.5"
                          mb="5px"
                          fontSize={matches ? '16px' : ''}
                        />
                        <FormErrorMessage>
                          {form.errors.subject}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="message">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.message && form.touched.message}
                      >
                        <FormLabel htmlFor="message">
                          <FormattedText
                            id={`contact.message.${values.type}`}
                            defaultMessage={isArabic ? 'الرسالة' : 'Message'}
                            fontSize={matches ? 'sm' : ''}
                          />
                        </FormLabel>
                        <Textarea
                          {...field}
                          height="auto"
                          minHeight="100px"
                          type="text"
                          id="message"
                          placeholder={intl.formatMessage({
                            id: `contact.message.${values.type}`,
                            defaultMessage: isArabic ? 'الرسالة' : 'Message',
                          })}
                          color="#000"
                          lineHeight="2.5"
                          mb="5px"
                          fontSize={matches ? '16px' : ''}
                        />
                        <FormErrorMessage>
                          {form.errors.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </React.Fragment>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter pb="20px">
            <FormControl isInvalid={!!reCaptchaError} width="100%">
              <Button
                isLoading={isSubmitting}
                loadingText="Submitting"
                background="#005B99"
                width="100%"
                _hover={{ opacity: '0.9' }}
                height="auto"
                color="#fff"
                type="submit"
                style={{ lineHeight: '2.5' }}
                size={matches ? 'sm' : ''}
              >
                <span>
                  <FormattedMessage
                    id="contact.submit"
                    defaultMessage="Submit"
                  />
                </span>
              </Button>
              <FormErrorMessage>{reCaptchaError}</FormErrorMessage>
            </FormControl>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Flex from '../common/Flex';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MuiText from './MuiText';
import { gradientButtonStyles } from '../../theme/materialTheme';
import { Link } from 'react-router-dom';
import WrapOn from '../common/WrapOn';
import { Grid } from '@mui/material';
import useEventTracker from '../../hooks/useEventTracker';
import { formatNamedLink } from '../../utils/formatNamedLink';
import useFacebookPixel from '../../hooks/useFacebookPixel';
import useParseBreakpointObject from '../../hooks/useParseBreakpointObject';
import { mergeProps } from '../../utils/mergeProps';

const MuiButton = forwardRef(
  (
    {
      id,
      dm,
      defaultMessage,
      children,
      color = 'primary',
      secondary,
      fontSize,
      variant = 'contained',
      borderRadius = 2,
      sx = {},
      sxText = {},
      outlined,
      gradient,
      textAs = 'span',
      textColor,
      disabled,
      loading,
      loadingIndicator = '',
      loadingPosition,
      startIcon,
      endIcon,
      iconSize: givenIconSize,
      iconColor,
      ripple,
      type,
      nonText,
      to,
      namedLink,
      item,
      itemProps,
      GA, // { category = '', action = '', label = '', debugMode: false }
      PE, // { event, data }
      onClick: givenOnClick,
      elementId,
      ...rest
    },
    ref,
  ) => {
    const eventTracker = useEventTracker();
    const parseBreakpoint = useParseBreakpointObject();
    const { pixelEvent } = useFacebookPixel();
    const [iconWidth, setIconWidth] = useState('');
    const [iconHeight, setIconHeight] = useState('');
    const textRef = useRef(null);
    const varProps = variantStyles({ variant });

    const iconSize = parseBreakpoint(givenIconSize ?? 20);

    if (defaultMessage) dm = defaultMessage;
    // if (outlined) variant = 'outlined';
    if (secondary) color = 'secondary';
    sx['borderRadius'] = sx.borderRadius || borderRadius;
    if (gradient) {
      gradient = gradient === true ? 'default' : gradient;
      sx = {
        ...sx,
        ...gradientButtonStyles[gradient],
        '&:hover': {
          ...sx['&:hover'],
          ...gradientButtonStyles[gradient]['&:hover'],
        },
      };
      if (!!loading || !!disabled) sx['background'] = 'rgba(0, 0, 0, 0.12)';
    }
    if (textColor && !(disabled || loading)) sxText['color'] = textColor;
    if (iconColor && !(disabled || loading)) sx['color'] = iconColor;

    useEffect(() => {
      if (!!loading) return;
      setIconWidth(textRef.current?.clientWidth);
      setIconHeight(textRef.current?.clientHeight);
    }, [loading]);

    const loadingCircle = <CircularProgress color="inherit" size={iconSize} />;

    const style = {
      gridItem: { Wrap: Grid, item: true, ...itemProps },
      button: {
        ...varProps,
        disabled: !!loading || !!disabled,
        ...(to && { component: Link, to }),
        ...(namedLink &&
          (formatNamedLink(namedLink)
            ? { component: Link, to: formatNamedLink(namedLink) }
            : { disabled: true })),
        disableRipple: !ripple,
        startIcon: loading ? startIcon && loadingCircle : startIcon,
        endIcon: loading ? endIcon && loadingCircle : endIcon,
        type,
        color,
        ...((['contained', 'outlined', 'text'].includes(variant) ||
          outlined) && { variant: outlined ? 'outlined' : variant }),
        sx: {
          '& .MuiButton-startIcon': {
            ml: '0',
            '& .MuiSvgIcon-root': { fontSize: `${iconSize}px` },
          },
          '& .MuiButton-endIcon': {
            mr: '0',
            '& .MuiSvgIcon-root': { fontSize: `${iconSize}px` },
          },
          ...varProps?.sx,
          ...sx,
        },
        onClick: (...clickArgs) => {
          if (GA) eventTracker(GA);
          if (PE) pixelEvent(PE);
          if (givenOnClick) givenOnClick(...clickArgs);
        },
        ...rest,
      },
      loadingBox: {
        sx: { alignItems: 'center' },
        mx: `${(iconWidth - iconSize) / 2}px`,
        height: iconHeight || `${iconSize}px`,
      },
      circle: { color: 'inherit', size: iconSize },
      text: {
        Wrap: MuiText,
        fontSize:
          fontSize ||
          sx?.fontSize ||
          varProps?.fontSize ||
          varProps?.sx?.fontSize ||
          '0.75rem',
        as: textAs,
        sx: sxText,
      },
    };

    return (
      <WrapOn {...{ on: item || itemProps, ...style.gridItem }}>
        <Button {...{ id: elementId, ...style.button }} {...(ref && { ref })}>
          {loading && !(startIcon || endIcon) ? (
            <Flex {...style.loadingBox}>
              <CircularProgress {...style.circle} />
            </Flex>
          ) : (
            <WrapOn {...{ on: !nonText, id, dm, ...style.text, ref: textRef }}>
              {children}
            </WrapOn>
          )}
        </Button>
      </WrapOn>
    );
    function variantStyles({ variant }) {
      const common = {
        formButton: {
          sx: {
            px: '2rem',
            height: '3rem',
            alignItems: 'center',
            fontSize: '0.875rem',
          },
        },
      };
      const variants = {
        formButtonText: mergeProps({ variant: 'text' }, common?.formButton),
        formButtonOutlined: mergeProps(
          { variant: 'outlined' },
          common?.formButton,
        ),
        formButtonContained: mergeProps(
          { variant: 'contained' },
          common?.formButton,
        ),
        formButton: mergeProps({ variant: 'contained' }, common?.formButton),
        iconEnd: mergeProps(
          {
            variant: 'contained',
            sx: { p: '0.75rem', gap: '4rem', justifyContent: 'space-between' },
          },
          common?.formButton,
        ),
        iconEndOutlined: mergeProps(
          {
            variant: 'outlined',
            sx: { p: '0.75rem', gap: '4rem', justifyContent: 'space-between' },
          },
          common?.formButton,
        ),
      };
      return variants[variant] || {};
    }
  },
);

export default MuiButton;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from '../../reducers/intlReducer';
import MuiText from '../intl/MuiText';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { boxShadows } from '../../theme/materialTheme';
import useAsyncState from '../../hooks/useAsyncState';

const MuiChangeLanguage = () => {
  const dispatch = useDispatch();
  const { locale } = useSelector(({ intl }) => intl);
  const [anchorEl, setAnchorEl] = useAsyncState(false);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const id = 'language-settings-menu';

  const handleClick = (lang) => {
    localStorage.setItem('locale', lang);
    dispatch(setLocale(lang));
    handleClose();
  };

  // const text = { en: 'English', fr: 'Français', ar: 'Arabic' };
  const text = { en: 'English', ar: 'عربي' };

  const style = {
    button: { sx: { ml: '0.15rem' } },
    icon: {
      sx: { color: 'iconsText.main', width: '1.5rem', height: '1.5rem' },
    },
    menu: {
      keepMounted: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      transformOrigin: { vertical: 'top', horizontal: 'right' },
      TransitionProps: { timeout: { enter: 300, exit: 300 } },
      sx: {
        '&>*': {
          boxShadow: '0',
          filter: `drop-shadow(${boxShadows.menu.large})`,
          borderRadius: 2,
        },
      },
    },
    item: (selected) => ({
      sx: { color: selected ? 'primary.main' : 'bodyText.main' },
    }),
  };

  return (
    <>
      <IconButton
        {...style.button}
        onClick={(e) => {
          e.preventDefault();
          handleOpen(e);
        }}
      >
        <LanguageIcon {...style.icon} />
      </IconButton>
      <Menu
        {...{ id, anchorEl, open: isOpen, onClose: handleClose, ...style.menu }}
      >
        {/* //{['en', 'fr', 'ar'].map((lang) => ( */}
        {['en', 'ar'].map((lang) => (
          <MenuItem {...{ key: lang }} onClick={() => handleClick(lang)}>
            <MuiText {...style.item(lang === locale)}>{text[lang]}</MuiText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MuiChangeLanguage;

import React, {
  createContext,
  useCallback,
  useContext,
  //   useEffect,
} from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { snackbarMessages } from '../../data/snackbarMessages';
import useAsyncState from '../../hooks/useAsyncState';
import useLocale from '../../hooks/useLocale';

export const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const { attemptFormat } = useLocale();
  const [open, setOpen] = useAsyncState(false);
  const [settings, setSettings] = useAsyncState({});
  const snackbar = useCallback(
    (snackbarInput) => {
      const newSettings = snackbarMessages(snackbarInput);
      if (!newSettings) return;
      setSettings({ type: 'success', autoHideDuration: 6000, ...newSettings });
      setOpen(true);
    },
    [setSettings, setOpen],
  );

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
    // setSettings({});
  };

  //   useEffect(() => {
  //     if (!open) setSettings({});
  //   }, [open, setSettings]);

  const sxDefault = {
    '& .MuiAlert-icon': { fontSize: '1em', alignItems: 'center' },
    '& .MuiAlert-message': { fontSize: '0.5em', alignItems: 'center' },
    '& .MuiAlert-action': { fontSize: '1em', alignItems: 'center' },
    '& .MuiButtonBase-root': { fontSize: '1em' },
    '& .MuiSvgIcon-root': { fontSize: '1em' },
    '& .MuiTypography-root': { fontSize: '1.5em' },
    fontSize: '1.5rem',
  };

  const {
    type: severity,
    style: sx,
    title,
    description,
    autoHideDuration,
  } = settings;

  return (
    <SnackbarContext.Provider value={{ snackbar }}>
      {children}
      {/* {!!type && ( */}
      <Snackbar {...{ open, onClose: handleClose, autoHideDuration }}>
        <Alert
          {...{ onClose: handleClose, severity, sx: { ...sxDefault, ...sx } }}
        >
          {title && (
            <AlertTitle sx={{ m: 0 }}>{attemptFormat(title)}</AlertTitle>
          )}
          {attemptFormat(description)}
        </Alert>
      </Snackbar>
      {/* )} */}
    </SnackbarContext.Provider>
  );
};

export const dateFormatter = (dateObj) => {
  const date = new Date(dateObj);
  return date.toISOString().slice(0, 10);
};

export const isToday = (dateString) => {
  const dateObject =
    typeof dateString === 'string' ? new Date(dateString) : dateString;
  return new Date().toLocaleDateString() === dateObject.toLocaleDateString();
};

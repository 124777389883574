import React, { forwardRef } from 'react';

const WrapOn = forwardRef(
  (
    {
      Wrap, // ReactComponent (variable)
      on = false, // when to wrap
      bothProps,
      Fallback, // ReactComponent (variable) wrap when on = false
      fallbackProps,
      children,
      ...rest
    },
    ref,
  ) =>
    on ? (
      <Wrap {...{ ...(ref && { ref }), ...bothProps, ...rest }}>
        {children}
      </Wrap>
    ) : Fallback ? (
      <WrapOn
        {...{
          ...(ref && { ref }),
          Wrap: Fallback,
          on: true,
          ...bothProps,
          ...fallbackProps,
        }}
      >
        {children}
      </WrapOn>
    ) : (
      <>{children}</>
    ),
);

export default WrapOn;

// Accessibility: Component for resizing the theme default font size.

import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  decreaseFontSize,
  increaseFontSize,
} from '../../reducers/settingsReducer';

const FontSize = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;
  height: calc(100% - 4px);
`;

function FontResizer({ ...rest }) {
  const { formatMessage } = useIntl();
  const { fontSize } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  return (
    <Flex {...rest} minHeight="40px">
      <Button
        aria-label={formatMessage({
          id: 'appbar.fontResizer.decrease',
          defaultMessage: 'Decrease font size',
        })}
        fontSize="sm"
        borderRadius="0.25em 0 0 0.25em"
        height="auto"
        colorScheme="newBlack"
        onClick={() => dispatch(decreaseFontSize())}
      >
        A
      </Button>
      <Box backgroundColor="gray.100">
        <FontSize fontSize="md" px={2} backgroundColor="white">
          {(fontSize / 1) * 100 + '%'}
        </FontSize>
      </Box>
      <Button
        aria-label={formatMessage({
          id: 'appbar.fontResizer.increase',
          defaultMessage: 'Increase font size',
        })}
        colorScheme="newBlack"
        fontSize="lg"
        borderRadius="0 0.25em 0.25em 0"
        height="auto"
        onClick={() => dispatch(increaseFontSize())}
      >
        A
      </Button>
    </Flex>
  );
}

export default FontResizer;

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { Field, getIn } from 'formik';
import React, { useState } from 'react';
import { SiGoogletranslate, IoMdHelpCircle, FaCopy } from 'react-icons/all';
import { useIntl } from 'react-intl';
import useToaster from '../../hooks/useToaster';
import { translate } from '../../utils/translate';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import useLocale from '../../hooks/useLocale';

function FormField({
  name,
  label,
  placeholder,
  type,
  multiline,
  required,
  min,
  max,
  disabled,
  noLabel,
  translateFrom,
  findHs6Button,
  readOnly,
  hasCopy,
  validate,
  onlyForAccountPage,
  ...rest
}) {
  const { formatMessage } = useIntl();
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { attemptFormat } = useLocale();

  const handleShowPassword = () => setShow(!show);

  const handleTranslate = async (translateText, setFieldValue) => {
    setIsLoading(true);
    try {
      const translatedText = await translate(translateText);
      setFieldValue(name, translatedText);
    } catch (e) {
      toaster('translateFail');
    }
    setIsLoading(false);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const translateButton = (translateText, form) => (
    <InputRightElement width="2.75rem">
      <Tooltip
        aria-label={formatMessage({
          id: 'forms.translate',
          defaultMessage: 'Translate',
        })}
        label={formatMessage({
          id: 'forms.translate',
          defaultMessage: 'Translate',
        })}
        placement="left"
        zIndex={1500}
      >
        <IconButton
          h="1.75rem"
          size="sm"
          aria-label={formatMessage({
            id: 'forms.translate',
            defaultMessage: 'Translate',
          })}
          isLoading={isLoading}
          icon={<SiGoogletranslate />}
          colorScheme="blue"
          onClick={() => handleTranslate(translateText, form.setFieldValue)}
        />
      </Tooltip>
    </InputRightElement>
  );

  const showPassword = () => (
    <InputRightElement width="2.5rem">
      <Tooltip
        aria-label={attemptFormat({
          ...(show
            ? { id: 'password.hide', dm: 'Hide' }
            : { id: 'password.show', dm: 'Show' }),
        })}
        label={attemptFormat({
          ...(show
            ? { id: 'password.hide', dm: 'Hide' }
            : { id: 'password.show', dm: 'Show' }),
        })}
        placement="right"
        zIndex={1500}
      >
        <IconButton
          h="2rem"
          size="sm"
          aria-label={attemptFormat({
            ...(show
              ? { id: 'password.hide', dm: 'Hide' }
              : { id: 'password.show', dm: 'Show' }),
          })}
          icon={show ? <ViewOffIcon /> : <ViewIcon />}
          // colorScheme="blue"
          variant="ghost"
          colorScheme="gray"
          onClick={handleShowPassword}
        />
      </Tooltip>
    </InputRightElement>
  );

  const hs6Button = () => (
    <InputRightElement width="2.75rem">
      <Tooltip
        aria-label={formatMessage({
          id: 'admin.table.itemType.link.hs6code',
          defaultMessage: 'Find HS6 Code?',
        })}
        label={formatMessage({
          id: 'admin.table.itemType.link.hs6code',
          defaultMessage: 'Find HS6 Code?',
        })}
        placement="left"
        zIndex={1500}
      >
        <IconButton
          h="1.75rem"
          size="sm"
          aria-label={formatMessage({
            id: 'admin.table.itemType.link.hs6code',
            defaultMessage: 'Find HS6 Code?',
          })}
          icon={<IoMdHelpCircle />}
          colorScheme="blue"
          onClick={() =>
            window.open(
              'https://www.canadapost.ca/cpotools/apps/wtz/business/findHsCode?execution=e1s1',
              '_blank',
              'noopener,noreferrer',
            )
          }
        />
      </Tooltip>
    </InputRightElement>
  );

  const copyToClipboard = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
      toaster('copySuccess');
    } catch (e) {
      toaster('copyError');
    }
  };

  const copyButton = (form) => (
    <InputRightElement width="2.75rem">
      <Tooltip
        aria-label={formatMessage({
          id: 'forms.copy',
          defaultMessage: 'Copy',
        })}
        label={formatMessage({
          id: 'forms.copy',
          defaultMessage: 'Copy',
        })}
        placement="left"
        zIndex={1500}
      >
        <IconButton
          h="1.75rem"
          size="sm"
          aria-label={formatMessage({
            id: 'forms.copy',
            defaultMessage: 'Copy',
          })}
          isLoading={isLoading}
          icon={<FaCopy />}
          colorScheme="blue"
          onClick={() => copyToClipboard(form.values[name])}
          isDisabled={disabled}
        />
      </Tooltip>
    </InputRightElement>
  );
  const star = (
    <Text color="red" as="span">
      &nbsp;*
    </Text>
  );
  return (
    <Field name={name} validate={validate}>
      {({ field, form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        const translateText = form.values[translateFrom];
        return (
          <FormControl {...rest} isInvalid={error && touch}>
            {!noLabel && (
              <FormLabel htmlFor={name}>
                {label ? (
                  onlyForAccountPage ? (
                    <Text fontSize="0.875rem">
                      {label}
                      {required && star}
                    </Text>
                  ) : (
                    <Text
                      fontSize={matches ? 'sm' : '0.875rem'}
                      lineHeight={'1.5'}
                    >
                      {label}
                      {required && star}
                    </Text>
                  )
                ) : onlyForAccountPage ? (
                  <Text fontSize="0.875rem">
                    {formatMessage({
                      id: 'forms.' + name,
                      defaultMessage: name,
                    })}
                    {required && star}
                  </Text>
                ) : (
                  <Text
                    fontSize={matches ? 'sm' : '0.875rem'}
                    lineHeight={'1.5'}
                  >
                    {formatMessage({
                      id: 'forms.' + name,
                      defaultMessage: name,
                    })}
                    {required && star}
                  </Text>
                )}
              </FormLabel>
            )}
            {multiline ? (
              <InputGroup>
                <Textarea
                  background="white"
                  fontSize="0.875rem"
                  pr={translateText ? '2.75rem' : 0}
                  {...field}
                  id={name}
                  placeholder={
                    placeholder
                      ? placeholder
                      : formatMessage({
                          id: `forms.${name}.placeholder`,
                        })
                  }
                  readOnly={readOnly}
                />
                {translateText && translateButton(translateText, form)}
                {findHs6Button && hs6Button()}
                {hasCopy && copyButton(form)}
              </InputGroup>
            ) : (
              <InputGroup size="md">
                <Input
                  background="white"
                  fontSize="0.875rem"
                  pr={translateText ? '2.75rem' : 0}
                  min={min}
                  max={max}
                  {...field}
                  type={
                    type === 'password'
                      ? show
                        ? 'text'
                        : 'password'
                      : type || 'text'
                  }
                  id={name}
                  placeholder={
                    placeholder
                      ? placeholder
                      : formatMessage({
                          id: `forms.${name}.placeholder`,
                        })
                  }
                  disabled={disabled}
                  readOnly={readOnly}
                />
                {translateText && translateButton(translateText, form)}
                {findHs6Button && hs6Button()}
                {hasCopy && copyButton(form)}
                {
                  type === 'password' && showPassword()
                  // (
                  //   <InputRightElement width="2.75rem">
                  //     <IconButton
                  //       h="1.75rem"
                  //       size="sm"
                  //       aria-label={formatMessage({
                  //         id: 'forms.translate',
                  //         defaultMessage: 'Translate',
                  //       })}
                  //       // isLoading={isLoading}
                  //       icon={show ? <ViewOffIcon /> : <ViewIcon />}
                  //       colorScheme="blue"
                  //       onClick={handleShowPassword}
                  //     />
                  //   </InputRightElement>
                  // )
                }
              </InputGroup>
            )}
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
}

export default FormField;

import React, { createContext, useCallback } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useAsyncState from '../../hooks/useAsyncState';
import useAuth from '../../hooks/useAuth';
import useGetData from '../../hooks/useGetData';
import { subscriptionPlanMapper } from '../../utils/fetchMapper';

export const SubscriptionContext = createContext();

const SubscriptionProvider = ({ children }) => {
  const { user } = useAuth();
  const { roles, id } = user || {};
  const { sellerPlans } = useSelector(({ settings }) => settings);
  const [planLoading, setPlanLoading] = useAsyncState(true);
  const [apiCallStarted, setApiCallStarted] = useAsyncState(false);
  const [bannerMoved, setBannerMoved] = useAsyncState(false);
  const isBuyer = roles === '3';

  const { data, loading } = useGetData({
    endpoint: id && '/get-my-subscription',
    dataHandler: useCallback(({ data }) => subscriptionPlanMapper(data), []),
    initialValue: { type: 'basic' },
  });

  const subscription = useMemo(
    () => ({ ...data, limits: sellerPlans[data.type] }),
    [sellerPlans, data],
  );

  useEffect(() => {
    if (!isBuyer) return;
    setPlanLoading(false);
  }, [isBuyer, setPlanLoading]);

  useEffect(() => {
    if (!loading || !id) return;
    setApiCallStarted(true);
  }, [id, loading, setApiCallStarted]);

  useEffect(() => {
    if (!apiCallStarted || loading) return;
    setPlanLoading(false);
  }, [apiCallStarted, loading, setPlanLoading]);

  return (
    <SubscriptionContext.Provider
      {...{
        value: {
          plan: subscription,
          subscription,
          subscriptionLoading: planLoading,
          planLoading,
          loading: planLoading,
          bannerMoved,
          setBannerMoved,
          isBasic: subscription.type === 'basic',
        },
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;

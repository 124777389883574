import { useState, useEffect } from 'react';

const initBeforeunLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (!showExitPrompt) return;
    const e = event || window.event;
    e.preventDefault();
    if (e) e.returnValue = '';
  };
};

export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);
  window.onload = () => initBeforeunLoad(showExitPrompt);

  useEffect(() => {
    initBeforeunLoad(showExitPrompt);
    return () => initBeforeunLoad(false);
  }, [showExitPrompt]);

  return setShowExitPrompt;
}

/*
To use in a file, import useExitPrompt from this file,

run the hook with the line:
const setShowExitPrompt = useExitPrompt(false);


set the prompt value to true to display prompt when refreshing/closing the page.

e.g. In a form:

{dirty && setShowExitPrompt(true)}
{!dirty && setShowExitPrompt(false)}

will prevent the user from refreshing/closing if the form has been modified, but does not throw a warning if the form has not been changed.


*/

import { DrawerCloseButton, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';

const TopSectionExpandedDrawer = () => {
  const intl = useIntl();
  return (
    <Flex flexDirection="coloumn" mt={4}>
      <Flex ml={6}>
        <Text fontSize="lg" fontWeight="bold" color="#ccc">
          {intl.formatMessage({
            id: 'navbar.welcome',
          })}
        </Text>
      </Flex>
      <Flex>
        <DrawerCloseButton color="#ccc" />
      </Flex>
    </Flex>
  );
};

export default TopSectionExpandedDrawer;

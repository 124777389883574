import client from './client';
import { unescape } from 'html-escaper';

export const translate = async (str) => {
  // translate currently only works from French to English

  const { data } = await client('/admin/translations', {
    body: {
      textData: [str],
    },
  });

  return unescape(data[0].text);
};

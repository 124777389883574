import React from 'react';
import useLocale from '../../hooks/useLocale';
import Flex from '../common/Flex';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, IconButton, InputAdornment, InputBase } from '@mui/material';
import useBreakpoint from '../../hooks/useBreakpoint';
import useQueryState from '../../hooks/useQueryState';
import useFormState from '../../hooks/useFormState';

const MobileSearchBar = ({
  triggerSearch,
  searchBarOpen,
  setSearchBarOpen,
  searchInputRef: inputRef,
}) => {
  const { attemptFormat } = useLocale();
  const [searchText, setSearchText] = useQueryState('search', '', {
    encoded: true,
  });
  const [value, onChange] = useFormState(searchText);
  const { md } = useBreakpoint();
  const isSearchPage = window.location.pathname === '/search';

  const clearSearch = () => {
    onChange({ target: { value: '' } });
    setSearchBarOpen(false);
    setSearchText('');
  };

  const { style } = makeProps({
    attemptFormat,
    clearSearch,
    value,
    onChange,
    triggerSearch,
  });
  return (
    <Collapse {...{ in: md && (searchBarOpen || isSearchPage || !!value) }}>
      <Flex {...style.search}>
        <InputBase {...{ inputRef, ...style.searchInput }} />
      </Flex>
    </Collapse>
  );

  function makeProps({
    attemptFormat,
    clearSearch,
    value,
    onChange,
    triggerSearch,
  }) {
    const onKeyDown = ({ key }) => key === 'Enter' && triggerSearch(value);

    return {
      style: {
        search: {
          sx: {
            position: 'relative',
            m: '0 1.25rem 0.5rem',
            height: '2.25rem',
            borderRadius: '2rem',
            border: '1px solid',
            borderColor: 'bodyText.light',
            backgroundColor: 'background.light',
          },
        },
        closeButton: {
          onclick: clearSearch,
          sx: {
            position: 'absolute',
            left: '0.25rem',
            height: '100%',
            pointerEvents: 'none',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        closeIcon: {
          color: 'bodyText.main',
          sx: { height: '1.5rem', width: '1.5rem' },
        },
        searchIconWrapper: {
          sx: {
            position: 'absolute',
            right: '0.75rem',
            height: '100%',
            pointerEvents: 'none',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        searchIcon: {
          color: 'primary',
          sx: { height: '1.5rem', width: '1.5rem' },
        },
        searchInput: {
          value,
          onChange,
          onKeyDown,
          type: 'search',
          placeholder: attemptFormat({ id: 'search.dots', dm: 'Search...' }),
          inputProps: {
            'aria-label': attemptFormat({ id: 'search', dm: 'Search' }),
          },
          sx: {
            width: '100%',
            height: '100%',
            fontSize: '0.875rem',
            color: 'bodyText.main',
            px: '0.25rem',
            '& input': { py: '0.25rem' },
            '& .MuiInputAdornment-root': { width: '1.75rem' },
          },
          startAdornment: (
            <InputAdornment {...{ position: 'start' }}>
              <IconButton
                {...{
                  onClick: () => triggerSearch(value),
                  color: 'primary',
                  sx: { p: '0.25rem' },
                }}
              >
                <SearchIcon {...{ sx: { fontSize: '1.375rem' } }} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment {...{ position: 'end' }}>
              <IconButton
                {...{
                  onClick: clearSearch,
                  color: 'bodyText',
                  sx: { p: '0.25rem' },
                }}
              >
                <CloseIcon {...{ sx: { fontSize: '1.375rem' } }} />
              </IconButton>
            </InputAdornment>
          ),
        },
      },
    };
  }
};

export default MobileSearchBar;

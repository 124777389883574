// A hook that returns functions that take into account the set language
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TAX_OPTIONS } from '../data/constants';

const useLocale = () => {
  const { locale } = useSelector(({ intl }) => intl);
  const isEnglish = useMemo(() => locale.includes('en'), [locale]);
  const isFrench = useMemo(() => locale.includes('fr'), [locale]);
  const isArabic = useMemo(() => locale.includes('ar'), [locale]);
  const { userRoles } = useSelector(({ settings }) => settings);
  const { formatMessage } = useIntl();

  // Takes array of objects with englishName and frenchName attributes. Returns a sorted array of objects with name attribute instead

  const names = useCallback(
    (data, { en = 'englishName', fr = 'frenchName', ar = 'arabicName' } = {}) =>
      data.map(
        ({
          [en]: englishName,
          [fr]: frenchName,
          [ar]: arabicName,
          ...rest
        }) => ({
          name: isEnglish ? englishName : isFrench ? frenchName : arabicName,
          ...rest,
        }),
      ),
    [isEnglish, isFrench],
  );

  const nameSort = useCallback(
    (data, { en = 'englishName', fr = 'frenchName', ar = 'arabicName' } = {}) =>
      names(data, { en, fr, ar }).sort((a, b) => a.name.localeCompare(b.name)),
    [names],
  );

  const formatNames = useCallback(
    (options) =>
      options.map(({ name, ...rest }) => ({
        ...rest,
        name: formatMessage(name),
      })),
    [formatMessage],
  );

  const attemptFormat = useCallback(
    (unformattedText) =>
      unformattedText?.id
        ? formatMessage({
            id: unformattedText?.id,
            defaultMessage:
              unformattedText?.dm || unformattedText?.defaultMessage,
          })
        : unformattedText || '',
    [formatMessage],
  );

  const rolesLang = useMemo(
    () =>
      userRoles.map(
        ({
          roleNameEnglish,
          roleNameFrench,
          roleDescriptionEnglish,
          roleDescriptionFrench,
          ...rest
        }) =>
          isEnglish
            ? {
                name: roleNameEnglish,
                description: roleDescriptionEnglish,
                ...rest,
              }
            : {
                name: roleNameFrench,
                description: roleDescriptionFrench,
                ...rest,
              },
      ),
    [isEnglish, userRoles],
  );

  // Takes a date object and returns a formatted date string. Optional argument to specify date format
  const formatDate = useCallback(
    (date, dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }) =>
      date
        ? date.toLocaleDateString(
            isEnglish ? 'en-US' : isFrench ? 'fr-FR' : 'ar-EG',
            dateOptions,
          )
        : null,
    [isEnglish, isFrench],
  );

  // Takes a number and returns a cost string. Optional argument to set other options
  const formatCost = useCallback(
    (cost, { currency, ...options } = {}) =>
      Number.isNaN(Number(cost))
        ? attemptFormat(cost)
        : (isEnglish
            ? '' +
              (Number(cost) || 0).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                ...options,
              })
            : isFrench
            ? (Number(cost) || 0).toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                ...options,
              }) + ''
            : (Number(cost) || 0).toLocaleString('ar-EG', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                ...options,
              }) + '') + (currency ? ` ${currency}` : '$'),
    [isEnglish, isFrench, attemptFormat],
  );

  const taxName = useCallback(
    (tax) =>
      TAX_OPTIONS.map(({ id }) => id).includes(tax.id) && tax.id !== 'OTHER'
        ? attemptFormat({ id: `tax.${tax.id}`, dm: tax.id })
        : tax.specifiedName,
    [attemptFormat],
  );

  const timeAgoDefaultText = useCallback(
    (number) =>
      isEnglish
        ? {
            invalid: 'Invalid Date',
            recent: 'Just Now',
            minute: '1 Minute Ago',
            minutes: `${number} Minutes Ago`,
            hour: '1 Hour Ago',
            hours: `${number} Hours Ago`,
            day: '1 Day Ago',
            days: `${number} Days Ago`,
            month: '1 Month Ago',
            months: `${number} Months Ago`,
          }
        : isFrench
        ? {
            invalid: 'Date Invalide',
            recent: 'Juste Maintenant',
            minute: 'Il Y A 1 Minute',
            minutes: `Il Y A ${number} Minutes`,
            hour: 'Il Y A 1 Heure',
            hours: `Il Y A ${number} Heures`,
            day: 'Il Y A 1 Jour',
            days: `Il Y A ${number} Jours`,
            month: 'Il Y A 1 Mois',
            months: `Il Y A ${number} Mois`,
          }
        : {
            invalid: 'وقت ما',
            recent: 'الأن',
            minute: 'منذ دقيقة',
            minutes: `منذ ${number}  دقائق`,
            hour: 'منذ ساعة',
            hours: `منذ ${number} ساعات`,
            day: 'منذ يوم',
            days: `منذ ${number} أيام`,
            month: 'منذ شهر',
            months: `منذ ${number} أشهر`,
          },
    [isEnglish, isFrench],
  );

  const timeAgo = useCallback(
    (date, messageText = timeAgoDefaultText) => {
      if (!date.getTime()) return messageText().invalid;
      const time = {
        minute: 60,
        hour: 3600,
        day: 86400,
        month: 2678400,
        year: 31536000,
      };

      const message = (unit) => {
        let number;
        if (unit !== 'month') number = numberAgo(diff, time[unit]);
        else number = monthsAgo(date, rightNow);

        if (number > 1) unit = unit + 's';
        return messageText(number)[unit];
      };

      const numberAgo = (diff, unit) => Math.floor(diff / unit);

      const monthsAgo = (date, now) => {
        let month1 = now.getMonth();
        const month2 = date.getMonth();

        if (now.getYear() > date.getYear()) month1 += 12;
        if (now.getDate() < date.getDate()) month1 -= 1;

        return month1 - month2;
      };

      const yearsAgo = (date, now) => {
        let year1 = now.getYear();
        const year2 = date.getYear();

        if (now.getMonth() <= date.getMonth() && now.getDate() < date.getDate())
          year1 -= 1;

        return year1 - year2;
      };

      const rightNow = new Date();
      const diff = (rightNow - date) / 1000; //gets diff in seconds
      if (diff < time.minute) return message('recent');
      if (diff < time.hour) return message('minute');
      if (diff < time.day) return message('hour');
      if (!monthsAgo(date, rightNow)) return message('day');
      if (!yearsAgo(date, rightNow)) return message('month');

      return formatDate(date);
    },
    [formatDate, timeAgoDefaultText],
  );

  const shortTimeAgo = useCallback(
    (date) =>
      timeAgo(date, (number) =>
        isEnglish
          ? {
              invalid: 'Invalid Date',
              recent: '<1m',
              minute: '1m',
              minutes: `${number}m`,
              hour: '1h',
              hours: `${number}h`,
              day: '1d',
              days: `${number}d`,
              month: '1M',
              months: `${number}M`,
            }
          : isFrench
          ? {
              invalid: 'Date Invalide',
              recent: '<1m',
              minute: '1m',
              minutes: `${number}m`,
              hour: '1h',
              hours: `${number}h`,
              day: '1j',
              days: `${number}j`,
              month: '1M',
              months: `${number}M`,
            }
          : {
              invalid: 'Date Invalide',
              recent: '<دقيقة',
              minute: 'دقيقة',
              minutes: `${number} دقائق`,
              hour: 'ساعة',
              hours: `${number} ساعات`,
              day: 'يوم',
              days: `${number} أيام`,
              month: 'شهر',
              months: `${number} أشهر`,
            },
      ),
    [timeAgo, isEnglish, isFrench],
  );

  const shortNumber = useCallback(
    (inputNum) => {
      if (Number.isNaN(Number(inputNum)))
        return isEnglish ? 'Invalid Number' : 'Numéro Invalide';
      const longNum = Number(inputNum);
      return (
        [
          { length: 9, symbol: isEnglish ? 'B' : 'G' },
          { length: 6, symbol: 'M' },
          { length: 3, symbol: isEnglish ? 'K' : 'k' },
          { length: 0, symbol: '' },
        ].reduce(
          (smallNum, { length, symbol }) =>
            10 ** length > longNum || smallNum
              ? smallNum
              : `${Math.round((longNum / 10 ** length) * 10) / 10}${symbol}`,
          '',
        ) || inputNum
      );
    },
    [isEnglish],
  );

  const timeCode = useCallback((timeNum) => {
    if ([null, undefined].includes(timeNum)) return;
    const pad = (time, zeroes) => time.toString().padStart(zeroes || 0, '0');

    const hours = Math.floor(timeNum / 3600);
    const minutes = Math.floor(timeNum / 60) % 60;
    const seconds = Math.floor(timeNum % 60);

    return [hours, pad(minutes, hours && 2), pad(seconds, 2)]
      .filter((el) => el)
      .join(':');
  }, []);

  return {
    locale,
    isEnglish,
    isFrench,
    isArabic,
    nameSort,
    names,
    formatNames,
    formatDate,
    formatCost,
    timeAgo,
    shortTimeAgo,
    rolesLang,
    attemptFormat,
    taxName,
    shortNumber,
    timeCode,
  };
};

export default useLocale;

import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_ROLES, MODERATOR_ROLES } from '../data/constants';
import Cookies from 'js-cookie';
import tryClient from '../utils/tryClient';

// hard coding in user permissions,
// to be removed once login endpoint returns user's permissions

const defaultPermissions = { view: [], post: [], manage: [] };

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    supplier: null,
    subscription: null,
    isAdmin: false,
    isModerator: false,
    isLoading: true,
    isSupplierLoading: true,
    isSubscriptionLoading: true,
    categories: null,
    listings: null,
    categoriesLoading: true,
    listingsLoading: true,

    permissions: {
      view: defaultPermissions.view,
      post: defaultPermissions.post,
      manage: defaultPermissions.manage,
    },
  },
  reducers: {
    addUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
      if (ADMIN_ROLES.includes(action.payload.roles)) state.isAdmin = true;
      if (MODERATOR_ROLES.includes(action.payload.roles))
        state.isModerator = true;
      state.permissions = {
        view: action.payload.viewPermissions,
        post: action.payload.postPermissions,
        manage: action.payload.managePermissions,
      };
    },
    addSupplier(state, action) {
      state.isSupplierLoading = false;
      if (!action?.payload) return;
      state.supplier = action.payload;
    },
    removeSupplier(state) {
      state.isSupplierLoading = false;
      state.supplier = null;
    },
    addCategories(state, action) {
      state.categoriesLoading = false;
      state.categories = action?.payload || null;
    },
    removeCategories(state) {
      state.categoriesLoading = false;
      state.categories = null;
    },
    addListings(state, action) {
      state.listingsLoading = false;
      state.listings = action?.payload || null;
    },
    removeListings(state) {
      state.listingsLoading = false;
      state.listings = null;
    },
    addSubscription(state, action) {
      if (!action?.payload) return;
      state.subscription = action.payload;
      state.isSubscriptionLoading = false;
    },
    removeSubscription(state) {
      state.subscription = null;
      state.isSubscriptionLoading = false;
    },
    removeUser(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.isAdmin = false;
      state.isModerator = false;
      localStorage.removeItem('accessToken');
      sessionStorage.removeItem('accessToken');
      Cookies.remove('accessToken');
    },
    updateUserData(state, action) {
      state.user = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  addUser,
  addSupplier,
  removeSupplier,
  removeUser,
  updateUserData,
  setIsLoading,
  addSubscription,
  removeSubscription,
  addCategories,
  addListings,
  removeCategories,
  removeListings,
} = authSlice.actions;

export default authSlice.reducer;

const clearDetails = () => async (dispatch) => {
  dispatch(removeUser());
  dispatch(removeSupplier());
  dispatch(removeSubscription());
  dispatch(removeCategories());
  dispatch(removeListings());
};

export const logout = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  await tryClient('/logout', { method: 'POST' });
  Cookies.remove('accessToken');
  dispatch(clearDetails());
};

export const loadUserInfo = ({ user, supplier }) => async (dispatch) => {
  const isSuspended = user?.status === 'suspended';
  if (isSuspended) {
    dispatch(clearDetails());
    throw new Error('suspended');
  }

  const [
    { r: subResp, e: subErr },
    { r: categoriesResp } = {},
    { r: listingsResp } = {},
  ] = await Promise.all(
    [
      tryClient('/get-my-subscription'),
      supplier?._id &&
        tryClient(
          `/categories/supplier-categories/${supplier?._id}?pagination[perpage]=100`,
        ),
      supplier?._id &&
        tryClient(
          `/supplier-products/${supplier?._id}?pagination[perpage]=100`,
        ),
    ].filter((el) => el),
  );

  if (subErr) dispatch(removeSubscription());
  else dispatch(addSubscription(subResp.data));
  dispatch(addCategories(categoriesResp?.data?.data));
  dispatch(addListings(listingsResp?.data?.data));
  dispatch(addSupplier(supplier));
  dispatch(addUser(user));
};

export const login = ({ email, password, code, rememberMe }) => async (
  dispatch,
) => {
  const apiCall = await tryClient(code ? '/verify-two-factor-code' : '/login', {
    body: { email, password, ...(code && { code }) },
  });
  if (apiCall?.err) return apiCall;
  const { user, supplier, access_token } = apiCall?.resp?.data || {};
  sessionStorage.setItem('accessToken', access_token);

  // Added domain element to share cookie across subdomain stores
  Cookies.set('accessToken', access_token, {
    domain: '.' + process.env.REACT_APP_FRONTEND_HOSTNAME_URL_NO_PORT,
    expires: rememberMe ? 21 : 1,
  });

  await dispatch(loadUserInfo({ user, supplier }));
  return apiCall;
};

export const checkMe = () => async (dispatch) => {
  const apiCall = await tryClient('/users/me', {
    method: 'POST',
  });
  if (apiCall?.err) return dispatch(clearDetails());
  const { user, supplier } = apiCall?.resp?.data || {};
  await dispatch(loadUserInfo({ user, supplier }));
  return apiCall;
};

export const updateUser = (userData) => async (dispatch) => {
  const { resp, err } = await tryClient('/users/update-profile', {
    body: userData,
    method: 'PATCH',
  });
  if (err) throw new Error(err);
  dispatch(updateUserData(resp.data));
};

export const uploadUserPicture = (formData) => async (dispatch) => {
  const imageData = new FormData();
  imageData.append('profile_picture', formData);
  const { resp, err } = await tryClient('/users/upload-picture', {
    formData: imageData,
    method: 'POST',
  });
  if (err) return;
  dispatch(updateUserData(resp.data));
};

const format = (inputText) =>
  inputText
    ? inputText
        .replaceAll(/\s/g, '-')
        .replaceAll('%', '')
        .replaceAll(',', '')
        .replaceAll('?', '')
        .replaceAll('#', '')
        .replaceAll('{', '(')
        .replaceAll('}', ')')
        .replaceAll('/', '-')
        .replaceAll(/-+/g, '-') + '-'
    : '';

export const formatNamedLink = ({
  product,
  listing,
  category,
  business,
  supplier,
  seller,
  post,
  feed,
  userProfile,
  tagLink,
  to,
  append,
}) => {
  const productCategory =
    product?.categoryName ||
    product?.category_name ||
    listing?.categoryName ||
    listing?.category_name;
  const productId =
    product?.id ||
    product?._id ||
    product?.productId ||
    listing?.id ||
    listing?._id ||
    listing?.listingId;
  const productName =
    product?.title ||
    product?.productTitle ||
    listing?.title ||
    listing?.productTitle;
  const categoryName =
    category?.categoryEnglishName || category?.en_name || category?.name;
  const categoryId = category?.id || category?._id;
  const businessProfile = business || supplier || seller;
  const businessName = businessProfile?.name || businessProfile?.supplierName;
  const businessId =
    businessProfile?.id || businessProfile?._id || businessProfile?.supplierId;
  const postId = post?.id || post?._id;
  const postTitle = post?.title || post?.post_title;
  const feedEntityId = feed?.parent_feed_id || feed?.parentFeedId;
  const feedEntityTitle = feed?.title || feed?.post_title;
  const userProfileId = userProfile?.id || userProfile?._id;

  const formattedLink = to
    ? to
    : (product || listing) && productId
    ? `/product/${format(productCategory)}${format(productName)}${productId}`
    : category && categoryId
    ? `/category/${format(categoryName)}${categoryId}`
    : businessProfile && businessId
    ? `/business/${format(businessName)}${businessId}`
    : post && postId
    ? `/post/${format(postTitle)}${postId}`
    : feed && feedEntityId
    ? `/post/${format(feedEntityTitle)}${feedEntityId}`
    : userProfile && userProfileId
    ? `/view-user-profile-${userProfileId}`
    : tagLink
    ? `/search?tag=${encodeURIComponent(tagLink.tag)}&type=${tagLink.type}`
    : undefined;

  return formattedLink ? formattedLink + (append || '') : undefined;
};

// Register form for the Register modal. Allows social auth or traditional
// account registration.

import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Flex,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useToaster from '../../hooks/useToaster';
import useValidator from '../../hooks/useValidator';
import client from '../../utils/client';
import FormField from '../forms/FormField';
import FormattedText from '../intl/FormattedText';
import LanguageSelectSection from '../userprofile/LanguageSelectSection';
import queryString from 'query-string';
import OrganizationTypeSelector from '../forms/OrganizationTypeSelector';
import { login } from '../../reducers/authReducer';
import CheckIcon from '@mui/icons-material/Check';
import {
  ORGANIZATION_TYPES,
  POSTAL_CODE_REGEX,
  ZIP_CODE_REGEX,
} from '../../data/constants';
import MultiStepForm, { FormStep } from '../forms/MultiStepForm';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const WizardSupplierRegistration = ({ onClose, closedOnce, setClosedOnce }) => {
  const validator = useValidator();
  const intl = useIntl();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierRoleId = '2';
  // For language form field
  const [languageSelection, setLanguageSelection] = useState('');
  // For accept term
  const [acceptTerm, setAccetpTerm] = useState(false);

  const [addressNotice, setAddressNotice] = useState(null);
  const [addressDetail, setAddressDetail] = useState(null);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const { locale } = useSelector((state) => state.intl);
  const flexBasis = { base: '100%', xs: '48%' };
  const fieldSpacing = { base: '4', xs: '0' };
  const postalCodeRef = useRef('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const validationSchema = validator((schema) => ({
    ...schema.firstName(),
    ...schema.lastName(),
    ...schema.email(),
    ...schema.organization({ required: true }),
    ...schema.organizationType(),
    ...schema.password(),
    ...schema.passwordConfirm(),
    ...schema.postalCode(),
  }));
  const initialValues = {
    organization: '', //   organization: 'XYZ Corp',
    organizationType: '',
    postalCode: '',
    lineOne: '',
    lineTwo: '',
    firstName: '', //   firstName: 'John',
    lastName: '', //   lastName: 'Doe',
    email: '', //   email: 'tests1@optimateam.testinator.com',
    password: '', //   password: 'Password1',
    passwordConfirm: '', //   passwordConfirm: 'Password1',
  };

  const validatePostalCode = async (value) => {
    if (value === postalCodeRef.current) return;
    postalCodeRef.current = value;
    const postalMatch = POSTAL_CODE_REGEX.test(value);
    const zipMatch = ZIP_CODE_REGEX.test(value);
    if (postalMatch || zipMatch) {
      try {
        const { status, data } = await client('/get-address/postal-code', {
          method: 'POST',
          body: { postal_code: value },
        });
        if (status === 200) {
          setAddressNotice(data.address);
          setAddressDetail(data.details);
          setIsValidAddress(true);
        }
      } catch (error) {
        setAddressNotice(error.error);
        setIsValidAddress(false);
        setAddressDetail(null);
      }
    } else {
      setAddressNotice('');
      setIsValidAddress(false);
      setAddressDetail(null);
    }
  };

  const handleChangeLanguage = (event) => {
    setLanguageSelection(event.target.value);
  };

  useEffect(() => {
    setLanguageSelection(locale);
  }, [locale]);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const { register } = query;

    if (register && !closedOnce) {
      setClosedOnce(true);
    }
  }, [closedOnce, setClosedOnce]);

  const handleSubmit = async (values, resetForm) => {
    const {
      firstName,
      lastName,
      email,
      organization,
      organizationType,
      password,
      passwordConfirm,
      postalCode,
      lineOne,
      lineTwo,
    } = values;

    const newUser = {
      first_name: firstName,
      last_name: lastName,
      email,
      password: password,
      password_confirmation: passwordConfirm,
      roles: supplierRoleId,
      organization,
      organization_type: organizationType,
      two_factor_enable: false,
      language: languageSelection ? languageSelection : 'en',
      location: {
        street_number: lineOne,
        street_name: lineTwo,
        city: addressDetail?.city,
        province: addressDetail?.province,
        postal_code: postalCode,
      },
    };
    try {
      const { status } = await client('/seller-register', {
        method: 'POST',
        body: newUser,
      });
      if (status === 200) {
        try {
          const resp = await client('/login', {
            body: { email, password },
          });
          const { data } = resp;
          const accessToken = data['access_token'];
          const user = data.user;
          sessionStorage.setItem('accessToken', accessToken);
          dispatch(login(user));
          history.push('/');
          toaster('loginSuccess');
        } catch (err) {}
      }
    } catch (err) {
      if (err.status === 422) {
        resetForm({
          values: {
            firstName,
            lastName,
            email,
            organization,
            organizationType,
            password: '',
            passwordConfirm: '',
            postalCode,
          },
          errors: {
            email: intl.formatMessage({
              id: 'auth.register.userExists',
              defaultMessage: 'That email address is already taken',
            }),
          },
          touched: { email: true },
        });
      }
      if (err.status === 401) {
        toaster('registrationError');
      }
    }
  };

  return (
    <>
      <ModalHeader>
        <Flex justifyContent="center" flexWrap="wrap">
          <FormattedText
            id="auth.register"
            defaultMessage="Register"
            fontSize={matches ? 'sm' : '3xl'}
            fontWeight="bold"
            as="h2"
            mt={2}
          />
        </Flex>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <MultiStepForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          termAcceptted={acceptTerm}
        >
          <FormStep stepName="step1" validationSchema={validationSchema}>
            <Stack spacing={4} shouldWrapChildren={true}>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box display="block" flexBasis={flexBasis} mb={fieldSpacing}>
                  <FormField name="organization" required />
                </Box>
                <Box display="block" flexBasis={flexBasis} mb={fieldSpacing}>
                  <OrganizationTypeSelector
                    name="organizationType"
                    options={ORGANIZATION_TYPES}
                    required
                  />
                </Box>
              </Flex>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box display="flex" flexBasis={flexBasis} mb={fieldSpacing}>
                  <FormField
                    name="postalCode"
                    validate={validatePostalCode}
                    required
                  />
                </Box>
                <Box
                  display="flex"
                  flexBasis={flexBasis}
                  mb={fieldSpacing}
                  alignItems="end"
                  p={2}
                  fontSize={matches ? '18px' : ''}
                >
                  {isValidAddress && <CheckIcon color="primary" />}
                  {addressNotice}
                </Box>
              </Flex>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box display="block" flexBasis={flexBasis} mb={fieldSpacing}>
                  <FormField name="lineOne" />
                </Box>
                <Box display="block" flexBasis={flexBasis} mb={fieldSpacing}>
                  <FormField name="lineTwo" />
                </Box>
              </Flex>
            </Stack>
          </FormStep>
          <FormStep stepName="step2" validationSchema={validationSchema}>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box display="block" flexBasis={flexBasis} mb={fieldSpacing}>
                <FormField name="firstName" required />
              </Box>
              <Box display="block" flexBasis={flexBasis}>
                <FormField flexBasis={flexBasis} name="lastName" required />
              </Box>
            </Flex>
          </FormStep>
          <FormStep stepName="step3" validationSchema={validationSchema}>
            <Stack spacing={4} shouldWrapChildren={true}>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box display="flex" flexBasis={flexBasis} mb={fieldSpacing}>
                  <FormField name="email" required />
                </Box>
              </Flex>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box display="block" flexBasis={flexBasis} mb={fieldSpacing}>
                  <FormField name="password" type="password" required />
                </Box>
                <Box display="block" flexBasis={flexBasis}>
                  <FormField name="passwordConfirm" type="password" required />
                </Box>
              </Flex>
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box display="flex" flexBasis={flexBasis} mb={fieldSpacing}>
                  <LanguageSelectSection
                    languageSelection={languageSelection}
                    onChange={handleChangeLanguage}
                  />
                </Box>
              </Flex>
              <Checkbox
                name="terms"
                size={matches ? 'sm' : 'md'}
                isChecked={acceptTerm}
                onChange={(e) => {
                  setAccetpTerm(!acceptTerm);
                }}
              >
                {intl.formatMessage({
                  id: 'forms.terms',
                  defaultMessage: 'Terms and conditions',
                })}
              </Checkbox>
            </Stack>
          </FormStep>
        </MultiStepForm>
      </ModalBody>
    </>
  );
};

export default WizardSupplierRegistration;

import React, { useEffect, useReducer, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Step, StepLabel, Stepper, Modal } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import Flex from '../common/Flex';
import MuiCard from '../common/MuiCard';
import MuiButton from '../intl/MuiButton';
import MuiText from '../intl/MuiText';
import checkFunc from '../../utils/checkFunc';
import useBreakpoint from '../../hooks/useBreakpoint';
import PreventExit from './PreventExit';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import MuiErrorText from './MuiErrorText';
import useScrollToTop from '../../hooks/useScrollToTop';
import { refHeight, totalOffset } from '../../utils/nodeInfo';
import useNavSize from '../../hooks/useNavSize';

const reducer = (state, { type, payload }) => ({
  ...state,
  ...{
    next: {
      ...(state.activeStep < state.numberOfSteps && {
        activeStep: state.activeStep + 1,
        stepProps: state.stepProps.map((props, index) => ({
          ...props,
          ...(state.activeStep === index && { completed: true }),
        })),
      }),
    },
    back: {
      ...(state.activeStep && {
        activeStep: state.activeStep - 1,
        stepProps: state.stepProps.map((props, index) => ({
          ...props,
          ...(index >= state.activeStep - 1 && { completed: false }),
        })),
      }),
    },
    complete: {
      ...(state.activeStep === state.numberOfSteps - 1 && {
        stepProps: state.stepProps.map((props) => ({
          ...props,
          completed: true,
        })),
      }),
    },
  }[type],
});

const MuiMultiStep = ({
  // required
  children,
  // optional
  stepLabels = [],
  initialStep = 0,
  formProps,
  stepperProps,
  navAndErrorProps,
  navAreaProps,
  errorProps,
  title, // string || {id,dm} || <TitleComponent />
  titleProps,
  labelProps, // { desktopLabelProps, mobileLabelBoxProps, mobileLabelProps, mobileLabelNumberProps }
  buttonProps, // { nextProps, submitProps, backProps, cancelProps }
  hasCancel,
  onCancel,
  confirmSubmit, // { title: {string, {id,dm}, <TitleComponent/>}, titleProps, modalProps, cardProps, buttonAreaProps, cancelProps, submitProps, bodyDetails: (form) => <DetailsComponent/> }
  preventExit,
  prevent,
  modalControl: givenModalControl, // useMuiModalControl() output, required for modal forms when preventExit is True
  setStepNumber,
  doScroll,
  dontScroll,
  stepColor = 'primary.main',
  initialValues,
  validationSchema,
  onSubmit,
  ...rest
}) => {
  const { headerRef, pageRef } = useNavSize();
  const navHeight = refHeight(headerRef);
  const { attemptFormat } = useLocale();
  const { md } = useBreakpoint();
  const internalModalControl = useMuiModalControl();
  const modalControl = givenModalControl ?? internalModalControl;
  const confirmModalControl = useMuiModalControl();
  const { smoothScroll } = useScrollToTop();
  const fieldRef = useRef({ step: null, fields: [] });
  const stepArray = (children?.length ? [...children] : [children]).filter(
    (el) => el,
  );
  const [{ activeStep, numberOfSteps, stepProps }, dispatch] = useReducer(
    reducer,
    {
      activeStep: initialStep,
      numberOfSteps: stepArray?.length,
      stepProps: Array(stepArray?.length).fill({}),
    },
  );

  useEffect(() => {
    if (setStepNumber) setStepNumber(activeStep);
  }, [activeStep, setStepNumber]);

  const isLastPage = activeStep === numberOfSteps - 1;
  const isFirstPage = !activeStep;

  const { children: stepChildren } = stepArray[activeStep]?.props;
  const { style, button } = makeProps({
    activeStep,
    dispatch,
    fieldRef,
    modalControl,
    confirmSubmit,
    confirmModalControl,
    smoothScroll,
    doScroll,
    dontScroll,
    navHeight,
    pageRef,
  });
  return (
    <Formik
      validateOnMount
      {...{ initialValues, validationSchema, onSubmit, ...rest }}
    >
      {(form) => (
        <>
          <Form {...style.form(formProps)}>
            {preventExit && (
              <PreventExit
                {...{
                  prevent,
                  dirty: form.dirty,
                  isSubmitting: form.isSubmitting,
                  modalControl,
                  resetForm: form.resetForm,
                  onCancel,
                }}
              />
            )}
            {React.isValidElement(checkFunc(title, form)) ? (
              checkFunc(title, form)
            ) : (
              <MuiText {...style.title(titleProps)}>
                {attemptFormat(checkFunc(title, form))}
              </MuiText>
            )}
            {!!stepLabels?.length && (
              <>
                {md ? (
                  <Flex
                    {...style.mobileLabelBox(labelProps?.mobileLabelBoxProps)}
                  >
                    <MuiText
                      {...style.mobileLabel(labelProps?.mobileLabelProps)}
                    >
                      {attemptFormat(stepLabels[activeStep])}
                    </MuiText>
                    <MuiText
                      {...style.mobileLabelNumber(
                        labelProps?.mobileLabelNumberProps,
                      )}
                    >{`${activeStep + 1} / ${stepLabels.length}`}</MuiText>
                  </Flex>
                ) : (
                  <Stepper {...{ activeStep, ...stepperProps }}>
                    {stepLabels.map((label, key) => (
                      // <Step {...{ key: label?.id || label }}>
                      <Step
                        {...{
                          key,
                          ...stepProps[key],
                          sx: {
                            '& svg.Mui-active': { color: stepColor },
                            '& svg.Mui-completed': { color: stepColor },
                          },
                        }}
                      >
                        <StepLabel
                          {...style.stepLabel(labelProps?.desktopLabelProps)}
                        >
                          {attemptFormat(label)}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </>
            )}
            {checkFunc(stepChildren, form)}
            <Flex {...style.navAndError(navAndErrorProps)}>
              <Flex {...style.navArea(navAreaProps)}>
                {!isFirstPage && (
                  <MuiButton
                    {...button.back(
                      form,
                      checkFunc(buttonProps?.backProps, activeStep),
                    )}
                  />
                )}
                {isFirstPage && hasCancel && (
                  <MuiButton
                    {...button.cancel(form, buttonProps?.cancelProps)}
                  />
                )}
                {isLastPage && (
                  <MuiButton
                    {...button.finished(
                      form,
                      stepChildren,
                      buttonProps?.submitProps,
                    )}
                  />
                )}
                {!isLastPage && (
                  <MuiButton
                    {...button.next(
                      form,
                      stepChildren,
                      checkFunc(buttonProps?.nextProps, activeStep),
                    )}
                  />
                )}
              </Flex>
              <MuiErrorText
                {...style.formError(
                  errorProps,
                  isLastPage,
                  getFields(stepChildren, form),
                )}
              />
            </Flex>
          </Form>
          <Modal
            {...{
              open: confirmModalControl.open,
              onClose: confirmModalControl.handleClose,
              ...confirmSubmit?.modalProps,
            }}
          >
            <MuiCard {...style.confirmCard(confirmSubmit?.cardProps)}>
              <MuiText {...style.title(confirmSubmit?.titleProps)}>
                {attemptFormat(
                  confirmSubmit?.title || {
                    id: 'form.multiStep.confirmMessage',
                    dm: 'Are You Sure?',
                  },
                )}
              </MuiText>
              {confirmSubmit?.bodyDetails(form) || <></>}
              <Flex
                {...style.confirmButtonArea(confirmSubmit?.buttonAreaProps)}
              >
                <MuiButton
                  {...button.confirmCancel(confirmSubmit?.cancelProps)}
                />
                <MuiButton
                  {...button.confirmSubmit(form, confirmSubmit?.submitProps)}
                />
              </Flex>
            </MuiCard>
          </Modal>
        </>
      )}
    </Formik>
  );

  function makeProps({
    activeStep,
    dispatch,
    fieldRef,
    modalControl,
    confirmSubmit,
    confirmModalControl,
    smoothScroll,
    doScroll,
    dontScroll,
    navHeight,
    pageRef,
  }) {
    const { setCloseAttempted } = modalControl || {};
    return {
      style: {
        form: (formProps) => ({
          ...formProps,
          style: { height: '100%', ...formProps?.style },
        }),
        title: (titleProps) => ({
          ...titleProps,
          sx: {
            fontSize: '1.5rem',
            color: 'headingText.main',
            ...titleProps?.sx,
          },
        }),
        stepLabel: (props) => ({
          ...props,
          sx: { fontSize: '0.875rem', color: 'headingText.main', ...props?.sx },
        }),
        mobileLabelBox: (props) => ({
          ...props,
          sx: { gap: '0.5rem', alignItems: 'center', ...props?.sx },
        }),
        mobileLabel: (props) => ({
          ...props,
          sx: { fontSize: '0.875rem', color: 'headingText.main', ...props?.sx },
        }),
        mobileLabelNumber: (props) => ({
          ...props,
          sx: {
            fontWeight: 'bold',
            fontSize: '0.875rem',
            color: 'primary.main',
            ...props?.sx,
          },
        }),
        navArea: (props) => ({ ...props, sx: { mt: 'auto', ...props?.sx } }),
        confirmCard: (props) => ({
          customScroll: true,
          ...props,
          sx: {
            m: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 12,
            minWidth: 'min(95vw, 30rem)',
            maxWidth: '95vw',
            maxHeight: '95vh',
            ...props?.sx,
          },
        }),
        confirmButtonArea: (props) => ({
          ...props,
          sx: {
            mt: '2rem',
            width: '100%',
            gap: '1rem',
            justifyContent: 'space-between',
            ...props?.sx,
          },
        }),
        navAndError: (props) => ({
          direction: 'column',
          ...props,
          sx: { ...props?.sx },
        }),
        formError: (props, isLastPage, fields) => ({
          ...(!isLastPage && { fields }),
          ...props,
          sx: { ml: 'auto', ...props?.sx },
        }),
      },
      button: {
        cancel: ({ isSubmitting }, props) => ({
          id: 'form.multiStep.cancelButton',
          dm: 'CANCEL',
          type: 'button',
          variant: 'formButtonText',
          color: 'bodyText',
          ...props,
          disabled: props?.disabled || isSubmitting,
          onClick: (event) => {
            if (props?.onClick) props.onClick(event);
            if (setCloseAttempted) setCloseAttempted(true);
          },
          sx: { ...props?.sx },
        }),
        back: ({ isSubmitting }, props) => ({
          id: 'form.multiStep.backButton',
          dm: 'BACK',
          type: 'button',
          onClick: () => {
            dispatch({ type: 'back' });
            if (doScroll || !(modalControl || dontScroll)) smoothScroll();
          },
          variant: 'formButtonText',
          ...props,
          disabled: props?.disabled || isSubmitting,
          sx: { ...props?.sx },
        }),
        next: (form, stepChildren, props) => {
          const { errors, setFieldTouched } = form;
          return {
            id: 'form.multiStep.nextButton',
            dm: 'NEXT',
            elementId: 'multi-step-form-next-button',
            type: 'button',
            variant: 'formButtonOutlined',
            ...props,
            onClick: async (event) => {
              if (props?.onClick) await props.onClick(event, form);
              const { err: clickError } = props?.onClick
                ? (await props.onClick(event, form)) || {}
                : {};
              if (clickError) return;
              if (activeStep !== fieldRef?.current?.step) {
                fieldRef.current.step = activeStep;
              }
              fieldRef.current.fields = getFields(stepChildren, form);
              fieldRef.current.fields.forEach((field) =>
                setFieldTouched(field),
              );
              const firstError = fieldRef.current.fields.reduce(
                (hasError, field) => {
                  return hasError || (errors[field] ? field : false);
                },
                false,
              );

              const firstErrorOffset = totalOffset(
                document.getElementById(firstError),
              );
              const scrollHeight =
                firstErrorOffset > navHeight ? firstErrorOffset - navHeight : 0;
              const pageScroll = pageRef?.current?.scrollTop || 0;
              if (firstError)
                return (
                  pageScroll > scrollHeight &&
                  smoothScroll({ top: scrollHeight })
                );

              dispatch({ type: 'next' });
              if (doScroll || !(modalControl || dontScroll)) smoothScroll();
            },
            sx: { ml: 'auto', ...props?.sx },
          };
        },
        finished: (form, stepChildren, props) => {
          const {
            errors = {},
            values = {},
            submitForm,
            isSubmitting,
            setFieldTouched,
          } = form || {};
          return {
            id: 'form.multiStep.submitButton',
            dm: 'SUBMIT',
            elementId: 'multi-step-form-submit-button',
            type: 'button',
            variant: 'formButton',
            loading: isSubmitting,
            onClick: () => {
              fieldRef.current.fields = getFields(stepChildren, form);
              fieldRef.current.fields.forEach((field) =>
                setFieldTouched(field),
              );

              const firstError = fieldRef.current.fields.reduce(
                (hasError, field) => {
                  return hasError || (errors[field] ? field : false);
                },
                false,
              );

              const firstErrorOffset = totalOffset(
                document.getElementById(firstError),
              );
              const scrollHeight =
                firstErrorOffset > navHeight ? firstErrorOffset - navHeight : 0;
              const pageScroll = pageRef?.current?.scrollTop || 0;

              if (firstError)
                return (
                  pageScroll > scrollHeight &&
                  smoothScroll({ top: scrollHeight })
                );

              const canSubmit = Object.keys(values).reduce(
                (noErrors, field) => {
                  setFieldTouched(field);
                  return noErrors && !errors[field];
                },
                true,
              );
              if (!canSubmit) return;
              dispatch({ type: 'complete' });
              if (confirmSubmit)
                !checkFunc(confirmSubmit.preventOpen, values) &&
                  confirmModalControl.handleOpen();
              else submitForm();
            },
            ...props,
            sx: { ml: 'auto', ...props?.sx },
          };
        },
        confirmCancel: (props) => ({
          id: 'form.multiStep.confirmCancel',
          dm: 'Cancel',
          type: 'button',
          variant: 'formButtonOutlined',
          onClick: confirmModalControl.handleClose,
          ...props,
          sx: { ...props?.sx },
        }),
        confirmSubmit: ({ submitForm }, props) => ({
          id: 'form.multiStep.confirmSubmit',
          dm: 'Submit',
          type: 'button',
          variant: 'formButton',
          onClick: () => {
            submitForm();
            confirmModalControl.handleClose();
          },
          ...props,
          sx: { ...props?.sx },
        }),
      },
    };
  }
  function getFields(stepChildren, form) {
    const reactNode = checkFunc(stepChildren, form);
    const allFields = Object.keys(form?.values || {});
    const findFields = (node) => {
      const children = (Array.isArray(node)
        ? node
        : node?.props?.name || node?.props?.names
        ? [node]
        : node?.props?.children?.length
        ? [...node?.props?.children]
        : [node?.props?.children]
      )
        .flat()
        .filter((el) => el?.props);

      return children.reduce((newFields, child) => {
        if (!child?.props) return [];
        const { names, name } = child.props;
        const includedName = allFields.includes(name);
        if (typeof names !== 'object')
          return newFields.concat(
            !name ? findFields(child) : includedName ? [name] : [],
          );
        return newFields.concat(
          allFields.includes(name)
            ? [name]
            : Array.isArray(names)
            ? names.filter((name) => allFields.includes(name))
            : Object.values(names).flat(),
        );
      }, []);
    };
    return [...new Set(findFields(reactNode))];
  }
};

export const MuiFormStep = ({ children }) => children;

export default MuiMultiStep;

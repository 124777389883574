// Button to change languages from English to French or vice versa

import React from 'react';
import { Button } from '@chakra-ui/react';
import { setLocale } from '../../reducers/intlReducer';
import { useDispatch, useSelector } from 'react-redux';

function ChangeLanguage({ ...rest }) {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.intl);

  const handleSwitchLocale = () => {
    if (locale.includes('en')) {
      localStorage.setItem('locale', 'fr');
      dispatch(setLocale('fr'));
    } else {
      localStorage.setItem('locale', 'en');
      dispatch(setLocale('en'));
    }
  };
  return (
    <Button
      colorScheme="newBlack"
      fontWeight="400"
      onClick={handleSwitchLocale}
      fontSize="md"
      height="100%"
      width="auto"
      overflow="hidden"
      {...rest}
    >
      {locale.includes('en') ? 'Français?' : 'English?'}
    </Button>
  );
}

export default ChangeLanguage;

import React from 'react';
import { Field } from 'formik';
import { FormHelperText } from '@mui/material';
import MuiText from '../intl/MuiText';
import useLocale from '../../hooks/useLocale';

const MuiErrorText = ({
  name,
  hasError = false,
  notTouched,
  fields,
  ...props
}) => {
  const { attemptFormat } = useLocale();
  const { style } = makeProps();
  return (
    <Field>
      {({ form: { errors, touched, values } }) => (
        <>
          {name
            ? (!!errors?.[name] || hasError) &&
              (!!touched?.[name] || notTouched) && (
                <FormHelperText
                  id={`${name}-errorText`}
                  {...style.fieldError(props)}
                >
                  {typeof hasError === 'boolean'
                    ? errors?.[name]
                    : attemptFormat(hasError)}
                </FormHelperText>
              )
            : ((fields ?? Object.keys(values || {})).reduce(
                (disabled, field) =>
                  disabled || (errors?.[field] && touched?.[field]),
                false,
              ) ||
                hasError) && <MuiText {...style.formError(props)} />}
        </>
      )}
    </Field>
  );
  function makeProps() {
    return {
      style: {
        fieldError: (props) => ({
          ...props,
          sx: {
            m: 0,
            px: '1.25rem',
            py: '0.5rem',
            color: 'error.main',
            fontSize: '0.75rem',
            ...props?.sx,
          },
        }),
        formError: (props) => ({
          id: 'form.hasErrors',
          dm: 'Form Contains Errors',
          ...props,
          sx: {
            m: 0,
            px: '1.25rem',
            py: '0.5rem',
            color: 'error.main',
            fontSize: '0.75rem',
            ...props?.sx,
          },
        }),
      },
    };
  }
};

export default MuiErrorText;

import React from 'react';
import useAuth from '../../hooks/useAuth';
import useLocale from '../../hooks/useLocale';
import Flex from '../common/Flex';
import ImageBox from '../common/ImageBox';
import Link from '@mui/material/Link';
import MuiText from '../intl/MuiText';
import tradvo from '../../data/iconFiles/main-logo-no-text.svg';

const SellerNavGoToTradvo = ({ handleClose }) => {
  const { supplier, supplierLoading } = useAuth();
  const { attemptFormat } = useLocale();

  const { text, style } = makeProps({ supplier, supplierLoading, handleClose });
  const mainMarketURL = process.env.REACT_APP_FRONTEND_URL;
  const avatar = {
    namedLink: { href: mainMarketURL },
    image: {
      src: tradvo,
      alt: attemptFormat({ id: 'tradvo.noTextLogo', dm: 'Tradvo Logo' }),
    },
    // ...(supplier
    //   ? { variant: 'sellerLogo', planName: planName, badgeSize: '0.625rem' }
    //   : { variant: 'profilePicture', userRole: roles }),
  };

  return !supplier ? (
    <></>
  ) : (
    <Flex {...style.box}>
      <ImageBox {...{ ...style.avatar, ...avatar }} />

      <Flex {...style.stack}>
        <Link href={mainMarketURL} style={{ textDecoration: 'none' }}>
          <MuiText {...text.name}></MuiText>
        </Link>

        {/* <MuiNamedLink {...text.name} />
        <MuiNamedLink {...text.view} /> */}
      </Flex>
    </Flex>
  );

  function makeProps({ supplier, supplierLoading, handleClose }) {
    const { logoImage, name, planName } = supplier || {};

    // logoImage: imageMapper({
    //   src: el?.logo,
    //   altEn: `${el?.name} Business Logo`,
    //   altFr: `Logo commercial ${el?.name}`,
    //   croppedArea: el?.logo_cropped_area,
    // }),
    return {
      text: {
        name: {
          id: 'navbar.navItem.backtoMainSiteTitle',
          dm: 'View Store Page',
          href: mainMarketURL,
          to: mainMarketURL,
          onClick: handleClose,
          children: name,
          display: 'block',
          fontWeight: 'bold',
          fontSize: '1.125rem',
          height: '1.5rem',
          lines: 1,
          // sxText: {
          //   display: 'block',
          //   fontWeight: 'bold',
          //   fontSize: '1.125rem',
          //   height: '1.5rem',
          //   color: 'headingText.main',
          // },
        },
        link: {
          id: 'navbar.navItem.backtoMainSiteTitle',
          dm: 'View Store Page',
          href: mainMarketURL,
          to: mainMarketURL,
          children: name,
          lines: 1,
          // sxText: {
          //   display: 'block',
          //   fontWeight: 'bold',
          //   fontSize: '1.125rem',
          //   height: '1.5rem',
          //   color: 'headingText.main',
          // },
        },
        view: {
          id: 'navbar.navItem.backtoMainSite',
          dm: 'View Store Page',
          supplier,
          href: mainMarketURL,
          to: mainMarketURL,
          onClick: handleClose,
          hoverColor: 'primary.main',
          sxText: { color: 'headingText.main', lineHeight: '1.25rem' },
        },
      },
      style: {
        box: {
          sx: { m: '0.5rem 1rem', gap: '1rem', alignItems: 'center' },
        },
        image: {
          namedLink: { supplier, onClick: handleClose },
          loading: supplierLoading,
          image: logoImage,
          variant: 'sellerLogo',
          size: '2.25rem',
          planName,
          badgeSize: '0.55rem',
        },
        stack: {
          direction: 'column',
          href: mainMarketURL,
          to: mainMarketURL,
          sx: { alignItems: 'flex-start' },
        },
        avatar: { size: '2.25rem' },
      },
    };
  }
};

export default SellerNavGoToTradvo;

import React from 'react';
import useLocale from '../../hooks/useLocale';
import checkFunc from '../../utils/checkFunc';
import MuiText from '../intl/MuiText';
import Flex from './Flex';
import WrapOn from './WrapOn';

const LabelledInfo = ({
  value,
  valueProps,
  label,
  labelProps,
  rowProps,
  nonText,
  valueFirst,
  loading,
  allowEmpty,
}) => {
  const { attemptFormat } = useLocale();
  const { style } = makeProps();
  const formattedLabel = React.isValidElement(label) ? (
    label
  ) : (
    <MuiText {...style.label(labelProps)}>{attemptFormat(label)}</MuiText>
  );
  return !(value || loading || allowEmpty) ? (
    <></>
  ) : (
    <Flex {...style.infoRow(rowProps)}>
      {!valueFirst && formattedLabel}
      <WrapOn {...{ Wrap: MuiText, on: !nonText, ...style.value(valueProps) }}>
        {checkFunc(value, (props) => style.value({ ...valueProps, ...props }))}
      </WrapOn>
      {!!valueFirst && formattedLabel}
    </Flex>
  );

  function makeProps() {
    return {
      style: {
        infoRow: (props) => ({
          ...props,
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            px: '0.5rem',
            fontSize: '0.875rem',
            ...props?.sx,
          },
        }),
        label: (props) => ({
          ...props,
          sx: {
            color: 'bodyText.main',
            fontWeight: 'bold',
            fontSize: 'inherit',
            ...props?.sx,
          },
        }),
        value: (props) => ({
          ...props,
          sx: {
            color: 'headingText.main',
            fontWeight: 'bold',
            fontSize: 'inherit',
            ...props?.sx,
          },
        }),
      },
    };
  }
};

export default LabelledInfo;

import { useState, useCallback } from 'react';

const useFormState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(({ target }) => setValue(target.value), []);

  return [value, onChange];
};

export default useFormState;

import React from 'react';
import useAuth from '../../hooks/useAuth';
import Flex from '../common/Flex';
import ImageBox from '../common/ImageBox';
import Link from '@mui/material/Link';
import MuiText from '../intl/MuiText';
import useLocale from '../../hooks/useLocale';
import defaultSiteLinkIcon from '../../data/iconFiles/dataset_linked_black_24dp.svg';

const SellerStoreNavInfo = ({ handleClose }) => {
  const { supplier, supplierLoading } = useAuth();
  const { isArabic } = useLocale();

  const { text, style } = makeProps({ supplier, supplierLoading, handleClose });

  const storeSiteURL = supplier?.subdomain
    ? process.env.REACT_APP_FRONTEND_HTTP_URL +
      supplier.subdomain +
      '.' +
      process.env.REACT_APP_FRONTEND_HOSTNAME_URL
    : '';

  const storeSiteURLLang = isArabic
    ? storeSiteURL + '?Setlang=ar'
    : storeSiteURL;

  const avatar = {
    namedLink: {
      href: !supplier?.subdomain ? '/profile/myStoreSite' : storeSiteURLLang,
    },
    image: supplier?.logoImage ? supplier?.logoImage : defaultSiteLinkIcon,
    // ...(supplier
    //   ? { variant: 'sellerLogo', planName: planName, badgeSize: '0.625rem' }
    //   : { variant: 'profilePicture', userRole: roles }),
  };

  return !supplier ? (
    <></>
  ) : (
    <Flex {...style.box}>
      <ImageBox {...{ ...style.avatar, ...avatar }} />
      <Link
        href={!supplier?.subdomain ? '/profile/myStoreSite' : storeSiteURLLang}
        {...style.stack}
        style={{ textDecoration: 'none' }}
      >
        <MuiText {...text.name}></MuiText>
        <MuiText {...text.view} />
      </Link>
      {/* <Flex {...style.stack}>
        <MuiNamedLink {...text.name} />
        <MuiNamedLink {...text.view} />
      </Flex> */}
    </Flex>
  );

  function makeProps({ supplier, supplierLoading, handleClose }) {
    const { logoImage, name, planName } = supplier || {};
    return {
      text: {
        name: {
          supplier,
          onClick: handleClose,
          children: name,
          lines: 1,
          textDecoration: 'none',
          fontWeight: 'bold',
          fontSize: '1.125rem',
          height: '1.5rem',
          color: 'headingText.main',
          display: 'block',
          // sxText: {
          //   display: 'block',
          //   fontWeight: 'bold',
          //   fontSize: '1.125rem',
          //   height: '1.5rem',
          //   color: 'headingText.main',
          // },
        },
        view: {
          id: 'navbar.navItem.viewStoreSite',
          dm: 'View Store Page',
          supplier,
          onClick: handleClose,
          hoverColor: 'primary.main',
          // sxText: { color: 'headingText.main', lineHeight: '1.25rem' },
        },
      },
      style: {
        box: {
          sx: { m: '0.5rem 1rem', gap: '1rem', alignItems: 'center' },
        },
        image: {
          namedLink: { supplier, onClick: handleClose },
          loading: supplierLoading,
          image: logoImage,
          variant: 'sellerLogo',
          size: '2.25rem',
          planName,
          badgeSize: '0.55rem',
        },
        stack: {
          direction: 'column',
          sx: { alignItems: 'flex-start' },
          textDecoration: 'none',
        },
        avatar: { size: '2.25rem' },
      },
    };
  }
};

export default SellerStoreNavInfo;

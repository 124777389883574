import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { Field, getIn } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import FormattedText from '../intl/FormattedText';
import useLocale from '../../hooks/useLocale';

const OrganizationTypeSelector = ({
  name,
  required,
  noLabel,
  placeholderId,
  options,
  onlyForAccountPage,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { nameSort } = useLocale();
  const star = (
    <Text color="red" as="span">
      &nbsp;*
    </Text>
  );
  return (
    <Field name={name}>
      {({ form, field }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);

        return (
          <FormControl {...rest} isInvalid={error && touch}>
            {!noLabel && (
              <FormLabel htmlFor={name}>
                {onlyForAccountPage ? (
                  <Flex fontSize="0.875rem" lineHeight={'1.5'}>
                    <FormattedText
                      id="forms.organizationType"
                      defaultMessage="Organization Type"
                      fontSize="0.875rem"
                      lineHeight={'1.5'}
                    />
                    {required && star}
                  </Flex>
                ) : (
                  <Flex fontSize="0.875rem" lineHeight={'1.5'}>
                    <FormattedText
                      id="forms.organizationType"
                      defaultMessage="Organization Type"
                      fontSize="0.875rem"
                      lineHeight={'1.5'}
                    />
                    {required && star}
                  </Flex>
                )}
              </FormLabel>
            )}
            <Select
              id={name}
              placeholder={formatMessage({
                id: placeholderId || 'forms.organizationType.placeholder',
                defaultMessage: 'Select a Organization Type',
              })}
              fontSize="0.875rem"
              {...field}
            >
              {nameSort(options).map(({ name, id }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
              <option value="other">
                {formatMessage({
                  id: 'forms.organizationType.other',
                  defaultMessage: 'Other',
                })}
              </option>
            </Select>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default OrganizationTypeSelector;

import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { mergeProps } from '../../utils/mergeProps';
import Flex from '../common/Flex';
import MuiLoading from '../common/MuiLoading';
import MuiErrorText from './MuiErrorText';

const MuiCardSelector = ({
  // required
  name,
  options,
  card, // (data, selected) => <DataCard {...{data}} />
  // optional
  accessor = 'id',
  optionsLoading,
  loadingProps,
  cardContainerProps,
  cardItemProps,
}) => {
  const {
    values: { [name]: fieldValue },
    setFieldValue,
  } = useFormikContext();

  const handleChange = (newValue) =>
    setFieldValue(
      name,
      fieldValue.includes(newValue)
        ? [...fieldValue].filter((oldValue) => oldValue !== newValue).sort()
        : [...fieldValue, newValue].sort(),
    );

  const { style } = makeProps({ handleChange, accessor, optionsLoading });
  return (
    <Field {...{ name }}>
      {() => (
        <Flex direction="column">
          <MuiLoading {...style.loading(loadingProps)}>
            <Grid {...style.cardContainer(cardContainerProps)}>
              {options.map((option) => (
                <Grid {...style.cardItem(option, cardItemProps)}>
                  {card(option, fieldValue.includes(option[accessor]))}
                </Grid>
              ))}
            </Grid>
            <MuiErrorText {...{ name }} />
          </MuiLoading>
        </Flex>
      )}
    </Field>
  );

  function makeProps({ handleChange, accessor, optionsLoading }) {
    return {
      style: {
        loading: (props) =>
          mergeProps(props, {
            loading: optionsLoading,
            sx: { my: '4rem' },
          }),
        cardContainer: (props) =>
          mergeProps(props, {
            container: true,
            spacing: 2,
          }),
        cardItem: (option, props) =>
          mergeProps(props, {
            item: true,
            key: option[accessor],
            xs: 6,
            sm: 4,
            md: 3,
            sx: { width: 'fit-content', cursor: 'pointer' },
            onClick: () => handleChange(option[accessor]),
          }),
      },
    };
  }
};

export default MuiCardSelector;

import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { useIntl } from 'react-intl';
import FormattedText from '../intl/FormattedText';

const LanguageSelectSection = ({
  width,
  languageSelection,
  onChange,
  noLabel,
}) => {
  const intl = useIntl();

  return (
    <Field name="language">
      {({ field, form }) => (
        <FormControl isInvalid={form.errors.language && form.touched.language}>
          {!noLabel && (
            <FormLabel htmlFor="language">
              <FormattedText
                id="forms.language"
                defaultMessage="Language"
                fontSize="0.875rem"
                lineHeight={'1.5'}
              />
            </FormLabel>
          )}
          <Select
            id="language"
            size="sm"
            color="#333"
            width={width}
            height="2.5rem"
            borderRadius={'0.375rem'}
            // lineHeight="2.5"
            onChange={onChange}
            value={languageSelection}
          >
            <option value="en">
              {intl.formatMessage({
                id: 'forms.english',
              })}
            </option>
            {/* <option value="fr">
              {intl.formatMessage({
                id: 'forms.french',
              })}
            </option> */}
            <option value="ar">
              {intl.formatMessage({
                id: 'forms.arabic',
              })}
            </option>
          </Select>
          <FormErrorMessage>{form.errors.language}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default LanguageSelectSection;

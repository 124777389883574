import { Box, Stack, Text, Icon, Collapse } from '@chakra-ui/react';
import React, { Fragment, useState } from 'react';
import {
  BsCaretDownFill,
  BsCaretRightFill,
  AiFillTag,
  MdSettings,
  MdMonetizationOn,
  MdExplicit,
  MdDashboard,
  MdStore,
} from 'react-icons/all';
import { useIntl } from 'react-intl';
import NavItemToggle from './NavItemToggle';
import { useSelector } from 'react-redux';
import usePermissions from '../../hooks/usePermissions';
import useAuth from '../../hooks/useAuth';

const NewAdminMenuSection = ({ showAdmin }) => {
  const intl = useIntl();
  const { isAdmin } = useAuth();
  const { canView } = usePermissions();
  const [activePath, setActionPath] = useState(null);

  /* NavItem toggle */
  const [showContent, setShowContent] = useState(true);
  const [showMarketPlace, setShowMarketPlace] = useState(true);
  const [showEmergency, setShowEmergency] = useState(true);
  const [showBilling, setShowBilling] = useState(true);
  const [showSetting, setShowSetting] = useState(true);

  const handleToggleContent = () => setShowContent(!showContent);
  const handleToggleMarketPlace = () => setShowMarketPlace(!showMarketPlace);
  const handleToggleEmergency = () => setShowEmergency(!showEmergency);
  const handleToggleBilling = () => setShowBilling(!showBilling);
  const handleToggleSetting = () => setShowSetting(!showSetting);

  const handleTabIsOpen = (path) => {
    //Update current path
    setActionPath(path);
    if (content.map((e) => e.path).includes(path)) {
      setShowContent(true);
      setShowMarketPlace(false);
      setShowEmergency(false);
      setShowBilling(false);
      setShowSetting(false);
    } else if (marketplace.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(true);
      setShowEmergency(false);
      setShowBilling(false);
      setShowSetting(false);
    } else if (emergency.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(false);
      setShowEmergency(true);
      setShowBilling(false);
      setShowSetting(false);
    } else if (billing.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(false);
      setShowEmergency(false);
      setShowBilling(true);
      setShowSetting(false);
    } else if (setting.map((e) => e.path).includes(path)) {
      setShowContent(false);
      setShowMarketPlace(false);
      setShowEmergency(false);
      setShowBilling(false);
      setShowSetting(true);
    } else {
      setShowContent(true);
      setShowMarketPlace(true);
      setShowEmergency(true);
      setShowBilling(true);
      setShowSetting(true);
    }
  };
  const { pending: pendingPosts } = useSelector(
    ({ data }) => data.statistics.posts || {},
  );
  const { pending: pendingUsers } = useSelector(
    ({ data }) => data.statistics.users || {},
  );
  const { pending: pendingSuppliers } = useSelector(
    ({ data }) => data.statistics.suppliers || {},
  );
  const { pending: pendingProducts } = useSelector(
    ({ data }) => data.statistics.products || {},
  );
  //TODO: add statistics when have the backend API
  const { pending: pendingReviews } = useSelector(
    ({ data }) => data.statistics.reviews || {},
  );
  const { pending: pendingExperts } = useSelector(
    ({ data }) => data.statistics.experts || {},
  );
  const { pending: pendingRegulations } = useSelector(
    ({ data }) => data.statistics.regulations || {},
  );
  const { pending: pendingSafetylabs } = useSelector(
    ({ data }) => data.statistics.safetylabs || {},
  );
  const { pending: pendingTickets } = useSelector(
    ({ data }) => data.statistics.tickets || {},
  );
  const { pending: pendingMessages } = useSelector(
    ({ data }) => data.statistics.conversations || {},
  );
  //TODO: add statistics when have the backend API
  // const { pending: pendingAds } = useSelector(
  //   (state) => state.data.statistics.ads || {},
  // );
  // const { pending: pendingDisputes } = useSelector(
  //   (state) => state.data.statistics.disputes || {},
  // );
  // const { pending: pendingOrders } = useSelector(
  //   (state) => state.data.statistics.orders || {},
  // );
  // const { pending: pendingRFQs } = useSelector(
  //   (state) => state.data.statistics.rfqs || {},
  // );
  // const { pending: pendingCoupons } = useSelector(
  //   (state) => state.data.statistics.coupons || {},
  // );
  // const { pending: pendingInvoices } = useSelector(
  //   (state) => state.data.statistics.invoices || {},
  // );
  // const { pending: pendingTransactions } = useSelector(
  //   (state) => state.data.statistics.transactions || {},
  // );
  const dashboard = {
    path: '',
    textId: 'dashboard',
    viewPermitted: true,
    count: 0,
  };
  const content = [
    {
      path: 'users',
      textId: 'users',
      viewPermitted: canView('1') && isAdmin,
      count: pendingUsers,
    },
    {
      path: 'categories',
      textId: 'categories',
      viewPermitted: canView('3'),
      count: 0,
    },
    {
      path: 'item-types',
      textId: 'itemtypes',
      viewPermitted: canView('6'),
      count: 0,
    },
    {
      path: 'posts',
      textId: 'posts',
      viewPermitted: canView('0'),
      count: pendingPosts,
    },
    {
      path: 'feeds',
      textId: 'feeds',
      viewPermitted: canView('0'),
      count: 0,
    },
    {
      path: 'messages',
      textId: 'threads',
      viewPermitted: canView('0'),
      count: pendingMessages,
    },
    {
      path: 'ads',
      textId: 'ads',
      viewPermitted: canView('0'),
      count: 0, //pendingAds,
    },
    {
      path: 'invites',
      textId: 'invites',
      //   viewPermitted: canView('0'),
      viewPermitted: true,
      count: 0, //pendingAds,
    },
  ];
  const marketplace = [
    {
      path: 'products',
      textId: 'products',
      viewPermitted: canView('8'),
      count: pendingProducts,
    },
    {
      path: 'business-profiles',
      textId: 'suppliers',
      viewPermitted: canView('2'),
      count: pendingSuppliers,
    },
    {
      path: 'orders',
      textId: 'orders',
      viewPermitted: canView('2'),
      count: 0, //pendingOrders,
    },
    {
      path: 'reviews',
      textId: 'reviews',
      viewPermitted: canView('2'),
      count: pendingReviews, //pendingReviews,
    },
    {
      path: 'tickets',
      textId: 'tickets',
      viewPermitted: canView('2'),
      count: pendingTickets, //pendingReviews,
    },
    {
      path: 'disputes',
      textId: 'disputes',
      viewPermitted: canView('2'),
      count: 0, //pendingDisputes,
    },
    {
      path: 'quotations',
      textId: 'rfqs',
      viewPermitted: canView('2'),
      count: 0, //pendingRFQs,
    },
    {
      path: 'coupons',
      textId: 'coupons',
      viewPermitted: canView('2'),
      count: 0, //pendingCoupons,
    },
  ];
  const emergency = [
    {
      path: 'emergencies',
      textId: 'emergencies',
      viewPermitted: canView('17'),
      count: 0,
    },
    {
      path: 'experts',
      textId: 'experts',
      viewPermitted: canView('7'),
      count: pendingExperts,
    },
    {
      path: 'biosafety-labs',
      textId: 'biosafetylabs',
      viewPermitted: canView('5'),
      count: pendingSafetylabs,
    },
    {
      path: 'regulations',
      textId: 'regulations',
      viewPermitted: canView('4'),
      count: pendingRegulations,
    },
  ];
  const billing = [
    {
      path: 'payments',
      textId: 'payments',
      viewPermitted: canView('5'),
      count: 0, //pendingInvoices,
    },
  ];
  const setting = [
    {
      path: 'site-settings',
      textId: 'sitesettings',
      viewPermitted: canView('18') && isAdmin,
      count: 0,
    },
    {
      path: 'ai-settings',
      textId: 'aisettings',
      viewPermitted: canView('18') && isAdmin,
      count: 0,
    },
    {
      path: 'plugins',
      textId: 'plugin',
      viewPermitted: canView('13') && isAdmin,
      count: 0,
    },
  ];
  return (
    showAdmin && (
      <>
        <Box
          as="nav"
          role="navigation"
          bg="newBlack.900"
          color="#ccc"
          p={3}
          pl={2}
        >
          <Box fontSize="md" ml={0} mb={0}>
            <Stack isInline>
              <Icon mt={3} as={MdDashboard} w={6} h={6} />
              <Box>
                {dashboard.viewPermitted && (
                  <NavItemToggle
                    path={`/admin-panel/${dashboard.path}`}
                    tab={dashboard.path}
                    handleTabIsOpen={handleTabIsOpen}
                    isOpen={activePath === 'dashboard' ? true : false}
                    linkName={intl.formatMessage({
                      id: `navbar.navitem.admin.${dashboard.textId}`,
                    })}
                    count={dashboard.count === 0 ? '' : dashboard.count}
                    style={{
                      ml: '0px',
                      mt: '4px',
                      pl: '0px',
                      fontWeight: 'bold',
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Box>
          <Box fontSize="md" mt={0}>
            <Stack isInline width="100%">
              <Icon as={AiFillTag} w={6} h={6} />
              <Box flex={4} onClick={handleToggleContent}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.content',
                    defaultMessage: 'Content',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={1} onClick={handleToggleContent}>
                {showContent ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showContent}>
              {content.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/admin-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.admin.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6 }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline>
              <Icon as={MdStore} w={6} h={6} />
              <Box flex={4} onClick={handleToggleMarketPlace}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.marketplace',
                    defaultMessage: 'Marketplace',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={1} onClick={handleToggleMarketPlace}>
                {showMarketPlace ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showMarketPlace}>
              {marketplace.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/admin-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.admin.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6 }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline>
              <Icon as={MdExplicit} w={6} h={6} />
              <Box flex={4} onClick={handleToggleEmergency}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.emergency',
                    defaultMessage: 'Emergency',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={1} onClick={handleToggleEmergency}>
                {showEmergency ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showEmergency}>
              {emergency.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/admin-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.admin.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6 }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline>
              <Icon as={MdMonetizationOn} w={6} h={6} />
              <Box flex={4} onClick={handleToggleBilling}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.billing',
                    defaultMessage: 'Billing',
                  })}
                </Text>
              </Box>
              <Box flex={1} pb={0} onClick={handleToggleBilling}>
                {showBilling ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showBilling}>
              {billing.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/admin-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.admin.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6 }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
          <Box fontSize="md" mt={2}>
            <Stack isInline>
              <Icon as={MdSettings} w={6} h={6} />
              <Box flex={4} onClick={handleToggleSetting}>
                <Text fontWeight="bold">
                  {intl.formatMessage({
                    id: 'navbar.navitem.category.setting',
                    defaultMessage: 'Setting',
                  })}
                </Text>
              </Box>
              <Box flex={1} mt={2} onClick={handleToggleSetting}>
                {showSetting ? (
                  <Icon as={BsCaretDownFill} w={5} h={5} />
                ) : (
                  <Icon as={BsCaretRightFill} w={5} h={5} />
                )}
              </Box>
            </Stack>
            <Collapse in={showSetting}>
              {setting.map(({ path, textId, viewPermitted, count }) => (
                <Fragment key={textId}>
                  {viewPermitted && (
                    <NavItemToggle
                      path={`/admin-panel/${path}`}
                      tab={path}
                      handleTabIsOpen={handleTabIsOpen}
                      isOpen={path === activePath ? true : false}
                      linkName={intl.formatMessage({
                        id: `navbar.navitem.admin.${textId}`,
                      })}
                      count={count === 0 ? '' : count}
                      style={{ ml: 6 }}
                    />
                  )}
                </Fragment>
              ))}
            </Collapse>
          </Box>
        </Box>
      </>
    )
  );
};

export default NewAdminMenuSection;

export const snackbarMessages = ({ message, options = {} }) =>
  ({
    error: {
      type: 'error',
      title: { id: 'auth.toast.error', dm: 'Something went wrong' },
    },
    errorSubdomain: {
      type: 'error',
      title: { id: 'auth.toast.errorSubdomain', dm: 'Something went wrong' },
    },
    mustLogin: {
      type: 'error',
      title: {
        id: 'auth.mustLogin',
        dm: 'You must be logged in to view this page.',
      },
    },
    deleteAboutImageError: {
      type: 'error',
      title: {
        id: 'businessProfile.deleteAboutImage.error',
        dm: 'Failed to delete media image(s)',
      },
    },
    deleteCertificationError: {
      type: 'error',
      title: {
        id: 'businessProfile.deleteCertification.error',
        dm: 'Failed to delete certification(s)',
      },
    },
    editTempSupplierError: {
      type: 'error',
      title: {
        id: 'businessProfile.editProfile.error',
        dm: 'Failed to submit edits',
      },
    },
    editTempSupplierSuccess: {
      type: 'success',
      title: {
        id: 'businessProfile.editProfile.success',
        dm: 'Your edits are submitted and pending review',
      },
    },
    createReviewSuccess: {
      type: 'success',
      title: {
        id: 'forms.success.review.create',
        dm: 'Review has been successfully created!',
      },
    },
    createReviewError: {
      type: 'error',
      title: {
        id: 'forms.error.review.create.failure',
        dm: 'Create review is failure. Check if order is closed!',
      },
    },
    offerPostSuccess: {
      type: 'success',
      title: {
        id: 'posts.offerPost.success',
        dm: 'Your Offer is submitted and pending for review',
      },
    },
    demandPostSuccess: {
      type: 'success',
      title: {
        id: 'posts.demandPost.success',
        dm: 'Your Request is submitted and pending for review',
      },
    },
    quoteSuccess: {
      type: 'success',
      title: {
        id: 'quotations.submit.success',
        dm:
          "New thread has been created with your request and is pending the seller's response",
      },
    },
    twoFactorSuccess: {
      type: 'success',
      title: {
        id: 'user.myAccount.twoFactor.success',
        dm: 'Two Factor Authentication successfully updated',
      },
    },
    twoFactorFailed: {
      type: 'error',
      title: {
        id: 'user.myAccount.twoFactor.failed',
        dm: 'Two Factor Authentication failed to updated',
      },
    },
    downloadPDFSuccess: {
      type: 'success',
      title: {
        id: 'bills.billingAndPayment.downloadPDF.success',
        dm: 'Successfully downloaded invoice PDF',
      },
    },
    downloadPDFFailed: {
      type: 'error',
      title: {
        id: 'bills.billingAndPayment.downloadPDF.failed',
        dm: 'Failed to download invoice PDF',
      },
    },
    billingAddressUpdateSuccess: {
      type: 'success',
      title: {
        id: 'bills.billingAndPayment.billingAddress.success',
        dm: 'Billing Address update successfully',
      },
    },
    billingAddressUpdateFailed: {
      type: 'error',
      title: {
        id: 'bills.billingAndPayment.billingAddress.failed',
        dm: 'Billing Address update failed',
      },
    },
    emailNotificationsSuccess: {
      type: 'success',
      title: {
        id: 'user.myAccount.emailNotifications.success',
        dm: 'Email Notifications successfully updated',
      },
    },
    emailNotificationsFailed: {
      type: 'error',
      title: {
        id: 'user.myAccount.emailNotifications.failed',
        dm: 'Email Notifications failed to updated',
      },
    },
    paymentSuccess: {
      type: 'success',
      title: {
        id: 'snackbar.success.payment',
        dm: 'Successfully Processed Payment',
      },
    },
    errorFollow: {
      type: 'error',
      title: {
        id: `${options?.isFollowing ? 'un' : ''}follow.error`,
        dm: `Something went wrong trying to ${
          options?.isFollowing ? 'un' : ''
        }follow the supplier`,
      },
    },
    emailUnVerifiedToFollow: {
      type: 'warning',
      title: {
        id: `follow.unVerifiedEmail.error`,
        dm: `Please verify your account to start following sellers`,
      },
    },
    emailUnVerifiedToSave: {
      type: 'warning',
      title: {
        id: `user.myAccount.savedLists.unverifiedEmail.error`,
        dm: `Please verify your account to save items`,
      },
    },
    emailUnVerifiedToRequestRFQ: {
      type: 'warning',
      title: {
        id: `quotations.unverifiedEmail.error`,
        dm: `Please verify your account to start requesting quotes`,
      },
    },
    emailUnVerifiedToSendQuote: {
      type: 'warning',
      title: {
        id: `test`,
        dm: `Please verify your account to start adding quotes`,
      },
    },
    emailUnVerifiedToPost: {
      type: 'warning',
      title: {
        id: `market.offer.post.unverifiedEmail.error`,
        dm: `Please verify your account to start adding posts`,
      },
    },
    loginToFollow: {
      type: 'warning',
      title: {
        id: 'follow.login.required',
        dm: 'Must be logged in as a Buyer to follow Suppliers',
      },
    },
    loginToLike: {
      type: 'warning',
      title: {
        id: 'snackbar.warning.loginToLike',
        dm: 'Please log in or sign up to like posts and comments',
      },
    },
    loginToPost: {
      type: 'warning',
      title: {
        id: 'market.offer.post.unLoggedIn',
        dm: 'You need to login',
      },
    },
    logInToRFQ: {
      type: 'warning',
      title: {
        id: 'quotations.logIn',
        dm: 'You must be logged in as a Buyer to request quotes',
      },
    },
    getRFQbuyerRole: {
      type: 'warning',
      title: {
        id: 'quotations.logIn',
        dm: 'You must be logged in as a Buyer to request quotes',
      },
    },
    sellerToSendQuote: {
      type: 'warning',
      title: {
        id: 'snackbar.warning.sellerToSendQuote',
        dm: 'You must be logged in as a Seller to add quotes',
      },
    },
    copySuccess: {
      type: 'success',
      title: { id: 'forms.copy.success', dm: 'Text Copied To Clipboard' },
    },
    copyError: {
      type: 'error',
      title: { id: 'forms.copy.error', dm: 'Text Not Copied' },
    },
    iframeCopySuccess: {
      type: 'success',
      title: {
        id: 'forms.copy.iframe.success',
        dm: 'iframe Copied To Clipboard',
      },
    },
    iframeCopyError: {
      type: 'error',
      title: { id: 'forms.copy.iframe.error', dm: 'iframe Not Copied' },
    },
    linkCopySuccess: {
      type: 'success',
      title: {
        id: 'snackbar.success.linkCopy',
        dm: 'Link Copied To Clipboard',
      },
    },
    linkCopyError: {
      type: 'error',
      title: { id: 'snackbar.warning.linkCopy', dm: 'Link Not Copied' },
    },
    postLinkCopySuccess: {
      type: 'success',
      title: {
        id: 'snackbar.success.postLinkCopy',
        dm: 'Post Link Copied To Clipboard',
      },
    },
    postLinkCopyError: {
      type: 'error',
      title: {
        id: 'snackbar.warning.postLinkCopy',
        dm: 'Post Link Not Copied',
      },
    },
    translateFail: {
      type: 'error',
      title: { id: 'forms.translate.error', dm: 'Could not translate.' },
      description: {
        id: 'table.update.failure.tryAgain',
        dm: 'Please try again later',
      },
    },
    errorSave: {
      type: 'error',
      title: {
        id: `${options?.isSaved ? 'un' : ''}save.error`,
        dm: `Something went wrong trying to ${
          options?.isSaved ? 'un' : ''
        }save the listing`,
      },
    },
    loginToSave: {
      type: 'warning',
      title: {
        id: 'save.login.required',
        dm: 'Must be logged in as a Buyer to save listings',
      },
    },
    paymentMethodAdded: {
      type: 'success',
      title: {
        id: 'snackbar.success.addPaymentMethod',
        dm: 'Payment Method Successfully Added',
      },
    },
    stripeCreatePaymentMethodError: {
      type: 'error',
      title: {
        id: 'snackbar.warning.createPaymentError',
        dm: 'Something went wrong creating Payment Method',
      },
    },
    paymentMethodAddError: {
      type: 'error',
      title: {
        id: 'snackbar.warning.addPaymentMethod',
        dm: 'There was an issue adding the Payment Method',
      },
    },
    newProduct: {
      type: 'success',
      title: {
        id: 'snackbar.success.newProduct.title',
        dm: 'Listing Successfully Added',
      },
      description: {
        id: 'snackbar.success.newProduct.description',
        dm: 'Pending Admin Approval',
      },
    },
    editProduct: {
      type: 'success',
      title: {
        id: 'snackbar.success.editProduct.title',
        dm: 'Listing Successfully Edited',
      },
      description: {
        id: 'snackbar.success.editProduct.description',
        dm: 'Pending Admin Approval',
      },
    },
    deleteProductImageError: {
      type: 'error',
      title: {
        id: 'snackbar.warning.deleteProductImage',
        dm: 'There was an issue deleting listing image(s)',
      },
    },
    deleteProductFileError: {
      type: 'error',
      title: {
        id: 'snackbar.warning.deleteProductFile',
        dm: 'There was an issue deleting listing file(s)',
      },
    },
    noSellerStripe: {
      type: 'error',
      title: {
        id: 'snackbar.warning.noSellerStripe',
        dm: 'No Seller Stripe Account',
      },
    },
    deleteFeedSuccess: {
      type: 'success',
      title: {
        id: 'post.deleteSuccess',
        dm: 'Delete post successfully',
      },
    },
    resendEmailSuccess: {
      type: 'success',
      title: {
        id: 'email.sendSuccess',
        dm: 'Email verification link sent successfully',
      },
    },
    emailLinkExpired: {
      type: 'warning',
      title: { id: 'email.linkExpired', dm: 'Email verification link expired' },
    },
    emailVerifiedSuccess: {
      type: 'success',
      title: {
        id: 'email.verifySuccess',
        dm: 'Email address verification successfully',
      },
    },
    emailAlreadyVerified: {
      type: 'warning',
      title: { id: 'email.alreadyVerified', dm: 'Email already verified' },
    },
    emailTimeLimit: {
      type: 'warning',
      title: {
        id: 'email.timiLimit.title',
        dm: 'Resend Email Verification Link Time Limit',
      },
      description: {
        id: 'email.timiLimit.text',
        dm:
          'Please wait 10 minutes after your last email verification re-send request',
      },
    },
    subscriptionSuccess: {
      type: 'success',
      title: {
        id: 'subscription.updateSuccess',
        dm: 'Categories of interests updated successfully',
      },
    },
    profileUpdateSuccess: {
      type: 'success',
      title: {
        id: 'userProfile.updateInfo.toast',
        dm: 'Profile info updated',
      },
    },
    storeLinkUpdateSuccess: {
      type: 'success',
      title: {
        id: 'userStore.updateSubdomainInfo.toast',
        dm: 'Store link updated',
      },
    },
    profileUpdateFailed: {
      type: 'error',
      title: {
        id: 'table.update.failure',
        dm: 'Update failed',
      },
      description: {
        id: 'table.update.failure.tryAgain',
        dm: 'Please try again later',
      },
    },
    loginSuccess: {
      type: 'success',
      title: { id: 'auth.login.success', dm: 'Logged in successfully.' },
    },
    loginError: {
      type: 'error',
      title: { id: 'auth.login.failure', dm: 'Could not log in.' },
    },
    registerError: {
      type: 'error',
      title: {
        id: 'auth.register.error',
        dm: 'Unable to create account. Try again later.',
      },
    },
    recaptchaFailed: {
      type: 'error',
      title: {
        id: 'snackbar.warning.recaptcha',
        dm: 'ReCaptcha verification failed. Try again later.',
      },
    },
    authExpired: {
      type: 'error',
      title: {
        id: 'snackbar.warning.authExpired',
        dm: 'Your session has expired, please login again.',
      },
    },
    stripeConnectError: {
      type: 'error',
      title: {
        id: 'snackbar.warning.stripeConnect',
        dm:
          'Your Stripe account was not successfully connected, please try again',
      },
    },
    excludeRole2: {
      type: 'error',
      title: {
        id: 'snackbar.error.pageExcludes.2',
        dm: 'Cannot view this page as a Seller',
      },
    },
    excludeRole3: {
      type: 'error',
      title: {
        id: 'snackbar.error.pageExcludes.3',
        dm: 'Cannot view this page as a Buyer',
      },
    },
    requiredRole2: {
      type: 'error',
      title: {
        id: 'snackbar.error.pageRequires.2',
        dm: 'Must be a Seller to view this page',
      },
    },
    requiredRole3: {
      type: 'error',
      title: {
        id: 'snackbar.error.pageRequires.3',
        dm: 'Must be a Buyer to view this page',
      },
    },
    notViewPermitted: {
      type: 'error',
      title: {
        id: 'auth.notViewPermitted',
        dm: 'You do not have permission to view that page.',
      },
    },
    locationUpdateError: {
      type: 'error',
      title: {
        id: 'snackbar.error.userUpdate.location',
        dm: 'There was a problem updating your location',
      },
    },
    upgradeToDownload: {
      type: 'warning',
      title: {
        id: 'snackbar.warning.followerDownloadUpgrade',
        dm: 'You must upgrade to Premium to download followers',
      },
    },
    upgradeToStartStoreSite: {
      type: 'error',
      title: {
        id: 'snackbar.warning.StartSiteSubdomain',
        dm: 'You must upgrade to Start Your Store Site',
      },
    },
    upgradeToDownloadOrders: {
      type: 'warning',
      title: {
        id: 'snackbar.warning.ordersDownloadUpgrade',
        dm: 'You must upgrade to Premium to download orders',
      },
    },
    followerDownloadError: {
      type: 'error',
      title: {
        id: 'snackbar.error.followerDownload',
        dm: 'There was a problem downloading your followers file',
      },
    },
    orderDownloadError: {
      type: 'error',
      title: {
        id: 'snackbar.error.orderDownload',
        dm: 'There was a problem downloading your orders file',
      },
    },
    inviteSuccess: {
      type: 'success',
      title: { id: 'invitations.snackbar.send', dm: 'Emails sent' },
    },
    inviteSuccessTrack: {
      type: 'success',
      title: {
        id: 'invitations.snackbar.sendTrack',
        dm: 'Emails sent, track all invites on the network page',
      },
    },
    invalidUrl: {
      type: 'error',
      title: { id: 'snackbar.error.invalidUrl', dm: 'Invalid URL' },
    },
  }[message]);

// util for creating fontSizes object for the theme. Used in the accessibility
// font resizing component.

export const fontSizeMapper = (rem) => {
  return {
    xs: rem - 0.25 + 'rem',
    sm: rem - 0.125 + 'rem',
    md: rem + 'rem',
    lg: rem + 0.125 + 'rem',
    xl: rem + 0.25 + 'rem',
    '2xl': rem + 0.5 + 'rem',
    '3xl': rem + 0.875 + 'rem',
    '4xl': rem + 1.25 + 'rem',
    '5xl': rem + 2 + 'rem',
    '6xl': rem + 3 + 'rem',
  };
};

import {
  Checkbox,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Stack,
  Flex,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useToaster from '../../hooks/useToaster';
import { login } from '../../reducers/authReducer';
import client from '../../utils/client';
import FormField from '../forms/FormField';
import SocialAuthButtons from '../header/SocialAuthButtons';
import FormattedButton from '../intl/FormattedButton';
import FormattedText from '../intl/FormattedText';
import OptForm from './OtpForm';

function LoginFormOld({ onClose, setIsForgotPasswordOpen }) {
  const history = useHistory();
  const intl = useIntl();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
  const [hasOtpCode, setHasOtpCode] = useState(false);
  const [user, setUser] = useState({});

  const msg = (id) => {
    return intl.formatMessage({ id: 'forms.error.' + id });
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(msg('email.valid'))
      .max(255, msg('max'))
      .required(msg('email')),
    password: Yup.string().required(msg('password')),
  });

  const submitTwoFactor = async (code) => {
    try {
      const resp = await client('/verify-two-factor-code', {
        body: { email: user.email, password: user.password, code: code },
      });

      const { data } = resp;

      let user_ = data.user;
      let accessToken_ = data['access_token'];

      if (rememberMe) {
        localStorage.setItem('accessToken', accessToken_);
      } else {
        sessionStorage.setItem('accessToken', accessToken_);
      }
      dispatch(login(user_));
      history.push('/dashboard');
      toaster('loginSuccess');
    } catch (err) {
      return err.error;
    }
  };

  const resendTwoFactor = async () => {
    try {
      const resp = await client('/send-two-factor-code', {
        body: { email: user.email, password: user.password },
      });

      const { data } = resp;
      let user_ = data.user;
      dispatch(login(user_));
    } catch (err) {
      toaster('wait');
      return err.error;
    }
  };

  return (
    <React.Fragment>
      {isOtpModal ? (
        <OptForm
          handleSubmitTwoFactor={submitTwoFactor}
          handleResendTwoFactor={resendTwoFactor}
        />
      ) : null}
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={async (values, actions) => {
          const { email, password } = values;
          setUser({ email, password });
          try {
            const resp = await client('/login', {
              body: { email, password },
            });
            const { status, message, data } = resp;

            if (
              status === 200 &&
              message === 'Two Factor Auth Code have sent to user' &&
              data.token_type === 'code'
            ) {
              setHasOtpCode(true);
            }

            if (status === 207) {
              if (message === 'user not verified!') {
                toaster('verifyEmail');
              }

              if (message === 'pending admin approval!') {
                toaster('adminApproval');
              }

              if (message === 'account suspended!') toaster('accountSuspended');

              actions.resetForm({
                values: { email, password: '' },
                touched: { email: true, password: false },
              });
              return;
            }

            const accessToken = data['access_token'];
            const user = data.user;

            if (rememberMe) {
              localStorage.setItem('accessToken', accessToken);
            } else {
              sessionStorage.setItem('accessToken', accessToken);
            }
            dispatch(login(user));
            //history.push('/dashboard');
            toaster('loginSuccess');
          } catch (err) {
            if (err.status === 401 && err.message === 'Unauthorized') {
              setIsOtpModal(false);
              actions.resetForm({
                values: { email, password: '' },
                errors: {
                  password: intl.formatMessage({
                    id: 'forms.auth.wrongPassword',
                    defaultMessage:
                      'Either the password or the username is incorrect',
                  }),
                },
                touched: { email: true, password: true },
              });
            } else toaster('error');
          }
          if (onClose) onClose();
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors }) => (
          <Form style={{ display: isOtpModal ? 'none' : 'block' }}>
            <ModalHeader
            // width="fit-content"
            >
              <FormattedText
                id="auth.login"
                defaultMessage="Login"
                fontSize="3xl"
                as="h2"
                mt={2}
                // width="fit-content"
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              pb={0}
              //  width="fit-content"
            >
              <Flex flexDirection={{ base: 'column', md: 'row' }}>
                {/* Input Fields */}
                <Stack spacing={2} shouldWrapChildren={true} width="100%">
                  <FormField name="email" required />
                  <FormField name="password" type="password" required />
                  {errors.password}
                  <Checkbox
                    isChecked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  >
                    {intl.formatMessage({
                      id: 'forms.rememberMe',
                      defaultMessage: 'Remember Me',
                    })}
                  </Checkbox>
                  <ModalFooter
                    justifyContent="space-between"
                    alignItems="center"
                    minWidth="18rem"
                    px={0}
                  >
                    <FormattedButton
                      id="auth.password.forgot"
                      variant="link"
                      fontWeight="400"
                      color="gray.800"
                      mr={4}
                      onClick={() => setIsForgotPasswordOpen(true)}
                    />
                    <FormattedButton
                      id="auth.login"
                      colorScheme="blue"
                      type="submit"
                      isLoading={isSubmitting}
                      onClick={hasOtpCode ? setIsOtpModal(true) : null}
                    />
                  </ModalFooter>
                </Stack>
                <Flex
                  paddingLeft={{ base: '0', md: '1rem' }}
                  marginLeft={{ base: '0', md: '1rem' }}
                  borderLeft={{ base: '0', md: '1px solid darkgrey' }}
                  paddingTop={{ base: '1rem', md: '0' }}
                  borderTop={{ base: '1px solid darkgrey', md: '0' }}
                  marginBottom={{ base: '0', md: '3rem' }}
                  justifyContent="center"
                >
                  <SocialAuthButtons
                    width={{ base: '100%', xs: '75%', md: '100%' }}
                  />
                </Flex>
              </Flex>
            </ModalBody>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default LoginFormOld;

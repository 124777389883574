import React from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';
import MuiButton from '../intl/MuiButton';

const SignupButton = ({ forceShow, ...props }) => {
  const { md } = useBreakpoint();
  const { button } = makeProps();
  const excludedPages = [
    '/buyer-signup',
    '/seller-signup',
    '/account-type',
    !md && '/login',
  ].filter((el) => el);
  const showButton =
    !excludedPages.includes(window.location.pathname) || forceShow;

  return <>{showButton && <MuiButton {...button.signup(props)} />}</>;

  function makeProps() {
    return {
      button: {
        signup: (props) => ({
          to: '/account-type',
          id: 'appbar.signup',
          dm: 'SIGN UP',
          fontSize: '0.875rem',
          color: 'success',
          textColor: 'card.main',
          iconColor: 'card.main',
          GA: { action: 'Sign Up Button' },
          ...props,
          sx: { ...props?.sx },
          sxText: { textAlign: 'center' },
        }),
      },
    };
  }
};

export default SignupButton;

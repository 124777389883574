import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMuiModalControl from '../../hooks/useMuiModalControl';
import useSnackbar from '../../hooks/useSnackbar';
import MuiButton from '../intl/MuiButton';
import MuiModal from './MuiModal';

const RestrictedButton = ({
  restrictions = [],
  modalProps: givenModalProps,
  modalControl: givenModalControl,
  overRideModalProps,
  onClick,
  buttonRef,
  onClose,
  ...rest
}) => {
  const [modalProps, setModalProps] = useState(givenModalProps);
  const { snackbar } = useSnackbar();
  const defaultModalControl = useMuiModalControl();
  const modalControl = givenModalControl || defaultModalControl;
  const { open } = modalControl;
  const { style } = makeProps({ modalControl });
  const { push } = useHistory();
  const [hasOpened, setHasOpened] = useState(false);

  useEffect(() => {
    if (!open) return;
    setHasOpened(true);
  }, [open]);

  useEffect(() => {
    if (open || !hasOpened) return;
    if (onClose) onClose();
  }, [open, hasOpened, onClose]);

  return (
    <>
      <MuiButton
        {...{
          onClick: (...clickArgs) => {
            const canClick = restrictions.reduce(
              (passed, { condition, message, modal, redirect, action }) => {
                if (!passed) return false;
                if (condition) return true;
                if (redirect) push(redirect);
                else if (message) snackbar({ message });
                else if (action) action();
                else {
                  if (modal) setModalProps(modal);
                  modalControl.handleOpen();
                }
                return false;
              },
              true,
            );
            if (canClick && onClick) onClick(...clickArgs);
          },
          ...(buttonRef && { ref: buttonRef }),
          ...rest,
        }}
      />
      <MuiModal {...style.modal(overRideModalProps ?? modalProps)} />
    </>
  );

  function makeProps({ modalControl }) {
    return {
      style: { modal: (props) => ({ modalControl, ...props }) },
    };
  }
};

export default RestrictedButton;

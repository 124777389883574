import React, { useMemo, useState } from 'react';
import MuiText from '../intl/MuiText';
import WrapOn from './WrapOn';
import Flex from './Flex';
import MuiNamedLink from './MuiNamedLink';
import useLocale from '../../hooks/useLocale';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { gradients } from '../../theme/materialTheme';
import useParseBreakpointObject from '../../hooks/useParseBreakpointObject';
import { SUBSCRIPTION_PLANS } from '../../data/constants';
import { mergeProps } from '../../utils/mergeProps';
import StarIcon from '@mui/icons-material/Star';

const ImageBox = ({
  image,
  src: givenSrc,
  alt: givenAlt,
  size,
  sxBox,
  boxProps,
  styleImage,
  imageProps,
  noImage,
  sxNoImageBox,
  noImageBoxProps,
  sxNoImage,
  noImageProps,
  loading,
  namedLink,
  hasDelete,
  deleteProps,
  hasPopup,
  aspectRatio,
  height,
  width,
  variant,
  blurred,
  blurProps,
  userRole,
  planName = 'basic',
  starBadgeProps,
  badgeSize,
}) => {
  const parseBreakpoint = useParseBreakpointObject();
  const { attemptFormat, isEnglish, isFrench } = useLocale();
  const [popupOpen, setPopupOpen] = useState(false);
  const src = givenSrc || image?.src;
  const alt =
    givenAlt ||
    image?.alt ||
    image?.[isEnglish ? 'altEn' : isFrench ? 'altFr' : 'altAr'] ||
    '';

  const sellerPlan = useMemo(
    () => SUBSCRIPTION_PLANS.find(({ id }) => id === planName),
    [planName],
  );

  const variantProps = variantStyles({
    variant,
    loading,
    userRole,
    sellerPlan,
  });
  const { style } = makeProps({
    size,
    loading,
    namedLink,
    hasDelete,
    image,
    aspectRatio,
    height,
    width,
    sxBox,
    boxProps,
    variantProps,
    parseBreakpoint,
    badgeSize,
  });

  return (
    <WrapOn {...{ on: namedLink && src, ...style.namedLink }}>
      <WrapOn
        {...{
          on: !!sellerPlan?.hasStar && !loading && variant === 'sellerLogo',
          ...style.starBadge(starBadgeProps),
        }}
      >
        <Flex
          {...style.box({ ...boxProps, sx: { ...boxProps?.sx, ...sxBox } })}
        >
          {blurred && <Flex {...style.blurBox(blurProps)} />}
          {popupOpen && (
            <Lightbox
              {...{ mainSrc: src, onCloseRequest: () => setPopupOpen(false) }}
            />
          )}
          {!!hasDelete && (
            <Flex {...style.deleteButtonBox(deleteProps?.boxProps)}>
              <IconButton {...style.deleteButton(deleteProps?.iconButtonProps)}>
                <ClearIcon {...style.deleteIcon(deleteProps?.iconProps)} />
              </IconButton>
            </Flex>
          )}
          {src ? (
            <img
              {...{
                alt: attemptFormat(alt),
                src,
                ...(!!hasPopup && { onClick: () => setPopupOpen(true) }),
                ...style.image({
                  ...imageProps,
                  style: { ...imageProps?.style, ...styleImage },
                }),
              }}
            />
          ) : (
            <Flex
              {...style.noImageBox({
                ...noImageBoxProps,
                sx: { ...noImageBoxProps?.sx, ...sxNoImageBox },
              })}
            >
              {noImage?.id ? (
                <MuiText
                  {...{
                    ...style.noImage({
                      ...noImageProps,
                      sx: { ...noImageProps?.sx, ...sxNoImage },
                    }),
                    ...noImage,
                  }}
                />
              ) : (
                noImage || <></>
              )}
            </Flex>
          )}
        </Flex>
      </WrapOn>
    </WrapOn>
  );
  function makeProps({
    size: givenSize,
    loading,
    namedLink,
    hasDelete,
    image,
    aspectRatio: givenAspectRatio,
    height,
    width,
    sxBox,
    boxProps,
    variantProps,
    parseBreakpoint,
    badgeSize,
  }) {
    const size = parseBreakpoint(givenSize ?? variantProps?.size);
    const aspectRatio = givenAspectRatio ?? variantProps?.aspectRatio;

    const { croppedArea } = image || {};
    const boxWidth =
      width || size || boxProps?.sx?.width || sxBox?.width || '10rem';
    const boxHeight =
      height ||
      (width && aspectRatio && `calc(${boxWidth} / ${aspectRatio})`) ||
      (size && aspectRatio && `calc(${size} / ${aspectRatio})`) ||
      boxProps?.sx?.height ||
      sxBox?.height ||
      size ||
      '10rem';

    const cropWidth = croppedArea?.width
      ? `calc(100 * ${boxWidth} / ${croppedArea?.width})`
      : 'auto';
    const cropHeight = croppedArea?.height
      ? `calc(100 * ${
          aspectRatio
            ? '100%'
            : height || boxProps?.sx?.height || sxBox?.height || size || '10rem'
        } / ${croppedArea?.height})`
      : 'auto';
    const shiftX =
      !Number.isNaN(Number(croppedArea?.x)) && croppedArea?.width !== 100
        ? `${
            Math.round(
              (100 * (100 * croppedArea?.x)) / (100 - croppedArea?.width),
            ) / 100
          }%`
        : '0%';

    return {
      style: {
        box: (props) =>
          mergeProps(props, {
            ...variantProps?.boxProps,
            loading,
            sx: {
              flexShrink: '0',
              overflow: 'hidden',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0',
              ...(hasDelete && { position: 'relative' }),
              width: `calc(${boxWidth} + 2 * ${
                props?.sx?.border?.split(' ')?.[0] ||
                props?.sx?.borderThickness ||
                variantProps?.boxProps?.sx?.border?.split(' ')?.[0] ||
                variantProps?.boxProps?.sx?.borderThickness ||
                '0px'
              })`,
              ...(aspectRatio
                ? { height: 0, paddingTop: `calc(${boxHeight})` }
                : {
                    height: `calc(${boxHeight} + 2 * ${
                      props?.sx?.border?.split(' ')?.[0] ||
                      props?.sx?.borderThickness ||
                      '0px'
                    })`,
                  }),
              ...variantProps?.boxProps?.sx,
            },
          }),
        blurBox: (props) =>
          mergeProps(props, {
            sx: {
              position: 'absolute',
              inset: '-1px',
              zIndex: '1',
              backgroundColor: 'rgba(100, 100, 100, 0.2)',
              backdropFilter: 'blur(8px)',
            },
          }),
        image: (props) =>
          mergeProps(props, {
            ...variantProps?.imageProps,
            style: {
              objectFit: 'cover',
              width: 'auto',
              height: '100%',
              minWidth: '100%',
              minHeight: '100%',
              ...(image && {
                position: 'absolute',
                ...(croppedArea
                  ? {
                      objectPosition: `${shiftX}`,
                      width: cropWidth,
                      height: cropHeight,
                      top: 0,
                      left: 0,
                      transform: `translateY(${-croppedArea.y}%)`,
                    }
                  : {
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }),
              }),
              ...variantProps?.imageProps?.style,
            },
          }),
        noImageBox: (props) =>
          mergeProps(props, {
            ...variantProps?.noImageBoxProps,
            sx: {
              p: '1rem',
              width: '100%',
              height: '100%',
              backgroundColor: 'background.main',
              justifyContent: 'center',
              alignItems: 'center',
              ...(aspectRatio && { position: 'absolute', top: 0, left: 0 }),
              ...variantProps?.noImageBoxProps?.sx,
            },
          }),
        noImage: (props) =>
          mergeProps(props, {
            ...variantProps?.noImageProps,
            sx: {
              display: 'block',
              fontSize: '1.25rem',
              color: 'bodyText.main',
              textAlign: 'center',
              ...variantProps?.noImageProps?.sx,
            },
          }),
        namedLink: {
          Wrap: MuiNamedLink,
          nonText: true,
          ...namedLink,
          ...variantProps?.namedLink,
        },
        deleteButtonBox: (props) =>
          mergeProps(props, {
            ...variantProps?.deleteButtonBox,
            sx: {
              backgroundColor: 'error.main',
              border: '2px solid',
              borderColor: 'card.main',
              borderRadius: '50%',
              position: 'absolute',
              top: '0.5rem',
              right: '0.5rem',
              ...variantProps?.deleteButtonBox?.sx,
            },
          }),
        deleteButton: (props) =>
          mergeProps(props, {
            ...variantProps?.deleteButton,
            sx: { p: '0', ...variantProps?.deleteButton?.sx },
          }),
        deleteIcon: (props) =>
          mergeProps(props, {
            ...variantProps?.deleteIcon,
            sx: { color: 'card.main', ...variantProps?.deleteIcon?.sx },
          }),
        starBadge: (props) =>
          mergeProps(props, {
            Wrap: Badge,
            badgeContent: (
              <StarIcon
                {...{ sx: { color: 'card.main', fontSize: 'inherit' } }}
              />
            ),
            color: 'secondary',
            sx: {
              '& .MuiBadge-badge': {
                p: '0.125rem',
                top: '5px',
                right: '5px',
                fontSize: badgeSize || '1rem',
                height: 'unset',
                minWidth: 'unset',
                borderRadius: '10rem',
              },
            },
          }),
      },
    };
  }
  function variantStyles({ variant, loading, userRole, sellerPlan }) {
    const variants = {
      buyerBanner: {
        size: '100%',
        aspectRatio: 3 / 1,
        noImageBoxProps: { sx: { backgroundColor: 'primary.main' } },
        boxProps: {
          sx: { width: '100%', overflow: 'hidden', position: 'relative' },
        },
      },
      sellerBanner: {
        size: '100%',
        aspectRatio: 3 / 1,
        noImageBoxProps: { sx: { background: gradients.default } },
        boxProps: {
          sx: { width: '100%', overflow: 'hidden', position: 'relative' },
        },
      },
      sellerLogo: {
        aspectRatio: 1 / 1,
        boxProps: {
          sx: {
            borderRadius: '10px',
            flexShrink: '0',
            flexGrow: '0',
            border: '2px solid',
            borderColor: 'iconsText.main',
            backgroundColor: 'card.main',
            ...(sellerPlan?.hasGold && { borderColor: 'secondary.main' }),
          },
        },
      },
      profilePicture: {
        aspectRatio: 1 / 1,
        boxProps: {
          sx: {
            borderRadius: '10px',
            flexShrink: '0',
            flexGrow: '0',
            border: '2px solid',
            borderColor: 'iconsText.main',
            backgroundColor: 'card.main',
            ...(['2', '3'].includes(userRole) &&
              !loading && {
                borderColor:
                  userRole === '2' ? 'secondary.main' : 'primary.main',
              }),
          },
        },
      },
    };
    return variants[variant];
  }
};

export default ImageBox;

import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Flex from './Flex';
import useBreakpoint from '../../hooks/useBreakpoint';

const MuiContainer = forwardRef(
  (
    { children, direction = 'row', sx, sxInner, px = '1.5rem', ...rest },
    ref,
  ) => {
    const {
      page: { maxWidth },
    } = useTheme();
    const { md } = useBreakpoint();

    const style = {
      outer: {
        direction: 'column',
        sx: {
          ...(md
            ? { px: 0 }
            : { pl: px, pr: `calc(${px} - calc(100vw - 100%))` }),
          maxWidth: '100%',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          ...sx,
        },
        ...rest,
      },
      inner: {
        direction,
        sx: { maxWidth, width: '100%', height: '100%', ...sxInner },
      },
    };

    return (
      <Stack {...style.outer} {...(ref && { ref })}>
        <Flex {...style.inner}>{children}</Flex>
      </Stack>
    );
  },
);

export default MuiContainer;

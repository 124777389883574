import React from 'react';
import useAuth from '../../hooks/useAuth';
import useLocale from '../../hooks/useLocale';
import Flex from '../common/Flex';
import ImageBox from '../common/ImageBox';
import MuiNamedLink from '../common/MuiNamedLink';
import tradvo from '../../data/iconFiles/ballot_black_24dp.svg';

const SellerNavInfo = ({ handleClose }) => {
  const { supplier, supplierLoading } = useAuth();
  const { attemptFormat } = useLocale();

  const { text, style } = makeProps({ supplier, supplierLoading, handleClose });
  return !supplier ? (
    <></>
  ) : (
    <Flex {...style.box}>
      <ImageBox {...style.image} />
      <Flex {...style.stack}>
        <MuiNamedLink {...text.name} />
        <MuiNamedLink {...text.view} />
      </Flex>
    </Flex>
  );

  function makeProps({ supplier, supplierLoading, handleClose }) {
    const { name, planName } = supplier || {};
    const logoImage = {
      src: tradvo,
      alt: attemptFormat({ id: 'tradvo.noTextLogo', dm: 'Tradvo Logo' }),
    };
    // logoImage: imageMapper({
    //   src: el?.logo,
    //   altEn: `${el?.name} Business Logo`,
    //   altFr: `Logo commercial ${el?.name}`,
    //   croppedArea: el?.logo_cropped_area,
    // }),
    return {
      text: {
        name: {
          id: 'navbar.navItem.viewBusinessContent',
          dm: 'View Store Page',
          supplier,
          onClick: handleClose,
          children: name,
          lines: 1,
          sxText: {
            display: 'block',
            fontWeight: 'bold',
            fontSize: '1.125rem',
            height: '1.5rem',
            color: 'headingText.main',
          },
        },
        view: {
          id: 'navbar.navItem.viewBusiness',
          dm: 'View Store Page',
          supplier,
          onClick: handleClose,
          hoverColor: 'primary.main',
          sxText: { color: 'headingText.main', lineHeight: '1.25rem' },
        },
      },
      style: {
        box: {
          sx: { m: '0.5rem 1rem', gap: '1rem', alignItems: 'center' },
        },
        image: {
          namedLink: { supplier, onClick: handleClose },
          loading: supplierLoading,
          image: logoImage,
          variant: 'sellerLogo',
          size: '2.25rem',
          planName,
          badgeSize: '0.55rem',
        },
        stack: {
          direction: 'column',
          sx: { alignItems: 'flex-start' },
        },
      },
    };
  }
};

export default SellerNavInfo;

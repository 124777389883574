import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SecurityIcon from '@mui/icons-material/Security';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import SupportIcon from '@mui/icons-material/Support';
import VerifiedIcon from '@mui/icons-material/Verified';

export const FILE_UPLOAD_PHOTO_SIZE_LIMIT = 10 * 1000 * 1024;
export const FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

export const FILE_UPLOAD_VIDEO_SIZE_LIMIT = 100 * 1000 * 1024;
export const VIDEO_UPLOAD_TYPES = [
  'video/mp4',
  // 'video/quicktime',
  // 'video/wmv',
  // 'video/avi',
];

export const DOCUMENT_UPLOAD_PHOTO_SUPPORTED_FORMATS = ['application/pdf'];

export const FILE_UPLOAD_CODE_SUPPORTED_FORMATS = [
  'application/x-python-code',
  'text/x-python',
  'text/plain',
];

export const DISPUTE_UPLOAD_FILE_TYPES = [
  ...FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS,
  'application/pdf',
  'application/msword',
];

export const MESSAGE_UPLOAD_SIZE_LIMIT = 5 * 1000 * 1024;
export const MESSAGE_UPLOAD_FILE_TYPES = [
  ...FILE_UPLOAD_PHOTO_SUPPORTED_FORMATS,
  'image/bmp',
  'image/tiff',
  'image/apng',
  'image/avif',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const TOAST_DEFAULT_DURATION = 7000;
export const TOAST_DEFAULT_IS_CLOSABLE = true;
export const TOAST_DEFAULT_POSITION = 'bottom-left';

export const PROVINCE_CODES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const PROVINCE_COORDINATES = {
  ab: [53.9333, -114.5765],
  bc: [53.7267, -124.6476],
  mb: [53.8951, -97.1384],
  nb: [45.9636, -66.6431],
  nl: [47.5615, -52.6126],
  ns: [45.1488, -63.0752],
  nt: [62.454, -117.3718],
  nu: [64.1467, -93.117],
  on: [50.4215, -84.6972],
  pe: [46.2382, -63.1311],
  qc: [50.8139, -71.208],
  sk: [53.1579, -105.6702],
  yt: [62.7212, -135.0568],
};

export const PROVINCE_STATE_CODE_TO_NAME = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AP: 'Armed Forces Pacific',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  VI: 'US Virgin Islands',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NF: 'Newfoundland',
  NT: 'Northwest Territory',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const FALLBACK_IMAGE_AVATAR =
  'https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png';

export const RECHARTS_COLOURS = {
  skyblue: '#0088FE',
  green: '#00C49F',
  orange: '#FFBB28',
  purple: '#8884d8',
  red: '#ff6961',
};

export const PLAN_1 = 'price_1L6F8VKhhIGkVNWRAQ9eeOXn';
export const PLAN_2 = 'price_1LKmSxKhhIGkVNWRRnHdcKms';
export const PLAN_3 = 'price_1L6FAdKhhIGkVNWRuTuYKd0x';
export const PLAN_4 = 'price_1LKmTrKhhIGkVNWRVSMRYgFJ';
export const ORGANIZATION_TYPES = [
  {
    id: '92c40050-72c4-4c24-b5b8-054c46cc8770',
    englishName: 'Association',
    frenchName: 'Association',
    arabicName: 'منظمة',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '8735ef9f-f081-4f58-a05d-3d32d4f2f1a5',
    englishName: 'Broker',
    frenchName: 'Courtier',
    arabicName: 'سمسار',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '45bebdd4-45df-4dd5-b2e7-6fc4865d8cd2',
    englishName: 'Service Provider',
    frenchName: 'Fournisseur de services',
    arabicName: 'مقدم خدمة',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: 'a08434e0-86fa-4051-bd91-3972b658ee38',
    englishName: 'Franchise',
    frenchName: 'Franchise',
    arabicName: 'وكيل أو فرع',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '4769cdcc-2fdc-4fdb-9294-73f799b1af74',
    englishName: 'Government',
    frenchName: 'Government',
    arabicName: 'حكومي',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '0fd05c6c-4b52-419f-a98b-f924d62fbf8b',
    englishName: 'Importer',
    frenchName: 'Importer',
    arabicName: 'مستورد',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: 'fd4039d6-9988-4548-8e63-405a30d1a867',
    englishName: 'Manufacturer',
    frenchName: 'Manufacturer',
    arabicName: 'صانع',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '951a1e58-9254-4455-ac2a-bf91395ff329',
    englishName: 'Reseller',
    frenchName: 'Reseller',
    arabicName: 'موزع',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '6e0be034-3602-4c5a-b24e-2e7153c9d4d9',
    englishName: 'Company Owned',
    frenchName: 'Company Owned',
    arabicName: 'تملكه شركة',
    roles: ['0', '1', '2', '3'],
  },
  {
    id: '80263235-172a-4417-a9f4-7efea0813bcb',
    englishName: 'Wholesaler',
    frenchName: 'Wholesaler',
    arabicName: 'تاجر جملة',
    roles: ['0', '1', '2', '3'],
  },
];
export const STATUS = {
  NEW: 'new',
  EDITED: 'edited',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  DELETED: 'deleted',
  RESTORED: 'restored',
};

export const DISPUTE_ISSUE_TYPES = [
  {
    id: '0',
    englishName: 'Missing Item(s)',
    frenchName: 'Élément(s) Manquant(s)',
    arabicName: 'عناصر ناقصة',
  },
  {
    id: '1',
    englishName: 'Broken Item(s)',
    frenchName: 'Article(s) cassé(s)',
    arabicName: 'عناصر مكسورة',
  },
  {
    id: '2',
    englishName: 'Shipment Never Arrived',
    frenchName: "L'Envoi N'Est Jamais Arrivé",
    arabicName: 'لم تصل الشحنة ',
  },
  {
    id: '3',
    englishName: 'Wrong Items Included',
    frenchName: 'Articles Incorrects Inclus',
    arabicName: 'تضمنت عناصر خاطئة',
  },
  { id: '4', englishName: 'Other', frenchName: 'Autre', arabicName: 'أخرى' },
];

export const ADMIN_ROLES = ['0'];
export const MODERATOR_ROLES = ['0', '1'];
export const SUPPLIER_ROLES = ['2'];
export const CONSUMER_ROLES = ['3'];

export const URL_REGEX = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i',
);

export const URL_TEXT_REGEX = new RegExp(
  '(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?', // fragment locator
  'i',
);

export const POSTAL_CODE_REGEX = new RegExp(
  '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d$',
  'i',
);

export const ZIP_CODE_REGEX = new RegExp('^\\d{5}(-{0,1}\\d{4})?$', 'i');

export const PHONE_NUMBER_REGEX = new RegExp(
  '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$',
);

export const PHONE_NUMBER_TEXT_REGEX = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/i;

export const EMAIL_REGEX = new RegExp(
  '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
);

export const EMAIL_TEXT_REGEX = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

export const MIN_TAG_SIZE = 2;

export const EMPLOYMENT_SIZES = ['<10', '10-100', '100-1000', '>1000'];

export const SUPPLIER_BADGES = [
  {
    id: 'credit',
    englishName: 'Credit Verified',
    frenchName: 'Crédit Vérifié',
    arabicName: 'تم التحقق من الائتمان',
    Icon: CreditCardIcon,
  },
  {
    id: 'delivery',
    englishName: 'Punctual Delivery',
    frenchName: 'Livraison ponctuelle',
    arabicName: 'توصيل في المواعيد',
    Icon: LocalShippingIcon,
  },
  {
    id: 'security',
    englishName: 'Security',
    frenchName: 'Sécurité',
    arabicName: 'أمان',
    Icon: SecurityIcon,
  },
  {
    id: 'communication',
    englishName: 'Communication',
    frenchName: 'La Communication',
    arabicName: 'تواصل',
    Icon: HeadsetMicIcon,
  },
  {
    id: 'support',
    englishName: 'Support',
    frenchName: 'Soutien',
    arabicName: 'يدعم',
    Icon: SupportIcon,
  },
  {
    id: 'verified',
    englishName: 'Verified',
    frenchName: 'Vérifié',
    arabicName: 'موثق',
    Icon: VerifiedIcon,
  },
];

export const DELIVERY_DATE_OPTIONS = [
  {
    id: '0',
    englishName: '1 Week',
    frenchName: '1 Semaine',
    arabicName: 'اسبوع',
  },
  {
    id: '1',
    englishName: '2 Weeks',
    frenchName: '1 Semaines',
    arabicName: 'اسبوعين',
  },
  {
    id: '2',
    englishName: '3 Weeks',
    frenchName: '1 Semaines',
    arabicName: '3 اسابيع',
  },
  { id: '3', englishName: '1 Month', frenchName: '1 Mois', arabicName: 'شهر' },
  {
    id: '4',
    englishName: '2 Months',
    frenchName: '2 Mois',
    arabicName: 'شهرين',
  },
  {
    id: '5',
    englishName: '3-6 Months',
    frenchName: '3-6 Mois',
    arabicName: '3-6 أشهر',
  },
  {
    id: '6',
    englishName: '6+ Months',
    frenchName: '6+ Mois',
    arabicName: '6+ أشهر',
  },
];

export const COURIERS = [
  { id: '0', name: 'DHL' },
  { id: '1', name: 'FedEx' },
  { id: '2', name: 'UPS' },
  { id: '3', name: 'Purolator' },
  { id: '4', name: { id: 'couriers.other', dm: 'Other' } },
];

export const DISPUTE_RESOLUTIONS = [
  {
    id: 'refund',
    name: {
      id: 'disputes.resolutionOptions.refund',
      defaultMessage: 'Refund the order',
    },
  },
  {
    id: 'return',
    name: {
      id: 'disputes.resolutionOptions.return',
      defaultMessage: 'Refund and return the shipment',
    },
  },
];

export const TRANSACTION_STATUS = [
  {
    id: 'failed',
    name: {
      id: 'bills.billingAndPayment.transactionStatus.failed',
      defaultMessage: 'Transaction Failed',
    },
    color: 'error',
  },
  {
    id: 'success',
    name: {
      id: 'bills.billingAndPayment.transactionStatus.success',
      defaultMessage: 'Transaction Success',
    },
    color: 'success',
  },
  {
    id: 'pending',
    name: {
      id: 'bills.billingAndPayment.transactionStatus.pending',
      defaultMessage: 'Transaction Pending',
    },
    color: 'primary',
  },
];

export const TRANSACTION_STATUSES = [
  {
    code: 'failed',
    id: 'transactions.status.options.failed',
    dm: 'Failed',
    color: 'error',
  },
  {
    code: 'succeed',
    id: 'transactions.status.options.succeed',
    dm: 'Succeed',
    color: 'success',
  },
  {
    code: 'pending',
    id: 'transactions.status.options.pending',
    dm: 'Pending',
    color: 'primary',
  },
];

export const TRANSACTION_STATUS_DROPDOWN_OPTIONS = [
  {
    id: ' ',
    name: {
      id: 'transactions.status.options.all',
      dm: 'All Statuses',
    },
  },
  ...TRANSACTION_STATUSES.map(({ code, id, dm }) => ({
    id: code,
    name: { id, dm },
  })),
];

export const TRANSACTION_SORT_DROPDOWN_OPTIONS = [
  {
    id: 'created_at',
    name: { id: 'transactions.sort.options.createdAt', dm: 'Date Created' },
  },
  {
    id: 'updated_at',
    name: { id: 'transactions.sort.options.updatedAt', dm: 'Date Updated' },
  },
];

export const TRANSACTION_TIME_DROPDOWN_OPTIONS = [
  {
    id: '3',
    startDate: { number: '3', unit: 'months' },
    name: { id: 'transactions.time.options.threeMonths', dm: '3 Months' },
  },
  {
    id: '6',
    startDate: { number: '6', unit: 'months' },
    name: { id: 'transactions.time.options.sixMonths', dm: '6 Months' },
  },
  {
    id: '12',
    startDate: { number: '1', unit: 'years' },
    name: { id: 'transactions.time.options.oneYear', dm: '1 Year' },
  },
];

export const INVOICES_STATUS = [
  {
    id: 'canceled',
    name: {
      id: 'bills.billingAndPayment.invoiceStatus.canceled',
      defaultMessage: 'Invoice Canceled',
    },
    color: 'error',
  },
  {
    id: 'paid',
    name: {
      id: 'bills.billingAndPayment.invoiceStatus.paid',
      defaultMessage: 'Invoice Paid',
    },
    color: 'success',
  },
  {
    id: 'pending',
    name: {
      id: 'bills.billingAndPayment.invoiceStatus.pending',
      defaultMessage: 'Invoice Pending',
    },
    color: 'primary',
  },
];

export const INVOICES_STATUS_INDICATOR = [
  {
    code: 'canceled',
    id: 'invoices.status.options.canceled',
    dm: 'Canceled',
    color: 'error',
  },
  {
    code: 'paid',
    id: 'invoices.status.options.paid',
    dm: 'Paid',
    color: 'success',
  },
  {
    code: 'pending',
    id: 'invoices.status.options.pending',
    dm: 'Pending',
    color: 'primary',
  },
];

export const INVOICES_STATUS_DROPDOWN_OPTIONS = [
  {
    id: ' ',
    name: {
      id: 'invoices.status.options.all',
      dm: 'All Statuses',
    },
  },
  ...INVOICES_STATUS_INDICATOR.map(({ code, id, dm }) => ({
    id: code,
    name: { id, dm },
  })),
];

export const INVOICES_SORT_DROPDOWN_OPTIONS = [
  {
    id: 'created_at',
    name: { id: 'invoices.createDate', dm: 'Date Created' },
  },
  {
    id: 'updated_at',
    name: { id: 'invoices.updateDate', dm: 'Date Updated' },
  },
  {
    id: 'total_sum',
    name: { id: 'invoices.totalAmount', dm: 'Total Amount' },
  },
];

export const INVOICES_TIME_DROPDOWN_OPTIONS = [
  {
    id: 'threeMonths',
    startDate: { number: '3', unit: 'months' },
    name: { id: 'invoices.threeMonths', dm: '3 Months' },
  },
  {
    id: 'sixMonths',
    startDate: { number: '6', unit: 'months' },
    name: { id: 'invoices.sixMonths', dm: '6 Months' },
  },
  {
    id: 'oneYear',
    startDate: { number: '1', unit: 'years' },
    name: { id: 'invoices.oneYear', dm: '1 Year' },
  },
];

export const RFQ_CONVERSATION_STATUSES = [
  {
    id: 'quoteRequest',
    name: {
      id: 'conversations.rfqs.status.options.quoteRequest',
      defaultMessage: 'Quote Request',
    },
    color: 'yellow',
  },
  {
    id: 'activeQuote',
    name: {
      id: 'conversations.rfqs.status.options.activeQuote',
      defaultMessage: 'Active Quote',
    },
    color: 'secondary',
  },
];

export const ORDER_CONVERSATION_STATUSES = [
  {
    id: 'orderPending',
    name: {
      id: 'conversations.order.status.options.orderPending',
      defaultMessage: 'Order Pending',
    },
    color: 'error',
  },
  {
    id: 'paymentPending',
    name: {
      id: 'conversations.order.status.options.paymentPending',
      defaultMessage: 'Pending Payment',
    },
    color: 'yellow',
  },
  {
    id: 'orderInProcess',
    name: {
      id: 'conversations.order.status.options.orderInProcess',
      defaultMessage: 'Order in Progress',
    },
    color: 'primary',
  },
  {
    id: 'shipped',
    name: {
      id: 'conversations.order.status.options.shipped',
      defaultMessage: 'Order Shipped',
    },
    color: 'success',
  },
  {
    id: 'delivered',
    name: {
      id: 'conversations.order.status.options.delivered',
      defaultMessage: 'Order Delivered',
    },
    color: 'headingText',
  },
  {
    id: 'deliveredChecked',
    name: {
      id: 'conversations.order.status.options.deliveredChecked',
      defaultMessage: 'Delivered By Supplier',
    },
    color: 'headingText',
  },
  {
    id: 'paymentInitiated',
    name: {
      id: 'conversations.order.status.options.paymentInitiated',
      defaultMessage: 'Payment Initiated',
    },
    color: 'success',
  },
  {
    id: 'paymentReleased',
    name: {
      id: 'conversations.order.status.options.paymentReleased',
      defaultMessage: 'Payment Released',
    },
    color: 'primary',
  },
  {
    id: 'closed',
    name: {
      id: 'conversations.order.status.options.closed',
      defaultMessage: 'Closed',
    },
    color: 'headingText',
  },
];

export const DISPUTE_CONVERSATION_STATUSES = [
  {
    id: 'disputeInitiated',
    code: 'disputeInitiated',
    name: {
      id: 'conversations.dispute.status.options.disputeInitiated',
      defaultMessage: 'In Dispute',
    },
    color: 'error',
  },
  {
    id: 'inDispute',
    code: 'inDispute',
    name: {
      id: 'conversations.dispute.status.options.inDispute',
      defaultMessage: 'In Dispute',
    },
    color: 'error',
  },
  {
    id: 'resolutionOffered',
    code: 'resolutionOffered',
    name: {
      id: 'conversations.dispute.status.options.resolutionOffered',
      defaultMessage: 'Resolution Offered',
    },
    color: 'secondary',
  },
  {
    id: 'returnRequested',
    code: 'returnRequested',
    name: {
      id: 'conversations.dispute.status.options.returnRequested',
      defaultMessage: 'Return Requested',
    },
    color: 'primary',
  },
  {
    id: 'refundInitiated',
    code: 'refundInitiated',
    name: {
      id: 'conversations.dispute.status.options.refundInitiated',
      defaultMessage: 'Refund Initiated',
    },
    color: 'success',
  },
  {
    id: 'counterOfferApproved',
    code: 'counterOfferApproved',
    name: {
      id: 'conversations.dispute.status.options.counterOfferApproved',
      defaultMessage: 'Offer Approved',
    },
    color: 'success',
  },
  {
    id: 'resolved',
    code: 'resolved',
    name: {
      id: 'conversations.dispute.status.options.resolved',
      defaultMessage: 'Dispute Resolved',
    },
    color: 'success',
  },
];

export const CONVERSATION_STATUSES = [
  ...RFQ_CONVERSATION_STATUSES,
  ...ORDER_CONVERSATION_STATUSES,
  ...DISPUTE_CONVERSATION_STATUSES,
  {
    id: 'closedSeller',
    name: {
      id: 'conversations.other.status.options.closedSeller',
      defaultMessage: 'Closed (by Buyer)',
    },
    color: 'headingText',
  },
  {
    id: 'closedBuyer',
    name: {
      id: 'conversations.other.status.options.closedBuyer',
      defaultMessage: 'Closed (by Seller)',
    },
    color: 'headingText',
  },
  {
    id: 'refundPayment',
    name: {
      id: 'conversations.other.status.options.refundPayment',
      defaultMessage: 'Payment Refunded',
    },
    color: 'headingText',
  },
  {
    id: 'rfqCanceled',
    name: {
      id: 'conversations.other.status.options.rfqCanceled',
      defaultMessage: 'RFQ Cancelled',
    },
    color: 'headingText',
  },
  {
    id: 'rfqCancelled',
    name: {
      id: 'conversations.other.status.options.rfqCancelled',
      defaultMessage: 'Request Cancelled',
    },
    color: 'headingText',
  },
  {
    id: 'rfqCanceledBySeller',
    name: {
      id: 'conversations.other.status.options.rfqCanceledBySeller',
      defaultMessage: 'RFQ Cancelled by Seller',
    },
    color: 'headingText',
  },
  {
    id: 'sellerLeftReview',
    name: {
      id: 'conversations.other.status.options.sellerLeftReview',
      defaultMessage: 'Seller Left Review',
    },
    color: 'yellow',
  },
  {
    id: 'buyerLeftReview',
    name: {
      id: 'conversations.other.status.options.buyerLeftReview',
      defaultMessage: 'Buyer Left Review',
    },
    color: 'yellow',
  },
];

export const ORDER_STATUSES = [
  {
    code: 'pending',
    id: 'orders.myOrders.status.pending',
    dm: 'Pending',
    color: 'error',
  },
  {
    code: 'approved',
    id: 'orders.myOrders.status.approved',
    dm: 'Approved',
    color: 'primary',
  },
  {
    code: 'deleted',
    id: 'orders.myOrders.status.deleted',
    dm: 'Deleted',
    color: 'error',
  },
  {
    code: 'rejected',
    id: 'orders.myOrders.status.rejected',
    dm: 'Rejected',
    color: 'secondary',
  },
  {
    code: 'completed',
    id: 'orders.myOrders.status.completed',
    dm: 'Completed',
    color: 'success',
  },
  {
    code: 'canceled',
    id: 'orders.myOrders.status.cancelled',
    dm: 'Cancelled',
    color: 'headingText',
  },
  {
    code: 'expired',
    id: 'orders.myOrders.status.expired',
    dm: 'Expired',
    color: 'error',
  },
  {
    code: 'shipped',
    id: 'orders.myOrders.status.shipped',
    dm: 'Shipped',
    color: 'success',
  },
  {
    code: 'delivered',
    id: 'orders.myOrders.status.delivered',
    dm: 'Delivered',
    color: 'headingText',
  },
  {
    code: 'disputed',
    id: 'orders.myOrders.status.disputed',
    dm: 'Disputed',
    color: 'secondary',
  },
  {
    code: 'refundInitiated',
    id: 'orders.myOrders.status.refundInitiated',
    dm: 'Refund Initiated',
    color: 'primary',
  },
  {
    code: 'refunded',
    id: 'orders.myOrders.status.refunded',
    dm: 'Refunded',
    color: 'success',
  },
  {
    code: 'in-hold',
    id: 'escrow.status.inhold',
    dm: 'In-hold',
    color: 'secondary',
  },
  {
    code: 'released',
    id: 'escrow.status.released',
    dm: 'Released',
    color: 'success',
  },
];

export const RFQ_STATUS = [
  {
    code: 'open',
    id: 'quotations.myQuotation.rfqStatus.open',
    dm: 'Open',
    color: 'primary',
  },
  {
    code: 'active',
    id: 'quotations.myQuotation.rfqStatus.active',
    dm: 'Active',
    color: 'success',
  },
  {
    code: 'closed',
    id: 'quotations.myQuotation.rfqStatus.closed',
    dm: 'Closed',
    color: 'error',
  },
];

export const DISPUTE_STATUSES = [
  {
    code: 'disputeInitiated',
    id: 'disputes.card.status.disputeInitiated',
    dm: 'Initiated',
    color: 'error',
  },
  {
    code: 'inDispute',
    id: 'disputes.card.status.inDispute',
    dm: 'In Dispute',
    color: 'error',
  },
  {
    code: 'resolutionOffered',
    id: 'disputes.card.status.resolutionOffered',
    dm: 'Resolution Offered',
    color: 'secondary',
  },
  {
    code: 'returnRequested',
    id: 'disputes.card.status.returnRequested',
    dm: 'Return Requested',
    color: 'primary',
  },
  {
    code: 'refundInitiated',
    id: 'disputes.card.status.refundInitiated',
    dm: 'Refund Initiated',
    color: 'success',
  },
  {
    code: 'counterOfferApproved',
    id: 'disputes.card.status.counterOfferApproved',
    dm: 'Offer Approved',
    color: 'success',
  },
  {
    code: 'resolved',
    id: 'disputes.card.status.resolved',
    dm: 'Resolved',
    color: 'success',
  },
];

export const INVITE_STATUSES = [
  {
    id: 'sent',
    name: { id: 'invitations.status.options.sent', dm: 'Sent' },
    color: 'primary',
  },
  {
    id: 'approved',
    name: { id: 'invitations.status.options.approved', dm: 'Approved' },
    color: 'success',
  },
];

export const INVITE_METHODS = [
  {
    id: 'email',
    name: { id: 'invitations.method.options.email', dm: 'Email' },
  },
  {
    id: 'directLink',
    name: { id: 'invitations.method.options.directLink', dm: 'Direct Link' },
  },
];

export const TIME_RANGE_UNITS = [
  { id: 'days', name: { id: 'timeRange.units.days', dm: 'Days' } },
  { id: 'months', name: { id: 'timeRange.units.months', dm: 'Months' } },
  { id: 'years', name: { id: 'timeRange.units.years', dm: 'Years' } },
];

export const INVITE_STATUS_DROPDOWN_OPTIONS = [
  {
    id: 'emailSent',
    name: {
      id: 'invitations.status.dropdown.options.emailSent',
      dm: 'Email (Sent)',
    },
  },
  {
    id: 'emailApproved',
    name: {
      id: 'invitations.status.dropdown.options.emailApproved',
      dm: 'Email (Approved)',
    },
  },
  {
    id: 'linkApproved',
    name: {
      id: 'invitations.status.dropdown.options.linkApproved',
      dm: 'Link',
    },
  },
];

export const INVITE_SORT_DROPDOWN_OPTIONS = [
  {
    id: 'created_at',
    name: { id: 'invitations.sort.options.createdAt', dm: 'Date Created' },
  },
  {
    id: 'updated_at',
    name: { id: 'invitations.sort.options.updatedAt', dm: 'Date Updated' },
  },
  {
    id: 'approval_date',
    name: { id: 'invitations.sort.options.approvalDate', dm: 'Date Accepted' },
  },
];

export const INVITE_TIME_DROPDOWN_OPTIONS = [
  {
    id: 'threeMonths',
    startDate: { number: '3', unit: 'months' },
    name: { id: 'invitations.time.options.threeMonths', dm: '3 Months' },
  },
  {
    id: 'sixMonths',
    startDate: { number: '6', unit: 'months' },
    name: { id: 'invitations.time.options.sixMonths', dm: '6 Months' },
  },
  {
    id: 'oneYear',
    startDate: { number: '1', unit: 'years' },
    name: { id: 'invitations.time.options.oneYear', dm: '1 Year' },
  },
];

export const ORDER_STATUS_DROPDOWN_OPTIONS = [
  { id: ' ', name: { id: 'orders.sort.options.all', dm: 'All Statuses' } },
  ...ORDER_STATUSES.map(({ code, id, dm }) => ({ id: code, name: { id, dm } })),
];

export const ORDER_SORT_DROPDOWN_OPTIONS = [
  {
    id: 'created_at',
    name: { id: 'orders.sort.options.createdAt', dm: 'Date Created' },
  },
  {
    id: 'updated_at',
    name: { id: 'orders.sort.options.updatedAt', dm: 'Date Updated' },
  },
  {
    id: 'total_cost',
    name: { id: 'orders.sort.options.totalCost', dm: 'Cost' },
  },
];

export const ORDER_TIME_DROPDOWN_OPTIONS = [
  {
    id: 'threeMonths',
    startDate: { number: '3', unit: 'months' },
    name: { id: 'orders.time.options.threeMonths', dm: '3 Months' },
  },
  {
    id: 'sixMonths',
    startDate: { number: '6', unit: 'months' },
    name: { id: 'orders.time.options.sixMonths', dm: '6 Months' },
  },
  {
    id: 'oneYear',
    startDate: { number: '1', unit: 'years' },
    name: { id: 'orders.time.options.oneYear', dm: '1 Year' },
  },
];

export const RFQ_STATUS_DROPDOWN_OPTIONS = [
  {
    id: ' ',
    name: {
      id: 'quotations.myQuotation.rfqStatus.allStatuses',
      dm: 'All Statuses',
    },
  },
  ...RFQ_STATUS.map(({ code, id, dm }) => ({ id: code, name: { id, dm } })),
];

export const RFQ_SORT_DROPDOWN_OPTIONS = [
  {
    id: 'created_at',
    name: { id: 'quotations.myQuotation.creatDate', dm: 'Date Created' },
  },
  {
    id: 'updated_at',
    name: { id: 'quotations.myQuotation.updateDate', dm: 'Date Updated' },
  },
];

export const RFQ_TIME_DROPDOWN_OPTIONS = [
  {
    id: 'threeMonths',
    startDate: { number: '3', unit: 'months' },
    name: { id: 'quotations.myQuotation.threeMonths', dm: '3 Months' },
  },
  {
    id: 'sixMonths',
    startDate: { number: '6', unit: 'months' },
    name: { id: 'quotations.myQuotation.sixMonths', dm: '6 Months' },
  },
  {
    id: 'oneYear',
    startDate: { number: '1', unit: 'years' },
    name: { id: 'quotations.myQuotation.oneYear', dm: '1 Year' },
  },
];

export const SAVED_PRODUCT_TIME_DROPDOWN_OPTIONS = [
  {
    id: '3',
    startDate: { number: '3', unit: 'months' },
    name: { id: 'user.myAccount.threeMonths', dm: '3 Months' },
  },
  {
    id: '6',
    startDate: { number: '6', unit: 'months' },
    name: { id: 'user.myAccount.sixMonths', dm: '6 Months' },
  },
  {
    id: '12',
    startDate: { number: '1', unit: 'years' },
    name: { id: 'user.myAccount.oneYear', dm: '1 Year' },
  },
];

export const CURRENCIES = [
  { id: 'USD', name: 'USD', symbol: '$' },
  { id: 'CAD', name: 'CAD', symbol: '$' },
  { id: 'SAR', name: 'SAR', symbol: 'ر.س' },
  { id: 'AED', name: 'AED', symbol: 'د.إ' },
  { id: 'QAR', name: 'QAR', symbol: 'ر.ق' },
  { id: 'KWD', name: 'KWD', symbol: 'د.ك' },
  { id: 'BHD', name: 'BHD', symbol: 'د.ب' },
  { id: 'OMR', name: 'OMR', symbol: 'ر.ع' },
  { id: 'EGP', name: 'EGP', symbol: 'ج.م' },
  { id: 'INR', name: 'INR', symbol: '₹' },
  { id: 'JOD', name: 'JOD', symbol: 'د.ا' },
];

// list of ISO2 codes for supported countries
export const COUNTRY_OPTIONS = [
  'US',
  'CA',
  'GB',
  'CN',
  'IN',
  'AE',
  'QA',
  'KW',
  'EG',
  'BH',
  'OM',
  'SA',
  'JO',
];

export const CERTIFICATION_LIST = [
  '100% Renewable Electricity Certified - Carbon Trust',
  '10993 - ISO',
  '11199-3:2005 - ISO',
  '13485:2016 - ISO',
  '14001:2015 - ISO',
  '17025 - ISO/IEC',
  '17065:2012 - ISO/IEC',
  '1% for the Planet Member',
  '22000:2018 - ISO',
  '22716:2007 - ISO',
  '45001:2018 - ISO',
  '4C Compliant Coffee - Common Code for the Coffee Community',
  '50001:2018 - ISO',
  '510(k) Cleared Device - FDA',
  '5511 - ASTM',
  '5526 - ASTM',
  '625-2559 - TISI',
  '8000:2014 - SAI',
  '8001-2553 - TLS',
  '9001:2015 - ISO',
  'A112.18.1/CSA B125.1-2018 - ASME',
  'AAFCO Proficiency Testing Program',
  'Accredited Business - BBB',
  'ADA Compliant',
  'African American Business Enterprise - SCTRCA',
  'AllergyCertified - AC',
  'American Grassfed Certified - AGA',
  'American Heart Association Certified',
  'American Wagyu Association Member',
  'Ammonium-Nitrate Free - Government of Canada RCR',
  'Animal and Plant Health Inspection Service Certification (APHIS) - USDA',
  'Animal Welfare Certified (Animal Centered) - G.A.P',
  'Animal Welfare Certified (Enriched Environment) - G.A.P',
  'Animal Welfare Certified (Entire Life on Farm) - G.A.P',
  'Animal Welfare Certified - G.A.P',
  'Animal Welfare Certified (Outdoor Access) - G.A.P',
  'Animal Welfare Certified (Pasture-Raised) - G.A.P',
  'ANSI-GBI 01-2019 - Green Building Initiative',
  'ANSI/NSF 372 - NSF Int-l',
  'ANSI/NSF 61 - NSF Int-l',
  'Antibiotic Free - FDA',
  'APMA Seal of Acceptance - APMA',
  'Approved by the EPA',
  'Approved by the USTA (United States Tennis Association)',
  'Approved Vegan - Vegetarian Society of UK',
  'Approved Vegetarian - Vegetarian Society of UK',
  'ASC Chain of Custody Standard - Aquaculture Stewardship Council',
  'Asian Minority-Owned Business - USPAACC',
  'ASI Performance Standard - Aluminum Stewardship Initiative',
  'ASTA Testing Report - Intertek',
  'Audubon Certified',
  'Australian Certified Organic',
  'Australia Organic Certified - OFC',
  'Banned Substances Control Group (BSCG)',
  'Bedding and Upholstery License Certificate - PA Dept. of Labor',
  'Bee Better Certified - The Xerces Society',
  'Best Aquaculture Practices - NSF Int-l',
  'Better Cotton Initiative Member',
  'BIFMA Performance Certification - UL',
  'Biodynamic Certification - Demeter Association Inc.',
  'bluesign®️ APPROVED',
  'Bluetooth Qualified',
  'BPA Free',
  'BPA Free - Made Safe',
  'BPI Certified',
  'BRCGS Global Standard',
  'Brunswick Labs Certified',
  'Business Friendly - BFC SEE',
  'Business Social Compliance Initiative Certified - SAAS',
  'California Green Building Standards Code - CALGreen',
  'California Proposition 65 Compliant - OEHHA',
  'Canada Consumer Product Safety Act (CCPSA)',
  'CanadaGAP Certification - NSF Int-l',
  'Canadian Aboriginal &amp; Minority Certified - CAMSC',
  'Canadian Women Business Enterprise - WBE Canada',
  'CARE Certified - WFCF',
  'CBD Analysis Seal of Approval - Green Scientific Labs',
  'CDSCO Compliant - India Central Drugs Standard Control Organization',
  'CE Mark - European Commission',
  'CertClean Certified',
  'Certificate of GMP - Costco',
  'Certificate of GMP - FDA',
  'Certificate of GMP - Health Canada',
  'Certificate of GMP - International Certification Group',
  'Certificate of GMP - Natural Products Association',
  'Certificate of GMP - NSF Int-l',
  'Certificate of GMP - SGS',
  'Certified Aboriginal Business - CCAB',
  'Certified Airport Concessions Disadvantaged Business Enterprise (ACDBE) - MDOT',
  'Certified Allergen Free - Apura Ingredients',
  'Certified Animal-Free - VeganMed',
  'Certified Animal Welfare Approved - AGW',
  'Certified Antibiotic Free - USDA',
  'Certified Asthma and Allergy Friendly - AAFA',
  'Certified Australian Made - AMCL',
  'Certified B Corporation - B Lab',
  'Certified Biobased Product - USDA',
  'Certified Biodegradable - RespirTek Laboratory',
  'Certified Bird Friendly - Smithsonian',
  'Certified Black-Owned - ByBlack (USBC)',
  'Certified by Intertek',
  'Certified by Juvenile Products Manufacturers Association',
  'Certified by MayaCert',
  'Certified by OMRI - Organic Materials Review Institute',
  'Certified by the Israeli Ministry of Health',
  'Certified by The Paleo Diet - WFCF',
  'Certified by the TÜV NORD Group',
  'Certified by the TUV Rheinland Group',
  'Certified by the U.S. Hemp Authority',
  'Certified by USB Implementers Forum, Inc. (USB-IF)',
  'Certified Cage-Free - USDA',
  'Certified Cajun - LA Dept. of Agriculture &amp; Diversity',
  'Certified California Green Business',
  'Certified California Sustainable Vineyard - CSWA',
  'Certified California Sustainable Vineyard &amp; Winery - CSWA',
  'Certified California Sustainable Wine - CSWA',
  'Certified California Sustainable Winery - CSWA',
  'Certified Canadian Organic Aquaculture - CFIA',
  'Certified Carbonfree - Carbonfund.org',
  'Certified Carbon Neutral - Carbon Trust',
  'Certified CarbonNeutral Product - Natural Capital Partners',
  'Certified Carbon Neutral - SCSGS',
  'Certified Clean Label - Clean Label Project',
  'Certified Climate Neutral - ClimatePartner',
  'Certified Compostable - BNQ',
  'Certified Cruelty Free - Choose Cruelty Free Ltd.',
  'Certified Direct Trade Coffee - Ethical Coffee',
  'Certified Disability-Owned Business Enterprise - Coupa Software Inc.',
  'Certified Disability-Owned Business Enterprise - Disability:IN',
  'Certified Disabled Veteran-Owned Business (DVOB) - State of NJ',
  'Certified Disadvantaged Business Enterprise (DBE) - MDOT',
  'Certified Ethnic Minority-Owned - NMSDC',
  'Certified Fair Trade - FairTSA',
  'Certified for Sport - NSF Int-l',
  'Certified Free From - MenuTrinfo',
  'Certified Free-Range - USDA',
  'Certified Gluten-Free - ACG &amp; CCA',
  'Certified Gluten-Free - Crossed Grain Trademark (Coeliac UK)',
  'Certified Gluten-Free - GFCO',
  'Certified Gluten-Free - NFC',
  'Certified Gluten-Free - NSF Int-l',
  'Certified Gluten-Free - SCS Global Services',
  'Certified Gluten-Free - WFCF',
  'Certified Glyphosate Residue Free - The Detox Project',
  'Certified Grain-Free Gluten-Free - Paleo Foundation',
  'Certified Grassfed - AGW',
  'Certified Grass-Fed - WFCF',
  'Certified Green - IAPMO',
  'Certified Green - LODI RULES',
  'Certified Halal - American Halal Foundation',
  'Certified Halal - BAS 1049:2010',
  'Certified Halal - CDIAL',
  'Certified Halal - Etimad',
  'Certified Halal - FIANZ',
  'Certified Halal - HASB',
  'Certified Halal - IFANCA',
  'Certified Halal - IIDC',
  'Certified Halal - India Halal Certification',
  'Certified Halal - JAKIM',
  'Certified Halal - NZIDT',
  'Certified Halal - The Central Islamic Council of Thailand',
  'Certified Halal - Turkish Standards Institution',
  'Certified Halal - USA Halal Chamber of Commerce',
  'Certified Halal - WHAD Italia',
  'Certified HUBZone Business - USSBA',
  'Certified Humane - Humane Farm Animal Care',
  'Certified Ketogenic - Q1',
  'Certified Kosher - 1K-Kosher',
  'Certified Kosher - Atlanta Kashruth Commission (AKC)',
  'Certified Kosher - Balkan Kosher',
  'Certified Kosher - BIR',
  'Certified Kosher - COR',
  'Certified Kosher - CRC',
  'Certified Kosher - Dallas Kosher',
  'Certified Kosher - Digital Kosher (K-ID)',
  'Certified Kosher - EarthKosher',
  'Certified Kosher - EZ Kosher Services',
  'Certified Kosher - Int-l Kosher Council',
  'Certified Kosher - KLBD',
  'Certified Kosher - KOF-K',
  'Certified Kosher - Kosher Australia',
  'Certified Kosher - Kosher Check',
  'Certified Kosher - Kosher LA',
  'Certified Kosher - Kosher Michigan',
  'Certified Kosher - KSA',
  'Certified Kosher - MK Kosher',
  'Certified Kosher - NFC',
  'Certified Kosher - North American Kosher',
  'Certified Kosher - OK Kosher',
  'Certified Kosher - ORB of Broward and Palm Beach FLA',
  'Certified Kosher - Oregon Kosher',
  'Certified Kosher - OU Kosher',
  'Certified Kosher - Quality Kosher Supervision',
  'Certified Kosher - Rabinado do Rio',
  'Certified Kosher - RCC',
  'Certified Kosher - Scroll-K',
  'Certified Kosher - Star-K',
  'Certified Kosher - The Federation',
  'Certified Kosher - Triangle K Kosher',
  'Certified Kosher - United Mehadrin Kosher',
  'Certified Kosher - Vaad HaKashruth of Kansas City',
  'Certified Kosher - Va’ad Leumi',
  'Certified Lead-Free - IAPMO',
  'Certified Low GI - Glycemic Index Foundation',
  'Certified Made Safe - Nontoxic Certified',
  'Certified Made with Organic 70%+ - BioGro',
  'Certified MBE - Carolinas-Virginia Minority Supplier Development Council (CVMSDC)',
  'Certified MBE - North Central Texas Regional Certification Agency (NCTRCA)',
  'Certified MBE - OMWBE',
  'Certified MBE - Southern California Minority Supplier Development Council',
  'Certified Minority Business Enterprise (MBE) - Connecticut-s Supplier Diversity Program',
  'Certified Minority Business Enterprise (MBE) - MDOT',
  'Certified Minority Business Enterprise (MBE) - NMSDC',
  'Certified Minority-Owned Business Enterprise (MBE) - SupplierGATEWAY EDC',
  'Certified Minority/Women Business Enterprise (M/WBE) - State of NJ',
  'Certified Naturally Grown - CNG',
  'Certified Non-GMO - AGW',
  'Certified Non-GMO - NFC',
  'Certified Non-GMO - NSF Int-l',
  'Certified Non-GMO - Project Verified',
  'Certified Organic - AGW',
  'Certified Organic - BioGro',
  'Certified Organic - CCOF',
  'Certified Organic - CFIA',
  'Certified Organic - European Commission',
  'Certified Organic - IBD',
  'Certified Organic - Japanese Agricultural Standards (JAS)',
  'Certified Organic - NFC',
  'Certified Organic - NM Dept. of Agriculture',
  'Certified Organic (NOP) - ICEA',
  'Certified Organic - Oregon Tilth',
  'Certified Organic - Primus Auditing Ops (PAO)',
  'Certified Organic - Pro-Cert',
  'Certified Organic - QAI',
  'Certified Organic - Regenerative Organic',
  'Certified Organic - TX Dept. of Agriculture',
  'Certified Organic - USDA',
  'Certified Organic - WFCF',
  'Certified Palm Oil Free - POFCAP',
  'Certified Pareve - Atlanta Kashruth Commission (AKC)',
  'Certified PFOA Free - IARC',
  'Certified Plant-Based - Control Union',
  'Certified Plant-Based - PBFA',
  'Certified Plantricious',
  'Certified Plastic Negative Product - rePurpose Global',
  'Certified Plastic Neutral Product - rePurpose Global',
  'Certified Regenerative - AGW',
  'Certified Responsible Fisheries - Alaska RFM',
  'Certified SDVOSB - US Dept. of Veteran Affairs',
  'Certified Service-Disabled Veteran Disability-Owned Business Enterprise - Disability:IN',
  'Certified SkinSAFE - SkinSAFE Products Inc.',
  'Certified Small Business Enterprise (SBE) - Connecticut-s Supplier Diversity Program',
  'Certified Small Business Enterprise (SBE) - MDOT',
  'Certified Small Business Enterprise (SBE) - State of NJ',
  'Certified Small Business Enterprise - SupplierGATEWAY EDC',
  'Certified South Carolina - SC Dept. of Agriculture',
  'Certified Sugar Alternative - OU Kosher',
  'Certified Sustainable - SFG',
  'Certified Sustainable - Sustainability in Practice',
  'Certified Vegan - BeVeg',
  'Certified Vegan - NFC',
  'Certified Vegan - SVB (Brazil)',
  'Certified Vegan - Vegan Action/Vegan Awareness Foundation',
  'Certified Vegan - VegeCert',
  'Certified Vegan - V-Label (EVU)',
  'Certified Vegan; Wine, Beer, &amp; Liquor - BeVeg',
  'Certified Vegetarian - V-Label (EVU)',
  'Certified Veteran Disability-Owned Business Enterprise - Disability:IN',
  'Certified Veteran-Owned Business (VOB) - State of NJ',
  'Certified Veteran Owned - NaVOBA',
  'Certified VOSB - US Dept. of Veteran Affairs',
  'Certified Women Business Enterprise (WBE) - Ministry of SMEs and Startups',
  'Certified Women Business Enterprise (WBE) - MWBE',
  'Certified Women Business Enterprise (WBE) - NWBOC',
  'Certified World Fair Trade Organization',
  'CertiPUR-US Certified Foam',
  'Chain of Custody Certification - FSC',
  'CLIA Waived - FDA',
  'Climate Neutral Certified - Climate Neutral',
  'Codex GMP - USDA',
  'Coeliac Australia Gluten-Free Certified',
  'Compact by Design - Amazon',
  'Compostable in Industrial-Scale Facilities - CMA',
  'CosmEthically ACTIVE Certificate - Modern CosmEthics',
  'COSMOS Standard - AISBL',
  'Cotton USA Mark',
  'CPC - USCPSC',
  'Cradle to Cradle Certified - C2C Products Innovation Institute',
  'Cruelty Free and Vegan - PETA',
  'Cruelty Free - PETA',
  'CSA Certification',
  'CSE - GfaW',
  'cUPC®️ Mark - IAPMO',
  'D4236 - ASTM',
  'D6400-19 - ASTM',
  'D6866 - ASTM',
  'D6954-18 - ASTM',
  'DesignLights Consortium Certification - DLC',
  'DIN (Drug Identification Number) - Health Canada',
  'Direct Trade Approval',
  'Dolphin Safe / Dolphin Friendly',
  'Drinking-Water Systems Certification - IAPMO',
  'EcoCert - Groupe EcoCert',
  'Ecogarantie Certificate - Probila-Unitrab',
  'ECOLOGO Certified - UL LLC',
  'Economically Disadvantaged Woman-Owned Small Business (EDWOSB) - USWCC',
  'EDGE Certificate - Excellence in Design for Greater Efficiencies',
  'EFSA Approval - European Food Safety Authority',
  'Emergency Use Authorization - FDA',
  'EN 13432 - CEN',
  'EN 14683 - CEN',
  'EN 14995 - CEN',
  'Energy Star Mark - USEPA',
  'EPEAT Verified - Green Electronics Council',
  'Equitable Food Initiative Standards - SCS Global Services',
  'Ethical Tea Partnership Member',
  'ETL Listed - Intertek',
  'EU 1169 - FIR',
  'EWG Verified - Environmental Working Group',
  'F2100-2 - ASTM',
  'F2100-3 - ASTM',
  'F2236 - ASTM',
  'F2326 - ASTM',
  'F2417 - ASTM',
  'F381-16 - ASTM',
  'F833-19 - ASTM',
  'F963 - ASTM',
  'Fair For Life - Fair of Life',
  'Fairtrade',
  'Fairtrade Certified - Fairtrade America',
  'Fair Trade Certified - Fair Trade USA',
  'Fair Trade Federation Member',
  'Family Tested Family Approved - PTPA',
  'Farmed Responsibly - ASC',
  'FCC Certification',
  'FIP Responsible Seafood Certification - WWF-US',
  'FODMAP Friendly Certified',
  'Food Safety and Inspection Service (FSIS) - USDA',
  'Food Safety Code for Manufacturing 8.1 Certified - SQF Institute',
  'Food Safety Manager Certificate - ServSafe®',
  'Food Safety Modernization Act (FSMA) - FDA',
  'Food Safety System Certification 22000 - NSF Int-l',
  'Food Sales Establishment License - GA Dept. of Agriculture',
  'Food Standard Certification - AFI',
  'Forest Management Certification - FSC',
  'Formulated Without Parabens - Built for Better (Walmart)',
  'Formulated Without Phthalates - Built for Better (Walmart)',
  'Formulated Without Sulfates - Built for Better (Walmart)',
  'FoSCoS Compliant - FSSAI',
  'Friend of the Sea Sustainable Fisheries Certificate',
  'FSSC 22000 V5 - Bureau Veritas',
  'FSSC 22000 V5 - SGS Global Services',
  'GBB Member - Green Business Bureau',
  'GCC - USCPSC',
  'Generally Recognized as Safe (GRAS) - FDA',
  'General Standard - Japanese Agricultural Standards (JAS)',
  'GFSI-Recognised Certification',
  'Global Coffee Program Certificate',
  'GLOBALG.A.P. Certification',
  'Global Markets Programme - NSF Int-l',
  'Global Organic Latex Standard (GOLS) - Control Union',
  'Global Organic Textile Standard (GOTS) - Global Standard gGmbH',
  'Global Real Estate Sustainability Benchmark',
  'Global Recycled Standard - International Working Group',
  'Good Agricultural Practices (GAP) - FDA',
  'Good Face Project Approved',
  'Good Handling Practices (GHP) - FDA',
  'Good Housekeeping Institute-s Green Good Housekeeping Seal',
  'Good Housekeeping Institute-s Limited Warranty Seal',
  'Go Texan Partner - TX Dept. of Agriculture',
  'Got to Be NC - NC Dept. of Agriculture',
  'Government Certified Disadvantaged Business Enterprise - DC Government',
  'Grain-Free Certified - Paleo Foundation',
  'Great for You Standard - Walmart',
  'Green America Certified Business',
  'Green CSM Certification - ACC',
  'Green-e Energy Certified - Center for Resource Solutions',
  'Green Plus',
  'Green Seal®️ Certification',
  'GSCL Certification - Global Certification Service Ltd.',
  'HACCP - FDA',
  'HACCP - International Certification Group',
  'Harmonized GAP Plus+ Standard - USDA',
  'Harmonized GAP Standard - USDA',
  'Healthy Grown Standard - Wisconsin Potato and Vegetable Growers Association',
  'HIPAA Covered Entities and Business Associates - USDHHS',
  'HSA Certified - Health Sciences Authority (Singapore Government)',
  'HSCG Certified - Handcrafted Soap &amp; Cosmetic Guild',
  'HVE - Ministère de l-Agriculture et de l-Alimentation',
  'ICADA Certified - International Association for Cosmetic Products',
  'ICAP – International Cannabinoid Analysis Program (THC compliance for CBD) - Nutrasource',
  'IC Certification - Science and Economic Development Canada',
  'ICEA Certified - The International Childbirth Education Association',
  'IFOS – International Fish Oil Standards (Omega-3 marine oils) - Nutrasource',
  'Indiana Grown - Indiana Dept. of Agriculture',
  'Industrial Certification - Whole Kosher Services',
  'Informed Choice Certified - LGC Group',
  'Informed Sport Certified - LGC Group',
  'International Certification - Whole Kosher Services',
  'International Featured Standards (IFS) - IFS Management GmbH',
  'International Tennis Federation Approved',
  'IPRO – International Probiotic Testing Standards - Nutrasource',
  'ISCC Certified',
  'ISCC Plus Certified',
  'ITA Affiliate - Asacert',
  'K142790 - FDA',
  'KeHE CAREtrade Member',
  'KeHE DIVERSEtrade Member',
  'Keto Certified - Paleo Foundation',
  'Leaping Bunny Certified',
  'LEED Certified - GBCI',
  'LEED Certified - U.S. Green Building Council',
  'LFGB Certification - Pony Testing International Group',
  'Made in Green - OEKO-TEX Service GmbH',
  'Made in Hawaii with Aloha - Hawaii Dept. Of Agriculture',
  'Manufacturers Declaration of Compliance - FDA',
  'MFI Certification - Paracable',
  'Military Spouse-Owned Enterprise (MSEC) - US MSCOC',
  'Minority Business Enterprise - SCTRCA',
  'Minority &amp; Veteran Business Certification - FL Office of Supplier Diversity',
  'MPS - ABC Certification',
  'MSC Certified - Marine Stewardship Council',
  'MSC Chain of Custody Standard - Marine Stewardship Council',
  'NAHA Certified - National Association for Holistic Aromatherapy',
  'National Advocates for Pregnant Women Member',
  'National Animal Supplement Certification - NASCouncil',
  'Natural Cosmetics Standard - GfaW',
  'Natural Seal: Home Care - Natural Products Association',
  'Natural Seal: Personal Care - Natural Products Association',
  'NEA Seal of Acceptance™️ - National Eczema Association',
  'New Mexico True Certified - 100% Made in NM',
  'NGBS Green Certified - National Green Building Standard',
  'Nonfood Compounds A1 Certification - NSF Int-l',
  'NIOSH Certified Equipment - CDC',
  'Non-GMO Certified',
  'Non-GMO Certified - EarthKosher',
  'Non-GMO Certified – IGEN (International GMO Evaluation Notification Program) - Nutrasource',
  'Non-GMO Tested – IGEN (International GMO Evaluation Notification Program) - Nutrasource',
  'Non-Hormone Treated Cattle - WFCF',
  'NSF 42 - NSF Int-l',
  'NSF 53 - NSF Int-l',
  'NSF/ANSI 2 - NSF Int-l',
  'NSF/ANSI 305 Organic Personal Care - NSF Int-l',
  'NVBDC Certification - National Veteran Business Development Council',
  'NY Grown &amp; Certified - NY State Dept. of Agriculture',
  'Ocean Bound Plastic Certified - ZPO',
  'Ocean Wise Certification - Ocean Wise Conservation Association',
  'OK Compost Home - TUV Austria',
  'OK Compost Industrial - TUV Austria',
  'OPPCR Certified - CA Dept. of Public Health',
  'Organic Content Standard Blended - Textile Exchange',
  'Organic Content Standards 100 - Textile Exchange',
  'Organic Production Regulation Certificate - IMOcert Latinoamerica Ltda',
  'Organic Standard Certificate - Institute for Market Ecology',
  'Paleo Certified - Paleo Foundation',
  'Parent Tested Parent Approved',
  'Parksmart Certification - GBCI',
  'PEER - GBCI',
  'Plant-Based Certified - WFCF',
  'Plantricious Friendly',
  'Plastic Free Certification Mark - Control Union',
  'Pride of New York - NY State Dept. of Agriculture',
  'PrimusGFS Certification',
  'Primus Standard - Azzule',
  'Process Verified Approval - USDA',
  'Product Certified by SGS - Societe Generale de Surveillance',
  'Product of Australia',
  'Product of Australia - ACCC',
  'Product Registered with FDA',
  'Programme for the Endorsement of Forest Certification',
  'Protected Designation of Origin - European Commission',
  'QKR Certified Face Mask - FDA',
  'R-2000 Standard - Natural Resources Canada',
  'Rainforest Alliance Certified',
  'Rated Excellent - Dermatest Research Institute',
  'Real California Milk Seal',
  'REAL®️ Seal - National Milk Producers Federation',
  'Recycled Claim Standard 100 - Textile Exchange',
  'Recycled Claim Standard Blended - Textile Exchange',
  'Recycled Content Certification (RCC) - SCSGS',
  'Reducing CO2 Certification - Carbon Trust',
  'Registered with Medicines and Healthcare products Regulatory Agency (MHRA)',
  'Registered with Voluntary Cosmetic Registration Program - FDA',
  'Registro Sanitario COFEPRIS - Gobierno de Mexico',
  'Regulation No. 1829/2003 - European Commission',
  'Regulation No. 828/2014 - European Commission',
  'Responsible Down Standard Certified - Textile Exchange',
  'Responsible Sourcing Assessment (ERSA) - Elevate',
  'Responsible Wool Standard - Textile Exchange',
  'Restriction of Hazardous Substances Certification',
  'Risk Assessment on Social Practice (GRASP) - GLOBALG.A.P.',
  'ROP Certified Farm - Real Organic Project',
  'RSPO Certification - Roundtable on Sustainable Palm Oil',
  'RTRS Certification - Round Table on Responsible Soy',
  'SABS Mark - South African Bureau of Standards',
  'Safe Food for Canadians Regulation - CFIA',
  'Safer Choice Certification - USEPA',
  'Salmon Welfare Certified - AGW',
  'Sanitary Notification of Processed Foods by Production Lines - NAHRCS',
  'Seafood Watch Certification - Monterey Bay Aquarium',
  'Seal of Approval - The Carpet and Rug Institute',
  'SEDEX Certified - Supplier Ethical Data Exchange',
  'SEDEX Members Ethical Trade Audit (SMETA) - Supplier Ethical Data Exchange',
  'Service-Disabled Veteran-Owned (VA Confirmed) - US Dept. of Veterans Affairs',
  'SITES - GBCI',
  'Skineco EcoDerm Compatible Product - International Association of Ecodermatology',
  'Small Business 8a Certified - USSBA',
  'Small Business Administration Certified',
  'Small Business Certification - CA OSDS',
  'Small Business Certification - Whole Kosher Services',
  'Small Business Enterprise - SCTRCA',
  'Small Disadvantaged Business - USSBA',
  'Small, Women-owned, and Minority-owned Business (SWaM) - Commonwealth of Virginia',
  'SOC for Service Organization: ICFR - American Institute of CPAs',
  'SOC for Service Organization: Trust Services Criteria - American Institute of CPAs',
  'SOC for Service Organization: Trust Services Criteria for General Use Report - American Institute of CPAs',
  'Specific Standard - Japanese Agricultural Standards (JAS)',
  'SQF Level 1 Certification - NSF Int-l',
  'SQF Level 2 Certification - NSF Int-l',
  'SQF Level 3 Certification - NSF Int-l',
  'Standard 100 - OEKO-TEX Service GmbH',
  'State Certified Minority Owned - National Conference of State Legislatures',
  'Supplier Clearinghouse Diversity Verification - CA Public Utilities Comm.',
  'Sustainable Forestry Initiative (SFI) Chain of Custody Standard - SCSGS',
  'Sustainable Supply Chain - SCSGS',
  'Sustainable Wine-growing (California) - LODI RULES',
  'Sustainable Wine-growing - LODI RULES',
  'Sustainably Grown Certified - SCSGS',
  'SVLK Certification - SCSGS',
  'Tax-Exempt 501(c)(3) Organization - IRS',
  'Tested for Accuracy Certification - Labdoor, Inc.',
  'Tested for Purity Certification - Labdoor, Inc.',
  'Tested for Sport Certification - Labdoor, Inc.',
  'The Consumer Product Safety Improvement Act (CPSIA) - USCPSC',
  'The Vegan Trademark - The Vegan Society',
  'TRUE Zero Waste Certification - GBCI',
  'TSCA Imported Chemicals Certification - EPA',
  'UL Certification',
  'UMF Honey License - Unique Mānuka Factor Honey Association',
  'Upcycled Food Association Member',
  'U.S. Grade A - National Oceanic and Atmospheric Administration',
  'USP61 Verified - The United States Pharmacopeial Convention',
  'USP62 Verified - The United States Pharmacopeial Convention',
  'US Proven Origin Certificate - Oritain',
  'U TRUST®️ Verification - REPREVE',
  'UTZ - The Rainforest Alliance',
  'Vegan Verified - EarthKosher',
  'Verified by Think Dirty',
  'Verified Natural Beef - WFCF',
  'Veteran or Servicemember Owned Business - WDVA',
  'WasteWise Certificate - USEPA',
  'Water Efficiency and Sanitation Standard (WE-Stand) - IAPMO',
  'WaterSense Label - EPA',
  'WELL v1 Certification - International Well Building Institute',
  'WELL v2 Certification - International Well Building Institute',
  'WERCSmart Registered - UL',
  'Whole30 - Thirty &amp; Co, LLC',
  'Whole Grain 100% Stamp - Oldways Whole Grains Council',
  'Whole Grain 50% Stamp - Oldways Whole Grains Council',
  'Whole Grain Basic Stamp - Oldways Whole Grains Council',
  'Women-Owned Certification - USWCC',
  'Women-Owned Small Business (WOSB)',
  'Women-Owned Small Business (WOSB) - USSBA',
  'Women’s Business Enterprise (WBE) Certification - FL Office of Supplier Diversity',
  'Women’s Business Enterprise (WBE) Certification – WBENC',
  'Women’s Business Enterprise (WBE) Certification - WEConnect International',
];

export const CERTIFICATIONS_SELECT_OPTIONS = [
  ...CERTIFICATION_LIST.map((e, index) => {
    return { id: index, value: e };
  }),
];

export const TAX_OPTIONS = [
  { id: 'HST', percent: 13 },
  { id: 'GST', percent: 5 },
  { id: 'PST', percent: 7 },
  { id: 'QST', percent: 9.9755 },
  { id: 'VAT', percent: 10 },
  { id: 'OTHER', percent: 0, specifiedName: '' },
];

export const SUBSCRIPTION_PLANS = [
  { id: 'basic', cost: { month: 0, year: 0 }, color: 'headingText' },
  {
    id: 'starter',
    cost: { month: 399, year: 3600 },
    costPreDiscount: { month: 1599, year: 18000 },
    costSAR: { month: 1499, year: 16800 },
    costPreDiscountSAR: { month: 5999, year: 67200 },
    costAED: { month: 1499, year: 16800 },
    costPreDiscountAED: { month: 5999, year: 67200 },
    costQAR: { month: 1499, year: 16800 },
    costPreDiscountQAR: { month: 5999, year: 67200 },
    color: 'starter',
    lookupKey: {
      month: 'price_1MnZ2AKhhIGkVNWRfRo9ouy4',
      year: 'price_1MnZ5pKhhIGkVNWREKQPH8Ej',
    },
    hasGold: true,
  },
  {
    id: 'pro',
    cost: { month: 999, year: 10800 },
    costPreDiscount: { month: 3999, year: 46800 },
    costSAR: { month: 3899, year: 45600 },
    costPreDiscountSAR: { month: 15599, year: 182400 },
    costAED: { month: 3899, year: 45600 },
    costPreDiscountAED: { month: 15599, year: 182400 },
    costQAR: { month: 3899, year: 45600 },
    costPreDiscountQAR: { month: 15599, year: 182400 },
    color: 'primary',
    lookupKey: {
      month: 'price_1MnZA6KhhIGkVNWRxrMgWD0s',
      year: 'price_1MnZEfKhhIGkVNWRdLKyWFDM',
    },
    hasGold: true,
    hasStar: true,
  },
  {
    id: 'premium',
    cost: { month: 1999, year: 22800 },
    costPreDiscount: { month: 7999, year: 94800 },
    costSAR: { month: 7999, year: 94800 },
    costPreDiscountSAR: { month: 31999, year: 379200 },
    costAED: { month: 7999, year: 94800 },
    costPreDiscountAED: { month: 31999, year: 379200 },
    costQAR: { month: 7999, year: 94800 },
    costPreDiscountQAR: { month: 31999, year: 379200 },
    color: 'darkerPrimary',
    lookupKey: {
      month: 'price_1MnZIAKhhIGkVNWRl2qHZjpu',
      year: 'price_1MnZLsKhhIGkVNWRSgWavPaL',
    },
    hasGold: true,
    hasStar: true,
  },
].sort((a, b) => a.cost.month - b.cost.month);

export const PAID_SUBSCRIPTIONS_PLANS = SUBSCRIPTION_PLANS.filter(
  ({ cost }) => !!cost.month,
).map(({ id }) => id);

export const SUBSCRIPTION_FEATURES = [
  {
    id: 'onlineStore',
    name: {
      id: 'subscriptions.feature.onlineStoreShort',
      dm: 'onlineStore',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.onlineStore.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'onlineStoreListing',
    name: {
      id: 'subscriptions.feature.onlineStoreListing',
      dm: 'onlineStore',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.onlineStoreListing.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'productListing',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.productListing',
      dm: 'Unlimited Listings',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.productListing.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'unlimitedCustomers',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.unlimitedCustomers',
      dm: 'Unlimited Customers',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.unlimitedCustomers.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'commissionFee',
    name: {
      id: 'subscriptions.feature.commissionFee',
      dm: 'Transaction Fees',
    },
    value: { basic: false, starter: '0%', pro: '0%', premium: '0%' },
    hint: {
      id: 'subscriptions.feature.commissionFee.hint',
      dm:
        'Your social eCommerce store will have no disturbing ads for your potential customers.',
    },
  },
  {
    id: 'fulfillOrders',
    name: {
      id: 'subscriptions.feature.fulfillOrders',
      dm: 'fulfillOrders',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.fulfillOrders.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'customDashboard',
    name: {
      id: 'subscriptions.feature.customDashboard',
      dm: 'Customized Dashboard',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.customDashboard.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'taxCalculations',
    name: {
      id: 'subscriptions.feature.taxCalculations',
      dm: 'Business Profile',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.taxCalculations.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'invoiceManagement',
    name: {
      id: 'subscriptions.feature.invoiceManagement',
      dm: 'Invoice and Transaction Management',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.invoiceManagement.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'processPaymentMada',
    name: {
      id: 'subscriptions.feature.processPaymentMada',
      dm: 'processPayment',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.processPaymentMada.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'processPaymentApple',
    name: {
      id: 'subscriptions.feature.processPaymentApple',
      dm: 'processPayment',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.processPaymentApple.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'processPaymentGoogle',
    name: {
      id: 'subscriptions.feature.processPaymentGoogle',
      dm: 'processPayment',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.processPaymentGoogle.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'processPaymentVisa',
    name: {
      id: 'subscriptions.feature.processPaymentVisa',
      dm: 'processPayment',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.processPaymentVisa.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'securePayment',
    name: {
      id: 'subscriptions.feature.securePayment',
      dm: 'Escrow Service',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.securePayment.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'currencySupport',
    name: {
      id: 'subscriptions.feature.currencySupport',
      dm: 'currencySupport',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.currencySupport.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'contactDetails',
    name: {
      id: 'subscriptions.feature.contactDetails',
      dm: 'Public Contact Details',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.contactDetails.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },

  {
    id: 'supportMultilanguages',
    name: {
      id: 'subscriptions.feature.supportMultilanguages',
      dm: 'supportMultilanguages',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.supportMultilanguages.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'followers',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.followers',
      dm: 'Unlimited Followers',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.followers.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'invitations',
    name: {
      id: 'subscriptions.feature.invitations',
      dm: 'Follower Invitations',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.invitations.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'followerAnalytics',
    name: {
      id: 'subscriptions.feature.followerAnalytics',
      dm: 'Followers Analytics',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.followerAnalytics.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'followerNotifications',
    name: {
      id: 'subscriptions.feature.followerNotification',
      dm: 'Notify Followers',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.followerNotification.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'storeReview',
    name: {
      id: 'subscriptions.feature.storeReview',
      dm: 'Collect Store Reviews',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.storeReview.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'customerReview',
    name: {
      id: 'subscriptions.feature.customerReview',
      dm: 'Collect Customer Reviews',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.customerReview.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'buyerReview',
    name: {
      id: 'subscriptions.feature.buyerReview',
      dm: 'Collect Buyer Reviews',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.buyerReview.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'submitQuotes',
    name: {
      id: 'subscriptions.feature.submitQuotes',
      dm: 'Submit Quotes',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.submitQuotes.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'customerDisputes',
    name: {
      id: 'subscriptions.feature.customerDisputes',
      dm: 'Disputes Management',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.customerDisputes.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'instantNotifications',
    name: {
      id: 'subscriptions.feature.instantNotifications',
      dm: 'Instant Notifications',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.instantNotifications.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'customerChat',
    name: {
      id: 'subscriptions.feature.customerChat',
      dm: 'Chat with Customer',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
  },
  {
    id: 'adFree',
    name: {
      id: 'subscriptions.feature.adFree',
      dm: 'adFree',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.adFree.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'answerRequests',
    name: {
      id: 'subscriptions.feature.answerRequests',
      dm: 'Answer Requests',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.answerRequests.hint',
      dm:
        'You can submit quotes to open request posts from your followers or potential clients on Tradvo.',
    },
  },
  {
    id: 'secureSSL',
    name: {
      id: 'subscriptions.feature.secureSSL',
      dm: 'SSL Secured Page',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
  },
  {
    id: 'seoFriendly',
    name: {
      id: 'subscriptions.feature.seoFriendly',
      dm: 'SEO Friendly Page',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
  },
  {
    id: 'chatThreads',
    name: {
      id: 'subscriptions.feature.chatThreads',
      dm: 'Customer Chat',
    },
    value: { basic: true, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.chatThreads.hint',
      dm:
        'Your contacts, such as email, website, phone, ..etc., will be visible to interested visitors of your store page.',
    },
  },
  {
    id: 'contactDetails',
    showInCards: false,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.contactDetails',
      dm: 'Public Contact Details',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.contactDetails.hint',
      dm:
        'Your contacts, such as email, website, phone, ..etc., will be visible to interested visitors of your store page.',
    },
  },
  {
    id: 'taxCalculations',
    showInCards: false,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.taxCalculations',
      dm: 'Public Contact Details',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.businessProfile.hint',
      dm:
        'Your contacts, such as email, website, phone, ..etc., will be visible to interested visitors of your store page.',
    },
  },
  {
    id: 'onlineStore',
    showInCards: true,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.onlineStore',
      dm: 'Store Webpage',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.onlineStore.hint',
      dm:
        'Your business page will act as an online store, including all your listings, promotions, contact details, and reviews.',
    },
  },
  {
    id: 'pitchVideo',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.pitchVideo',
      dm: 'Pitch Video',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.pitchVideo.hint',
      dm:
        'You can upload a pitch video to your store page. It is highly recommended to market your social eCommerce store in a short and sweet pitch video.',
    },
  },
  {
    id: 'accountBadge',
    name: {
      id: 'subscriptions.feature.accountBadge',
      dm: 'Account Badges',
    },
    value: { basic: false, starter: false, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.accountBadge.hint',
      dm:
        'You can upload a pitch video to your store page. It is highly recommended to market your social eCommerce store in a short and sweet pitch video.',
    },
  },
  {
    id: 'processPayment',
    showInCards: true,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.processPayment',
      dm: 'Process Payments',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.processPayment.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },
  {
    id: 'fulfillOrders',
    showInCards: false,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.fulfillOrders',
      dm: 'Fulfill Orders',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.processPayment2.hint',
      dm:
        'Your customers will pay directly on Tradvo using Credit/Debit cards. You do not need to set up a payment checkout. All payments will be kept in an Escrow wallet and transferred to you on order delivery.',
    },
  },

  {
    id: 'answerRequests',
    showInCards: true,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.answerRequests',
      dm: 'Answer Requests',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.answerRequests.hint',
      dm:
        'You can submit quotes to open request posts from your followers or potential clients on Tradvo.',
    },
  },
  {
    id: 'multipleOrders',
    showInCards: true,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.multipleOrders',
      dm: 'Handle Multiple Orders',
    },
    value: { basic: false, starter: false, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.multipleOrders.hint',
      dm:
        'You can manage multiple threads of RFQs and orders at the same time. Hence, more sales and better customer support.',
    },
  },

  {
    id: 'aiInsights',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.aiInsights',
      dm: 'AI Insights',
    },
    value: { basic: false, starter: false, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.aiInsights.hint',
      dm:
        'You can get AI predictive analytics about the most requested products or categories in the Tradvo market.',
    },
  },
  {
    id: 'exportFollowers',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.exportFollowers',
      dm: 'Export Followers',
    },
    value: { basic: false, starter: false, pro: false, premium: true },
    hint: {
      id: 'subscriptions.feature.exportFollowers.hint',
      dm:
        'Unlike social media, on Tradvo, you can export your store page followers in a standard CSV format that is easy to be imported to other platforms.',
    },
  },
  {
    id: 'exportOrders',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.exportOrders',
      dm: 'Export Orders',
    },
    value: { basic: false, starter: false, pro: false, premium: true },
    hint: {
      id: 'subscriptions.feature.exportOrders.hint',
      dm:
        'Unlike social media, on Tradvo, you can export your store page orders in a standard CSV format that is easy to be imported to other platforms.',
    },
  },
  {
    id: 'openThreads',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.openThreads',
      dm: 'Customers Chat',
    },
    value: {
      basic: false,
      starter: 1,
      pro: 10,
      premium: {
        id: 'subscriptions.feature.value.threads.unlimited',
        dm: 'Unlimited',
      },
    },
    hint: ({ value }) => ({
      id: 'subscriptions.feature.openThreads.hint',
      dm:
        'A thread is opened when you receive a message, RFQ, or submit a quote. Based on this plan quota, you can manage {VALUE} open threads simultaneously.',
      tooltipProps: {
        textProps: { replaceMap: { '{VALUE}': value ? value : 'X' } },
      },
    }),
  },
  {
    id: 'offerPosts',
    showInCards: true,
    cardName: {
      id: 'subscriptions.feature.offerPosts.card',
      dm: 'Post Promotions',
    },
    name: {
      id: 'subscriptions.feature.offerPosts',
      dm: 'Post Promotions',
    },
    value: { basic: 1, starter: 3, pro: 5, premium: 10 },
    hint: ({ value }) => ({
      id: 'subscriptions.feature.offerPosts.hint',
      dm:
        'You can post {VALUE} special offers about your social store. Your page visitors and followers of your store page will be notified. The notifications will include sending bulk emails and on-site highlights.',
      tooltipProps: { textProps: { replaceMap: { '{VALUE}': value } } },
    }),
  },
  {
    id: 'followerEmail',
    showInCards: true,
    name: {
      id: 'subscriptions.feature.followerEmail',
      dm: 'Bulk Emails',
    },
    value: { basic: false, starter: 500, pro: 1000, premium: 2000 },
    hint: ({ value }) => ({
      id: 'subscriptions.feature.followerEmail.hint',
      dm:
        'Unlike social media, {VALUE} followers a month will receive direct notification emails about your recent offer posts and updates.',
      tooltipProps: { textProps: { replaceMap: { '{VALUE}': value } } },
    }),
  },
  {
    id: 'transactionFee',
    name: {
      id: 'subscriptions.feature.transactionFee',
      dm: 'Transaction Fees',
    },
    value: { basic: false, starter: '3.3%', pro: '3.0%', premium: '2.9%' },
    hint: {
      id: 'subscriptions.feature.transactionFee.hint',
      dm:
        'Your social eCommerce store will have no disturbing ads for your potential customers.',
    },
  },
  {
    id: 'adFree',
    showInCards: false,
    hideInTable: true,
    name: {
      id: 'subscriptions.feature.adFree',
      dm: 'Ad Free',
    },
    value: { basic: false, starter: true, pro: true, premium: true },
    hint: {
      id: 'subscriptions.feature.adFree.hint',
      dm:
        'Your social eCommerce store will have no disturbing ads for your potential customers.',
    },
  },
  {
    id: 'supportResponse',
    name: {
      id: 'subscriptions.feature.supportResponse',
      dm: 'Technical Support Response',
    },
    value: {
      basic: {
        id: 'subscriptions.feature.value.supportResponse.regular',
        dm: 'Regular',
      },
      starter: {
        id: 'subscriptions.feature.value.supportResponse.regular',
        dm: 'Regular',
      },
      pro: {
        id: 'subscriptions.feature.value.supportResponse.priority',
        dm: 'Priority',
      },
      premium: {
        id: 'subscriptions.feature.value.supportResponse.priority',
        dm: 'Priority',
      },
    },
  },
];

export const SELLER_PLAN_FAQ = [
  {
    question: {
      id: 'plan.faq.getpayments.question',
      dm: 'Can I cancel my subscription anytime?',
    },
    answer: {
      id: 'plan.faq.getpayments.answer',
      dm:
        'Yes, you cancel your subscription at any time. After the cancelation, You will continue leveraging the paid plan features for the current payment period, but you will not be charged again on the next payment cycle.',
    },
  },
  {
    question: {
      id: 'plan.faq.country.question',
      dm: 'Can I cancel my subscription anytime?',
    },
    answer: {
      id: 'plan.faq.country.answer',
      dm:
        'Yes, you cancel your subscription at any time. After the cancelation, You will continue leveraging the paid plan features for the current payment period, but you will not be charged again on the next payment cycle.',
    },
  },
  {
    question: {
      id: 'plan.faq.whatIsThread.question',
      dm: 'What is an Order thread?',
    },
    answer: {
      id: 'plan.faq.whatIsThread.answer',
      dm:
        'Whenever a customer asks for a quote on your listing, a conversation thread is opened between you as a seller and the potential customer. You can manage the customer inquiry on an open thread starting from the Request for Quotation (RFQ), Customer Chat, and Order Fulfillment.',
    },
  },
  {
    question: {
      id: 'plan.faq.threadLimit.question',
      dm: 'How the number of Order threads is limited?',
    },
    answer: {
      id: 'plan.faq.threadLimit.answer',
      dm:
        'Based on your subscription plan, you can simultaneously respond and interact with a limited number of open RFQ threads. At any time, you will always be able to receive any number of RFQ threads. However, you may need to upgrade your plan limit to interact and respond to these new threads simultaneously.',
    },
  },
  {
    question: {
      id: 'plan.faq.whatIsOffer.question',
      dm: 'What is an Offer post?',
    },
    answer: {
      id: 'plan.faq.whatIsOffer.answer',
      dm:
        'As a seller and probably a business owner, you can post special offers to your business page followers. Tradvo will notify your followers in different ways, including emails.',
    },
  },
  {
    question: {
      id: 'plan.faq.cancel.question',
      dm: 'Can I cancel my subscription anytime?',
    },
    answer: {
      id: 'plan.faq.cancel.answer',
      dm:
        'Yes, you cancel your subscription at any time. After the cancelation, You will continue leveraging the paid plan features for the current payment period, but you will not be charged again on the next payment cycle.',
    },
  },
  {
    question: {
      id: 'plan.faq.paymentOptions.question',
      dm: 'What are my payment options?',
    },
    answer: {
      id: 'plan.faq.paymentOptions.answer',
      dm:
        'You primarily can be billed monthly, but you can save some cost if you pay annually. We currently accept credit card payments through Stripe. We provide the highest grade of payment processing security and do not store any financial details at Tradvo.',
    },
  },
];
